export const paths = {
  app: '/app',
  authenticate: '/authenticate',
  forgotPassword: '/forgot-password',
  logout: '/logout',
  resetPassword: '/reset-password',
  root: '/',
  signin: '/login',
  signup: '/register',
  screening: '/app/screening',
  matching: '/app/matching',
  profile: '/app/profile',
  paymentService: '/app/payment-service',
  uploadRecords: '/app/upload-records',
  navigator: '/app/navigator',
  dashboard: '/app/dashboard',
};

export const appPaths = {
  screening: 'screening',
  matching: 'matching',
  profile: 'profile',
  paymentService: 'payment-service',
  uploadRecords: 'upload-records',
  navigator: 'navigator',
  dashboard: 'dashboard',
};

export const forgotPasswordPaths = {
  checkEmail: 'check-email',
};

export const signupPaths = {
  checkEmail: 'check-email',
  confirmEmail: 'confirm-email',
  emailNotConfirmed: 'not-confirmed',
  parents: 'parents',
  surrogate: 'surrogate',
  navigator: 'navigator',
};

export const matchingPaths = {
  start: 'start',
  profile: 'profile',
  parents: 'parents',
  matches: 'matches',
  favorites: 'favorites',
};

export const navigatorPaths = {
  start: 'start',
  profile: 'profile',
};

export const matchesPaths = {
  profile: ':profileId',
};

export const favoritesPaths = {
  profile: ':profileId',
};

export const screeningPaths = {
  profile: 'profile',
  questions: 'questions',
  medical: 'medical',
  identity: 'identity',
  phone: 'phone',
  background: 'background',
  start: 'start',
  signing: 'signing',
  disqualified: 'disqualified',
};

export const paymentServicePaths = {
  singlePayment: 'payment',
  subscriptionPayment: 'payment/:planId',
  paymentComplete: 'payment/complete',
  edit: 'edit',
};
