import type { FC } from 'react';

import { Menu } from '@headlessui/react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import type { MenuItem, MenuItemsProps } from './MenuItems.interface';

export const MenuItems: FC<MenuItemsProps> = ({ menuItems }) => {
  const menuItemsClassName = (active: boolean) =>
    classNames(
      active ? 'bg-beige-200 text-grey-forest-900' : '',
      'block px-3 py-2 rounded-md text-sm text-grey-forest-700 font-normal',
    );

  return (
    <div className="p-2 pb-3 space-y-1">
      {menuItems?.length
        ? menuItems.map(({ label, href, disabled }: MenuItem) =>
            disabled ? (
              <Menu.Item key={label}>
                <p className="block py-2 px-3 text-sm font-normal text-grey-forest-400">
                  {label}
                </p>
              </Menu.Item>
            ) : (
              <Menu.Item key={label}>
                {({ active }) => (
                  <Link
                    to={href}
                    className={menuItemsClassName(active)}
                    aria-current={'page'}
                  >
                    {label}
                  </Link>
                )}
              </Menu.Item>
            ),
          )
        : null}
    </div>
  );
};
