export const medicalReviewsUids = {
  orgCandidateMedicalRecordPull: 'org-candidate-med-records-pull',
  orgCandidateRxDxHistory: 'org-candidate-data-pull',
  orgCandidateMfmClearance: 'org-candidate-mfm-clearance',
  orgCandidateReviewDecision: 'org-candidate-review-decision',
  donorMedicalRecordPull: 'donor-med-records-pull',
  donorRxDxHistory: 'donor-data-pull',
  donorMfmClearance: 'donor-mfm-clearance',
  donorReviewDecision: 'donor-review-decision',
  orgCandidateClinicRecords: 'org-candidate-clinic-records',
  donorClinicRecords: 'donor-clinic-records',
} as const;

export type MedicalReviewsUid =
  typeof medicalReviewsUids[keyof typeof medicalReviewsUids];
