import { useState, useEffect } from 'react';

export type LocalOrRemoteFile = File | string;

export const useFileReader = (file?: LocalOrRemoteFile) => {
  const [preview, setPreview] = useState<string>();

  useEffect(() => {
    if (!file || typeof file === 'string') return;

    // NOTE: create the preview
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    // NOTE: free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  if (typeof file === 'string') return file;
  return preview;
};
