import type { FC } from 'react';

import { t } from '@nodal/i18n';

import { PlaceInput } from '@uikit/components/PlaceInput';
import { TextInput } from '@uikit/components/TextInput';
import { getFormattedAddress } from '@uikit/utils';

import type { PlaceAndNameInputProps } from './PlaceAndNameInput.interface';

export const PlaceAndNameInput: FC<PlaceAndNameInputProps> = ({
  name,
  label,
  disabled = false,
}) => {
  return (
    <div>
      {label && (
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="flex flex-col gap-6 w-full sm:flex-row sm:gap-2">
        <TextInput
          type="text"
          className="w-full"
          name={`${name}.line1`}
          placeholder={t('Enter the name')}
          disabled={disabled}
        />

        <PlaceInput
          name={name}
          type="address"
          formatter={(place) => ({
            city: place.city,
            state: place.state,
            zipcode: place.zipcode,
            line2: getFormattedAddress(place, ['city', 'state', 'zipcode']),
            location: place.location,
          })}
          placeholder={t('Enter the address and select location')}
          hiddenFieldKeys={['location', 'state', 'city', 'zipcode']}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
