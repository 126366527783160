import type { FC } from 'react';

import { VideoCameraIcon } from '@heroicons/react/outline';
import { VideoPreview } from '@nodal/uikit/components/VideoPreview';

import type { VideoSectionDetailsProps } from './SectionDetails.interface';

export const VideoSectionDetails: FC<VideoSectionDetailsProps> = ({
  videoUrl,
}) => {
  return (
    <div className="flex h-video-mobile sm:h-video-desktop">
      {videoUrl ? (
        <VideoPreview
          className="bg-black"
          width="100%"
          height="100%"
          src={videoUrl}
          controls
        />
      ) : (
        <div className="flex justify-center items-center w-full h-full rounded-lg border border-grey-forest-300">
          <VideoCameraIcon className="w-20 h-20 text-grey-forest-400 bg-transparent rounded-full" />
        </div>
      )}
    </div>
  );
};
