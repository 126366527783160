import { apiEnums, ApiModel } from '@nodal/api';
import { useSignUp } from '@nodal/core/flows/hooks/useSignUp';
import { t } from '@nodal/i18n';
import { signupPaths } from 'consts/paths';
import { FC } from 'react';
import { SignUpScreen } from './SignUpScreen';
import { SignUpScreenConnectedProps } from './SignUpScreen.interface';

const userRoleToTitle = new Map<ApiModel.RegisterRoleEnum, string>([
  [apiEnums.RegisterRoleEnum.Dnr, t('Join Nodal as a Surrogate')],
  [apiEnums.RegisterRoleEnum.Par, t('Join Nodal as an Intended Parent')],
  [apiEnums.RegisterRoleEnum.Nap, t('Join Nodal Navigator')],
]);

const userRoleToDescription = new Map<ApiModel.RegisterRoleEnum, string>([
  [apiEnums.RegisterRoleEnum.Dnr, t('Connecting Future Families Together')],
  [apiEnums.RegisterRoleEnum.Par, t('Connecting Future Families Together')],
  [apiEnums.RegisterRoleEnum.Nap, t('Jumpstart your surrogacy journey')],
]);

export const SignUpScreenConnected: FC<SignUpScreenConnectedProps> = ({
  role,
}) => {
  const { onSubmit, validationSchema, initialValues } = useSignUp({
    redirectPaths: { checkEmail: `../${signupPaths.checkEmail}` },
    role,
  });

  const title = userRoleToTitle.get(role);
  const description = userRoleToDescription.get(role);

  return (
    <SignUpScreen
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      title={title}
      description={description}
    />
  );
};
