import en from './en.json';
export { default as en } from './en.json';

export const t = (key: keyof typeof en, ...params: string[]) => {
  const separator = ' ';
  const inWords = en[key].split(separator);

  let foundIndex = -1;
  const transformWord = (inWord: string) => {
    if (/^{(.*?)}$/.test(inWord)) {
      foundIndex += 1;
      return params[foundIndex] as string;
    }

    return inWord;
  };

  const outWords = inWords.reduce<string[]>(
    (out, inWord) => [...out, transformWord(inWord)],
    [],
  );

  return outWords.join(separator);
};
