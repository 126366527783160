import { getDetailsForSection, getSectionQuestionsByRole } from '../utils';

import type { ProfileData } from '../MatchProfile.interface';
import type { SectionDetailsProps } from './SectionDetails.interface';

export const WordsFromNodalSectionDetails = <T extends ProfileData>({
  profile,
  role,
}: SectionDetailsProps<T>) => {
  const questions = getSectionQuestionsByRole('wordsFromNodal', role);

  return (
    <div className="flex flex-col space-y-10 w-full">
      {getDetailsForSection(profile, questions).map(({ label, value }) => (
        <div
          className="flex flex-col gap-6 justify-start items-start sm:flex-row"
          key={label}
        >
          <div className="space-y-2 w-fit">
            <p className="text-grey-forest-500 whitespace-pre-wrap">
              {value ?? 'A short message about you from Nodal.'}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
