import { t } from '@nodal/i18n';
import { forwardRef } from 'react';

import { HiddenField } from 'components/HiddenField';

import { TextInput } from '@uikit/components/TextInput';

import type { PlaceInputProps } from './PlaceInput.interface';

export const PlaceInput = forwardRef<HTMLInputElement, PlaceInputProps>(
  (
    {
      name,
      label,
      hiddenFieldKeys = ['location', 'line1', 'state', 'city', 'zipcode'],
      placeholder = t('Enter the name and select location'),
      ...props
    },
    inputRef,
  ) => {
    return (
      <div className="relative w-full">
        <TextInput
          label={label}
          name={`${name}.line2`}
          type="text"
          placeholder={placeholder}
          {...props}
          ref={inputRef}
        />
        {hiddenFieldKeys.map((key) => (
          <HiddenField name={`${name}.${key}`} type="hidden" key={key} />
        ))}
      </div>
    );
  },
);

PlaceInput.displayName = 'PlaceInput';
