import { ApiModel, useApiClient } from '@nodal/api';
import type { MarketplaceParents } from '@nodal/api';
import { MatchProfileSkeleton } from '@nodal/core/flows/MatchProfile';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { NoParentsScreen } from './NoParentsScreen';
import { ParentsMarketplaceScreen } from './ParentsMarketplaceScreen';
import { queryKeys } from '@nodal/core/consts/query';

const useParentsMarketplaceScreenData = () => {
  const apiClient = useApiClient();

  const { data: marketplaceParentsList } = useQuery(
    queryKeys.marketplaceParentsList,
    () => apiClient.api.MarketplaceApi.marketplaceParentsList(),
    { cacheTime: 0 },
  );

  const { data: viewedProfiles } = useQuery(
    queryKeys.usersProfileViewsList,
    () =>
      apiClient.api.UsersApi.usersProfileViewsList({
        ordering: '-created',
        limit: 1,
      }),
    { cacheTime: 0 },
  );

  const { results: viewedProfileResults } = viewedProfiles?.data || {};
  const [lastViewedProfile] = viewedProfileResults || [];

  const { data: meUser } = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );

  const lastViewedProfileId = viewedProfileResults
    ? lastViewedProfile?.viewee || marketplaceParentsList?.data[0]?.id
    : undefined;

  return {
    marketplaceParentsList: marketplaceParentsList?.data,
    meUser: meUser?.data,
    lastViewedProfileId,
  };
};

const useMarketplaceNavigation = (
  users: MarketplaceParents[],
  lastViewedProfileId?: number,
) => {
  const [index, setIndex] = useState<undefined | number>();
  const [loadingProfile, setLoadingProfile] = useState(false);

  const apiClient = useApiClient();

  const { mutate: updateViewsList } = useMutation(
    'updateViewsList',
    (requestParameters: ApiModel.UsersApiUsersProfileViewsCreateRequest) =>
      apiClient.api.UsersApi.usersProfileViewsCreate(requestParameters),
  );

  useEffect(() => {
    if (lastViewedProfileId && index === undefined && users?.length) {
      const lastViewedIndex = users.findIndex(
        (item) => item.id === lastViewedProfileId,
      );

      setIndex(lastViewedIndex >= 0 ? lastViewedIndex : 0);
    }
  }, [users, lastViewedProfileId, index]);

  useEffect(() => {
    window.scrollTo(0, 0);

    // NOTE: scroll to the ref element only when the profile index is changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  useEffect(() => {
    const viewedProfileId = users?.find((user, idx) => idx === index)?.id;

    if (viewedProfileId) {
      updateViewsList({
        // NOTE: Bug in Api Schema, viewer don't need to be passed in post data
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        userProfileView: {
          viewee: viewedProfileId,
          created: new Date().toISOString(),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, users?.length]);

  if (index === undefined) {
    return;
  }

  const marketUser = users[index];

  const handleSwitchProfile = async (index: number) => {
    setLoadingProfile(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setIndex(index);
    setLoadingProfile(false);
  };

  const handleShowPrevious = async () =>
    await handleSwitchProfile(index > 0 ? index - 1 : users.length - 1);
  const handleShowNext = async () =>
    await handleSwitchProfile(index < users.length - 1 ? index + 1 : 0);

  return {
    marketUser,
    onShowPrevious: handleShowPrevious,
    onShowNext: handleShowNext,
    loadingProfile,
  };
};

export const ParentsMarketplaceScreenConnected = () => {
  const { marketplaceParentsList, meUser, lastViewedProfileId } =
    useParentsMarketplaceScreenData();

  const marketplaceNavigationData = useMarketplaceNavigation(
    marketplaceParentsList || [],
    lastViewedProfileId,
  );

  if (marketplaceParentsList && !marketplaceParentsList.length) {
    return <NoParentsScreen />;
  }

  if (!marketplaceNavigationData?.marketUser || !meUser || !lastViewedProfileId)
    return <MatchProfileSkeleton inMarketplace />;

  const { marketUser, ...marketplaceNavigation } = marketplaceNavigationData;

  return (
    <ParentsMarketplaceScreen
      marketUser={marketUser}
      meUser={meUser}
      {...marketplaceNavigation}
    />
  );
};
