import { apiEnums } from '@nodal/api';

import type { ApiModel } from '@nodal/api';

export const doesIntroductionRequireAction = (
  {
    initial_parent_accepted,
    match_accepted_parent,
    match_accepted_donor,
    stage,
  }: ApiModel.Introduction,
  myRole: ApiModel.UserRoleEnum,
) => {
  const meUserMatchAccepted =
    myRole === apiEnums.UserRoleEnum.Par
      ? match_accepted_parent
      : match_accepted_donor;

  return (
    (stage === 'intros' && !initial_parent_accepted) ||
    (stage === 'match_acceptance' && !meUserMatchAccepted)
  );
};
