import { FC } from 'react';

import { t } from '@nodal/i18n';

import { TasksContainerProps } from './TasksContainer.interface';
import { taskPrerequisitesMet, taskToDetails } from './utils';
import { Task } from './Task';
import { apiEnums } from '@nodal/api';

export const TasksContainer: FC<TasksContainerProps> = ({
  currentStageTitle,
  tasks,
  handleTaskClick,
  disabled = false,
}) => (
  <div className="pt-8">
    <h2 className="text-2xl font-semibold text-forest-500">{t('Tasks')}</h2>
    <div
      className={`flex flex-col justify-start p-8 mt-4 w-full bg-forest-400 rounded-3xl text-white`}
    >
      <h3 className="text-xl font-semibold">
        {t('Current stage: {currentStage}', currentStageTitle)}
      </h3>
      <p>
        {t(
          'Please make sure to complete each task in order to proceed to the next stage.',
        )}
      </p>
      <div className="flex flex-col gap-4 pt-6">
        {tasks
          .sort((a, b) => (a.order && b.order ? a.order - b.order : 0))
          .map(
            ({
              status,
              id,
              type,
              title,
              content_object,
              prerequisite_uids,
            }) => {
              const arePrerequisitesMet = taskPrerequisitesMet(
                tasks,
                prerequisite_uids,
              );

              const isCompleted = status === apiEnums.TaskStatusEnum.Complete;
              const isDisabled =
                !arePrerequisitesMet || isCompleted || disabled;
              const isDocument = taskToDetails.get(type)!.external;

              return (
                <Task
                  key={id}
                  url={taskToDetails.get(type)!.url}
                  // TODO adjust completion_type after schema update https://linear.app/nodal-health/issue/NOD-897/be-adjust-schema-for-ip-dashboard
                  onClick={() =>
                    handleTaskClick(type, id, content_object, status)
                  }
                  disabled={isDisabled}
                  completed={isCompleted}
                  title={title || ''}
                  completeTime={taskToDetails.get(type)!.completeTime}
                  external={isDocument}
                />
              );
            },
          )}
      </div>
    </div>
  </div>
);
