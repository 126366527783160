import { matchingPaths, paths } from 'consts/paths';
import { useNavigate, useParams } from 'react-router-dom';
import { FavoriteParentsProfileScreen } from './FavoriteParentsProfileScreen';
import { useQuery } from 'react-query';
import { GlobalError, useApiClient } from '@nodal/api';
import { MatchProfileSkeleton } from '@nodal/core/flows/MatchProfile';
import { queryKeys } from '@nodal/core/consts/query';

const useFavoriteParentsProfile = () => {
  const navigate = useNavigate();
  const apiClient = useApiClient();

  const { profileId } = useParams() as { profileId: string };

  const { data: favoriteParent } = useQuery(
    queryKeys.favoriteParentsRetrieve,
    () =>
      apiClient.api.FavoriteParentsApi.favoriteParentsRetrieve({
        likedParent: Number(profileId),
      }),
    {
      cacheTime: 0,
      retry: false,
      onError: (error: GlobalError) => {
        if (error?.request?.status === 404) {
          navigate(`${paths.matching}/${matchingPaths.favorites}`);
        }
      },
    },
  );

  return favoriteParent?.data?.parent_profile;
};

export const FavoriteParentsProfileScreenConnected = () => {
  const profileData = useFavoriteParentsProfile();

  if (!profileData) {
    return (
      <div className="py-6 w-full sm:py-8">
        <MatchProfileSkeleton inMarketplace={false} />
      </div>
    );
  }

  return <FavoriteParentsProfileScreen profileData={profileData} />;
};
