import type { FC } from 'react';

import { ButtonLoading } from '@uikit/components/ButtonLoading';

import type { IdentityCheckProps } from './IdentityCheck.interface';

export const IdentityCheck: FC<IdentityCheckProps> = ({
  disabled,
  onClick,
  children,
}) => {
  return (
    <ButtonLoading
      variant="primary"
      disabled={disabled}
      onClick={onClick}
      className="block justify-center w-max"
    >
      {children}
    </ButtonLoading>
  );
};
