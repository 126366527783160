import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { paths, paymentServicePaths } from 'consts/paths';
import { PaymentFlow } from './PaymentFlow';
import { PaymentComplete } from './PaymentComplete';
import { SinglePaymentServiceProps } from './SinglePaymentService.interface';
import { apiEnums } from '@nodal/api';
import { CompletedPaymentSummary } from './CompletedPaymentSummary';
import { ProcessingPaymentSummary } from './ProcessingPaymentSummary';
import { StartPaymentPreview } from './StartPaymentPreview';

export const SinglePaymentService: FC<SinglePaymentServiceProps> = ({
  status,
}) => (
  <Routes>
    {status === apiEnums.PaymentStatusEnum.Completed && (
      <Route path="/" element={<CompletedPaymentSummary />} />
    )}
    {status === apiEnums.PaymentStatusEnum.Processing && (
      <Route path="/" element={<ProcessingPaymentSummary />} />
    )}
    {(status === apiEnums.PaymentStatusEnum.Initiated ||
      status === apiEnums.PaymentStatusEnum.Failed ||
      status === apiEnums.PaymentStatusEnum.NotInitiated) && (
      <>
        <Route path="/" element={<StartPaymentPreview />} />
        <Route
          path={paymentServicePaths.singlePayment}
          element={<PaymentFlow />}
        />
      </>
    )}
    <Route
      path={paymentServicePaths.paymentComplete}
      element={<PaymentComplete />}
    />
    <Route path="*" element={<Navigate to={paths.paymentService} />} />
  </Routes>
);
