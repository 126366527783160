import { useApiClient } from '@nodal/api';
import { useQuery } from 'react-query';
import { queryKeys } from '@nodal/core/consts/query';
import { SupportTeamCard } from './SupportTeamCard';
import { FC } from 'react';
import { SupportTeamCardConnectedProps } from './SupportTeamCard.interface';
import { createInitials } from '@nodal/core/utils';

export const SupportTeamCardConnected: FC<SupportTeamCardConnectedProps> = ({
  userId,
}) => {
  const apiClient = useApiClient();

  const { data: usersSupportTeamUser } = useQuery(
    queryKeys.usersSupportTeamRetrieve,
    () => apiClient.api.UsersApi.usersSupportTeamRetrieve({ id: userId }),
    { enabled: !!userId },
  );

  const assignedSupportTeamUsers =
    usersSupportTeamUser?.data?.assigned_support_team_members;

  const supportTeamUser =
    !!assignedSupportTeamUsers && assignedSupportTeamUsers.length > 0
      ? assignedSupportTeamUsers[0]
      : undefined;

  if (
    !supportTeamUser ||
    !supportTeamUser.email ||
    !supportTeamUser.first_name ||
    !supportTeamUser.last_name
  ) {
    return null;
  }

  return (
    <SupportTeamCard
      email={supportTeamUser.email}
      userName={`${supportTeamUser.first_name} ${supportTeamUser.last_name}`}
      initials={createInitials(
        supportTeamUser.first_name,
        supportTeamUser.last_name,
      )}
      avatarUrl={supportTeamUser.display_photo_url ?? undefined}
    />
  );
};
