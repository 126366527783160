import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { useStripeIdentity } from '@nodal/uikit/src/components/IdentityCheck';
import { useCallback, useEffect, useState } from 'react';

import type { Data } from './IdentityVerification.interface';
import type { ScreenStep, ScreeningStepAlert } from '@core/flows/Screening';
import type { ApiModel } from '@nodal/api';

const alerts = new Map<
  ApiModel.ScreenStatusEnum,
  ScreeningStepAlert | undefined
>([
  // NOTE:
  // Adding 'undefined' deliberately to make sure we don't show any messages for that state.
  // In ScreenStatusEnum.Proc state, user is allowed to resume stripe verification process,
  // e.g. if it was interrupted before for whatever reason.
  [apiEnums.ScreenStatusEnum.Proc, undefined],
  [
    apiEnums.ScreenStatusEnum.Rev,
    {
      variant: 'warning',
      title: t("We've Hit a Snag"),
      message: t(
        'Thank you for your interest in Nodal, but your application has been flagged to a staff member to review. Please check your email for next steps.',
      ),
    },
  ],
  [
    apiEnums.ScreenStatusEnum.Rej,
    {
      variant: 'error',
      title: t('Eligibility was not successful'),
      message: t(
        `We are sorry. Looks like you're not a good fit for Nodal at this time. You can no longer go to the next step.`,
      ),
    },
  ],
]);

export const useIdentityVerification = ({
  stripeKey,
  submit,
  sessionRetrieve,
  currentStep,
  refresh,
}: {
  stripeKey: string;
  submit: (values: Data) => Promise<ApiModel.Screen | undefined>;
  sessionRetrieve: (
    id: number,
  ) => Promise<ApiModel.StripeIdentityCheckVerificationSession>;
  currentStep?: ScreenStep;
  refresh: () => Promise<void>;
}) => {
  const { verify, result } = useStripeIdentity(stripeKey);
  const [alert, setAlert] = useState<ScreeningStepAlert>();

  const handleSubmit = useCallback(
    async (id: number) => {
      const data = await submit({ id });

      // NOTE: Workaround to the issue when users attempt a second identity verification before we refresh the screen status.
      // This situation occurs when we already get the updated status in response but we haven't refreshed the screen data yet.
      // The issue outlined in the ongoing discussion: https://kellton.slack.com/archives/C02VAT67R2B/p1693066910816429
      // will be addressed in the upcoming PR from the UX perspective: https://linear.app/nodal-health/issue/NOD-529/updates-to-identity-check-process
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (data && data.status !== apiEnums.ScreenStatusEnum.App) {
        // Generate new client secret
        const { client_secret } = await sessionRetrieve(id);
        await verify(client_secret);
      }
    },
    [submit, verify, sessionRetrieve],
  );

  useEffect(() => {
    if (currentStep?.status) {
      const alert = alerts.get(currentStep.status);
      setAlert(alert);
    }
  }, [currentStep?.status]);

  // TODO: https://tivix.slack.com/archives/C02VAT67R2B/p1654172952801009
  useEffect(() => {
    const interval = setInterval(() => {
      // NOTE:
      // After completing the verification, stripe will notify backend
      // about the status through a webhook. Before that happens, we need
      // to poll step (screen) endpoint to observe for expected status change.
      if (
        result &&
        (currentStep?.status === apiEnums.ScreenStatusEnum.Pend ||
          currentStep?.status === apiEnums.ScreenStatusEnum.Proc)
      ) {
        refresh();
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [result, currentStep?.status, refresh]);

  return {
    onSubmit: handleSubmit,
    alert,
  };
};
