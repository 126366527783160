import { apiEnums, ApiModel } from '@nodal/api';
import { t } from '@nodal/i18n';
import { appPaths, matchingPaths, screeningPaths } from 'consts/paths';
import { TaskDetails } from './TasksContainer.interface';

interface StaffCompletedTaskDetails {
  title: string;
  description: string;
}

export const taskToDetails = new Map<string, TaskDetails>([
  [
    'parents-profile',
    {
      url: `../${appPaths.screening}/${screeningPaths.profile}`,
      completeTime: 3,
      external: false,
    },
  ],
  [
    'parents-questionnaire',
    {
      url: `../${appPaths.screening}/${screeningPaths.questions}`,
      completeTime: 15,
      external: false,
    },
  ],
  [
    'stripe-identity',
    {
      url: `../${appPaths.screening}/${screeningPaths.identity}`,
      completeTime: 5,
      external: false,
    },
  ],
  [
    'checkr',
    {
      url: `../${appPaths.screening}/${screeningPaths.background}`,
      completeTime: 2,
      external: false,
    },
  ],
  [
    'ip-match-profile',
    {
      url: `../${appPaths.matching}/${matchingPaths.profile}`,
      completeTime: 25,
      external: false,
    },
  ],
  ['ip-contract', { url: '', completeTime: 5, external: false }],
  [
    'ip-matching-payment',
    { url: `../${appPaths.paymentService}`, completeTime: 5, external: false },
  ],
  [
    'ip-review-runbook',
    {
      url: 'https://docsend.com/view/xuxkdjqa9f8myu6d',
      completeTime: 20,
      external: true,
    },
  ],
  ['ip-support-call', { url: '', completeTime: 5, external: false }],
  [
    'ip-review-call-preparation',
    {
      url: '/assets/HowToPrepareForIntroCall.pdf',
      completeTime: 15,
      external: true,
    },
  ],
  ['ip-final-payment', { url: '', completeTime: 5, external: false }],
  ['ip-match-agreement', { url: '', completeTime: 5, external: false }],
  ['ip-match-sheet', { url: '', completeTime: 5, external: false }],
]);

export const staffCompletedTaskToDetails = new Map<
  string,
  StaffCompletedTaskDetails
>([
  [
    'ip-support-call',
    {
      title: t('Scheduling your call to review the surrogacy guide'),
      description: t(
        'You will receive an email with a link to schedule your call with Carly. Carly is a wonderful resource who happens to be an attorney in the field, and an intended parent via surrogacy. During this call, Carly will be happy to answer any questions you have about the surrogacy journey. Please review "Your Guide to the Surrogacy Journey" prior to the call and come prepared with any questions you may have.',
      ),
    },
  ],
  [
    'ip-final-payment',
    {
      title: t('Submit final payment'),
      description: t(
        'You will receive an email with the invoice for the final payment. Once payment has been submitted, this task will be completed.',
      ),
    },
  ],
]);

export const taskTypeToModalDetails = new Map<
  string,
  {
    title: string;
    description: string;
    inProgressDescription: string;
    confirmationMessage: string;
  }
>([
  [
    'ip-contract',
    {
      title: t('Sign your intended parent contract'),
      description: t(
        'Your contract is ready! Please confirm the information below and we will send the agreement to your email for you to sign.',
      ),
      inProgressDescription: t(
        'Your Nodal contract is waiting for your signature in your inbox. Already signed? Give our system a few minutes to refresh.',
      ),
      confirmationMessage: t(
        'Thanks for confirming your information. Check your email to review your contract.',
      ),
    },
  ],
  [
    'ip-match-agreement',
    {
      title: t('Sign Match Agreement'),
      description: t(
        "You're almost there! The match agreement will be sent to your email address(es) on file, and is ready for you to sign.",
      ),
      inProgressDescription: t(
        'Your match agreement is waiting for your signature in your inbox. Already signed? Give our system a few minutes to refresh.',
      ),
      confirmationMessage: t(
        'The match agreement has been sent. Check your email to review.',
      ),
    },
  ],
  [
    'ip-match-sheet',
    {
      title: t('Fill out your match sheet'),
      description: t(
        "The next step is completing your match sheet. It's a simple form with doctor and clinic information we'll be using as reference for your journey. We can only send it to one email for completion, so please confirm the best email to send it to:",
      ),
      inProgressDescription: t(
        'Your match sheet is waiting for your completion in your inbox. Already completed? Give our system a few minutes to refresh.',
      ),
      confirmationMessage: t(
        'The match sheet has been sent. Check your email to review.',
      ),
    },
  ],
]);

export const taskPrerequisitesMet = (
  tasks: ApiModel.Task[],
  prerequisiteUids?: string[],
) => {
  if (!prerequisiteUids) return false;

  return prerequisiteUids.every(
    (prerequisite) =>
      tasks.find((task) => task.uid === prerequisite)?.status ===
      apiEnums.TaskStatusEnum.Complete,
  );
};
