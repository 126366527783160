import type { ChangeEvent } from 'react';

import { useField } from 'formik';
import { createPipe } from 'imask';
import { useCallback, forwardRef } from 'react';

import { SsnInput } from './SsnInput';

import type { SsnInputConnectedProps } from './SsnInput.interface';

export const SsnInputConnected = forwardRef<
  HTMLInputElement,
  SsnInputConnectedProps
>(({ id, ...props }, inputRef) => {
  const [field, meta, { setValue }] = useField<string>(props);

  const mask = createPipe({
    mask: '****',
  });

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(mask(e.target.value));
    },
    [setValue, mask],
  );

  return (
    <SsnInput
      id={id || props.name}
      error={meta.touched && meta.error ? meta.error : undefined}
      ref={inputRef}
      {...props}
      {...field}
      onChange={handleChange}
    />
  );
});

SsnInputConnected.displayName = 'SsnInputConnected';
