import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import * as Yup from 'yup';

import { convertToFeetAndInches, convertToInches } from 'utils';

import {
  educationChoices,
  ethnicityChoices,
  parentageChoices,
  radioChoices,
} from './questionChoices';

import type {
  DonorQuestionnaireScreen,
  FormQuestion,
} from '@core/flows/Screening/FormQuestions';
import type { ApiModel } from '@nodal/api';

export const questions: FormQuestion<DonorQuestionnaireScreen>[] = [
  {
    value: 'body_measurements',
    label: t('Weight and Height'),
    type: 'bmi',
    default: {
      weight: null,
      height_in: null,
      height_ft: null,
    },
    required: true,
  },
  {
    value: 'ethnicity',
    label: t('What is your ethnicity?'),
    type: 'select',
    default: null,
    choices: ethnicityChoices,
    required: true,
  },
  {
    value: 'ethnicity_other',
    label: t('Please specify other ethnicity'),
    type: 'text',
    default: null,
    required: true,
    hidden: ({ ethnicity = null }) =>
      !ethnicity || ethnicity !== apiEnums.EthnicityEnum.O,
  },
  {
    value: 'native_american',
    label: t(
      'Are you or any member of your family an active member of a Native American tribe?',
    ),
    type: 'radio',
    default: null,
    choices: radioChoices,
    required: true,
  },
  {
    value: 'parentage',
    label: t('What is your parentage?'),
    type: 'select',
    default: null,
    choices: parentageChoices,
    required: true,
  },
  {
    value: 'us_citizen',
    label: t('Are you a US citizen or permanent resident?'),
    type: 'radio',
    default: null,
    choices: radioChoices,
    required: true,
  },
  {
    value: 'highest_education',
    label: t('What is you highest degree of education?'),
    type: 'select',
    default: null,
    choices: educationChoices,
    required: true,
  },
  {
    value: 'is_convicted_of_crime',
    label: t('Have you ever been convicted of a felony?'),
    type: 'radio',
    default: null,
    choices: radioChoices,
    required: true,
  },
  {
    value: 'currently_pregnant',
    label: t('Are you currently pregnant?'),
    type: 'radio',
    default: null,
    choices: radioChoices,
    required: true,
  },
  {
    value: 'currently_breastfeeding',
    label: t('Are you currently breastfeeding?'),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'currently_breastfeeding_wean_date',
    label: t('If so, when do you plan to wean?'),
    type: 'future_date',
    default: null,
    required: true,
    hidden: ({ currently_breastfeeding = false }) =>
      !currently_breastfeeding ||
      currently_breastfeeding?.toString() === 'false',
  },
  {
    value: 'both_ovaries',
    label: t('Do you have both ovaries?'),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'usage_of_grass',
    label: t('Do you currently use marijuana, CBD or herbal supplements?'),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'smoked_last_year',
    label: t('Have you smoked, vaped or used e-cigs in the last year?'),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'heroin_use',
    label: t(
      'Have you used Heroin, Cocaine, Barbiturates or Amphetamines in the past two years?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'alcoholism',
    label: t(
      'Have you or any family members been diagnosed with alcoholism or drug addiction?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'medications_depression',
    label: t(
      'Are you currently taking medications for Depression, Anxiety, ADD/ADHD or Bipolar Disorder?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'history_of_cardiac',
    label: t(
      'Do you have a history of any cardiac disorders including (blood pressure issues, clotting disorders or cardiovascular health problems)?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'history_of_autoimmune',
    label: t(
      'Do you have a history of any autoimmune, clotting or coagulation disorders?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
];

export const schema = Yup.object({
  currently_breastfeeding: Yup.boolean()
    .nullable()
    .required(t('This field is required')),
  currently_breastfeeding_wean_date: Yup.string()
    .nullable()
    .when('currently_breastfeeding', {
      is: true,
      then: Yup.string().nullable().required(t('This field is required')),
    }),
  native_american: Yup.boolean()
    .nullable()
    .required(t('This field is required')),
  us_citizen: Yup.boolean().nullable().required(t('This field is required')),
  body_measurements: Yup.object()
    .nullable()
    .shape({
      weight: Yup.number().nullable().required(t('This field is required')),
      height_ft: Yup.number().nullable().required(t('This field is required')),
      height_in: Yup.number().nullable().required(t('This field is required')),
    }),
  history_of_cardiac: Yup.boolean()
    .nullable()
    .required(t('This field is required')),
  history_of_autoimmune: Yup.boolean()
    .nullable()
    .required(t('This field is required')),
  usage_of_grass: Yup.boolean()
    .nullable()
    .required(t('This field is required')),
  smoked_last_year: Yup.boolean()
    .nullable()
    .required(t('This field is required')),
  ethnicity: Yup.string().nullable().required(t('This field is required')),
  ethnicity_other: Yup.string()
    .nullable()
    .when('ethnicity', {
      is: apiEnums.EthnicityEnum.O,
      then: Yup.string().nullable().required(t('This field is required')),
    }),
  parentage: Yup.string().nullable().required(t('This field is required')),
  highest_education: Yup.string()
    .nullable()
    .required(t('This field is required')),
  is_convicted_of_crime: Yup.boolean()
    .nullable()
    .required(t('This field is required')),
  currently_pregnant: Yup.boolean()
    .nullable()
    .required(t('This field is required')),
  both_ovaries: Yup.boolean().nullable().required(t('This field is required')),
  heroin_use: Yup.boolean().nullable().required(t('This field is required')),
  alcoholism: Yup.boolean().nullable().required(t('This field is required')),
  medications_depression: Yup.boolean()
    .nullable()
    .required(t('This field is required')),
});

export const transformSubmitValues = (
  data: DonorQuestionnaireScreen,
): ApiModel.EggDonorQuestionnaireScreen => {
  const { body_measurements, ...postData } = data || {};

  const { height_in, height_ft, weight } = body_measurements || {};

  return {
    ...postData,
    height: convertToInches(height_ft, height_in),
    weight,
  };
};

export const transformInitialValues = (
  values: ApiModel.EggDonorQuestionnaireScreen,
): DonorQuestionnaireScreen => {
  const { height, weight } = values || {};

  const { feet, inches } = convertToFeetAndInches(height ?? null);

  return {
    ...values,
    body_measurements: {
      height_in: inches,
      height_ft: feet,
      weight,
    },
  };
};
