import { apiEnums, GlobalError, useApiClient } from '@nodal/api';
import { useMatching } from 'components/Matching';
import { MatchProfileSkeleton } from '@nodal/core/flows/MatchProfile';
import { matchingPaths, paths } from 'consts/paths';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { UserProfileScreen } from './UserProfileScreen';
import { queryKeys } from '@nodal/core/consts/query';

const useUserProfileScreen = () => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const { profileId } = useParams() as { profileId: string };

  const { profileData: meUserData } = useMatching();

  const { data: userIntroduction } = useQuery(
    queryKeys.introductionsUserRetrieve,
    () =>
      apiClient.api.IntroductionsApi.introductionsUserRetrieve({
        id: Number.parseInt(profileId),
      }),
    {
      cacheTime: 0,
      retry: false,
      onError: (error: GlobalError) => {
        if (error?.request?.status === 404) {
          navigate(`${paths.matching}/${matchingPaths.matches}`);
        }
      },
    },
  );

  if (!userIntroduction?.data) {
    return undefined;
  }

  const { parent_profile, donor_profile } = userIntroduction?.data;

  const { role: meUserRole } = meUserData || {};

  const userProfileFrom = {
    par: {
      profile_photos: donor_profile?.user.profile_photos,
      role: apiEnums.UserRoleEnum.Dnr,
      profile: donor_profile,
      id: donor_profile.user.id,
      introduction: userIntroduction?.data,
    },
    dnr: {
      profile_photos: parent_profile?.user.profile_photos,
      role: apiEnums.UserRoleEnum.Par,
      profile: parent_profile,
      id: parent_profile.user.id,
      introduction: userIntroduction?.data,
    },
  };

  return meUserRole ? userProfileFrom[meUserRole] : undefined;
};

export const UserProfileScreenConnected: FC = () => {
  const userProfile = useUserProfileScreen();

  if (!userProfile) {
    return (
      <div className="py-6 w-full sm:py-8">
        <MatchProfileSkeleton inMarketplace={false} />
      </div>
    );
  }

  return <UserProfileScreen userProfile={userProfile} />;
};
