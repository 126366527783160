import { FC, useCallback, useEffect, useState } from 'react';
import { ApiModel, useApiClient, apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { useScreening } from '@nodal/core/flows/Screening';
import type { ScreeningStepAlert } from '@nodal/core/flows/Screening';
import type { CalendlyEventScheduledEvent } from '@nodal/uikit/components/CalendlyWidget';
import { PhoneScreen } from './PhoneScreen';
import { FormValues } from './PhoneScreen.interface';
import { useQuery } from 'react-query';
import { queryKeys } from '@nodal/core/consts/query';

const title = t('Phone Screen');
const calendlyUrl = import.meta.env.VITE_CALENDLY_CALENDAR_LINK;

const alerts = new Map<ApiModel.ScreenStatusEnum, ScreeningStepAlert>([
  [
    apiEnums.ScreenStatusEnum.Rej,
    {
      variant: 'error',
      title: t('Eligibility was not successful'),
      message: t(
        `We are sorry. Looks like you're not a good fit for Nodal at this time. You can no longer go to the next step.`,
      ),
    },
  ],
]);

export const PhoneScreenConnected: FC = () => {
  const { submit, currentStep } = useScreening<FormValues>();

  const apiClient = useApiClient();

  const { data: userData } = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );

  const prefill = {
    email: userData?.data?.email || '',
    name: `${userData?.data?.profile?.first_name || ''} ${
      userData?.data?.profile?.last_name || ''
    }`,
  };

  const [alert, setAlert] = useState<ScreeningStepAlert>();

  //TODO: Add default description if will be defined
  const [description, setDescription] = useState<string>('');
  const [scheduled, setScheduled] = useState<boolean>(false);

  const handleSubmit = useCallback(
    (id: number, event: CalendlyEventScheduledEvent) => {
      submit({
        id,
        event_uri: event.data.payload.event.uri,
        invitee_uri: event.data.payload.invitee.uri,
      });
    },
    [submit],
  );

  useEffect(() => {
    if (currentStep?.status) {
      const alert = alerts.get(currentStep.status);
      setAlert(alert);

      if (
        currentStep.status === apiEnums.ScreenStatusEnum.Rev ||
        currentStep.status === apiEnums.ScreenStatusEnum.Proc
      ) {
        setDescription(
          t(
            "You've succesfully scheduled a phone call with Nodal coordinators. The invitation has been sent to your email address. After completing Phone Screen you can continue to the next step.",
          ),
        );

        setScheduled(true);
      }
    }
  }, [currentStep?.status]);

  if (currentStep === undefined || !currentStep.status) {
    return null;
  }

  return (
    <PhoneScreen
      title={title}
      description={description}
      id={currentStep.id}
      calendlyUrl={calendlyUrl}
      alert={alert}
      onSubmit={handleSubmit}
      prefill={prefill}
      scheduled={scheduled}
    />
  );
};
