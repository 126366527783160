import { UploadedFileList } from '@nodal/uikit/components/UploadedFileList';
import { FileDropZone } from '@nodal/uikit/components/FileDropZone';
import { t } from '@nodal/i18n';
import { UploadRecordsProps } from './UploadRecords.interface';
import { FC } from 'react';

export const UploadRecords: FC<UploadRecordsProps> = ({
  files,
  disabled,
  onUpload,
  onDelete,
}) => {
  return (
    <div className="flex gap-4 justify-center py-6 px-4 mx-auto w-full max-w-5xl sm:py-8">
      <div className="w-full lg:w-step-view">
        <div className="p-8 bg-white rounded-lg">
          <h3 className="text-xl font-semibold leading-7 text-gray-900">
            {t('Upload Medical Records')}
          </h3>
          <div className="mt-6 rounded-lg border border-grey-forest-100">
            <FileDropZone
              onUpload={onUpload}
              disabled={disabled}
              acceptFileTypes="image/*, .pdf, .doc, .docx"
            />

            <UploadedFileList
              files={files}
              onDelete={onDelete}
              uploaderIconVisible={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
