import { t } from '@nodal/i18n';
import * as Yup from 'yup';

import {
  idealStartChoices,
  parentsDonorUsageChoices,
  parentsEmbryoRange,
  parentsEmbryoTest,
} from '@core/flows/MatchProfile/profileInformationOptions';

import { radioChoices } from './questionChoices';

import type { FormQuestion } from './FormQuestions.interface';
import type { ApiModel } from '@nodal/api';

export const questions: FormQuestion<ApiModel.ParentsQuestionnaireScreen>[] = [
  {
    value: 'is_convicted_of_crime',
    label: t('Have you ever been convicted of a felony?'),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'has_been_visited_by_social_service',
    label: t('Has social services ever had to visit your home?'),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'has_completed_psych_eval',
    label: t(
      'Have you already undergone a psychological evaluation as required by your fertility clinic or state law?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'prepared_to_fund_escrow',
    label: t(
      'Are you prepared to fund escrow (base compensation plus 10k) before embryo transfer?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'ideal_start',
    label: t('When is your ideal IVF transfer date?'),
    type: 'select',
    choices: idealStartChoices,
    default: null,
    required: true,
  },
  {
    value: 'using_donor',
    label: t('Are you using a donor (either egg of sperm)?'),
    type: 'select',
    choices: parentsDonorUsageChoices,
    default: null,
    required: true,
  },
  {
    value: 'frozen_embryo_count',
    label: t('How many embryos do you have frozen?'),
    type: 'select',
    choices: parentsEmbryoRange,
    default: null,
    required: true,
  },
  {
    value: 'embryos_pgs_tested',
    label: t('Are the embryos PGS tested?'),
    type: 'select',
    choices: parentsEmbryoTest,
    default: null,
    required: true,
  },
  {
    value: 'twibling_journey',
    label: t(
      'Are you planning or hoping to do a twibling journey (ie. two journeys at the same time)?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'partner_is_convicted_of_crime',
    label: t('(Partner) Have you ever been convicted of a felony?'),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'partner_has_been_visited_by_social_service',
    label: t(
      `(Partner) Has social services ever had to visit your partner's home?`,
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'partner_has_completed_psych_eval',
    label: t(
      '(Partner) Has your partner already undergone a psychological evaluation as required by your fertility clinic or state law?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
];

export const getParentSchema = (inRelationship: boolean) =>
  Yup.object({
    is_convicted_of_crime: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    has_been_visited_by_social_service: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    prepared_to_fund_escrow: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    has_completed_psych_eval: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    ideal_start: Yup.string().nullable().required(t('This field is required')),
    using_donor: Yup.string().nullable().required(t('This field is required')),
    frozen_embryo_count: Yup.string()
      .nullable()
      .required(t('This field is required')),
    embryos_pgs_tested: Yup.string()
      .nullable()
      .required(t('This field is required')),
    twibling_journey: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    partner_is_convicted_of_crime: inRelationship
      ? Yup.boolean().nullable().required(t('This field is required'))
      : Yup.boolean().nullable(),
    partner_has_been_visited_by_social_service: inRelationship
      ? Yup.boolean().nullable().required(t('This field is required'))
      : Yup.boolean().nullable(),
    partner_has_completed_psych_eval: inRelationship
      ? Yup.boolean().nullable().required(t('This field is required'))
      : Yup.boolean().nullable(),
  });
