import { useEffect, useRef } from 'react';

export const usePortal = (id: string) => {
  const elRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const portalRoot = document.getElementById(id);
    portalRoot?.appendChild(elRef.current!);
    return () => {
      portalRoot?.removeChild(elRef.current!);
    };
  }, [id]);

  const getRootEl = () => {
    if (!elRef.current) {
      elRef.current = document.createElement('div');
    }
    return elRef.current;
  };

  return getRootEl();
};
