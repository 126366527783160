import type { FC } from 'react';

import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { FormErrors } from '@nodal/uikit/components/FormErrors';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import type { SignInFormProps } from './SignInForm.interface';

export const SignInForm: FC<SignInFormProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  redirectPaths,
}) => {
  const [passwordShown, setPasswordShown] = useState<boolean>(false);

  const handleChangePasswordShown = useCallback(
    () => setPasswordShown(!passwordShown),
    [passwordShown],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="space-y-8">
          <FormErrors />

          <TextInput
            label={t('Email')}
            name="email"
            autoComplete="email"
            required
            type="text"
          />
          <div className="relative">
            <TextInput
              label={t('Password')}
              name="password"
              autoComplete="password"
              required
              type={passwordShown ? 'text' : 'password'}
            />
            <button
              type="button"
              onClick={handleChangePasswordShown}
              className="absolute right-3 bottom-2 mb-0.25"
              aria-label="password icon"
            >
              {passwordShown ? (
                <EyeOffIcon className="w-5 h-5 text-grey-forest-400" />
              ) : (
                <EyeIcon className="w-5 h-5 text-grey-forest-400" />
              )}
            </button>
          </div>
          <div className="space-y-6 text-center">
            <Link
              to={redirectPaths.forgotPassword}
              className="text-base font-medium leading-6 text-center text-forest-500 hover:text-forest-600 sm:font-semibold"
            >
              {t('Forgot your password?')}
            </Link>
            <ButtonLoading
              variant="primary"
              type="submit"
              className="justify-center w-full"
              loading={isSubmitting}
            >
              {t('Sign in')}
            </ButtonLoading>
          </div>
        </Form>
      )}
    </Formik>
  );
};
