import type { FC } from 'react';

import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { FormErrors } from '@nodal/uikit/components/FormErrors';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import * as Yup from 'yup';

import type {
  NewPasswordFormValues,
  PasswordsShown,
} from './ResetPasswordForm.interface';
import type { FormikConfig } from 'formik';

const validationSchema: Yup.SchemaOf<NewPasswordFormValues> =
  Yup.object().shape({
    new_password1: Yup.string().required(t('Required field')),
    new_password2: Yup.string().required(t('Required field')),
    non_field_errors: Yup.string().optional(),
  });

export const ResetPasswordForm: FC<FormikConfig<NewPasswordFormValues>> = ({
  initialValues,
  onSubmit,
}) => {
  const [passwordsShown, setPasswordsShown] = useState<PasswordsShown>({
    password1: false,
    password2: false,
  });

  const handleChangePasswordsShown = useCallback(
    (key: keyof PasswordsShown) =>
      setPasswordsShown({ ...passwordsShown, [key]: !passwordsShown[key] }),
    [passwordsShown],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="space-y-8">
          <FormErrors />
          <div className="relative">
            <TextInput
              label={t('New password')}
              name="new_password1"
              required
              type={passwordsShown?.password1 ? 'text' : 'password'}
            />
            <button
              type="button"
              onClick={() => handleChangePasswordsShown('password1')}
              className="absolute right-3 bottom-2 mb-0.25"
            >
              {passwordsShown?.password1 ? (
                <EyeOffIcon className="w-5 h-5 text-grey-forest-400" />
              ) : (
                <EyeIcon className="w-5 h-5 text-grey-forest-400" />
              )}
            </button>
          </div>
          <div className="relative">
            <TextInput
              label={t('Confirm new password')}
              name="new_password2"
              required
              type={passwordsShown?.password2 ? 'text' : 'password'}
            />
            <button
              type="button"
              onClick={() => handleChangePasswordsShown('password2')}
              className="absolute right-3 bottom-2 mb-0.25"
            >
              {passwordsShown?.password2 ? (
                <EyeOffIcon className="w-5 h-5 text-grey-forest-400" />
              ) : (
                <EyeIcon className="w-5 h-5 text-grey-forest-400" />
              )}
            </button>
          </div>
          <ButtonLoading
            variant="primary"
            type="submit"
            className="justify-center w-full"
            loading={isSubmitting}
          >
            {t('Reset password')}
          </ButtonLoading>
        </Form>
      )}
    </Formik>
  );
};
