import { useQuery } from 'react-query';
import { PaymentFlow } from './PaymentFlow';
import { useApiClient } from '@nodal/api';
import { useParams } from 'react-router-dom';
import { CreditCardIcon, LibraryIcon } from '@heroicons/react/outline';
import { BillingPaymentIntent } from './PaymentFlow.interface';
import { t } from '@nodal/i18n';
import { PaymentMethodDetails } from './PaymentMethodSelect';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { queryKeys } from '@nodal/core/consts/query';

const useClientSecrets = (planId?: number) => {
  const apiClient = useApiClient();

  const { data } = useQuery(
    queryKeys.billingStripeSubscriptionRetrieve,
    () =>
      apiClient.api.BillingApi.billingStripeSubscriptionRetrieve({
        id: planId || 0,
      }),
    { enabled: !!planId },
  );

  // NOTE: Using cast and ts-ignore due to broken API types,
  // expected type is BillingPaymentIntent.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const paymentIntent = data?.data as BillingPaymentIntent;

  return {
    card: paymentIntent?.card.client_secret,
    us_bank_account: paymentIntent?.us_bank_account.client_secret,
  };
};

const usePayment = () => {
  const apiClient = useApiClient();
  const { planId } = useParams() as { planId: string };

  const { data: plans } = useQuery(queryKeys.billingPlansList, () =>
    apiClient.api.BillingApi.billingPlansList(),
  );

  const plan = plans?.data?.find((p) => p.id === Number.parseInt(planId));

  const clientSecrets = useClientSecrets(plan?.id) || undefined;

  const paymentMethods: PaymentMethodDetails[] = [
    {
      method: 'card',
      label: t('Card'),
      icon: CreditCardIcon,
      secret: clientSecrets.card,
      fees: plan?.price.card_payment_fees || undefined,
    },
    {
      method: 'us_bank_account',
      label: t('US Bank Account'),
      icon: LibraryIcon,
      secret: clientSecrets.us_bank_account,
      fees: plan?.price.bank_transfer_fees || undefined,
    },
  ];

  return {
    plan,
    paymentMethods,
  };
};

export const PaymentFlowConnected = () => {
  const { plan, ...payment } = usePayment();

  if (!plan || !payment.paymentMethods) {
    return <LoadingScreen />;
  }

  return <PaymentFlow plan={plan} {...payment} />;
};
