import React, { FC } from 'react';
import { useSinglePaymentService } from '../SinglePaymentService.connect';
import { ProcessingPaymentSummary } from './ProcessingPaymentSummary';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';

export const ProcessingPaymentSummaryConnected: FC = () => {
  const { paymentModel, paymentPackage } = useSinglePaymentService();

  if (!paymentModel) return <LoadingScreen />;

  const { price, payment_type } = paymentModel || {};

  return (
    <ProcessingPaymentSummary
      amount={price.amount}
      paymentType={payment_type}
      paymentPackage={paymentPackage}
    />
  );
};
