import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { ImgWithFallback } from '@nodal/uikit/components/ImgWithFallback';

import { NodalLogoForest } from '@core/assets';

import type { InitialScreensDecoratorProps } from './InitialScreensDecorator.interface';

export const InitialScreensDecorator: FC<InitialScreensDecoratorProps> = ({
  children,
  image,
}) => (
  <div className="flex gap-8 justify-center items-center min-h-screen bg-beige-50">
    <div className="flex relative flex-col justify-between items-center mx-4 w-full min-h-screen lg:w-7/12">
      <div className="m-4 w-full max-w-md sm:p-0 sm:mx-auto sm:mt-10">
        <img
          className="w-[80px] h-8 sm:w-[100px] sm:h-10"
          src={NodalLogoForest}
          alt={t('Nodal Logo Forest')}
        />
      </div>
      <div className="m-auto space-y-8 w-full max-w-md">{children}</div>
    </div>
    <div className="hidden h-screen lg:block lg:w-5/12">
      <ImgWithFallback
        source={{ src: image.src }}
        fallback={{
          src: image.fallbackSrc,
          alt: image.alt,
          className: `object-cover w-full h-full opacity-50 ${
            image.position || 'object-center'
          }`,
        }}
      />
    </div>
  </div>
);
