import { PencilIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { forwardRef } from 'react';

import type { ProfileCardProps } from './ProfileCard.interface';

export const ProfileCard = forwardRef<HTMLDivElement, ProfileCardProps>(
  ({ title, subTitle, edit, children, className = '' }, ref) => (
    <div
      className={`flex flex-col col-span-12 col-start-2 gap-6 justify-start p-4 sm:p-8 w-full bg-white rounded-lg ${className}`}
      ref={ref}
    >
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-semibold leading-7 text-grey-forest-900">
          {title}
        </h3>
        {edit && (
          <button
            onClick={() => edit(title, subTitle)}
            type="button"
            className="flex gap-2 justify-between items-center px-3"
          >
            <PencilIcon className="w-5 h-5 text-forest-400 sm:w-4 sm:h-4" />
            <p className="hidden text-sm font-medium leading-4 text-forest-500 sm:block">
              {t('Edit')}
            </p>
          </button>
        )}
      </div>
      {children}
    </div>
  ),
);

ProfileCard.displayName = 'ProfileCard';
