import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n';
import { ToastNotification } from '@nodal/uikit/components/Toast';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CheckEmailScreen } from './CheckEmailScreen';

import type {
  CheckEmailScreenConnectedProps,
  EmailValue,
} from './CheckEmailScreen.interface';
import type { ApiModel, GlobalError } from '@nodal/api';

export const CheckEmailScreenConnected: FC<CheckEmailScreenConnectedProps> = ({
  redirectPaths,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation() as { state: EmailValue };

  const { email = '' } = state || {};

  useEffect(() => {
    if (!email) {
      navigate('../');
    }
  }, [email, navigate]);

  const apiClient = useApiClient();

  const resetPassword = useMutation(
    (data: ApiModel.AuthApiAuthPasswordResetCreateRequest) =>
      apiClient.api.AuthApi.authPasswordResetCreate(data),
  );

  const handleResend = () => {
    resetPassword.mutate(
      { passwordReset: { email } },
      {
        onSuccess: () => {
          toast.success(
            <ToastNotification
              title={t('E-mail resent!')}
              message={t('Check your e-mail and follow instructions.')}
            />,
          );
        },

        onError: (e: GlobalError) => {
          if (e?.response?.data?.email) {
            toast.error(t('Something went wrong...'));
          }
        },
      },
    );
  };

  return (
    <CheckEmailScreen
      onResend={handleResend}
      email={email}
      redirectPaths={{ signin: redirectPaths.signin }}
    />
  );
};
