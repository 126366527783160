import type { FC } from 'react';

import classNames from 'classnames';

import { BmiInput } from '@uikit/components/BmiInput';
import {
  DateInput,
  PastDateInput,
  FutureDateInput,
} from '@uikit/components/DateInput';
import { NameInput } from '@uikit/components/NameInput/NameInput';
import { NumberInput } from '@uikit/components/NumberInput';
import { PlaceAndNameInput, PlaceInput } from '@uikit/components/PlaceInput';
import { RadioGroup } from '@uikit/components/RadioGroup';
import { Select } from '@uikit/components/Select';
import { Textarea } from '@uikit/components/Textarea';
import { TextInput } from '@uikit/components/TextInput';
import { VideoInput } from '@uikit/components/VideoInput';

import type { SurveyCardProps } from './SurveyCard.interface';

export const SurveyCard: FC<SurveyCardProps> = ({
  value,
  label,
  choices,
  type,
  description,
  error,
  className,
  disabled,
  placeholder,
  optionsPosition,
}) => {
  const renderFormField = () => {
    if (!value) return;

    switch (type) {
      case 'text':
        return <TextInput type="text" name={value} disabled={disabled} />;
      case 'textarea':
        return (
          <Textarea
            name={value}
            style={{ minHeight: '160px' }}
            placeholder={placeholder}
          />
        );
      case 'select':
        return (
          choices && (
            <Select
              options={choices}
              name={value}
              optionsPosition={optionsPosition}
            />
          )
        );
      case 'select_multiple':
        return (
          choices && (
            <Select
              options={choices}
              name={value}
              optionsPosition={optionsPosition}
              multiple
            />
          )
        );
      case 'radio':
        return choices && <RadioGroup options={choices} name={value} />;
      case 'radio_range':
        return (
          choices && <RadioGroup options={choices} name={value} type="range" />
        );
      case 'number':
        return <NumberInput name={value} />;
      case 'date':
        return <DateInput name={value} placeholder="YYYY-DD-MM" />;
      case 'past_date':
        return <PastDateInput name={value} placeholder="YYYY-DD-MM" />;
      case 'delivery_past_date':
        return (
          <PastDateInput
            name={value}
            placeholder="YYYY-DD-MM"
            min="1990-01-01"
            openToDate={new Date('1990-01-01')}
          />
        );
      case 'future_date':
        return <FutureDateInput name={value} placeholder="YYYY-DD-MM" />;
      case 'bmi':
        return <BmiInput name={value} />;
      case 'place':
        return (
          <>
            {value === 'airport_address' && (
              <PlaceInput name={value} type="airport" />
            )}
            {value === 'hospital_address' && (
              <PlaceInput name={value} type="hospital" />
            )}
            {value === 'clinic_address' && <PlaceAndNameInput name={value} />}
          </>
        );
      case 'name':
        return <NameInput name={value} />;
      case 'video':
        return <VideoInput name={value} />;
      default:
        return null;
    }
  };

  const formClassName = classNames(
    'p-8 pb-10 w-full text-left bg-white rounded-lg border border-grey-forest-200 hover:border-sage-500 ',
    error ? 'border-red-500 hover:border-red-500 hover:border' : '',
    className,
  );

  return (
    <div className={formClassName}>
      {(label || description) && (
        <div className="pb-4">
          {label && (
            <h3 className="text-xl font-medium leading-7 text-grey-forest-700 sm:text-lg sm:leading-8">
              {label}
            </h3>
          )}
          {description && (
            <p className="pt-2 text-base font-normal leading-6 text-grey-forest-600 sm:text-sm sm:leading-5">
              {description}
            </p>
          )}
        </div>
      )}
      {renderFormField()}
    </div>
  );
};
