import type { FC } from 'react';

import classNames from 'classnames';

import type { ProgressBarProps } from './ProgressBar.interface';

export const ProgressBar: FC<ProgressBarProps> = ({
  progress,
  variant = 'default',
}) => {
  const normalizedProgress = Math.max(Math.min(progress ?? 0, 1), 0);

  return (
    <div
      className={classNames(
        'w-full relative',
        progress ? 'bg-grey-forest-200' : 'transparent',
        variant === 'lg' ? 'h-3 rounded-md' : 'h-1',
      )}
    >
      <div
        className={classNames(
          'absolute w-full transition-all',

          variant === 'lg'
            ? 'h-3 rounded-md bg-forest-300'
            : 'h-1 bg-forest-200',
        )}
        style={{ width: `${normalizedProgress * 100}%` }}
      />
    </div>
  );
};
