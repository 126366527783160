import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { Modal } from '@nodal/uikit/components/Modal';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import type { ImageCropperModalProps } from './ImageCropperModal.interface';

export const ImageCropperModal: FC<ImageCropperModalProps> = ({
  onClose,
  imageDataUrl,
  open,
  onSave,
  variant = 'rectangle',
}) => {
  const [cropper, setCropper] = useState<Cropper>();
  const [ready, setReady] = useState<boolean>(false);

  const onInitializeCropper = (instance: Cropper) => {
    setCropper(instance);
  };

  const handleSave = useCallback(async () => {
    if (typeof cropper !== 'undefined' && imageDataUrl) {
      const url = cropper
        .getCroppedCanvas({
          maxWidth: 4096,
          maxHeight: 4096,
        })
        .toDataURL();

      await onSave(url);
      onClose();
    }
  }, [cropper, imageDataUrl, onSave, onClose]);

  useEffect(() => {
    // NOTE: Clear the state after closing the modal
    if (!open) {
      setReady(false);
      setCropper(undefined);
    }

    return () => {
      cropper?.destroy();
    };
  }, [open, cropper]);

  return (
    <Modal title={t('Edit Photo')} open={open} onClose={onClose}>
      <div className="p-4 h-96 sm:min-h-85">
        <Cropper
          style={{ height: '100%', width: '100%' }}
          initialAspectRatio={1}
          aspectRatio={1}
          src={imageDataUrl}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          autoCropArea={1}
          background={false}
          checkOrientation={false}
          checkCrossOrigin
          crossOrigin="anonymous"
          guides
          onInitialized={onInitializeCropper}
          ready={() => setReady(true)}
          className={classNames({
            'cropper-rounded': variant === 'rounded',
            'cropper-rectangle': variant === 'rectangle',
          })}
        />
      </div>
      <div className="py-3 px-8 ml-auto text-right border-t border-grey-forest-200">
        <ButtonLoading
          variant="primary"
          onClick={handleSave}
          className="justify-center ml-auto w-full sm:w-max"
          disabled={!ready}
        >
          {t('Save')}
        </ButtonLoading>
      </div>
    </Modal>
  );
};
