import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ConfirmEmailScreen } from './ConfirmEmailScreen';

import type {
  Confirmation,
  ConfirmEmailScreenConnectedProps,
} from './ConfirmEmailScreen.interface';
import type { ApiModel, GlobalError } from '@nodal/api';

export const ConfirmEmailScreenConnected: FC<
  ConfirmEmailScreenConnectedProps
> = ({ redirectPaths }) => {
  const navigate = useNavigate();
  const [confirmation, setConfirmation] = useState<Confirmation>();

  const { search } = useLocation() as {
    search: string;
  };

  const urlSearchParams = new URLSearchParams(search);
  const key = urlSearchParams.get('key');
  const email = urlSearchParams.get('email');

  useEffect(() => {
    if (!key || !email) {
      navigate('../');
    }
  }, [email, key, navigate, redirectPaths]);

  const apiClient = useApiClient();

  const confirm = useMutation(
    (payload: ApiModel.RegistrationApiRegistrationVerifyEmailCreateRequest) =>
      apiClient.api.RegistrationApi.registrationVerifyEmailCreate(payload),
  );

  const setConfirmationForExpiredOrInvalidLink = () => {
    setConfirmation({
      status: 'error',
      title: t('The confirmation link has expired or is invalid'),
      message: t(
        'Please click the button below to get a new confirmation link and finalize the sign up process.',
      ),
    });
  };

  const handleConfirm = async () => {
    !key
      ? setConfirmationForExpiredOrInvalidLink()
      : await confirm.mutateAsync(
          { verifyEmail: { key } },
          {
            onSuccess: () => {
              setConfirmation({
                status: 'success',
                title: t('E-mail confirmed'),
                message: t(
                  'Your account has been successfully created. You can now log in to your account.',
                ),
              });
            },

            onError: (error: GlobalError) => {
              if (error?.request?.status === 404) {
                setConfirmationForExpiredOrInvalidLink();
              } else {
                // TODO: Temp.workaround with error response, since we don't have information about error type
                toast.error(t('Something went wrong...'));
              }
            },
          },
        );
  };

  const { signin, checkEmail } = redirectPaths;

  return (
    <ConfirmEmailScreen
      onConfirm={handleConfirm}
      email={email}
      confirmation={confirmation}
      redirectPaths={{ signin, checkEmail }}
    />
  );
};
