import { useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n/src';
import isNumber from 'lodash/isNumber';
import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryKeys } from 'consts/query';

import {
  initialValues,
  ivfTransferInitialValues,
  pregnancyDetailInitialValues,
} from './initialValues';
import { schema } from './validation';

import type { MedicalRecordReviewScreen } from './MedicalRecordReview.interface';
import type { ApiModel } from '@nodal/api';

export const useMedicalRecordReview = ({
  submit,
  screenId,
}: {
  submit: (
    values: ApiModel.MedicalRecordReviewScreen,
  ) => Promise<ApiModel.Screen | undefined>;
  screenId?: number;
}) => {
  const apiClient = useApiClient();

  const { data: screensList } = useQuery(queryKeys.screensList, () =>
    apiClient.api.ScreensApi.screensList(),
  );

  const uploadInsurancePhotos = useMutation(
    (request: ApiModel.ScreensApiScreensInsuranceDocumentUpdateRequest) =>
      apiClient.api.ScreensApi.screensInsuranceDocumentUpdate(request, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    {
      onError: () => {
        toast.error(t('Failed to upload insurance photos'));
      },
    },
  );

  const donorQuestionnaire = screensList?.data?.find(
    ({ type }) => type === 'donor-questionnaire',
  );

  const { previous_pregnancy_count, previous_ivf_transfer_count } =
    (donorQuestionnaire?.content_object as ApiModel.DonorQuestionnaireScreen) ||
    {};

  const onSubmit = async (values: MedicalRecordReviewScreen) => {
    if (!screenId) {
      throw new Error('Screening step id is undefined');
    }

    const { no_insurance, ...formValues } = values || {};

    const submitData = {
      ...formValues,
      pregnancy_details: values.pregnancy_details.map((detail) =>
        detail.same_doctor_details_as_first
          ? {
              ...values.pregnancy_details[0],
              delivery_date: detail.delivery_date,
            }
          : detail,
      ),
      ivf_transfers_details: values.ivf_transfers_details.map((detail) =>
        detail.same_doctor_details_as_first
          ? {
              ...values.ivf_transfers_details[0],
              transfer_date: detail.transfer_date,
            }
          : detail,
      ),
      health_insurance: no_insurance ? null : values.health_insurance,
    };

    if (!no_insurance) {
      await uploadInsurancePhotos.mutateAsync({
        id: screenId,
        medicalRecordReviewScreenDocuments: {
          insurance_photo_front: values.insurance_photo_front || null,
          insurance_photo_back: values.insurance_photo_back || null,
        },
      });
    }

    await submit(submitData);
  };

  const initialPregnancyCount =
    previous_pregnancy_count || initialValues.previous_pregnancy_count;

  const initialPregnacyDetails = Array(initialPregnancyCount).fill(
    pregnancyDetailInitialValues,
  );

  const initialIvfTransferCount =
    previous_ivf_transfer_count || initialValues.previous_ivf_transfer_count;

  const initialIvfTransferDetails = Array(initialIvfTransferCount).fill(
    ivfTransferInitialValues,
  );

  return {
    initialValues: {
      ...initialValues,
      previous_pregnancy_count: initialPregnancyCount,
      pregnancy_details: initialPregnacyDetails,
      previous_ivf_transfer_count: initialIvfTransferCount,
      ivf_transfers_details: initialIvfTransferDetails,
    },
    // NOTE: those inputs should be visible and active only if
    // previous_...._count has not been selected (set in the api).
    displayPregnancyCountInput: !isNumber(previous_pregnancy_count),
    displayIvfTransferCountInput: !isNumber(previous_ivf_transfer_count),
    onSubmit,
    validationSchema: schema,
  };
};
