import type { ReactNode } from 'react';

import { createContext, useContext, useState } from 'react';

import { Modal } from './Modal';

import type { ModalContextType, ModalOptions } from './Modal.interface';

const ModalContext = createContext<ModalContextType>({
  isOpen: false,
  options: undefined,
  open: () => {
    throw new Error('open not initialized');
  },
  close: () => {
    throw new Error('close not initialized');
  },
});

export const useModal: () => ModalContextType = () => useContext(ModalContext);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<ModalOptions | undefined>();

  const handleOpen = (options: ModalOptions) => {
    setOptions(options);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ModalContext.Provider
        value={{
          open: handleOpen,
          close: handleClose,
          isOpen,
          options,
        }}
      >
        {children}
        <Modal
          open={isOpen}
          onClose={handleClose}
          title={options?.title}
          subTitle={options?.subTitle}
          variant={options?.modalVariant}
          size={options?.size}
        >
          {options?.render && options.render()}
        </Modal>
      </ModalContext.Provider>
    </>
  );
};
