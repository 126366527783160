import { useCallback, useEffect, useState } from 'react';

import { convertDataUrlToCompressedFile, sortPhotosByOrder } from '@core/utils';

import type {
  DataUrl,
  EditedImageData,
  EditPhotosConnectedProps,
} from './EditPhotos.interface';
import type { UserProfilePhotoFile, UserProfilePhoto } from '@nodal/api';

const generateOrderedPhotoList = (
  userPhotos: Array<UserProfilePhoto>,
  minPhotos: number,
) => {
  const photoLength =
    Array.from({
      length: Math.max(minPhotos - userPhotos.length, 1),
    }).length + userPhotos.length;

  const extendPhotoArray = userPhotos.filter((d) =>
    d.order && d.order > photoLength - 1 ? d : null,
  );

  const photoNumbers = [...Array(photoLength + extendPhotoArray.length).keys()];

  const photoList: Array<UserProfilePhoto> = photoNumbers.map((n) => ({
    image: userPhotos.find((i) => i.order === n)?.image || '',
    order: n,
    // NOTE: each photo has id number (ordinal number), so for empty photo slots, we need to assign a unique id number, which will not be the same as the photo id
    id: userPhotos.find((i) => i.order === n)?.id || Date.now() + n,
  }));

  // NOTE: Backend doesn't return photos sorted by order, so we do it on the frontend,
  // which is important for the presence of photos in the carousel and as a profile photo (first in order)
  return sortPhotosByOrder(photoList);
};

export const useEditPhotos = ({
  minPhotos,
  onUpload,
  onUpdate,
  onRemove,
  profileData,
}: EditPhotosConnectedProps) => {
  const [loadingPhotos, setLoadingPhotos] = useState<number[]>([]);

  const [editedImageData, setEditedImageData] = useState<
    EditedImageData | undefined
  >();

  const [photos, setPhotos] = useState<UserProfilePhoto[]>([]);

  useEffect(() => {
    const orderedPhotoList = generateOrderedPhotoList(
      profileData?.profile_photos || [],
      minPhotos,
    );

    setPhotos(orderedPhotoList);
  }, [minPhotos, profileData?.profile_photos]);

  const handleAddImage = useCallback(
    (file: File, index: number, item: UserProfilePhoto) => {
      const reader = new FileReader();
      reader.onload = () => {
        setEditedImageData({
          photo: {
            ...item,
            order: index,
          },
          name: file.name,
          dataUrl: reader.result as DataUrl,
        });
      };

      reader.readAsDataURL(file);
    },
    [],
  );

  const handleUploadNewImage = useCallback(
    async (url: string, imageData: EditedImageData) => {
      const image = await convertDataUrlToCompressedFile({
        dataUrl: url,
        fileName: imageData.name,
      });

      // NOTE: BUG in Api Schema - imageUrl is required
      // reported as an issue: https://linear.app/nodal-health/issue/NOD-254/backend-bug-in-api-schema-userprofilephoto
      await onUpload({ ...imageData.photo, image, imageUrl: '' });
    },
    [onUpload],
  );

  const handleUpdateImage = useCallback(
    async (url: string, imageData: EditedImageData) => {
      const image = await convertDataUrlToCompressedFile({
        dataUrl: url,
        fileName: imageData.name,
      });

      const updatedImages: Array<UserProfilePhotoFile> = [
        { ...imageData.photo, image },
      ];

      await onUpdate(updatedImages);
    },
    [onUpdate],
  );

  const handleDelete = useCallback(
    async (item: UserProfilePhoto) => {
      setLoadingPhotos([...loadingPhotos, item?.id || 0]);

      await onRemove(item);

      setLoadingPhotos(loadingPhotos.filter((photo) => photo !== item.id));
    },
    [loadingPhotos, onRemove],
  );

  const handleEdit = async (item: UserProfilePhoto) => {
    setEditedImageData({
      photo: { ...item },
      dataUrl: item.image || '',
      // NOTE: Since we do not save the file name and we need it to convert from url to file,
      // we provide the default, hardcoded file name.
      name: 'uploadedFile.jpg',
    });
  };

  const handleReorderImages = useCallback(
    async (sourceId: number, destinationId: number) => {
      const sourceItem = photos.find((photo) => photo.id === sourceId);
      const destinationItem = photos.find(
        (photo) => photo.id === destinationId,
      );

      if (sourceItem && destinationItem) {
        const { order: sourceOrder } = sourceItem;
        const { order: destinationOrder } = destinationItem;

        const reorderedPhotos = photos.map((item, idx) => {
          if (idx === sourceOrder) {
            return { ...item, order: destinationOrder };
          }

          if (idx === destinationOrder) {
            return { ...item, order: sourceOrder };
          }

          return { ...item, order: idx };
        });

        const sortedPhotos = sortPhotosByOrder(reorderedPhotos);

        setPhotos(sortedPhotos);

        const updatedFiles = sortedPhotos
          .filter(
            (photo: UserProfilePhoto) =>
              photo?.order === destinationOrder || photo?.order === sourceOrder,
          )
          .map((photo: UserProfilePhoto) => ({
            id: photo.id,
            order: photo.order,
          }));

        setLoadingPhotos([...loadingPhotos, sourceId, destinationId]);

        const response = await onUpdate(updatedFiles);

        if (!response.success) {
          setPhotos([...photos]);
        }

        setLoadingPhotos(
          loadingPhotos.filter(
            (photo) => photo !== sourceId && photo !== destinationId,
          ),
        );
      }
    },
    [loadingPhotos, photos, onUpdate],
  );

  const handleSave = async (url: string) => {
    if (!editedImageData) return;

    return editedImageData.photo.image
      ? handleUpdateImage(url, editedImageData)
      : handleUploadNewImage(url, editedImageData);
  };

  return {
    onAdd: handleAddImage,
    onEdit: handleEdit,
    onDelete: handleDelete,
    onReorder: handleReorderImages,
    onSave: handleSave,
    photos,
    setPhotos,
    editedImageData,
    loadingPhotos,
  };
};
