import type { AxiosInstance } from 'axios';
import {
  AuthApi,
  Configuration,
  MarketplaceApi,
  RegistrationApi,
  ScreensApi,
  UserPhotosApi,
  UsersApi,
  WebhooksApi,
  IntroductionsApi,
  NotificationsApi,
  BillingApi,
  FavoriteParentsApi,
  B2bApi,
  MedicalReviewsApi,
  TasksApi,
} from './core';
import type { ApiServices } from './api.interface';

const coreServices = {
  AuthApi,
  MarketplaceApi,
  RegistrationApi,
  ScreensApi,
  UserPhotosApi,
  UsersApi,
  WebhooksApi,
  IntroductionsApi,
  NotificationsApi,
  BillingApi,
  FavoriteParentsApi,
  B2bApi,
  MedicalReviewsApi,
  TasksApi,
};

const createApiServices = (
  configuration: Configuration,
  basePath: string,
  axios: AxiosInstance,
): ApiServices =>
  Object.entries(coreServices).reduce(
    (acc, [name, Service]) => ({
      ...acc,
      [name]: new Service(configuration, basePath, axios),
    }),
    {} as ApiServices,
  );

export class ApiClient {
  readonly api: ApiServices;
  readonly ts: number;
  readonly configuration: Configuration;

  constructor(protected axios: AxiosInstance, basePath: string) {
    this.configuration = new Configuration({
      baseOptions: {
        baseURL: basePath,
        headers: {
          Accept: 'application/json',
        },
      },
    });
    this.api = createApiServices(this.configuration, basePath, axios);
    this.ts = Date.now();
  }
}
