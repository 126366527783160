import React, { FC } from 'react';
import { useSubscriptionContext } from '../SubscriptionFlow.connect';
import { SubscriptionProcessing } from './SubscriptionProcessing';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';

export const SubscriptionProcessingConnected: FC = () => {
  const { subscription } = useSubscriptionContext();

  if (!subscription) return <LoadingScreen />;

  const { plan, payment_type } = subscription || {};

  return <SubscriptionProcessing plan={plan} paymentType={payment_type} />;
};
