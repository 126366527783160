import { ApiModel } from '@nodal/api';
import { t } from '@nodal/i18n';
import groupBy from 'lodash/groupBy';
import { StageMeta } from './Dashboard.interface';

export const parentStepsMeta: Record<ApiModel.TaskStageEnum, StageMeta> = {
  screening: {
    title: t('Screening'),
    index: 0,
    bannerTitle: t('Welcome to Nodal!'),
    bannerDescription: t(
      "We're excited to help you find your perfect match. Before our surrogates can connect with you, there are a few simple tasks to complete.\n1. Follow the prompts to go through basic screening\n2. Make your match profile.\nOnce everything is set, you'll be ready to find your ideal match!",
    ),
  },
  match_profile: {
    title: t('Match Profile'),
    index: 1,
    bannerTitle: t(
      'Congratulations on successfully completing the screening steps!',
    ),
    bannerDescription: t(
      "Now, let's take the next step and fill out your match profile so that potential surrogates can learn all about you. Your unique story is the key to making meaningful connections on Nodal!",
    ),
  },
  introduction: {
    title: t('Introductions'),
    index: 2,
    bannerTitle: t('Get ready to meet your match!'),
    bannerDescription: t(
      "As you await your first introduction requests, we've provided several documents to offer insights into our matching process and what you can expect.",
    ),
  },
  match: {
    title: t('Match'),
    index: 3,
    bannerTitle: t('Congratulations on finding your match!'),
    bannerDescription: t(
      "Now, let's complete your match documents and prepare to move forward in your exciting journey.",
    ),
  },
};

export const stagesTitles = Object.values(parentStepsMeta).map(
  (stage) => stage.title,
);

export const getWelcomeMessage = (
  firstName?: string | null,
  partnerFirstName?: string | null,
): string | undefined => {
  if (firstName && partnerFirstName) {
    return t(
      'Hi {firstName} & {partnerFirstName}!',
      firstName,
      partnerFirstName,
    );
  } else if (firstName) {
    return t('Hi {firstName}!', firstName);
  } else {
    return undefined;
  }
};

export const getTasksForCurrentStage = (
  currentStage: ApiModel.TaskStageEnum,
  tasksList?: ApiModel.Task[],
): ApiModel.Task[] => {
  // Return [] when tasks are not loaded yet or for existing users not migrated for IP Dashboard flow
  if (!tasksList || tasksList.length === 0) return [];

  const stages = groupBy(tasksList, (task: ApiModel.Task) => task.stage);

  return stages[currentStage];
};
