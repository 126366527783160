import React, { useCallback } from 'react';
import type { FC } from 'react';
import { t } from '@nodal/i18n';
import type { CalendlyEventScheduledEvent } from '@nodal/uikit/components/CalendlyWidget';
import {
  CalendlyInline,
  CalendlyModal,
} from '@nodal/uikit/components/CalendlyWidget';
import { PhoneScreenProps } from './PhoneScreen.interface';
import { Alert } from '@nodal/uikit/components/Alert';

export const PhoneScreen: FC<PhoneScreenProps> = ({
  title,
  description,
  id,
  calendlyUrl,
  alert,
  onSubmit,
  prefill,
  scheduled,
}) => {
  const handleSubmit = useCallback(
    (event: CalendlyEventScheduledEvent) => {
      onSubmit(id, event);
    },
    [onSubmit, id],
  );

  return (
    <>
      <div className="flex flex-col justify-start items-start w-full lg:w-step-view">
        {alert ? (
          <div className="pb-6 w-full">
            <Alert {...alert} />
          </div>
        ) : (
          <div className="p-4 w-full h-fit bg-white rounded-lg sm:p-8">
            <div className="space-y-2">
              <h2 className="hidden pb-2 text-2xl font-semibold leading-8 text-grey-forest-900 sm:block">
                {title}
              </h2>
              <p className="text-sm leading-5 text-gray-500">{description}</p>
            </div>
            <div className="flex flex-col gap-4 pt-6 w-full sm:pt-0">
              {!scheduled && (
                <>
                  <div className="hidden gap-4 justify-center sm:flex">
                    <CalendlyInline
                      url={calendlyUrl}
                      styles={{ width: '100%', height: '100vh' }}
                      onEventScheduled={handleSubmit}
                      prefill={prefill}
                    />
                  </div>

                  <div className="flex gap-4 justify-center sm:hidden">
                    <CalendlyModal
                      url={calendlyUrl}
                      onEventScheduled={handleSubmit}
                      prefill={prefill}
                    >
                      {' '}
                      {t('Schedule Appointment')}{' '}
                    </CalendlyModal>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
