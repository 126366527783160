export type PlaceTypeKey =
  | 'airport_address'
  | 'clinic_address'
  | 'hospital_address';

export const placeTypeKeys: PlaceTypeKey[] = [
  'airport_address',
  'clinic_address',
  'hospital_address',
];
