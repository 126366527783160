import type { FC } from 'react';

import { useField } from 'formik';

import { RadioGroup } from './RadioGroup';

import type { RadioGroupConnectedProps } from './RadioGroup.interface';

export const RadioGroupConntected: FC<RadioGroupConnectedProps> = ({
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <RadioGroup
      error={meta.touched && meta.error ? meta.error : undefined}
      {...field}
      {...props}
    />
  );
};
