import { t } from '@nodal/i18n';

import { isInRelationship } from '@core/utils/profile';

import { parProfileInformation } from '../profileInformationOptions';
import {
  getFormattedDateOfBirth,
  getFormattedLocation,
  getValueFromChoice,
  getQuestionLabel,
} from '../utils';

import type {
  ProfileData,
  ParentProfileInformation,
  TopProfileChoice,
} from '../MatchProfile.interface';
import type { SectionDetailsProps } from '../SectionDetails/SectionDetails.interface';

const getDetailsForPersonalInformation = (
  profile: ParentProfileInformation,
): TopProfileChoice[] => {
  const {
    personalInformation: { questions },
  } = parProfileInformation;
  const {
    first_name,
    address,
    date_of_birth,
    occupation,
    relationship_status,
    partner_first_name,
    partner_date_of_birth,
    partner_occupation,
    ideal_start,
  } = profile || {};

  return [
    {
      value: getFormattedLocation(address),
      label: t('Location'),
    },
    isInRelationship(relationship_status)
      ? {
          value: getFormattedDateOfBirth(date_of_birth, first_name),
          partnerValue: getFormattedDateOfBirth(
            partner_date_of_birth,
            partner_first_name,
          ),
          label: t('Age'),
        }
      : {
          value: getFormattedDateOfBirth(date_of_birth),
          label: t('Age'),
        },
    isInRelationship(relationship_status)
      ? {
          value: `${first_name} - ${occupation}`,
          partnerValue: `${partner_first_name} - ${partner_occupation}`,
          label: t('Job'),
        }
      : {
          value: occupation?.toString(),
          label: t('Job'),
        },
    {
      value: getValueFromChoice(questions, 'ideal_start', ideal_start),
      label: getQuestionLabel(questions, 'ideal_start'),
    },
  ];
};

export const ParentsPersonalSectionDetails = <T extends ProfileData>({
  profile,
}: SectionDetailsProps<T>) => {
  return (
    <div className="flex flex-col gap-x-8 gap-y-0 w-full text-sm font-medium leading-5 text-grey-forest-900 sm:gap-y-3">
      {getDetailsForPersonalInformation(profile).map(
        ({ label, value, partnerValue }) => (
          <div
            key={label}
            className="grid-cols-2/3 col-span-2 gap-x-8 items-end py-4 border-b sm:grid sm:py-0 sm:border-none"
          >
            <p className="col-span-1 col-start-1 text-grey-forest-500">
              {label}
            </p>
            <p className="col-span-1 col-start-2">{value}</p>
            {partnerValue && <p className="col-start-2">{partnerValue}</p>}
          </div>
        ),
      )}
    </div>
  );
};
