import React, { FC } from 'react';
import { AppProgress, AppProgressProps } from 'components/AppProgress';
import { roleToSteps } from 'components/AppProgress/steps';
import { ScreeningProgressProps } from './ScreeningProgress.interface';
import { apiEnums } from '@nodal/api';

const startStep: AppProgressProps['status'] = {
  index: 1,
  status: 'in_progress',
};

const roleToProgressBarVisible = new Map([
  [apiEnums.UserRoleEnum.Par, true],
  [apiEnums.UserRoleEnum.Dnr, true],
  [apiEnums.UserRoleEnum.Nap, false],
]);

export const ScreeningStart: FC<ScreeningProgressProps> = ({
  role,
  onNext,
}) => {
  const { title } =
    roleToSteps[role].steps.find((s) => s.index === startStep.index) || {};

  return (
    <AppProgress
      {...roleToSteps[role]}
      status={startStep}
      button={role === apiEnums.UserRoleEnum.Nap ? title : `Complete ${title}`}
      onNext={onNext}
      progressBarVisible={!!roleToProgressBarVisible.get(role)}
    />
  );
};
