import type { FC } from 'react';

import { ClockIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { CheckIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import type { PaymentStatus } from './PaymentStatusCard.interface';

const iconMap = {
  active: 'text-forest-300 w-6 h-6',
  processing: 'text-yellow-500 w-6 h-6',
  failed: 'text-red-600 bg-red-100 stroke-1 h-20 w-20 z-10 absolute',
};

const backgroundMap = {
  active: 'bg-forest-100',
  processing: 'bg-yellow-200',
  failed: 'bg-red-100',
};

const getIcon = (status: PaymentStatus) => {
  switch (status) {
    case 'active':
      return CheckIcon;
    case 'processing':
      return ClockIcon;
    case 'failed':
      return ExclamationCircleIcon;
    default:
      return null;
  }
};

export const PaymentStatusIcon: FC<{
  status: PaymentStatus;
}> = ({ status }) => {
  const Icon = getIcon(status);
  const iconClassName = classNames({
    [iconMap[status]]: true,
  });

  const iconBackgroundClassName = classNames(
    'relative flex justify-center items-center w-12 h-12 rounded-full z-10 overflow-hidden',
    {
      [backgroundMap[status]]: true,
    },
  );

  return (
    <div className={iconBackgroundClassName}>
      {Icon && <Icon className={iconClassName} />}
    </div>
  );
};
