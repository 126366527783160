import type { FC } from 'react';

import { SignInScreen } from './SignInScreen';
import { useSignIn } from '@nodal/core/flows/hooks/useSignIn';
import { paths, signupPaths } from 'consts/paths';
import { apiEnums, ApiModel } from '@nodal/api';
import { useNavigate } from 'react-router-dom';
import { settings } from 'settings';
import { addBasePath } from '@nodal/core/utils';

const roleToSignUpBasePath = new Map<ApiModel.RegisterRoleEnum, string>([
  [apiEnums.RegisterRoleEnum.Par, settings.getMatchingSignUpBasePath()],
  [apiEnums.RegisterRoleEnum.Dnr, settings.getMatchingSignUpBasePath()],
  [apiEnums.RegisterRoleEnum.Nap, settings.getNavigatorSignUpBasePath()],
]);

export const SignInScreenConnected: FC = () => {
  const navigate = useNavigate();

  const onNavigateToApp = () => navigate(paths.app);
  const onNavigateToEmailNotConfirmed = (
    email: string,
    role: ApiModel.RegisterRoleEnum,
  ) => {
    const signupPath = addBasePath(
      paths.signup,
      roleToSignUpBasePath.get(role),
    );

    navigate(`../${signupPath}/${signupPaths.emailNotConfirmed}`, {
      state: { email },
    });
  };

  const { onSubmit, initialValues, validationSchema } = useSignIn({
    onNavigateToApp,
    onNavigateToEmailNotConfirmed,
    permittedRoles: [
      apiEnums.UserRoleEnum.Dnr,
      apiEnums.UserRoleEnum.Par,
      apiEnums.UserRoleEnum.Nap,
    ],
  });

  return (
    <SignInScreen
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    />
  );
};
