import type { FC } from 'react';

import { PlayIcon } from '@heroicons/react/solid';
import { useRef, useState } from 'react';

import type { VideoPreviewProps } from './VideoPreview.interface';

export const VideoPreview: FC<VideoPreviewProps> = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const startPlaying = () => {
    setIsPlaying(true);
    if (videoRef.current) videoRef.current.play();
  };

  return (
    <div className="flex relative w-full h-full">
      <video
        ref={videoRef}
        {...props}
        controls={isPlaying ? props.controls : false}
      />
      {!isPlaying && (
        <div
          className="flex absolute inset-0 justify-center items-center cursor-pointer"
          onClick={startPlaying}
        >
          <PlayIcon className="aspect-square w-16 fill-white/90" />
        </div>
      )}
    </div>
  );
};
