import { MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { useState } from 'react';

import type { Active, DragEndEvent, DragStartEvent } from '@dnd-kit/core';

export const useDragAndDrop = (
  onReorder: (sourceId: number, destinationId: number) => void,
) => {
  const [draggedId, setDraggedId] = useState<Active['id']>();

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 0,
      },
    }),
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const sourceId = Number(active.id);
      const destinationId = Number(over.id);

      await onReorder(sourceId, destinationId);

      setDraggedId(undefined);
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    setDraggedId(event.active.id);
  };

  const handleDragCancel = () => {
    setDraggedId(undefined);
  };

  return {
    onDragHandlers: {
      onDragStart: handleDragStart,
      onDragCancel: handleDragCancel,
      onDragEnd: handleDragEnd,
    },
    sensors,
    draggedId,
  };
};
