import { SectionTag } from '../SectionTag';
import { getDetailsForSection, getSectionQuestionsByRole } from '../utils';

import type { ProfileData } from '../MatchProfile.interface';
import type { SectionDetailsProps } from './SectionDetails.interface';

export const PreferencesSectionDetails = <T extends ProfileData>({
  profile,
  role,
  edit,
}: SectionDetailsProps<T>) => {
  const questions = getSectionQuestionsByRole('preferences', role);

  return (
    <div className="flex flex-col w-full">
      {getDetailsForSection(profile, questions).map(({ label, value }) => (
        <div
          id={
            (label == 'LGBTQ+ IP' || label == 'LGBTQ+ Surrogate') &&
            edit == false
              ? 'hide'
              : ''
          }
          className="flex flex-col gap-2 justify-start items-start py-4 border-t border-grey-forest-100 sm:flex-row sm:gap-0 sm:items-center sm:py-3"
          key={label}
        >
          <p className="w-full sm:w-1/2">{label}</p>
          <p className="w-full sm:w-1/2">
            <SectionTag tag={value} />
          </p>
        </div>
      ))}
    </div>
  );
};
