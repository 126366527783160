export const queryKeys = {
  authUserRetrieve: 'authUserRetrieve',
  usersMeRetrieve: 'usersMeRetrieve',
  requestRetrieve: 'requestRetrieve',
  organizationsList: 'organizationsList',
  invitationsList: 'invitationsList',
  candidateRetrieve: 'candidateRetrieve',
  candidateProfileRetrieve: 'candidateProfileRetrieve',
  usersDocumentsList: 'usersDocumentsList',
  usersMarketplaceUsersList: 'usersMarketplaceUsersList',
  usersViewedList: 'usersViewedList',
  usersFavoritedList: 'usersFavoritedList',
  usersIntroductionsList: 'usersIntroductionsList',
  usersProfileViewCountRetrieve: 'usersProfileViewCountRetrieve',
  usersFavoritedByList: 'usersFavoritedByList',
  plutoDetailsRetrieve: 'plutoDetailsRetrieve',
  usersIntroductionsRetrieve: 'usersIntroductionsRetrieve',
  billingScreeningPackagesList: 'billingScreeningPackagesList',
  usersDocumentsRetrieve: 'usersDocumentsRetrieve',
  introductionsList: 'introductionsList',
  screensList: 'screensList',
  notificationsList: 'notificationsList',
  usersList: 'usersList',
  billingPlansList: 'billingPlansList',
  favoriteParentsList: 'favoriteParentsList',
  marketplaceParentsList: 'marketplaceParentsList',
  favoriteParentsRetrieve: 'favoriteParentsRetrieve',
  introductionsUserRetrieve: 'introductionsUserRetrieve',
  billingStripeInvitationPaymentRetrieve:
    'billingStripeInvitationPaymentRetrieve',
  usersProfileViewsList: 'usersProfileViewsList',
  billingCustomerRetrieve: 'billingCustomerRetrieve',
  billingStripeSubscriptionRetrieve: 'billingStripeSubscriptionRetrieve',
  billingCustomerPortalCreate: 'billingCustomerPortalCreate',
  billingStripeNodalServicesPaymentRetrieve:
    'billingStripeNodalServicesPaymentRetrieve',
  usersNonDetailedList: 'usersNonDetailedList',
  b2bIntroductionsList: 'b2bIntroductionsList',
  medicalReviewsMatchProfileRetrieve: 'medicalReviewsMatchProfileRetrieve',
  screensForIntroductionUsersRetrieve: 'screensForIntroductionUsersRetrieve',
  checkrScreenForIntroductionUsersRetrieve:
    'checkrScreenForIntroductionUsersRetrieve',
  faxStatusDetailsRetrieve: 'faxStatusDetailsRetrieve',
  b2bDashboardMatchingRetrieve: 'b2bDashboardMatchingRetrieve',
  medicalDocumentsList: 'medicalDocumentsList',
  userNotesList: 'userNotesList',
  usersSupportTeamUsersList: 'usersSupportTeamUsersList',
  usersSupportTeamRetrieve: 'usersSupportTeamRetrieve',
  tasksList: 'tasksList',
  b2bUserTasksList: 'b2bUserTasksList',
  tasksDocumentResourcesList: 'tasksDocumentResourcesList',
  tasksMeStageRetrieve: 'tasksMeStageRetrieve',
} as const;

export type QueryKey = typeof queryKeys[keyof typeof queryKeys];
