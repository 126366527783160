import type { ProfileData } from '@nodal/core/flows/MatchProfile';
import { EditFormConnectedProps } from './EditForm.interface';
import { EditForm, useEditForm } from '@nodal/core/flows/MatchProfile';
import { useGooglePlacesApi } from '@nodal/core/hooks/useGooglePlacesApi';
import { useUserProfile } from 'hooks/useUserProfile';

export const EditFormConnected = <T extends ProfileData>({
  profileSections,
  section,
  onCloseModal,
}: EditFormConnectedProps<T>) => {
  const placesApiReady = useGooglePlacesApi();

  const { submit, profileData: user, uploadProgress } = useUserProfile();
  const { initialValues, questions, onSubmit, validationSchema } = useEditForm({
    profileSections,
    section,
    onCloseModal,
    submit,
    userProfile: user?.profile,
  });

  if (!placesApiReady) {
    return null;
  }

  return (
    <EditForm
      initialValues={initialValues}
      questions={questions}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      section={section}
      role={user?.role}
      uploadProgress={uploadProgress}
    />
  );
};
