import type { FC } from 'react';

import { MailIcon } from '@heroicons/react/outline';
import { ArrowNarrowLeftIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { Link } from 'react-router-dom';

import type { CheckEmailScreenProps } from './CheckEmailScreen.interface';

export const CheckEmailScreen: FC<CheckEmailScreenProps> = ({
  email = '',
  onResend,
}) => (
  <div className="flex flex-col gap-4">
    <div className="flex flex-col gap-6 justify-center items-center">
      <MailIcon className="w-16 text-grey-forest-400 stroke-1" />
      <div className="text-center">
        <h2 className="mb-2 text-2xl font-semibold leading-8 text-grey-forest-900">
          {t('Check your e-mail')}
        </h2>
        <p className="text-base font-normal leading-6 text-grey-forest-600">
          {t('We sent a verification link to')}&nbsp;
          <span className="font-medium">{email}</span>.&nbsp;
          <br />
          {t('Follow the link provided to finalize the sign up process.')}
        </p>
        <p className="mt-4 text-base leading-6 text-grey-forest-600">
          {t("Didn't receive email? Check your spam or")}&nbsp;
          <button
            type="button"
            className="font-medium text-forest-500"
            onClick={onResend}
          >
            {t('Resend')}
          </button>
        </p>
      </div>
      <div className="flex gap-4 justify-center items-center mt-2">
        <ArrowNarrowLeftIcon className="w-5 h-5 text-forest-400" />
        <Link
          to="../"
          className="text-base font-medium leading-6 text-forest-500"
        >
          {t('Back to Sign Up')}
        </Link>
      </div>
    </div>
  </div>
);
