import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
} from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { posthog } from 'posthog-js';
import { apiEnums, ApiModel, useApiClient } from '@nodal/api';
import { TopNav } from 'components/TopNav';
import { Screening } from 'components/Screening';
import { Matching } from 'components/Matching';
import { ContactDetails } from 'components/ContactDetails';
import { SubscriptionFlow } from 'components/SubscriptionFlow';
import { AppProtectedContextValue } from './App.interface';
import { MatchingInProgress } from 'components/Matching/MatchingProgress';
import { useForcedRedirect } from './AppAccess';
import { settings } from 'settings';
import { useUsersMeRetrieve } from 'api/hooks/useUsersMeRetrieve';
import { AppShell } from '@nodal/uikit/components/AppShell';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { ModalProvider } from '@nodal/uikit/components/Modal';
import { useQuery } from 'react-query';
import { queryKeys } from '@nodal/core/consts/query';
import { appPaths, paths } from 'consts/paths';
import { SinglePaymentService } from 'components/SinglePaymentService';
import { User } from 'api';
import { UploadRecords } from 'components/UploadRecords';
import { ConfirmationDialogProvider } from '@nodal/uikit/components/ConfirmationDialog';
import { Navigator } from 'components/Navigator';
import { Dashboard } from 'components/Dashboard';

const renderPaymentServiceElement = (
  role: User['role'],
  status: ApiModel.UserStatusEnum,
  isSubscription: boolean,
) => {
  if (
    status === apiEnums.UserStatusEnum['Scr+'] &&
    role === apiEnums.UserRoleEnum.Nap
  ) {
    return <Navigate to={paths.app} />;
  } else if (
    status === apiEnums.UserStatusEnum['Scr+'] &&
    role !== apiEnums.UserRoleEnum.Nap
  ) {
    return <MatchingInProgress role={role} />;
  } else if (isSubscription) {
    return <SubscriptionFlow />;
  } else {
    return <SinglePaymentService />;
  }
};

const initialValue: AppProtectedContextValue = {
  profile: undefined,
  matchProfileCompleted: false,
  screeningConditionalCompleted: false,
};

export const AppProtectedContext = createContext(initialValue);

export const AppProtectedContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const apiClient = useApiClient();
  const { data: userData } = useUsersMeRetrieve();

  const { data: billingCustomer } = useQuery(
    queryKeys.billingCustomerRetrieve,
    () => apiClient.api.BillingApi.billingCustomerRetrieve(),
    {
      enabled:
        userData?.data?.role === apiEnums.UserRoleEnum.Par ||
        userData?.data?.role === apiEnums.UserRoleEnum.Nap,
    },
  );

  const matchProfileCompleted =
    userData?.data?.profile?.is_match_profile_completed || false;

  const screeningConditionalCompleted =
    userData?.data?.half_passed_screens || false;

  const { role, email, profile } = userData?.data ?? {};
  const { first_name, last_name } = profile || {};

  useEffect(() => {
    if (!email || !settings.getPostHogEnabled()) return;

    posthog.identify(email, {
      name: `${first_name} ${last_name}`,
      role,
    });
  }, [role, email, first_name, last_name]);

  return (
    <AppProtectedContext.Provider
      value={{
        profile: userData?.data,
        matchProfileCompleted,
        screeningConditionalCompleted,
        billingCustomer: billingCustomer?.data,
      }}
    >
      {children}
    </AppProtectedContext.Provider>
  );
};

export const useAppProtected = () => {
  const {
    profile,
    matchProfileCompleted,
    screeningConditionalCompleted,
    billingCustomer,
  } = useContext(AppProtectedContext);

  return {
    profile,
    matchProfileCompleted,
    screeningConditionalCompleted,
    billingCustomer,
  };
};

export const AppProtectedConsumer: FC = () => {
  const { profile, billingCustomer } = useAppProtected();
  const scrollToTopRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    role: myRole,
    status: myStatus,
    passed_screens: hasCompletedScreening,
  } = profile || {};

  const forcedRedirectPath = useForcedRedirect({
    status: myStatus,
    role: myRole,
    hasCompletedScreening,
  });

  useEffect(() => {
    scrollToTopRef?.current?.scrollIntoView();
  }, [pathname]);

  useEffect(() => {
    if (forcedRedirectPath) {
      navigate(forcedRedirectPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcedRedirectPath]);

  return (
    <AppShell
      ref={scrollToTopRef}
      top={<TopNav profileData={profile} />}
      center={
        myRole && myStatus ? (
          <ConfirmationDialogProvider>
            <ModalProvider>
              <Routes>
                <Route path="screening/*" element={<Screening />} />
                <Route path="matching/*" element={<Matching />} />
                <Route
                  path={`${appPaths.navigator}/*`}
                  element={<Navigator />}
                />
                {myRole === apiEnums.UserRoleEnum.Par &&
                  settings.getIpDashboardFeatureEnabled() && (
                    <Route
                      path={`${appPaths.dashboard}/*`}
                      element={<Dashboard />}
                    />
                  )}
                <Route
                  path="profile"
                  element={<ContactDetails role={myRole} />}
                />
                <Route
                  path={appPaths.uploadRecords}
                  element={<UploadRecords />}
                />
                <Route
                  path={`${appPaths.paymentService}/*`}
                  element={renderPaymentServiceElement(
                    myRole,
                    myStatus,
                    !!billingCustomer?.is_subscription_user,
                  )}
                />
              </Routes>
            </ModalProvider>
          </ConfirmationDialogProvider>
        ) : (
          <LoadingScreen />
        )
      }
    />
  );
};

export const AppProtected = () => {
  return (
    <AppProtectedContextProvider>
      <AppProtectedConsumer />
    </AppProtectedContextProvider>
  );
};
