import { apiEnums, useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import * as Yup from 'yup';

import { queryKeys } from '@core/consts/query';
import { isInRelationship } from 'utils/profile';

import type { FormValues, SubmitValues } from './BackgroundCheck.interface';
import type { ScreenStep, ScreeningStepAlert } from '@core/flows/Screening';
import type { ApiModel } from '@nodal/api';

const alerts = new Map<ApiModel.ScreenStatusEnum, ScreeningStepAlert>([
  [
    apiEnums.ScreenStatusEnum.Rev,
    {
      variant: 'warning',
      title: t("We've Hit a Snag"),
      message: t(
        'Thank you for your interest in Nodal, but your application has been flagged to a staff member to review. Please check your email for next steps.',
      ),
    },
  ],
  [
    apiEnums.ScreenStatusEnum.Rej,
    {
      variant: 'error',
      title: t('Eligibility was not successful'),
      message: t(
        `We are sorry. Looks like you're not a good fit for Nodal at this time. You can no longer go to the next step.`,
      ),
    },
  ],
]);

const getDescriptionBy = (
  status: ApiModel.ScreenStatusEnum,
  role?: ApiModel.UserRoleEnum,
) => {
  if (status === apiEnums.ScreenStatusEnum.Pend) {
    return t(
      'Nodal requires a Background check on anyone over 18 who lives in your household. Please enter their email address, click Start Background Check and follow the link we provided. You will be taken to our parter company, Checkr, and it should take about 3 mins to complete. The rest of your household will receive an email from Checkr Background Service with a link to submit the required information. Simply return to this tab when you are done.',
    );
  }

  if (status === apiEnums.ScreenStatusEnum.Proc) {
    switch (role) {
      case apiEnums.UserRoleEnum.Dnr:
      case apiEnums.UserRoleEnum.Par:
        return t(
          `Thank you for submitting your email address. You have been redirected to our partner company, Checkr, to complete your Background Check. If applicable, the rest of your household will receive an email from Checkr Background Service with a link to submit the required information. After you have finished, you will receive an email from us when it's time to proceed.`,
        );

      case apiEnums.UserRoleEnum.Odo:
      case apiEnums.UserRoleEnum.Oed:
      case apiEnums.UserRoleEnum.Osd:
      default:
        return t(
          `Thank you for submitting your email address. You have been redirected to our partner company, Checkr, to complete your Background Check. If applicable, the rest of your household will receive an email from Checkr Background Service with a link to submit the required information.`,
        );
    }
  }
};

const useEmailData = (
  hasPartner: boolean,
  primaryEmail?: string,
  partnerEmail?: string,
) => {
  const [emailData, setEmailData] = useState<FormValues>({
    primaryEmail: '',
    otherEmails: [],
  });

  useEffect(() => {
    if (primaryEmail) {
      setEmailData(
        hasPartner
          ? { primaryEmail, partnerEmail: partnerEmail || '', otherEmails: [] }
          : { primaryEmail, otherEmails: [] },
      );
    }
    // NOTE: Update emails list only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryEmail, hasPartner]);

  return { emailData };
};

export const useBackgroundCheck = ({
  refresh,
  currentStep,
  submit,
  relationshipStatus,
  email,
  partnerEmail,
}: {
  submit: (values: SubmitValues) => Promise<ApiModel.Screen | undefined>;
  currentStep?: ScreenStep;
  refresh: () => Promise<void>;
  relationshipStatus?: ApiModel.ParentsProfile['relationship_status'];
  email?: string;
  partnerEmail?: string;
}) => {
  const apiClient = useApiClient();
  const [alert, setAlert] = useState<ScreeningStepAlert>();
  const [description, setDescription] = useState(
    getDescriptionBy(apiEnums.ScreenStatusEnum.Pend),
  );
  const [submitted, setSubmitted] = useState<boolean>(false);

  const { data: usersMe } = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );

  const hasPartner = isInRelationship(relationshipStatus) || !!partnerEmail;

  const { emailData } = useEmailData(hasPartner, email, partnerEmail);

  const validationSchema = Yup.object({
    primaryEmail: Yup.string()
      .email(t('Enter a valid email address'))
      .required(t('This field is required')),
    partnerEmail: hasPartner
      ? Yup.string()
          .email(t('Enter a valid email address'))
          .required(t('Partner email is required'))
      : Yup.string().email(t('Enter a valid email address')),
    otherEmails: Yup.array().of(
      Yup.string().email(t('Enter a valid email address')),
    ),
  });

  const handleSubmit = async (emailData: FormValues) => {
    const inputCandidates = Object.entries(emailData).reduce(
      (array: Array<ApiModel.CheckrScreenCandidateInput>, [key, email]) => {
        switch (key) {
          case 'primaryEmail':
            return [...array, { role: apiEnums.RoleFd4Enum.Primary, email }];
          case 'partnerEmail':
            return [...array, { role: apiEnums.RoleFd4Enum.Secondary, email }];
          case 'otherEmails': {
            const otherEmails = email.reduce(
              (arr: ApiModel.CheckrScreenCandidateInput[], value: string) =>
                value
                  ? [
                      ...arr,
                      {
                        role: apiEnums.RoleFd4Enum.Secondary,
                        email: value,
                      },
                    ]
                  : arr,
              [],
            );
            return [...array, ...otherEmails];
          }
          default:
            return array;
        }
      },
      [],
    );

    const postData: SubmitValues = {
      candidates: [],
      input_candidates: inputCandidates,
    };

    const response = await submit(postData);

    // NOTE: Using cast due to broken API types, expected type is ApiModel.CheckrScreen
    const screenContentObject =
      response?.content_object as ApiModel.CheckrScreen;

    const checkrInvitationUrl = screenContentObject?.candidates?.find(
      (candidate) => candidate.role === apiEnums.RoleFd4Enum.Primary,
    )?.invitation_url;

    checkrInvitationUrl && window.open(checkrInvitationUrl, '_blank')?.focus();
  };

  useEffect(() => {
    if (currentStep?.status && usersMe?.data?.role) {
      const alert = alerts.get(currentStep.status);
      setAlert(alert);

      if (currentStep.status === apiEnums.ScreenStatusEnum.Proc) {
        setDescription(getDescriptionBy(currentStep.status, usersMe.data.role));

        setSubmitted(true);

        const interval = setInterval(() => {
          // NOTE: After completing the checkr, backend will notify frontend about the status.
          // Before that happens, we need to poll step (screen) endpoint to observe for expected status change.
          refresh();
        }, 5000);

        return () => clearInterval(interval);
      }
    }
  }, [currentStep?.status, refresh, usersMe?.data?.role]);

  return {
    alert,
    description,
    submitted,
    validationSchema,
    onSubmit: handleSubmit,
    emailData,
  };
};
