import { FC, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useApiClient, ApiModel, apiEnums } from '@nodal/api';
import { AxiosResponse } from 'axios';
import { t } from '@nodal/i18n';
import type { PaymentStatus as PaymentCompleteStatus } from '@nodal/uikit/components/Payment/PaymentStatusCard';

import { PaymentMethod, PaymentDetails } from 'components/SubscriptionFlow';
import { useNavigate, useLocation } from 'react-router-dom';
import { paths } from 'consts/paths';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { PaymentComplete } from '@nodal/uikit/components/Payment';

import { queryKeys } from '@nodal/core/consts/query';
import { PaymentStatus } from './PaymentComplete.interface';

const failedMessage = {
  title: t('Payment Failed'),
  messages: [
    {
      line1: t("We've encountered an error when processing your payment."),
      line2: t('Please try again.'),
    },
  ],
};

const transformPaymentStatus = (
  status: PaymentStatus,
): PaymentCompleteStatus => {
  switch (status) {
    case apiEnums.PaymentStatusEnum.Completed:
      return 'active';
    case apiEnums.PaymentStatusEnum.Initiated:
    case apiEnums.PaymentStatusEnum.Processing:
      return 'processing';
    case apiEnums.PaymentStatusEnum.Failed:
      return 'failed';
  }
};

const getPaymentInfo = (
  status: PaymentStatus,
  method: PaymentMethod,
  email?: string,
) => {
  const completeMessage = {
    title: t('Payment complete'),
    messages: [
      {
        line1: t('Thank you! Your payment has been successful.'),
        line2: t('A confirmation email has been sent to'),
        boldText: `${email}.`,
      },
    ],
  };

  const getPaymentInfoDetails = (status: PaymentStatus) => {
    switch (status) {
      case apiEnums.PaymentStatusEnum.Completed:
        return {
          card: completeMessage,
          us_bank_account: completeMessage,
        };
      case apiEnums.PaymentStatusEnum.Initiated:
      case apiEnums.PaymentStatusEnum.Processing:
        return {
          card: {
            title: t('Processing your payment'),
            messages: [
              {
                line1: t(
                  'Please wait, your order is being processed and it may take up to few seconds to finalize.',
                ),
                line2: t('We will email you when your order succeeds.'),
              },
            ],
          },
          us_bank_account: {
            title: t('Processing your payment'),
            messages: [
              {
                line1: t('Thank you for submitting your payment!'),
                line2: t(
                  'Your bank transfer has been initiated and it may take up to 4 days to finalize.',
                ),
              },
            ],
          },
        };

      case apiEnums.PaymentStatusEnum.Failed:
        return {
          card: failedMessage,
          us_bank_account: failedMessage,
        };
    }
  };

  return getPaymentInfoDetails(status)[method];
};

const usePaymentComplete = (paymentDetails?: PaymentDetails) => {
  const apiClient = useApiClient();

  const { data: customer } = useQuery<AxiosResponse<ApiModel.Customer>>(
    queryKeys.billingCustomerRetrieve,
    () => apiClient.api.BillingApi.billingCustomerRetrieve(),
    // Payments are async, check customer subscription status every 3 seconds
    { refetchInterval: 3000 },
  );

  const { data: usersMe } = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );

  const { payment_status: paymentStatus } =
    customer?.data?.services_payment || {};

  if (!usersMe?.data || !paymentDetails) {
    return null;
  }

  const { email } = usersMe?.data || {};
  const { created, amount, method } = paymentDetails || {};

  // NOTE: As long as the backend does not update the customer, the status will be inactive, in this case show processing status
  const status: ApiModel.PaymentStatusEnum =
    paymentStatus === apiEnums.PaymentStatusEnum.NotInitiated || !paymentStatus
      ? 'processing'
      : paymentStatus;

  const { messages, title } = getPaymentInfo(status, method, email) || {};

  return {
    email,
    date: created ? new Date(created) : undefined,
    amount,
    status: transformPaymentStatus(status),
    title,
    messages,
  };
};

export const PaymentCompleteConnected: FC = () => {
  const { state: paymentDetails } = useLocation() as { state: PaymentDetails };

  const navigate = useNavigate();

  const paymentComplete = usePaymentComplete(paymentDetails);

  useEffect(() => {
    if (!paymentDetails) {
      // NOTE: If there is no state passed to the component, redirect to subscription
      navigate(paths.paymentService);
    }
  }, [paymentDetails, navigate]);

  if (!paymentComplete) return <LoadingScreen />;

  return (
    <PaymentComplete
      {...paymentComplete}
      buttonMeta={
        paymentComplete.status === 'active'
          ? {
              path: paths.paymentService,
              title: t('See Payment Summary'),
            }
          : undefined
      }
    />
  );
};
