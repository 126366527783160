import type { MutableRefObject } from 'react';

import { useEffect, useState } from 'react';

import type { Address, Location } from '../models';

const defaultOptions = {
  componentRestrictions: { country: 'us' },
  fields: ['address_components', 'geometry', 'icon', 'name'],
};

export const useGooglePlacesAutocomplete = (
  inputRef: MutableRefObject<HTMLInputElement | null>,
  optionTypes: string[] = ['address'],
) => {
  const options = { ...defaultOptions, types: optionTypes };

  const [address, setAddress] = useState<Address>();
  const [name, setName] = useState<string>();
  const [location, setLocation] = useState<Location>();

  useEffect(() => {
    const element = inputRef.current;

    if (element) {
      // NOTE: prevent autosubmit after pressing enter on the selected location
      const handleEnter = (e: KeyboardEvent) =>
        e.key === 'Enter' && e.preventDefault();

      element.addEventListener('keydown', handleEnter);

      return () => {
        element.removeEventListener('keydown', handleEnter);
      };
    }
  }, [inputRef]);

  useEffect(() => {
    if (!inputRef?.current) return;

    const autocomplete = new google.maps.places.Autocomplete(
      inputRef.current,
      options,
    );

    google.maps.event.addListener(autocomplete, 'place_changed', async () => {
      const place = await autocomplete.getPlace();

      let line1 = '';
      let zipcode = '';
      let city = '';
      let state = '';

      if (!place?.address_components) {
        return;
      }

      if (place?.address_components?.length) {
        place.address_components.forEach(
          (component: google.maps.GeocoderAddressComponent) => {
            const componentType = component.types[0];
            switch (componentType) {
              case 'street_number': {
                line1 = `${component.long_name} ${line1}`;
                break;
              }

              case 'route': {
                line1 += component.short_name;
                break;
              }

              case 'postal_code': {
                zipcode = component.long_name;
                break;
              }

              case 'locality': {
                city = component.long_name;
                break;
              }

              case 'administrative_area_level_1': {
                state = component.short_name;
                break;
              }
            }
          },
        );
      }

      setAddress({ line1, zipcode, city, state });

      if (place?.geometry?.location) {
        setLocation([
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        ]);
      }

      if (place?.name) {
        setName(place.name);
      }
    });

    return () => {
      google.maps.event.clearInstanceListeners(autocomplete);
    };
    // NOTE: Only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { address, name, location };
};
