import type { FC } from 'react';

import { FormInputError } from '@uikit/components/FormInputError';

import type { RadioOption, RadioGroupProps } from './RadioGroup.interface';

export const RadioGroup: FC<RadioGroupProps> = ({
  name,
  value,
  label,
  options,
  error,
  helperText,
  className,
  type = 'radio',
  ...props
}) => {
  return (
    <div className={`relative ${className || ''}`}>
      <label className="text-lg font-medium leading-8 text-grey-forest-700">
        {label}
      </label>
      <p className="text-sm leading-5 text-gray-500">{helperText}</p>
      <fieldset className={`${type === 'range' ? 'mt-0' : 'mt-4'}`}>
        <div
          className={`${
            type === 'range'
              ? 'flex sm:gap-4 sm:gap-x-6 items-center pt-4 justify-center gap-3 sm:m-0 -ml-12 -mr-8'
              : 'space-y-6'
          }`}
        >
          {options.map((option: RadioOption) => (
            <div key={option.value} className="flex flex-col">
              <div className="flex items-center">
                <input
                  type="radio"
                  className="w-4 h-4 text-forest-500 border-forest-500 focus:ring-forest-500"
                  id={`${name}_${option.value}`}
                  name={name}
                  value={option.value}
                  checked={value?.toString() === option.value.toString()}
                  {...props}
                />
                <div>
                  <label
                    htmlFor={`${name}_${option.value}`}
                    className="block ml-3 text-base font-medium text-grey-forest-900"
                  >
                    {option.label}
                  </label>
                </div>
              </div>
              <label
                className="ml-7 text-sm text-grey-forest-600"
                htmlFor={`${name}_${option.value}`}
              >
                {option.description}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
      {error && name && <FormInputError error={error} id={name} />}
    </div>
  );
};
