import type { ChangeEvent, SyntheticEvent } from 'react';

import { CalendarIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import classNames from 'classnames';
import { addMinutes, isValid, parseISO } from 'date-fns';
import { createRef, forwardRef, useCallback, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import type { TextInputProps } from '@uikit/components/TextInput';
import { TextInput } from '@uikit/components/TextInput/TextInput';

import { useDateTimeMask } from './useDateTimeMask';

import type { DateInputProps } from './DateInput.interface';

const datePickerNumberOfYears = 115;
const timeIntervals = 15;

const CustomInput = forwardRef<HTMLInputElement, Omit<TextInputProps, 'name'>>(
  ({ error, label, ...otherProps }, ref) => {
    const calendarClassName = classNames(
      'absolute right-4 w-4 h-4 bottom-3 sm:bottom-3.5',
      error ? 'text-red-300' : 'text-gray-400',
    );

    return (
      <div className="relative">
        <TextInput
          {...otherProps}
          className="!mb-0"
          type="text"
          label={label}
          error={error}
          ref={ref}
        />
        <CalendarIcon className={calendarClassName} />
      </div>
    );
  },
);

CustomInput.displayName = 'DateCustomInputField';

export const DateInput = ({
  value,
  min,
  max,
  label,
  error,
  className,
  onChange,
  openToDate,
  dateFormat,
  timeFormat,
  ...props
}: DateInputProps): JSX.Element => {
  const inputRef = createRef<HTMLInputElement>();

  const { mask, format, parse, minDate, maxDate } = useDateTimeMask({
    dateFormat,
    timeFormat,
    min,
    max,
  });

  const handleTextInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | undefined) => {
      // NOTE: Ref. to NOTE in handleDatePickerChange.
      if (!e || (e && e.target.value === undefined)) {
        return;
      }

      onChange(e.target.value ? mask(e.target.value) : '');
    },
    [mask, onChange],
  );

  const handleDatePickerChange = useCallback(
    (date: Date | null, event: SyntheticEvent<HTMLElement> | undefined) => {
      // NOTE:
      // The 'change' event type is called not only when date is selected with picker,
      // but also when date is updated with text input. For input changes,
      // onChangeRaw hooked up to handleTextInputChange should be used.
      // Another peculiarity is that this handler might be called with 'undefined' event,
      // but correct date. This happens when the date is updated with month or year dropdown.
      // Both of those peculiarities should most likely be seen as bugs in react-datepicker
      // and can be linked to https://github.com/Hacker0x01/react-datepicker/issues/3482.
      if ((event && event.type !== 'change') || (!event && date)) {
        const formatted = date ? format(date) : '';
        onChange(formatted);
      }
    },
    [format, onChange],
  );

  // NOTE: The date received from the API is with the T format,
  // in order not to format the date when changing the input,
  // we format only the initial displayed value
  // Also we need to set the UTC offset manually
  // to prevent time shifting due to local time
  // TODO: Consider improving this
  const dateValue =
    value?.includes('T') && isValid(parseISO(value))
      ? format(addMinutes(parseISO(value), parseISO(value).getTimezoneOffset()))
      : value;

  const selected = useMemo(
    () => (dateValue ? parse(dateValue) : undefined),
    [parse, dateValue],
  );

  return (
    <div className={className}>
      <DatePicker
        customInput={<CustomInput label={label} error={error} ref={inputRef} />}
        selected={selected}
        value={dateValue}
        openToDate={selected ? selected : openToDate}
        onChange={handleDatePickerChange}
        onChangeRaw={handleTextInputChange}
        scrollableYearDropdown
        dropdownMode="scroll"
        showMonthDropdown
        showYearDropdown
        placeholderText={props.placeholder}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        autoComplete="off"
        adjustDateOnChange
        yearDropdownItemNumber={datePickerNumberOfYears}
        minDate={minDate}
        maxDate={maxDate}
        showTimeSelect={!!timeFormat}
        timeIntervals={timeIntervals}
        timeInputLabel={t('Time:')}
        {...props}
      />
    </div>
  );
};
