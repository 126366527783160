import type { FC } from 'react';

import { useSortable } from '@core/hooks/useSortable';

import { SortablePhoto } from './SortablePhoto';

import type { SortablePhotoConnectedProps } from './SortablePhoto.interface';

export const SortablePhotoConnected: FC<SortablePhotoConnectedProps> = ({
  id,
  src,
  loading,
}) => {
  const { hovered, style, dragged, setNodeRef, attributes, listeners } =
    useSortable({
      id,
      disabled: loading,
    });

  return (
    <SortablePhoto
      ref={setNodeRef}
      src={src}
      loading={loading}
      style={style}
      hovered={hovered}
      dragged={dragged}
      {...attributes}
      {...listeners}
    />
  );
};
