import type { ChangeEvent } from 'react';

import { useField } from 'formik';

// NOTE: hook responsible for returning custom input props for the place field.
// Could be used when entering a value into the input responsible for displaying the place value,
// associated with the location field:
// -> when changing the input, it sets input value and clears the location value,
// -> it shows validation error considering input and location field values
// We use it to force the user to enter address and select a location.
export const usePlaceInputField = ({
  inputName,
  locationName,
}: {
  inputName: string;
  locationName: string;
}) => {
  const [
    ,
    inputMeta,
    { setValue: setInputValue, setTouched: setInputTouched },
  ] = useField(inputName);
  const [
    ,
    locationMeta,
    { setValue: setLocationValue, setTouched: setLocationTouched },
  ] = useField(locationName);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);

    // NOTE: Reset the location if the user starts changing the input value
    setLocationValue(null);
    setLocationTouched(false);
  };

  const onBlur = () => {
    setLocationTouched(true);
    setInputTouched(true);
  };

  const meta = inputMeta?.error ? inputMeta : locationMeta;
  const error = meta.error && meta.touched ? meta.error : undefined;

  return { onChange, error, onBlur };
};
