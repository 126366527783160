import { FC } from 'react';
import { AvailableIntros } from './AvailableIntros';
import { StageBannerProps } from './StageBanner.interface';
import { ImgWithFallback } from '@nodal/uikit/components/ImgWithFallback';
import { DashboardBanner, DashboardBannerJpg } from 'assets';
import { t } from '@nodal/i18n';

export const stageBannerImage = {
  source: { src: DashboardBanner },
  fallback: {
    src: DashboardBannerJpg,
    alt: t('Stage banner'),
    className: 'object-cover w-full h-full',
  },
};

export const StageBanner: FC<StageBannerProps> = ({
  availableIntros,
  description,
  title,
  live = false,
  showAvailableIntros = false,
}) => (
  <div className="flex flex-col justify-start p-6 bg-white rounded-tl-3xl rounded-br-3xl md:px-8">
    <div
      className={`flex ${
        showAvailableIntros ? 'flex-col-reverse' : 'flex-col'
      } gap-4 md:grid md:grid-cols-5`}
    >
      <div className="col-span-5 lg:col-span-3">
        <h3 className="pt-5 text-3xl font-semibold text-forest-500 md:pt-0">
          {title}
        </h3>
        <p className="pt-4 text-gray-600 whitespace-pre-line">{description}</p>
      </div>
      <div className="col-span-5 lg:flex-row lg:col-span-2">
        {showAvailableIntros ? (
          <AvailableIntros count={availableIntros} live={live} />
        ) : (
          <div className="flex object-cover justify-center w-full h-full">
            <ImgWithFallback {...stageBannerImage} />
          </div>
        )}
      </div>
    </div>
  </div>
);
