import type { ChangeEvent, FC } from 'react';

import classNames from 'classnames';
import { useCallback } from 'react';

import { FormInputError } from '@uikit/components/FormInputError';

import type { NumberTagInputProps } from './NumberTagInput.interface';

export const NumberTagInput: FC<NumberTagInputProps> = ({
  id,
  name,
  value,
  label,
  error,
  className = '',
  tag,
  errorMsg,
  onChange,
  ...props
}) => {
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | undefined) => {
      onChange(e && e.target.value !== '' ? Number(e.target.value) : undefined);
    },
    [onChange],
  );

  const inputClassName = classNames(
    'w-full block pr-3 focus:outline-none text-sm leading-6 rounded-lg input-number mt-0',
    error
      ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
      : 'shadow-sm focus:ring-forest-500 focus:border-forest-500 border-grey-forest-300',
  );

  return (
    <div className={`relative ${className}`}>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium leading-5 text-grey-forest-700"
      >
        {label}
      </label>
      <div className="flex relative items-center mt-1">
        <input
          type="number"
          className={inputClassName}
          id={id}
          name={name}
          value={value || value == 0 ? value : ''}
          onChange={handleOnChange}
          {...props}
        />

        <div className="absolute right-0 items-center py-2 px-4 mr-0.25 space-x-2 text-sm font-medium text-grey-forest-700 bg-grey-forest-50 rounded-r-md border-l sm:text-base">
          <span>{tag}</span>
        </div>
      </div>

      {errorMsg && id && <FormInputError error={errorMsg} id={id} />}
    </div>
  );
};
