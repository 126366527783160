import type { FC } from 'react';

import { t } from '@nodal/i18n';

import { TextInput } from '@uikit/components/TextInput';

import type { NameInputProps } from './NameInput.interface';

export const NameInput: FC<NameInputProps> = ({ name }) => {
  return (
    <div className="flex flex-col gap-6 w-full lg:flex-row lg:gap-2">
      <TextInput
        type="text"
        className="w-full lg:w-1/2"
        name={`${name}.first_name`}
        placeholder={t('Enter first name')}
      />
      <TextInput
        type="text"
        className="w-full lg:w-1/2"
        name={`${name}.last_name`}
        placeholder={t('Enter last name')}
      />
    </div>
  );
};
