import type { FC } from 'react';

import { format } from 'date-fns';

import { DateInput } from './';

import type { FutureDateInputProps } from './FutureDateInput.interface';

const defaultFutureYears = 5;

export const FutureDateInput: FC<FutureDateInputProps> = ({
  futureYears = defaultFutureYears,
  ...props
}) => {
  return (
    <DateInput
      min={format(new Date(), 'yyyy-MM-dd')}
      max={format(
        new Date().setFullYear(new Date().getFullYear() + futureYears),
        'yyyy-MM-dd',
      )}
      {...props}
    />
  );
};
