import type { FC } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { t } from '@nodal/i18n';
import { forwardRef, Fragment, useRef } from 'react';

import { Button } from 'components/Button';
import { ButtonLoading } from 'components/ButtonLoading';

import type { ConfirmationDialogProps } from './ConfirmationDialog.interface';

export const ConfirmationDialogContent = forwardRef<
  HTMLButtonElement,
  Omit<ConfirmationDialogProps, 'open'>
>(({ title, message, onSubmit, onClose, variant = 'question' }, cancelRef) => (
  <>
    <div className="sm:flex sm:items-start">
      {variant === 'alert' && (
        <div className="flex shrink-0 justify-center items-center mx-auto w-12 h-12 bg-red-100 rounded-full sm:mx-0 sm:w-10 sm:h-10">
          <ExclamationIcon
            className="w-6 h-6 text-red-600"
            aria-hidden="true"
          />
        </div>
      )}
      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <h3 className="text-xl font-medium leading-7 text-grey-forest-900">
          {title}
        </h3>
        {message && (
          <div className="mt-2">
            <p className="text-sm text-grey-forest-500">{message}</p>
          </div>
        )}
      </div>
    </div>
    <div className="gap-2 mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
      <ButtonLoading
        variant={variant === 'alert' ? 'danger' : 'primary'}
        onClick={onSubmit}
      >
        {t('Yes')}
      </ButtonLoading>
      <Button variant="tertiary" onClick={onClose} ref={cancelRef}>
        {t('Cancel')}
      </Button>
    </div>
  </>
));

ConfirmationDialogContent.displayName = 'ConfirmationDialogContent';

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  title,
  message,
  open,
  onSubmit,
  onClose,
  variant = 'question',
}) => {
  const cancelButtonRef = useRef(null);

  return (
    // TODO: Consider using the Modal component from @nodal/uikit if it's ready
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75  transition-opacity" />
        </Transition.Child>
        <div className="overflow-y-auto fixed inset-0 z-10">
          <div className="flex justify-center items-end p-4 min-h-full text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="overflow-hidden relative px-4 pt-5 pb-4 text-left bg-white rounded-lg shadow-xl transition-all sm:p-6 sm:my-8 sm:w-full sm:max-w-lg">
                <ConfirmationDialogContent
                  title={title}
                  message={message}
                  variant={variant}
                  onSubmit={onSubmit}
                  onClose={onClose}
                  ref={cancelButtonRef}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
