import React, { FC, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { t } from '@nodal/i18n';
import {
  PlanFeature,
  SubscriptionPlanProps,
} from './SubscriptionPlan.interface';

const planFeatures: PlanFeature[] = [
  {
    id: 1,
    included: true,
    title: t('Highly-vetted surrogates'),
  },
  {
    id: 2,
    included: true,
    title: t('Legal & Insurance support'),
  },
  {
    id: 3,
    included: true,
    title: t('Transparency'),
  },
  {
    id: 4,
    included: true,
    title: t('Educational tools and resources'),
  },
];

export const SubscriptionPlan: FC<SubscriptionPlanProps> = ({
  name,
  amount,
  selected,
}) => {
  const [open, setOpen] = useState(false);

  const onToggleIsOpen = () => setOpen((prevOpen) => !prevOpen);

  const openOrSelected = selected === undefined ? open : selected;

  const subscriptionPlanContainerClassName = classNames(
    'py-4 px-8 space-y-6 w-full bg-white rounded-lg shadow-sm hover:cursor-pointer',
    openOrSelected
      ? 'border-2 border-forest-500'
      : 'border border-grey-forest-200',
  );

  return (
    <div
      className={subscriptionPlanContainerClassName}
      onClick={onToggleIsOpen}
    >
      <div className="flex justify-between items-center w-full text-sm font-medium text-left text-grey-forest-900 focus:outline-none">
        <div>
          {selected && (
            <p className="pb-2 text-base font-medium leading-6 text-grey-forest-600">
              {t('Your plan')}
            </p>
          )}
          <h2 className="text-xl font-semibold leading-7 text-grey-forest-900">
            {/* NOTE: Possibly plan name should be localized as well, but need to come up with solution to localize backend information. */}
            {name}
          </h2>
          <p className="text-base font-normal leading-6 text-grey-forest-900">
            ${t('{amount} USD + fees', amount)}
            <span className="text-sm text-grey-forest-600">/{t('month')}</span>
          </p>
        </div>

        <ChevronDownIcon
          className={`${
            openOrSelected ? 'rotate-180 transform' : ''
          } h-6 w-6 text-grey-forest-400`}
        />
      </div>
      {openOrSelected && (
        <div className="space-y-6 text-sm text-gray-500">
          <div className="relative">
            <div
              className="flex absolute inset-0 items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-grey-forest-200" />
            </div>
            <div className="flex relative justify-start">
              <span className="text-xs font-medium tracking-wide leading-4 uppercase bg-white">
                {t("What's included")}
              </span>
            </div>
          </div>
          <div className="space-y-5">
            {planFeatures.map(({ id, title }) => (
              <div className="flex gap-3 w-full" key={id}>
                <CheckCircleIcon className="w-4 h-4 text-forest-200" />
                {/* NOTE: Possibly plan features should be localized as well, but need to come up with solution to localize backend information. */}
                <p>{title}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
