import type { FC } from 'react';

import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';

import { calculateTotal } from '@uikit/components/Payment/utils';
import { Tooltip } from '@uikit/components/Tooltip';

import type { PaymentSummaryCardProps } from './PaymentSummaryCard.interface';

export const PaymentSummaryCard: FC<PaymentSummaryCardProps> = ({
  subtotal,
  taxes,
  fees,
}) => (
  <div className="py-4 px-8 space-y-6 w-full bg-white rounded-lg border border-grey-forest-200 shadow-sm">
    <p className="text-lg font-medium leading-6 text-grey-forest-900">
      {t('Summary')}
    </p>
    <div className="flex flex-col gap-2">
      <div className="flex justify-between text-base font-medium leading-6">
        <p className=" text-grey-forest-600">{t('Subtotal')}</p>
        <p className="text-grey-forest-900">${subtotal}</p>
      </div>

      {!!fees && (
        <div className="flex justify-between text-base font-medium leading-6">
          <p className=" text-grey-forest-600">{t('Stripe Processing Fees')}</p>
          <p className="text-grey-forest-900">${fees}</p>
        </div>
      )}

      {!!taxes && (
        <div className="flex justify-between text-base font-medium leading-6">
          <div className="flex gap-2 items-center">
            <p className="text-grey-forest-600">{t('Taxes')}</p>
            {/* TODO: Add proper message for Tooltip */}
            <Tooltip message={t('Taxes')} position="top">
              <QuestionMarkCircleIcon className="w-4 h-4 text-grey-forest-400" />
            </Tooltip>
          </div>

          <p className="text-grey-forest-900">${taxes}</p>
        </div>
      )}

      <div className="flex justify-between pt-3 border-t border-grey-forest-200">
        <p className="text-lg font-medium leading-6 text-grey-forest-600">
          {t('Total')}
        </p>
        <p className="font-medium text-grey-forest-900">
          ${calculateTotal(subtotal, fees, taxes)}
        </p>
      </div>
    </div>
  </div>
);
