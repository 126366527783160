import type { FC } from 'react';

import { CheckIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import type { StepProps } from './Step.interface';

export const Step: FC<StepProps> = ({
  title,
  index,
  label,
  status,
  active,
}) => {
  const stepContainerClassName = classNames(
    label ? 'items-start' : 'items-center',
    'flex py-5 px-3 text-sm font-medium sm:px-6',
  );

  const activeBarClassName = classNames(
    active ? 'bg-forest-500' : 'bg-transparent',
    'absolute top-0 left-0 w-1 h-full sm:block hidden',
  );

  return (
    <div className="overflow-hidden w-72">
      {status === 'complete' && (
        <div className="group">
          <span className={activeBarClassName} aria-hidden="true" />
          <span className={stepContainerClassName}>
            <span className="shrink-0">
              <span className="flex justify-center items-center w-10 h-10 bg-forest-500 rounded-full">
                <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
              </span>
            </span>
            <span className="flex flex-col mt-0.5 ml-4 min-w-0">
              <span className="text-xs font-semibold tracking-wide text-forest-500 uppercase">
                {title}
              </span>
              <span className="text-sm font-medium text-grey-forest-600">
                {label}
              </span>
            </span>
          </span>
        </div>
      )}
      {status === 'processing' && (
        <div aria-current="step">
          <span className={activeBarClassName} aria-hidden="true" />
          <span className={stepContainerClassName}>
            <span className="shrink-0">
              <span className="flex justify-center items-center w-10 h-10 rounded-full border-2 border-forest-500">
                <span className="text-forest-500">{index}</span>
              </span>
            </span>
            <span className="flex flex-col mt-0.5 ml-4 min-w-0">
              <span className="text-xs font-semibold tracking-wide text-forest-500 uppercase">
                {title}
              </span>
              <span className="text-sm font-medium text-grey-forest-600">
                {label}
              </span>
            </span>
          </span>
        </div>
      )}
      {status === 'pending' && (
        <div className="group">
          <span
            className="absolute top-0 left-0 w-1 h-full bg-transparent"
            aria-hidden="true"
          />
          <span className={stepContainerClassName}>
            <span className="shrink-0">
              <span className="flex justify-center items-center w-10 h-10 rounded-full border-2 border-gray-300">
                <span className="text-grey-forest-600">{index}</span>
              </span>
            </span>
            <span className="flex flex-col mt-0.5 ml-4 min-w-0">
              <span className="text-xs font-semibold tracking-wide text-grey-forest-600 uppercase">
                {title}
              </span>
              <span className="text-sm font-medium text-grey-forest-600">
                {label}
              </span>
            </span>
          </span>
        </div>
      )}
    </div>
  );
};
