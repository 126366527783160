import type { ReactNode } from 'react';

import { createContext, useContext, useRef, useState } from 'react';

import { ConfirmationDialog } from './ConfirmationDialog';

import type {
  ConfirmationDialogContextType,
  ConfirmationOptions,
} from './ConfirmationDialog.interface';

const ConfirmationDialogContext = createContext<ConfirmationDialogContextType>(
  Promise.reject,
);

export const useConfirmationDialog: () => ConfirmationDialogContextType = () =>
  useContext(ConfirmationDialogContext);

export const ConfirmationDialogProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [open, setOpen] = useState(false);
  const [confirmationOptions, setConfirmationOptions] = useState<
    ConfirmationOptions | undefined
  >();

  const awaitingPromiseRef = useRef<{
    resolve: (value: boolean) => void;
  }>();

  const openConfirmation = async (options: ConfirmationOptions) => {
    setConfirmationOptions(options);
    setOpen(true);

    return new Promise<boolean>((resolve) => {
      awaitingPromiseRef.current = { resolve };
    });
  };

  const handleSubmit = async () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(true);
    }

    setOpen(false);
  };

  const handleClose = async () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(false);
    }

    setOpen(false);
  };

  return (
    <>
      <ConfirmationDialogContext.Provider value={openConfirmation}>
        {children}
        <ConfirmationDialog
          open={open}
          onSubmit={handleSubmit}
          onClose={handleClose}
          {...confirmationOptions}
        />
      </ConfirmationDialogContext.Provider>
    </>
  );
};
