import classNames from 'classnames';
import { CheckIcon } from '@heroicons/react/solid';
import { FC } from 'react';
import { StageProgressProps } from './StageProgress.interface';

export const StageProgress: FC<StageProgressProps> = ({
  titles,
  currentStage,
}) => {
  return (
    <nav aria-label="Progress" className="w-full">
      <ol
        role="list"
        className="flex justify-between items-center w-full whitespace-nowrap"
      >
        {titles.map((title, stepIdx) => (
          <li
            key={title}
            className={classNames(
              stepIdx !== titles.length - 1 ? 'w-full' : '',
              'relative',
            )}
          >
            {stepIdx < currentStage && (
              <>
                <div
                  className="flex absolute inset-0 items-center"
                  aria-hidden="true"
                >
                  <div className="w-full h-0.5 bg-forest-400" />
                </div>
                <div className="flex relative justify-center items-center w-8 h-8 bg-forest-400 rounded-full border-2 border-forest-400">
                  <CheckIcon
                    className="w-5 h-5 text-white"
                    aria-hidden="true"
                  />
                  <span className="absolute top-full p-2 w-min text-sm font-medium text-center text-forest-400 lg:w-max">
                    {title}
                  </span>
                </div>
              </>
            )}
            {stepIdx === currentStage && (
              <>
                <div
                  className="flex absolute inset-0 items-center"
                  aria-hidden="true"
                >
                  <div className="w-full h-0.5 bg-moss-400" />
                </div>
                <div
                  className="flex relative justify-center items-center w-8 h-8 bg-white rounded-full border-2 border-forest-400"
                  aria-current="step"
                >
                  <span
                    className="absolute w-2.5 h-2.5 bg-forest-400 rounded-full"
                    aria-hidden="true"
                  />
                  <span className="absolute top-full p-2 w-min text-sm font-medium text-center text-forest-400 lg:w-max">
                    {title}
                  </span>
                </div>
              </>
            )}
            {stepIdx > currentStage && (
              <>
                <div
                  className="flex absolute inset-0 items-center"
                  aria-hidden="true"
                >
                  <div className="w-full h-0.5 bg-moss-400" />
                </div>
                <div className="group flex relative justify-center items-center w-8 h-8 bg-white rounded-full border-2 border-moss-300">
                  <span
                    className="w-2.5 h-2.5 bg-transparent rounded-full"
                    aria-hidden="true"
                  />
                  <span className="absolute top-full p-2 w-min text-sm font-medium text-center text-forest-400 lg:w-max">
                    {title}
                  </span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};
