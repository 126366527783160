import type { FC } from 'react';

import { MailIcon } from '@heroicons/react/outline';
import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';

import type { ConfirmEmailInfoScreenProps } from './ConfirmEmailInfoScreen.interface';

export const ConfirmEmailInfoScreen: FC<ConfirmEmailInfoScreenProps> = ({
  email,
  onConfirm,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-6 justify-center items-center">
        <MailIcon className="w-16 text-grey-forest-400 stroke-1" />
        <div className="text-center">
          <h2 className="mb-2 text-2xl font-semibold leading-8 text-grey-forest-900">
            {t('Confirm your e-mail')}
          </h2>
          <p className="w-fit text-base font-normal leading-6 text-grey-forest-600">
            {t('Please confirm that')}&nbsp;
            <span className="font-medium">{email}</span>&nbsp;
            {t(
              "is your e-mail address. Once you confirm you'll finish creating your account.",
            )}
          </p>
        </div>
        <ButtonLoading
          variant="primary"
          onClick={onConfirm}
          className="justify-center mt-2 w-full sm:w-max"
        >
          {t('Confirm my E-mail')}
        </ButtonLoading>
      </div>
    </div>
  );
};
