import { MenuButton } from '@nodal/uikit/components/MenuButton';
import { NavigationItems } from '@nodal/uikit/components/NavigationItems';
import { Avatar } from '@nodal/uikit/components/Avatar';
import { NodalLogo } from 'assets';
import { createInitials, sortPhotosByOrder } from '@nodal/core/utils';
import { MobileNavProps } from './MobileNav.interface';
import { useMemo } from 'react';
import { NotificationCenter } from 'components/TopNav/NotificationCenter';
import { Popover } from '@headlessui/react';
import { apiEnums } from '@nodal/api';

export const MobileNav: React.FC<MobileNavProps> = ({
  open,
  menuItems,
  navigationItems,
  profileData,
  onClose,
}) => {
  const avatarUrl = useMemo(
    () =>
      profileData?.profile_photos?.length
        ? sortPhotosByOrder(profileData.profile_photos).find((photo) => photo)
            ?.image
        : '',
    [profileData?.profile_photos],
  );

  const { email } = profileData || {};
  const { first_name, last_name } = profileData?.profile || {};

  const initials = useMemo(
    () =>
      !avatarUrl && first_name && last_name
        ? createInitials(first_name, last_name)
        : '',
    [first_name, last_name, avatarUrl],
  );

  return (
    <>
      <div className="flex relative justify-start items-center px-4 h-[var(--height-top-nav)]">
        <img className="block w-[80px] h-8" src={NodalLogo} alt="Nodal Logo" />
      </div>
      <div className="absolute top-8 right-2 -translate-y-1/2">
        <MenuButton open={open} />
      </div>
      <div className="flex justify-end items-center w-full">
        <Popover.Panel className="left-0 w-full">
          <div className="space-y-1">
            <div className="block">
              <div
                className={
                  navigationItems?.length
                    ? 'px-2 pt-2 pb-3 border-t border-forest-400'
                    : ''
                }
              >
                <NavigationItems
                  navigationItems={navigationItems}
                  onClose={onClose}
                />
              </div>
              <div className="p-2 pb-3 border-t border-forest-400">
                <div className="group block visible shrink-0 px-3 pt-2 pb-3">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <Avatar
                        avatarUrl={avatarUrl}
                        initials={initials}
                        className="w-10 h-10"
                      />
                      <div className="ml-3">
                        <p className="text-base font-medium text-beige-500">
                          {first_name} {last_name}
                        </p>
                        <p className="text-sm font-medium text-moss-300">
                          {email}
                        </p>
                      </div>
                    </div>
                    {profileData?.role !== apiEnums.UserRoleEnum.Nap && (
                      <NotificationCenter />
                    )}
                  </div>
                </div>
                <NavigationItems
                  navigationItems={menuItems}
                  onClose={onClose}
                />
              </div>
            </div>
          </div>
        </Popover.Panel>
      </div>
    </>
  );
};
