import { useMemo } from 'react';

import { getValidationSchema } from './validation';

import type {
  ProfileData,
  QuestionData,
  FormValues,
} from '../MatchProfile.interface';
import type { EditFormConnectedProps } from './EditForm.interface';

export const useEditForm = <T extends ProfileData>({
  profileSections,
  section,
  onCloseModal,
  submit,
  userProfile,
}: EditFormConnectedProps<T>) => {
  const questions: QuestionData<T>[] = useMemo(() => {
    return section
      ? profileSections[section]?.questions.filter(
          (question: QuestionData<T>) =>
            userProfile && question?.hidden
              ? !question.hidden(userProfile)
              : true,
        )
      : [];
  }, [profileSections, section, userProfile]);

  const initialValues = useMemo(
    () =>
      questions.reduce<FormValues<T>>(
        (acc: FormValues<T>, question: QuestionData<T>) => ({
          ...acc,
          [question.value]: question.default,
        }),

        // NOTE: use casting, due we don't use an initial object with profile data but an empty object literal
        {} as FormValues<T>,
      ),
    [questions],
  );

  const onSubmit = async (data: T) => {
    if (userProfile && section && data) {
      const transformedSubmitData = profileSections[section].transform
        ? profileSections[section].transform!(data)
        : data;

      await submit(transformedSubmitData);

      onCloseModal();
    }
  };

  return {
    validationSchema: getValidationSchema(questions),
    questions,
    section,
    onSubmit,
    initialValues,
  };
};
