import type { FC } from 'react';

import classNames from 'classnames';

import {
  comfortLevelChoices,
  importanceChoices,
  radioChoices,
} from '../profileInformationOptions';

import type { SectionTagProps } from './SectionTag.interface';

const getRadioChoice = (tag: SectionTagProps['tag']) =>
  radioChoices?.find((choice) => choice.label === tag);

export const SectionTag: FC<SectionTagProps> = ({ tag }) => {
  const RadioIcon = getRadioChoice(tag)?.icon;
  const radioValue = getRadioChoice(tag)?.value;

  const radioIconClassName = classNames({
    'w-5 h-5 text-forest-200': radioValue === 'true',
    'w-5 h-5 text-rose-500': radioValue === 'false',
  });

  const isComfortChoice = !!comfortLevelChoices.find((c) => c.label === tag);
  const isImportanceChoice = !!importanceChoices.find((i) => i.label === tag);

  const tagClassName = classNames({
    'py-1 px-2 w-max bg-grey-forest-100 rounded-md':
      isComfortChoice || isImportanceChoice,
  });

  return RadioIcon ? (
    <RadioIcon className={radioIconClassName} />
  ) : (
    <span className={tagClassName}>{tag}</span>
  );
};
