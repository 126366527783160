import type { FC } from 'react';

import { PaymentElement } from '@nodal/core/flows/PaymentElement';
import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { paths, paymentServicePaths } from 'consts/paths';
import { useCallback, useRef } from 'react';

import type { PaymentFormProps } from './PaymentForm.interface';
import type { PaymentElementRef } from '@nodal/core/flows/PaymentElement';

export const PaymentForm: FC<PaymentFormProps> = ({ initiatePayment }) => {
  const paymentElementRef = useRef<PaymentElementRef>(null);
  const onConfirm = useCallback(async () => {
    await paymentElementRef.current?.confirmPaymentIntent();
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <PaymentElement
        ref={paymentElementRef}
        initiatePayment={initiatePayment}
        redirectPath={`${paths.paymentService}/${paymentServicePaths.paymentComplete}`}
      />
      <ButtonLoading
        variant="primary"
        onClick={onConfirm}
        className="justify-center"
      >
        {t('Confirm Payment')}
      </ButtonLoading>
    </div>
  );
};
