import { UserAddIcon } from '@heroicons/react/outline';
import { t } from '@nodal/i18n';

export const NoFavoritesScreen = () => {
  return (
    <div className="flex flex-col flex-1 justify-center items-center">
      <UserAddIcon className="aspect-square mb-8 w-12 stroke-grey-forest-400 stroke-1" />
      <p className="mb-2 font-avenir-next text-2xl font-semibold leading-8 text-grey-forest-900">
        {t('No favorites')}
      </p>

      <p className="mb-6 font-avenir-next text-base font-normal leading-6 text-center text-grey-forest-600">
        {t(
          "At the moment, you don't have any saved profiles in your favorites.",
        )}
      </p>
    </div>
  );
};
