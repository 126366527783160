import { states } from '@nodal/core/consts/states';
import { t } from '@nodal/i18n';
import { AddressValue, ContactDetail } from 'components/ContactDetails';
import { ContactValues } from './SurrogateContactDetails.interface';

export const donorAddressDetails: AddressValue[] = [
  {
    value: 'line1',
    label: t('Address Line 1'),
    type: 'text',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'line2',
    label: t('Address Line 2'),
    type: 'text',
    choices: null,
    default: null,
    required: false,
  },
  {
    value: 'city',
    label: t('City'),
    choices: null,
    type: 'text',
    default: null,
    required: true,
  },
  {
    value: 'state',
    label: t('State/Province'),
    choices: states,
    type: 'select',
    default: null,
    required: true,
  },
  {
    value: 'zipcode',
    label: t('Zip/Postal'),
    choices: null,
    type: 'text',
    default: null,
    required: true,
  },
];

export const donorContactDetails: ContactDetail<ContactValues>[] = [
  {
    value: 'first_name',
    label: t('First Name'),
    type: 'text',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'middle_name',
    label: t('Middle Name'),
    type: 'text',
    choices: null,
    default: null,
    required: false,
  },
  {
    value: 'last_name',
    label: t('Last Name'),
    type: 'text',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'maiden_name',
    label: t('Maiden name or any other names used in the past (optional)'),
    type: 'text',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'date_of_birth',
    label: t('Date of Birth'),
    type: 'past_date',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'phone_number',
    label: t('Phone Number'),
    type: 'phone',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'address',
    choices: null,
    default: donorAddressDetails,
    required: true,
  },
];
