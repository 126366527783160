import { MatchProfile } from 'components/MatchProfile';
import { NavigatorMatchProfileProps } from './MatchProfile.interface';
import { FC } from 'react';
import { hasSamePartnerAddress } from '@nodal/core/utils/profile';

export const NavigatorMatchProfile: FC<NavigatorMatchProfileProps> = ({
  profileData,
}) => {
  const navigatorProfileData = profileData && {
    ...profileData,
    profile: {
      ...profileData?.profile,
      same_partner_address: hasSamePartnerAddress(profileData.profile),
    },
  };

  return <MatchProfile profileData={navigatorProfileData} owner={true} />;
};
