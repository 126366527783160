import type { FC } from 'react';

import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { Checkbox } from '@nodal/uikit/components/Checkbox';
import { FormErrors } from '@nodal/uikit/components/FormErrors';
import { HiddenField } from '@nodal/uikit/components/HiddenField';
import { Select } from '@nodal/uikit/components/Select';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { Form, Formik } from 'formik';
import { useCallback, useState } from 'react';

import type { PasswordsShown, SignUpFormProps } from './SignUpForm.interface';

const roleOptions = [
  {
    label: t('Intended parent'),
    value: apiEnums.UserRoleEnum.Opa,
  },
  {
    label: t('Organization'),
    value: apiEnums.UserRoleEnum.Oad,
  },
];

export const SignUpForm: FC<SignUpFormProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  disabledEmail = false,
  roleSelectionVisible,
}) => {
  const [passwordsShown, setPasswordsShown] = useState<PasswordsShown>({
    password1: false,
    password2: false,
  });

  const handleChangePasswordsShown = useCallback(
    (key: keyof PasswordsShown) =>
      setPasswordsShown({ ...passwordsShown, [key]: !passwordsShown[key] }),
    [passwordsShown],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="space-y-8">
          <FormErrors />
          {roleSelectionVisible ? (
            <Select
              label={t(
                'Are you part of an organization or an intended parent?',
              )}
              options={roleOptions}
              name="role"
            />
          ) : (
            <HiddenField name="role" type="hidden" />
          )}
          <TextInput
            label={t('Email address')}
            name="email"
            required
            type="text"
            disabled={disabledEmail}
          />
          <div className="relative">
            <TextInput
              label={t('Password')}
              name="password1"
              required
              type={passwordsShown?.password1 ? 'text' : 'password'}
            />
            <button
              type="button"
              onClick={() => handleChangePasswordsShown('password1')}
              className="absolute right-3 bottom-2 mb-0.25"
            >
              {passwordsShown?.password1 ? (
                <EyeOffIcon className="w-5 h-5 text-grey-forest-400" />
              ) : (
                <EyeIcon className="w-5 h-5 text-grey-forest-400" />
              )}
            </button>
          </div>
          <div className="relative">
            <TextInput
              label={t('Confirm Password')}
              name="password2"
              required
              type={passwordsShown?.password2 ? 'text' : 'password'}
            />
            <button
              type="button"
              onClick={() => handleChangePasswordsShown('password2')}
              className="absolute right-3 bottom-2 mb-0.25"
            >
              {passwordsShown?.password2 ? (
                <EyeOffIcon className="w-5 h-5 text-grey-forest-400" />
              ) : (
                <EyeIcon className="w-5 h-5 text-grey-forest-400" />
              )}
            </button>
          </div>
          <div className="flex gap-2 items-center">
            <Checkbox name="agree_terms" label="" />
            <label htmlFor="agree_terms" className="text-grey-forest-700">
              {t('I agree to the')}&nbsp;
              <a
                href="https://www.nodal.com/terms-of-use"
                title={t('Nodal Terms')}
                className="font-medium text-forest-500"
                target="_blank"
                rel="noreferrer"
              >
                {t('Nodal Terms')}
              </a>
            </label>
          </div>
          <ButtonLoading
            variant="primary"
            type="submit"
            className="justify-center w-full"
            loading={isSubmitting}
          >
            {t('Sign up')}
          </ButtonLoading>
        </Form>
      )}
    </Formik>
  );
};
