import { paths, matchingPaths, navigatorPaths } from 'consts/paths';
import { t } from '@nodal/i18n';
import { NavigationItem } from '@nodal/uikit/components/NavigationItems';
import { TopNavItems } from './TopNav.interface';
import { apiEnums } from '@nodal/api';
import compact from 'lodash/compact';
import { settings } from 'settings';

/**
 * Basic navigation items that apply after completed screening
 */
const navigationItemsScreeningComplete: NavigationItem[] = [
  {
    label: t('Matches'),
    href: `${paths.matching}/${matchingPaths.matches}`,
  },
  {
    label: t('Match Profile'),
    href: `${paths.matching}/${matchingPaths.profile}`,
  },
];

/**
 * Navigation items based on user's role, always displayed regardless of status
 * in getNavigationItems function, where we currently render empty arrays [] (Scr, Dea, Dis)
 */
export const roleToNavigationItemsAlwaysDisplayed: TopNavItems = {
  [apiEnums.UserRoleEnum.Dnr]: [],
  [apiEnums.UserRoleEnum.Par]: compact([
    settings.getIpDashboardFeatureEnabled() && {
      label: t('Home'),
      href: `${paths.dashboard}`,
    },
  ]),
  [apiEnums.UserRoleEnum.Nap]: [],
};

/**
 * Navigation items based on user's role, after completed screening
 */
export const roleToNavigationItemsScreeningComplete: TopNavItems = {
  [apiEnums.UserRoleEnum.Dnr]: [
    {
      label: t('Find Parents'),
      href: `${paths.matching}/${matchingPaths.parents}`,
    },
    {
      label: t('Favorites'),
      href: `${paths.matching}/${matchingPaths.favorites}`,
    },
    ...navigationItemsScreeningComplete,
  ],
  [apiEnums.UserRoleEnum.Par]: compact([
    settings.getIpDashboardFeatureEnabled() && {
      label: t('Home'),
      href: `${paths.dashboard}`,
    },
    ...navigationItemsScreeningComplete,
  ]),
  [apiEnums.UserRoleEnum.Nap]: [
    {
      label: t('Navigator Profile'),
      href: `${paths.navigator}/${navigatorPaths.profile}`,
    },
  ],
};

/**
 * Navigation items based on user's role, for Mat+ status
 */
export const roleToNavigationItemsMatched: TopNavItems = {
  [apiEnums.UserRoleEnum.Dnr]: navigationItemsScreeningComplete,
  [apiEnums.UserRoleEnum.Par]: compact([
    settings.getIpDashboardFeatureEnabled() && {
      label: t('Home'),
      href: `${paths.dashboard}`,
    },
    ...navigationItemsScreeningComplete,
  ]),
  [apiEnums.UserRoleEnum.Nap]: [],
};

/**
 * Navigation items based on user's role, for Dea status
 */
export const roleToNavigationItemsDeactivated: TopNavItems = {
  [apiEnums.UserRoleEnum.Dnr]: [
    {
      label: t('Match Profile'),
      href: `${paths.matching}/${matchingPaths.profile}`,
    },
  ],
  [apiEnums.UserRoleEnum.Par]: compact([
    settings.getIpDashboardFeatureEnabled() && {
      label: t('Home'),
      href: `${paths.dashboard}`,
    },
    {
      label: t('Match Profile'),
      href: `${paths.matching}/${matchingPaths.profile}`,
    },
  ]),
  [apiEnums.UserRoleEnum.Nap]: [
    {
      label: t('Navigator Profile'),
      href: `${paths.navigator}/${navigatorPaths.profile}`,
    },
  ],
};

/**
 * Basic user menu items that apply for all users, no matter their role
 */
export const basicMenuItems: NavigationItem[] = [
  {
    href: paths.profile,
    label: t('Edit Profile'),
  },
  {
    href: paths.logout,
    label: t('Sign out'),
    active: true,
  },
];

/**
 * User menu items based on user's role, always displayed regardless of status
 */
export const roleToUserMenuItemsAlwaysDisplayed = {
  [apiEnums.UserRoleEnum.Dnr]: basicMenuItems.filter((item) => item.active),
  [apiEnums.UserRoleEnum.Par]: basicMenuItems.filter((item) => item.active),
  [apiEnums.UserRoleEnum.Nap]: basicMenuItems.filter((item) => item.active),
};

/**
 * User menu items based on user's role, after completed screening
 */
export const roleToUserMenuItemsScreeningComplete: TopNavItems = {
  [apiEnums.UserRoleEnum.Dnr]: [
    { href: paths.profile, label: t('Edit Profile') },
    { href: paths.uploadRecords, label: t('Upload Medical Records') },
    {
      href: paths.logout,
      label: t('Sign out'),
      active: true,
    },
  ],
  [apiEnums.UserRoleEnum.Par]: [
    {
      href: paths.profile,
      label: t('Edit Profile'),
    },
    {
      href: paths.paymentService,
      label: t('Nodal Services'),
    },
    {
      href: paths.logout,
      label: t('Sign out'),
      active: true,
    },
  ],
  [apiEnums.UserRoleEnum.Nap]: [
    {
      href: paths.profile,
      label: t('Edit Profile'),
    },
    {
      href: paths.paymentService,
      label: t('Nodal Services'),
      // NOTE: By default it will be disabled,
      // only after obtaining a specific status will the user have access to it
      disabled: true,
    },
    {
      href: paths.logout,
      label: t('Sign out'),
      active: true,
    },
  ],
};

/**
 * User menu items based on user's role, for Dea status
 */
export const roleToUserMenuItemsDeactivated: TopNavItems = {
  [apiEnums.UserRoleEnum.Dnr]: basicMenuItems,
  [apiEnums.UserRoleEnum.Par]: basicMenuItems,
  [apiEnums.UserRoleEnum.Nap]: basicMenuItems,
};
