import React, { FC, useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { FeaturesProps } from './Features.interface';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/outline';

export const Features: FC<FeaturesProps> = ({ amount, title, features }) => {
  const [open, setOpen] = useState(false);

  const onToggleIsOpen = () => setOpen((prevOpen) => !prevOpen);

  const featuresContainerClassName = classNames(
    'py-4 px-8 space-y-6 w-full bg-white rounded-lg shadow-sm hover:cursor-pointer',
    open ? 'border-2 border-forest-500' : 'border border-grey-forest-200',
  );

  return (
    <div className={featuresContainerClassName} onClick={onToggleIsOpen}>
      <div className="flex justify-between items-center w-full text-sm font-medium text-left text-grey-forest-900 focus:outline-none">
        <div>
          <h2 className="text-xl font-semibold leading-7 text-grey-forest-900">
            {title}
          </h2>
          <p className="text-base font-normal leading-6 text-grey-forest-900">
            ${t('{amount} USD + fees', amount)}
          </p>
        </div>

        <ChevronDownIcon
          className={`${
            open ? 'rotate-180 transform' : ''
          } h-6 w-6 text-grey-forest-400`}
        />
      </div>
      {open && (
        <div className="space-y-6 text-sm text-gray-500">
          <div className="relative">
            <div
              className="flex absolute inset-0 items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-grey-forest-200" />
            </div>
            <div className="flex relative justify-start">
              <span className="text-xs font-medium tracking-wide leading-4 uppercase bg-white">
                {t("What's included")}
              </span>
            </div>
          </div>
          <div className="space-y-5">
            {features.map(({ id, title }) => (
              <div className="flex gap-3 w-full" key={id}>
                <CheckCircleIcon className="w-4 h-4 text-forest-200" />
                {/* NOTE: Possibly plan features should be localized as well, but need to come up with solution to localize backend information. */}
                <p>{title}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
