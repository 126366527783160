import * as dateFns from 'date-fns';
import { createPipe, MaskedEnum, MaskedRange } from 'imask';
import { useMemo } from 'react';

import { dateTimeFormat } from './dateFormat';

export const minDateTime = '1899-01-01, 01:00 AM';
export const maxDateTime = `${new Date().getFullYear()}-12-31, 12:59 PM`;

const formatFn = (date: Date, format: string) => dateFns.format(date, format);

const parseFn = (date: string, format: string) => {
  const parsed = dateFns.parse(date, format, new Date());

  return dateFns.isValid(parsed) ? parsed : undefined;
};

export const useDateTimeMask = ({
  dateFormat,
  timeFormat,
  min,
  max,
}: {
  dateFormat: string;
  timeFormat?: string;
  min?: string;
  max?: string;
}) => {
  const format = timeFormat ? dateTimeFormat : dateFormat;

  const minDate = (
    min ? parseFn(min, format) : parseFn(minDateTime, dateTimeFormat)
  )!;

  const maxDate = (
    max ? parseFn(max, format) : parseFn(maxDateTime, dateTimeFormat)
  )!;

  const dateOptions = useMemo(
    () => ({
      pattern: 'Y-`m-`d',
      blocks: {
        Y: {
          mask: MaskedRange,
          from: minDate.getFullYear(),
          to: maxDate.getFullYear(),
        },
        m: {
          mask: MaskedRange,
          from: minDate.getMonth() + 1,
          to: maxDate.getMonth() + 1,
          maxLength: 2,
        },
        d: {
          mask: MaskedRange,
          from: minDate.getDate(),
          to: maxDate.getDate(),
          maxLength: 2,
        },
      },
    }),
    [minDate, maxDate],
  );

  const timeOptions = useMemo(
    () => ({
      pattern: 'hh:mm aa',
      blocks: {
        hh: {
          mask: MaskedRange,
          from: minDate.getHours() % 12 || 12,
          to: maxDate.getHours() % 12 || 12,
          maxLength: 2,
        },
        mm: {
          mask: MaskedRange,
          from: minDate.getMinutes(),
          to: maxDate.getMinutes(),
          maxLength: 2,
        },
        aa: {
          mask: MaskedEnum,
          enum: ['AM', 'PM'],
          maxLength: 2,
        },
      },
    }),
    [minDate, maxDate],
  );

  const options = useMemo(
    () => ({
      mask: Date,
      pattern: timeFormat
        ? `${dateOptions.pattern}, ${timeOptions.pattern}`
        : `${dateOptions.pattern}`,
      blocks: {
        ...dateOptions.blocks,
        ...timeOptions.blocks,
      },
      format: (date: Date) => formatFn(date, format),
      parse: (date: string) => parseFn(date, format),
    }),
    [format, dateOptions, timeOptions, timeFormat],
  );

  const mask = useMemo(() => createPipe(options), [options]);

  return {
    mask,
    options,
    minDate,
    maxDate,
    format: options.format,
    parse: options.parse,
  };
};
