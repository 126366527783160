import { apiEnums } from '@nodal/api';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import some from 'lodash/some';

import type { ApiModel } from '@nodal/api';

export const isInRelationship = (
  value?:
    | ApiModel.DonorProfile['relationship_status']
    | ApiModel.ParentsProfile['relationship_status'],
) =>
  value === apiEnums.RelationshipStatusEnum.Rel ||
  value === apiEnums.RelationshipStatusEnum.Mar ||
  value === apiEnums.RelationshipStatusEnum.Eng;

export const hasSamePartnerAddress = (
  values: ApiModel.ParentsProfileScreen,
): boolean =>
  isEqual(values.address, values.partner_address) &&
  some(mapValues(values.address), (value) => value !== null);

export const dataWithSingleParentNoPartner = (
  values: ApiModel.ParentsProfile,
) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    let partnerValue = null;

    if (key === 'partner_address' && value !== null) {
      partnerValue = Object.fromEntries(
        Object.entries(value).map(([addressKey]) => [addressKey, null]),
      );
    }

    return key.includes('partner_') && value !== null
      ? { ...acc, [key]: partnerValue }
      : { ...acc, [key]: value };
  }, values);
};

export const dataWithSamePartnerAddress = (values: ApiModel.ParentsProfile) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    return key === 'partner_address'
      ? { ...acc, [key]: values['address'] }
      : { ...acc, [key]: value };
  }, values);
};
