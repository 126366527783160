import type { FC } from 'react';

import { Spinner } from '@nodal/uikit/components/Spinner';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSubscriptionContext } from '../../SubscriptionFlow.connect';
import { settings } from 'settings';

import { PaymentForm } from './PaymentForm';

import type { PaymentFormConnectedProps } from './PaymentForm.interface';

const stripe = loadStripe(settings.getStripePublishableKey());

export const PaymentFormConnected: FC<PaymentFormConnectedProps> = ({
  clientSecret,
}) => {
  const { initiatePayment } = useSubscriptionContext();

  if (!stripe || !clientSecret) {
    return (
      <div className="flex justify-center items-center my-0.5 h-28">
        <Spinner size="large" variant="secondary" />
      </div>
    );
  }

  const options = { clientSecret };
  return (
    <Elements stripe={stripe} options={options}>
      <PaymentForm initiatePayment={initiatePayment} />
    </Elements>
  );
};
