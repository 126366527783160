import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { ResetPasswordScreen } from './ResetPasswordScreen';

import type {
  NewPasswordFormValues,
  ResetPasswordScreenConnectedProps,
  ResetPasswordStatus,
} from './ResetPasswordScreen.interface';
import type { ApiModel, GlobalError } from '@nodal/api';
import type { FormikHelpers } from 'formik';

const initialValues: NewPasswordFormValues = {
  new_password1: '',
  new_password2: '',
};

export const ResetPasswordScreenConnected: FC<
  ResetPasswordScreenConnectedProps
> = ({ redirectPaths }) => {
  const navigate = useNavigate();

  const [resetPasswordStatus, setResetPasswordStatus] = useState<
    ResetPasswordStatus | undefined
  >();

  const { search } = useLocation() as {
    search: string;
  };

  const urlSearchParams = new URLSearchParams(search);
  const uid = urlSearchParams.get('uid');
  const token = urlSearchParams.get('token');
  const email = urlSearchParams.get('email');

  useEffect(() => {
    if (!uid || !token) {
      navigate(redirectPaths.signin);
    }
  }, [uid, token, navigate, redirectPaths.signin]);

  const apiClient = useApiClient();

  const resetConfirm = useMutation(
    (payload: ApiModel.AuthApiAuthPasswordResetConfirmCreateRequest) =>
      apiClient.api.AuthApi.authPasswordResetConfirmCreate(payload),
  );

  const setExpiredOrInavlidLinkError = () =>
    setResetPasswordStatus({
      status: 'not_found_error',
      title: t('The reset password link has expired or is invalid'),
      message: t(
        'Please click the button below to get a new reset instructions.',
      ),
    });

  const handleSubmit = async (
    { new_password1, new_password2 }: NewPasswordFormValues,
    { setErrors }: FormikHelpers<NewPasswordFormValues>,
  ) => {
    !uid || !token
      ? setExpiredOrInavlidLinkError()
      : await resetConfirm.mutateAsync(
          {
            passwordResetConfirm: {
              new_password1,
              new_password2,
              uid,
              token,
            },
          },
          {
            onSuccess: () => {
              setResetPasswordStatus({
                status: 'success',
                title: t('Password reset'),
                message: t(
                  'Your password has been successfully reset. Please log in with your new password.',
                ),
              });
            },

            onError: (error: GlobalError) => {
              if (error?.response?.data?.token) {
                setExpiredOrInavlidLinkError();
              } else if (error?.response?.data) {
                //TODO: Temp. workaround - agree on how to manage errors (notification or using Formik)
                setErrors(error.response.data);
              }
            },
          },
        );
  };

  return (
    <ResetPasswordScreen
      resetPasswordStatus={resetPasswordStatus}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      email={email}
      redirectPaths={redirectPaths}
    />
  );
};
