import type { FC } from 'react';

import { Popover } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/solid';

import type { MobileMenuProps } from './MenuButton.interface';

export const MenuButton: FC<MobileMenuProps> = ({ open }) => (
  <Popover.Button className="justify-center items-center p-2 text-beige-500 hover:text-white hover:bg-forest-400 rounded-md focus:outline-none">
    <span className="sr-only">Open main menu</span>
    {open ? (
      <XIcon className="block w-6 h-6" aria-hidden="true" />
    ) : (
      <MenuIcon className="block w-6 h-6" aria-hidden="true" />
    )}
  </Popover.Button>
);
