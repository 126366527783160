import { GlobalError, useApiClient } from '@nodal/api';
import type { MarketplaceParents } from '@nodal/api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { FavoriteParentsList } from './FavoriteParentsList';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { NoFavoritesScreen } from 'components/FavoriteParents/NoFavoritesScreen';
import { useMatching } from 'components/Matching';
import { toast } from 'react-toastify';
import { t } from '@nodal/i18n';
import { queryKeys } from '@nodal/core/consts/query';

const useFavoriteParentsList = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const { profileData } = useMatching();

  const favoriteParentsList = useQuery(
    queryKeys.favoriteParentsList,
    () => apiClient.api.FavoriteParentsApi.favoriteParentsList(),
    {
      cacheTime: 0,
    },
  );

  const createIntroduction = (favoriteParent?: MarketplaceParents) => {
    // NOTE: Only marketplace users have known id
    if (!favoriteParent || !('id' in favoriteParent))
      throw new Error('Unknown user id');
    return apiClient.api.IntroductionsApi.introductionsCreate({
      createIntroduction: { parent_id: favoriteParent.id },
    });
  };

  const { mutateAsync: requestIntroduction } = useMutation(
    'requestIntroduction',
    createIntroduction,
    {
      // NOTE: data of these queries contains an introduction or introductions_left property
      // that needs to be updated
      onSuccess: () =>
        Promise.all([
          queryClient.invalidateQueries(queryKeys.favoriteParentsList),
          queryClient.invalidateQueries(queryKeys.usersMeRetrieve),
        ]),
      onError: (error: GlobalError) => {
        console.log(error?.response?.data);
        if (error?.response?.data) {
          toast.error(t('Something went wrong...'));
        }
      },
    },
  );

  const navigate = useNavigate();

  const onFavoriteClick = (favoriteUserId: number) =>
    navigate(`${favoriteUserId}`);

  const favoriteParents = favoriteParentsList?.data?.data.map(
    ({ parent_profile }) => parent_profile,
  );

  return {
    favoriteParents,
    isLoading: !favoriteParentsList?.data,
    onRequestIntroduction: requestIntroduction,
    onFavoriteClick,
    remainingRequests: profileData?.introductions_left,
  };
};

export const FavoriteParentsListConnected = () => {
  const {
    favoriteParents,
    isLoading,
    onRequestIntroduction,
    onFavoriteClick,
    remainingRequests,
  } = useFavoriteParentsList();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (favoriteParents && !favoriteParents.length) {
    return <NoFavoritesScreen />;
  }

  return (
    <FavoriteParentsList
      favorites={favoriteParents}
      onRequestIntroduction={onRequestIntroduction}
      onFavoriteClick={onFavoriteClick}
      remainingRequests={remainingRequests}
    />
  );
};
