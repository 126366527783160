import { t } from '@nodal/i18n';

export const states = [
  {
    value: 'AL',
    label: t('Alabama'),
  },
  {
    value: 'AK',
    label: t('Alaska'),
  },
  {
    value: 'AZ',
    label: t('Arizona'),
  },
  {
    value: 'AR',
    label: t('Arkansas'),
  },
  {
    value: 'CA',
    label: t('California'),
  },
  {
    value: 'CO',
    label: t('Colorado'),
  },
  {
    value: 'CT',
    label: t('Connecticut'),
  },
  {
    value: 'DC',
    label: t('DC'),
  },
  {
    value: 'DE',
    label: t('Delaware'),
  },
  {
    value: 'FL',
    label: t('Florida'),
  },
  {
    value: 'GA',
    label: t('Georgia'),
  },
  {
    value: 'HI',
    label: t('Hawaii'),
  },
  {
    value: 'ID',
    label: t('Idaho'),
  },
  {
    value: 'IL',
    label: t('Illinois'),
  },
  {
    value: 'IN',
    label: t('Indiana'),
  },
  {
    value: 'IA',
    label: t('Iowa'),
  },
  {
    value: 'KS',
    label: t('Kansas'),
  },
  {
    value: 'KY',
    label: t('Kentucky'),
  },
  {
    value: 'LA',
    label: t('Louisiana'),
  },
  {
    value: 'ME',
    label: t('Maine'),
  },
  {
    value: 'MD',
    label: t('Maryland'),
  },
  {
    value: 'MA',
    label: t('Massachusetts'),
  },
  {
    value: 'MI',
    label: t('Michigan'),
  },
  {
    value: 'MN',
    label: t('Minnesota'),
  },
  {
    value: 'MS',
    label: t('Mississippi'),
  },
  {
    value: 'MO',
    label: t('Missouri'),
  },
  {
    value: 'MT',
    label: t('Montana'),
  },
  {
    value: 'NE',
    label: t('Nebraska'),
  },
  {
    value: 'NV',
    label: t('Nevada'),
  },
  {
    value: 'NH',
    label: t('New Hampshire'),
  },
  {
    value: 'NJ',
    label: t('New Jersey'),
  },
  {
    value: 'NM',
    label: t('New Mexico'),
  },
  {
    value: 'NY',
    label: t('New York'),
  },
  {
    value: 'NC',
    label: t('North Carolina'),
  },
  {
    value: 'ND',
    label: t('North Dakota'),
  },
  {
    value: 'OH',
    label: t('Ohio'),
  },
  {
    value: 'OK',
    label: t('Oklahoma'),
  },
  {
    value: 'OR',
    label: t('Oregon'),
  },
  {
    value: 'PA',
    label: t('Pennsylvania'),
  },
  {
    value: 'RI',
    label: t('Rhode Island'),
  },
  {
    value: 'SC',
    label: t('South Carolina'),
  },
  {
    value: 'SD',
    label: t('South Dakota'),
  },
  {
    value: 'TN',
    label: t('Tennessee'),
  },
  {
    value: 'TX',
    label: t('Texas'),
  },
  {
    value: 'UT',
    label: t('Utah'),
  },
  {
    value: 'VT',
    label: t('Vermont'),
  },
  {
    value: 'VA',
    label: t('Virginia'),
  },
  {
    value: 'WA',
    label: t('Washington'),
  },
  {
    value: 'WV',
    label: t('West Virginia'),
  },
  {
    value: 'WI',
    label: t('Wisconsin'),
  },
  {
    value: 'WY',
    label: t('Wyoming'),
  },
];

export const statesWithDisabledFields = states.map((s) => {
  const disabledStates = ['MI', 'NE', 'LA'];

  return { ...s, disabled: disabledStates.includes(s.value) ? true : false };
});
