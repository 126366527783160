import React, { FC } from 'react';
import { SubscriptionPlan } from 'components/SubscriptionFlow/SubscriptionPlan';
import { ClockIcon, CreditCardIcon, LibraryIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { ApiModel } from '@nodal/api';
import { SubscriptionProcessingProps } from './SubscriptionProcessing.interface';
import { nodalEmail } from 'consts/email';

const paymentTypeDetails = (paymentType?: ApiModel.PaymentTypeEnum) => {
  switch (paymentType) {
    case 'card_payment':
      return { icon: CreditCardIcon, label: t('Card') };
    case 'bank_transfer':
      return { icon: LibraryIcon, label: t('US Bank Account') };

    default:
      return null;
  }
};

export const SubscriptionProcessing: FC<SubscriptionProcessingProps> = ({
  plan,
  paymentType,
}) => {
  const { icon: Icon, label } = paymentTypeDetails(paymentType) || {};

  return (
    <div className="flex flex-col items-center py-8">
      <div className="flex flex-col gap-4 max-w-4xl">
        {!!plan && (
          <SubscriptionPlan name={plan.name} amount={plan.price.amount} />
        )}
        <div className="flex flex-col gap-4 justify-between p-8 text-base font-normal leading-6 text-left text-grey-forest-900 bg-white rounded-lg border border-grey-forest-200 shadow-sm">
          <p className="font-medium">{t('Payment Details')}</p>
          <div className="flex gap-2 items-center mt-2">
            {Icon && <Icon className="w-6 h-6 text-grey-forest-400" />}
            <span className="text-sm leading-5 text-grey-forest-500">
              {label}
            </span>
          </div>
          <div className="flex gap-2 items-center mt-2">
            <ClockIcon className="w-5 h-5 text-grey-forest-400" />
            <span className="text-sm leading-5 text-grey-forest-500">
              {t('We are processing your payment')}
            </span>
          </div>
        </div>
        <p className="text-xs font-normal leading-4 text-grey-forest-600">
          {t('*If you want to cancel your subscription')}
          &nbsp;
          <a
            className="font-medium text-forest-400"
            href={`mailto:${nodalEmail}`}
          >
            {t('contact Nodal')}
          </a>
          &nbsp;
          {t('and we will do it for you right away.')}
        </p>
      </div>
    </div>
  );
};
