import type { FC } from 'react';

import { AvatarPlaceholder as SvgComponent } from '@uikit/assets';

import type { AvatarPlaceholderProps } from './AvatarPlaceholder.interface';

export const AvatarPlaceholder: FC<AvatarPlaceholderProps> = ({
  className = 'w-9 h-9',
}) => (
  <span
    className={`inline-block rounded-full overflow-hidden bg-gray-100 ${className}`}
  >
    <SvgComponent className="w-full h-full text-gray-300" fill="currentColor" />
  </span>
);
