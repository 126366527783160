import { UserRoleEnum } from '@nodal/api/core';
import { statesWithDisabledFields } from '@nodal/core/consts/states';
import React, { FC } from 'react';
import { ContactDetailsProps } from './ContactDetails.interface';
import { ParentContactDetails } from './ParentContactDetails';
import { SurrogateContactDetails } from './SurrogateContactDetails';

export const ContactDetails: FC<ContactDetailsProps> = ({
  role,
  submit,
  profileData,
}) => {
  return (
    <div className="flex gap-4 justify-center py-6 px-4 mx-auto w-full max-w-5xl sm:py-8">
      {role === UserRoleEnum.Dnr ? (
        <SurrogateContactDetails
          submit={submit}
          profileData={profileData}
          states={statesWithDisabledFields}
        />
      ) : (
        <ParentContactDetails submit={submit} profileData={profileData} />
      )}
    </div>
  );
};
