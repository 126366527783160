import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { Checkbox } from '@nodal/uikit/components/Checkbox';
import { PastDateInput } from '@nodal/uikit/components/DateInput';
import { PhoneNumberInput } from '@nodal/uikit/components/PhoneNumberInput';
import {
  PlaceAndNameInput,
  PlaceInput,
} from '@nodal/uikit/components/PlaceInput';
import { Select } from '@nodal/uikit/components/Select';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';

import { getOrdinalNumber } from '@core/utils';

import { pregnancyDetailInitialValues } from './initialValues';

import type {
  FacilityTypeOption,
  PregnancyDetail,
} from './MedicalRecordReview.interface';
import type { FormikValues } from 'formik';

const facilityTypeOptions: FacilityTypeOption[] = [
  {
    label: t('Hospital'),
    value: apiEnums.FacilityTypeEnum.Hospital,
  },
  {
    label: t('Birthing center'),
    value: apiEnums.FacilityTypeEnum.BirthCenter,
  },
  {
    label: t('Home birth'),
    value: apiEnums.FacilityTypeEnum.HomeBirth,
  },
  {
    label: t('Other'),
    value: apiEnums.FacilityTypeEnum.Other,
  },
];

const clinicCountryOptions = [
  {
    label: t('United States of America'),
    value: apiEnums.DoctorClinicCountryEnum.Us,
  },
  {
    label: t('Other'),
    value: apiEnums.DoctorClinicCountryEnum.Other,
  },
];

// NOTE: The component must be embedded inside Formik
export const PregnancyDetailsForm: FC<{
  pregnancyDetails: PregnancyDetail[];
  editable: boolean;
}> = ({ pregnancyDetails, editable }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext<FormikValues>();

  const handleFacilityTypeChange = useCallback(
    (value: string, name: string) => {
      if (
        value === apiEnums.FacilityTypeEnum.Other ||
        value === apiEnums.FacilityTypeEnum.HomeBirth
      ) {
        setFieldValue(
          `${name}.delivery_address`,
          pregnancyDetailInitialValues.delivery_address,
        );
        setFieldValue(
          `${name}.delivery_international_address`,
          pregnancyDetailInitialValues.delivery_international_address,
        );
      }

      setFieldValue(`${name}.facility_type`, value);
      setFieldTouched(`${name}.facility_type`, false);
    },
    [setFieldTouched, setFieldValue],
  );

  const handleClinicCountryChange = useCallback(
    (value: string, name: string) => {
      if (value === apiEnums.DoctorClinicCountryEnum.Other) {
        setFieldValue(
          `${name}.doctor_clinic_address`,
          pregnancyDetailInitialValues.doctor_clinic_address,
        );
      }

      if (value === apiEnums.DoctorClinicCountryEnum.Us) {
        setFieldValue(
          `${name}.doctor_clinic_international_address`,
          pregnancyDetailInitialValues.doctor_clinic_international_address,
        );
      }

      setFieldValue(`${name}.doctor_clinic_country`, value);
      setFieldTouched(`${name}.doctor_clinic_country`, false);
    },
    [setFieldTouched, setFieldValue],
  );

  return (
    <>
      {pregnancyDetails?.map((detail: PregnancyDetail, idx: number) => (
        <div className="grid grid-cols-1 gap-x-4 gap-y-10" key={idx}>
          <div className="relative">
            <div
              className="flex absolute inset-0 items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="flex relative justify-start">
              <span className="pr-3 text-base font-semibold leading-7 text-gray-900 bg-white">
                {t(
                  '{numberWithOrdinal} Pregnancy and Delivery',
                  `${getOrdinalNumber(idx + 1)}`,
                )}
              </span>
            </div>
          </div>
          <PastDateInput
            label={t("Child's date of birth")}
            name={`pregnancy_details.${idx}.delivery_date`}
            placeholder="YYYY-DD-MM"
            className="lg:w-1/2"
            disabled={!editable}
            min="1990-01-01"
            openToDate={new Date('1990-01-01')}
          />
          {idx !== 0 && (
            <Checkbox
              name={`pregnancy_details.${idx}.same_doctor_details_as_first`}
              label={t('Same doctor details as first pregnancy')}
              disabled={!editable}
            />
          )}

          {(idx === 0 || !detail.same_doctor_details_as_first) && (
            <>
              <TextInput
                label={t("Doctor's First Name")}
                name={`pregnancy_details.${idx}.doctor_first_name`}
                type="text"
                disabled={!editable}
              />
              <TextInput
                label={t("Doctor's Middle Name (optional)")}
                name={`pregnancy_details.${idx}.doctor_middle_name`}
                type="text"
                disabled={!editable}
              />
              <TextInput
                label={t("Doctor's Last Name")}
                name={`pregnancy_details.${idx}.doctor_last_name`}
                type="text"
                disabled={!editable}
              />
              <TextInput
                label={t("Doctor's Clinic Name")}
                name={`pregnancy_details.${idx}.doctor_clinic_name`}
                type="text"
                disabled={!editable}
              />
              <Select
                label={t("Doctor's Clinic Country")}
                name={`pregnancy_details.${idx}.doctor_clinic_country`}
                options={clinicCountryOptions}
                disabled={!editable}
                onChange={(value: string) =>
                  handleClinicCountryChange(value, `pregnancy_details.${idx}`)
                }
              />
              {detail.doctor_clinic_country ===
                apiEnums.DoctorClinicCountryEnum.Us && (
                <PlaceInput
                  name={`pregnancy_details.${idx}.doctor_clinic_address`}
                  label={t("Doctor's Clinic Address")}
                  type="address"
                  disabled={!editable}
                  placeholder={t('Enter the address and select location')}
                />
              )}
              {detail.doctor_clinic_country ===
                apiEnums.DoctorClinicCountryEnum.Other && (
                <TextInput
                  label={t("Doctor's Clinic Address")}
                  name={`pregnancy_details.${idx}.doctor_clinic_international_address`}
                  type="text"
                  disabled={!editable}
                  placeholder={t('Enter the address manually')}
                />
              )}
              <PhoneNumberInput
                label={t("Doctor's Phone Number")}
                name={`pregnancy_details.${idx}.doctor_phone_number`}
                placeholder={
                  detail.doctor_clinic_country ===
                  apiEnums.DoctorClinicCountryEnum.Other
                    ? '+1 555 987 6543'
                    : '(555) 987-6543'
                }
                type="text"
                className="lg:w-1/2"
                disabled={!editable}
                international={
                  detail.doctor_clinic_country ===
                  apiEnums.DoctorClinicCountryEnum.Other
                }
              />

              <Select
                label={t('At what type of location did your delivery occur?')}
                name={`pregnancy_details.${idx}.facility_type`}
                options={facilityTypeOptions}
                onChange={(value: string) =>
                  handleFacilityTypeChange(value, `pregnancy_details.${idx}`)
                }
                disabled={!editable}
                optionsPosition="top"
              />
              {detail.facility_type === apiEnums.FacilityTypeEnum.Hospital &&
                detail.doctor_clinic_country ===
                  apiEnums.DoctorClinicCountryEnum.Us && (
                  <PlaceInput
                    name={`pregnancy_details.${idx}.delivery_address`}
                    type="hospital"
                    label={t('Hospital name and address')}
                    disabled={!editable}
                  />
                )}
              {detail.facility_type === apiEnums.FacilityTypeEnum.Hospital &&
                detail.doctor_clinic_country ===
                  apiEnums.DoctorClinicCountryEnum.Other && (
                  <TextInput
                    label={t('Hospital name and address')}
                    name={`pregnancy_details.${idx}.delivery_international_address`}
                    type="text"
                    disabled={!editable}
                    placeholder={t('Enter the name and address manually')}
                  />
                )}
              {detail.facility_type === apiEnums.FacilityTypeEnum.BirthCenter &&
                detail.doctor_clinic_country ===
                  apiEnums.DoctorClinicCountryEnum.Us && (
                  <PlaceAndNameInput
                    label={t('Birthing center name and address')}
                    name={`pregnancy_details.${idx}.delivery_address`}
                    disabled={!editable}
                  />
                )}
              {detail.facility_type === apiEnums.FacilityTypeEnum.BirthCenter &&
                detail.doctor_clinic_country ===
                  apiEnums.DoctorClinicCountryEnum.Other && (
                  <TextInput
                    label={t('Birthing center name and address')}
                    name={`pregnancy_details.${idx}.delivery_international_address`}
                    type="text"
                    disabled={!editable}
                    placeholder={t('Enter the name and address manually')}
                  />
                )}
            </>
          )}
        </div>
      ))}
    </>
  );
};
