import { useQuery } from 'react-query';
import { PaymentFlow } from './PaymentFlow';
import { useApiClient } from '@nodal/api';
import { CreditCardIcon, LibraryIcon } from '@heroicons/react/outline';
import { BillingPaymentIntent } from './PaymentFlow.interface';
import { t } from '@nodal/i18n';
import { PaymentMethodDetails } from './PaymentMethodSelect';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { queryKeys } from '@nodal/core/consts/query';
import { useSinglePaymentService } from '../SinglePaymentService.connect';

const useClientSecrets = () => {
  const apiClient = useApiClient();

  const { data } = useQuery(
    queryKeys.billingStripeNodalServicesPaymentRetrieve,
    () => apiClient.api.BillingApi.billingStripeNodalServicesPaymentRetrieve(),
  );

  // NOTE: Using cast and ts-ignore due to broken API types,
  // expected type is BillingPaymentIntent.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const paymentIntent = data?.data as BillingPaymentIntent;

  return {
    card: paymentIntent?.card.client_secret,
    us_bank_account: paymentIntent?.us_bank_account.client_secret,
  };
};

const usePayment = () => {
  const { paymentModel } = useSinglePaymentService();
  const clientSecrets = useClientSecrets() || undefined;

  const paymentMethods: PaymentMethodDetails[] = [
    {
      method: 'card',
      label: t('Card'),
      icon: CreditCardIcon,
      secret: clientSecrets.card,
      fees: paymentModel?.price.card_payment_fees || undefined,
    },
    {
      method: 'us_bank_account',
      label: t('US Bank Account'),
      icon: LibraryIcon,
      secret: clientSecrets.us_bank_account,
      fees: paymentModel?.price.bank_transfer_fees || undefined,
    },
  ];

  if (!paymentModel) {
    return null;
  }

  return {
    amount: paymentModel.price.amount,
    paymentMethods,
  };
};

export const PaymentFlowConnected = () => {
  const payment = usePayment();
  const { paymentPackage } = useSinglePaymentService();

  if (!payment) {
    return <LoadingScreen />;
  }

  return <PaymentFlow {...payment} paymentPackage={paymentPackage} />;
};
