import type { FC, PropsWithChildren } from 'react';

import { CheckIcon, ChevronRightIcon } from '@heroicons/react/outline';

import { LinkWrapperProps, TaskProps } from './Task.interface';
import { Button } from '@nodal/uikit/components/Button';
import { Link } from 'react-router-dom';

const LinkWrapper: FC<PropsWithChildren<LinkWrapperProps>> = ({
  external,
  url,
  children,
}) => {
  return external ? (
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <Link to={url} rel="noreferrer">
      {children}
    </Link>
  );
};

export const Task: FC<TaskProps> = ({
  onClick,
  disabled,
  title,
  completeTime,
  completed,
  url,
  external,
}) => (
  <LinkWrapper url={url} external={external}>
    <Button
      variant="tertiary"
      onClick={onClick}
      disabled={disabled}
      className={`w-full flex relative justify-between !p-5 ${
        disabled ? '!text-grey-forest-400' : '!text-forest-400'
      } bg-white disabled:bg-white rounded-2xl`}
    >
      <div className="flex gap-4">
        <div
          className={`flex relative justify-center items-center w-8 h-8 rounded-full shrink-0 ${
            completed
              ? 'bg-forest-400 hover:bg-forest-400'
              : 'bg-white border-2 border-forest-400'
          }`}
        >
          {completed ? (
            <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
          ) : (
            <span
              className="absolute w-2.5 h-2.5 bg-transparent rounded-full"
              aria-hidden="true"
            />
          )}
        </div>
        <div className="flex flex-col justify-start items-start md:flex-row md:items-center">
          <p className="flex justify-start items-center text-lg font-semibold text-left">
            {title}
          </p>
          <p className="md:hidden">{`${completeTime} min`}</p>{' '}
        </div>
      </div>
      <div className="flex gap-4 items-center">
        <p className="hidden items-center md:block">{`${completeTime} min`}</p>
        <ChevronRightIcon
          className={`w-5 h-5 ${
            disabled
              ? 'text-grey-forest-500 hover:text-grey-forest-600'
              : 'text-forest-500 hover:text-forest-600'
          }`}
          aria-hidden="true"
        />
      </div>
    </Button>
  </LinkWrapper>
);
