import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useScreening } from '@nodal/core/flows/Screening';
import {
  useMedicalRecordReview,
  MedicalRecordReview,
} from '@nodal/core/flows/Screening/MedicalRecordReview';
import { useGooglePlacesApi } from '@nodal/core/hooks/useGooglePlacesApi';
import { useUploadMedicalDocuments } from '@nodal/core/hooks/useUploadMedicalDocuments';
import { t } from '@nodal/i18n';
import { useConfirmationDialog } from '@nodal/uikit/components/ConfirmationDialog';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { useUsersMeRetrieve } from 'api/hooks/useUsersMeRetrieve';
import { useQuery } from 'react-query';

const title = t('Medical Record Review');
const description = t(
  'If you are a surrogate, please complete the provider details for your OB/GYN or the provider of your last delivery. If you are an egg or sperm donor, please provide details for your primary care physician.',
);

export const MedicalRecordReviewConnected = () => {
  const placesApiReady = useGooglePlacesApi();
  const { currentStep, submit } = useScreening();
  const { data: userData } = useUsersMeRetrieve();
  const confirm = useConfirmationDialog();
  const apiClient = useApiClient();

  const { data: medicalDocuments } = useQuery(
    queryKeys.medicalDocumentsList,
    () =>
      apiClient.api.MedicalReviewsApi.medicalReviewsMedicalDocumentsOwnList(),
  );

  const {
    initialValues,
    displayPregnancyCountInput,
    displayIvfTransferCountInput,
    onSubmit,
    validationSchema,
  } = useMedicalRecordReview({ submit, screenId: currentStep?.id });

  const { onDelete, onUpload, uploadedFiles, disabled } =
    useUploadMedicalDocuments({
      documents: medicalDocuments?.data || [],
      confirm,
      documentType: apiEnums.MedicalDocumentDocumentTypeEnum.SurroDoc,
      medicalReviewId: userData?.data?.medical_review_id,
    });

  if (!placesApiReady || !currentStep) {
    return <LoadingScreen />;
  }

  return (
    <MedicalRecordReview
      initialValues={{
        ...initialValues,
        id: currentStep.id,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      displayPregnancyCountInput={displayPregnancyCountInput}
      displayIvfTransferCountInput={displayIvfTransferCountInput}
      title={title}
      description={description}
      editable
      previewPhotoEnabled={false}
      documentsControl={{
        onDelete,
        onUpload,
        uploadedFiles,
        disabled,
      }}
    />
  );
};
