import React, { FC } from 'react';
import { ParentsMatchProfile } from 'components/MatchProfile/ParentsMatchProfile';
import { ParentsMarketplaceScreenProps } from './ParentsMarketplaceScreen.interface';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/solid';
import { apiEnums } from '@nodal/api';
import { Tooltip } from '@nodal/uikit/components/Tooltip';

export const ParentsMarketplaceScreen: FC<ParentsMarketplaceScreenProps> = ({
  marketUser,
  meUser,
  onShowPrevious,
  onShowNext,
  loadingProfile,
}) => {
  return (
    <div className="flex flex-col col-span-12 w-full h-full font-avenir-next text-sm font-normal leading-5">
      <div className="w-full h-12 sm:h-16">
        <div className="fixed z-10 w-full bg-white shadow-sm">
          <div className="flex py-2 px-6 mx-auto w-full max-w-7xl lg:px-8">
            <Button
              className="hidden font-medium sm:flex sm:text-sm"
              variant="tertiary"
              disabled={loadingProfile}
              onClick={onShowPrevious}
            >
              <ChevronLeftIcon className="aspect-square w-6 fill-current" />
              {t('Previous Profile')}
            </Button>
            <button onClick={onShowPrevious}>
              <ChevronLeftIcon className="aspect-square block w-7 fill-current sm:hidden sm:w-6" />
            </button>
            <div className="flex flex-1 justify-center items-center ml-0 sm:justify-start sm:ml-12">
              <div className="flex gap-2 items-center font-medium">
                <div className="hidden sm:block">
                  {t('Remaining requests')}:
                </div>
                <div className="aspect-square flex justify-center items-center w-7 bg-grey-forest-100 rounded-md sm:w-6">
                  <span>{meUser.introductions_left}</span>
                </div>
                {!meUser.introductions_left && (
                  <Tooltip
                    message={t(
                      'You are currently out of available matches, please wait until one of your match requests expire or is no longer active.',
                    )}
                    position="bottom"
                  >
                    <ExclamationCircleIcon className="aspect-square m-auto w-5 fill-red-500" />
                  </Tooltip>
                )}
              </div>
            </div>
            <Button
              className="hidden font-medium sm:flex sm:text-sm"
              variant="tertiary"
              disabled={loadingProfile}
              onClick={onShowNext}
            >
              {t('Next Profile')}
              <ChevronRightIcon className="aspect-square w-6 fill-current" />
            </Button>
            <button onClick={onShowNext}>
              <ChevronRightIcon className="aspect-square block w-7 fill-current sm:hidden sm:w-6" />
            </button>
          </div>
        </div>
      </div>
      <div className="h-full">
        <div className="flex-1 mx-auto max-w-5xl">
          <ParentsMatchProfile
            profileData={{
              ...marketUser,
              role: apiEnums.UserRoleEnum.Par,
            }}
            owner={false}
            loadingProfile={loadingProfile}
          />
        </div>
      </div>
    </div>
  );
};
