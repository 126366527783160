import type { FC } from 'react';

import { PaymentElement as StripePaymentElement } from '@stripe/react-stripe-js';

import type { PaymentElementProps } from './PaymentElement.interface';

export const PaymentElement: FC<PaymentElementProps> = ({
  errorMessage,
  processing,
}) => (
  <div className="flex flex-col gap-2 min-h-[200px]">
    <StripePaymentElement options={{ readOnly: processing }} />
    {!!errorMessage && <p className="text-sm text-red-700">{errorMessage}</p>}
  </div>
);
