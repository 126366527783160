import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { useEffect, useState } from 'react';

import type { ScreeningStepAlert, ScreenStep } from '@core/flows/Screening';
import type { ApiModel } from '@nodal/api';

const alerts = new Map<ApiModel.ScreenStatusEnum, ScreeningStepAlert>([
  [
    apiEnums.ScreenStatusEnum.Rev,
    {
      variant: 'warning',
      title: t("We've Hit a Snag"),
      message: t(
        'Thank you for your interest in Nodal, but your application has been flagged to a staff member to review. Please check your email for next steps.',
      ),
    },
  ],
  [
    apiEnums.ScreenStatusEnum.Rej,
    {
      variant: 'error',
      title: t('Eligibility was not successful'),
      message: t(
        `We are sorry. Looks like you're not a good fit for Nodal at this time. You can no longer go to the next step.`,
      ),
    },
  ],
]);

export const useAlertFormQuestions = (currentStep?: ScreenStep) => {
  const [alert, setAlert] = useState<ScreeningStepAlert>();

  useEffect(() => {
    if (currentStep?.status) {
      const alert = alerts.get(currentStep.status);
      setAlert(alert);
    }
  }, [currentStep?.status]);

  return { alert };
};
