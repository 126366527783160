import { t } from '@nodal/i18n';
import { forwardRef } from 'react';

import { Select } from '@uikit/components/Select';
import { TextInput } from '@uikit/components/TextInput';

import type { AddressInputProps } from './AddressInput.interface';

export const AddressInput = forwardRef<HTMLInputElement, AddressInputProps>(
  ({ states, name, disabled = false }, inputRef) => {
    return (
      <>
        <TextInput
          label={t('Address Line 1')}
          name={`${name}.line1`}
          type="text"
          ref={inputRef}
          disabled={disabled}
        />
        <TextInput
          className="relative child-p:absolute child-p:top-0 child-p:right-0 child-p:mt-0 child-p:text-left"
          label={t('Address Line 2')}
          name={`${name}.line2`}
          type="text"
          helperText="Optional"
          disabled={disabled}
        />
        <TextInput
          label={t('City')}
          name={`${name}.city`}
          type="text"
          className="w-full lg:w-1/2"
          disabled={disabled}
        />
        <div className="flex flex-col gap-x-4 gap-y-10 lg:flex-row">
          <Select
            label={t('State/Province')}
            name={`${name}.state`}
            options={states}
            className="lg:w-3/4"
            optionsPosition="top"
            disabled={disabled}
          />
          <TextInput
            label={t('ZIP/Postal')}
            name={`${name}.zipcode`}
            type="text"
            className="lg:w-1/4"
            disabled={disabled}
          />
        </div>
      </>
    );
  },
);

AddressInput.displayName = 'AddressInput';
