import React, { FC, Fragment } from 'react';
import { Transition, Popover } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/outline';
import { t } from '@nodal/i18n';
import { NotificationCenterProps } from './NotificationCenter.interface';
import { Notification } from './Notification/Notification';
import { XIcon } from '@heroicons/react/solid';
import { formatDistance } from 'date-fns';
import type { NotificationPatched } from 'api';
import { matchingPaths, paths } from 'consts/paths';
import {
  CtaToNotificationActionMap,
  NotificationAction,
} from './Notification/Notification.interface';

const callToActions: CtaToNotificationActionMap = {
  match_profile: {
    label: t('Match Profile'),
    path: `${paths.matching}/${matchingPaths.profile}`,
  },
  get_started: {
    label: t('Get Started'),
    path: paths.paymentService,
  },
  check_out_matches: {
    label: t('See Matches'),
    path: `${paths.matching}/${matchingPaths.matches}`,
  },
  find_parents: {
    label: t('Find Parents'),
    path: `${paths.matching}/${matchingPaths.parents}`,
  },
  see_your_match: {
    label: t('See Match'),
    path: `${paths.matching}/${matchingPaths.matches}`,
  },
  subscription_issue: {
    label: t('Go to Subscription'),
    path: paths.paymentService,
  },
};

const getNotificationActionWithCallback = (
  action: NotificationAction,
  callback: () => void,
) => ({
  ...action,
  callback,
});

export const NotificationCenter: FC<NotificationCenterProps> = ({
  unreadNotifications,
  readNotifications,
  isOpen,
  onToggleOpen,
}) => {
  const unreadCount = unreadNotifications.length;
  const readCount = readNotifications.length;
  const notificationsCount = unreadCount + readCount;

  const renderNotification = ({
    id,
    title,
    content,
    created,
    is_read,
    cta_type,
  }: NotificationPatched) => (
    <Notification
      key={id}
      title={title}
      content={content}
      isRead={is_read ?? false}
      action={
        cta_type
          ? getNotificationActionWithCallback(
              callToActions[cta_type],
              onToggleOpen,
            )
          : undefined
      }
      timestamp={formatDistance(Date.parse(created), Date.now())}
    />
  );

  return (
    <Popover
      as="div"
      className={`block relative font-avenir-next ${
        isOpen && 'sm:h-fit h-screen'
      }`}
    >
      <Popover.Button
        className="flex text-sm rounded-full focus:outline-none"
        onClick={onToggleOpen}
      >
        <span className="sr-only">{t('Open notifications list')}</span>
        <BellIcon className="p-1 w-8 h-8 stroke-moss-300" />
        {unreadCount > 0 && (
          <span className="absolute top-0 right-0 px-1 h-4 text-xs text-white bg-red-500 rounded-full">
            {unreadNotifications.length}
          </span>
        )}
      </Popover.Button>
      {isOpen && (
        <Popover.Overlay
          className="fixed inset-0 bg-transparent opacity-100"
          onClick={onToggleOpen}
          static
        />
      )}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        show={isOpen}
      >
        <Popover.Panel className="flex overflow-hidden fixed inset-0 right-0 flex-col bg-white focus:outline-none ring-1 ring-black/5 shadow-lg origin-top-right sm:absolute sm:inset-auto sm:right-0 sm:w-80 sm:rounded-md">
          <div className="flex justify-between items-center py-4 px-3 sm:px-8">
            <div className="flex gap-6">
              <Popover.Button className="sm:hidden" onClick={onToggleOpen}>
                <XIcon className="w-6 h-6 stroke-grey-forest-500" />
              </Popover.Button>
              <div className="text-lg font-semibold leading-6 text-gray-900">
                {t('Notifications')}
              </div>
            </div>
          </div>
          <hr />
          {notificationsCount > 0 ? (
            <div className="overflow-y-scroll sm:max-h-84">
              {unreadNotifications.map(renderNotification)}
              {readNotifications.map(renderNotification)}
            </div>
          ) : (
            <div className="flex flex-col gap-2 justify-center items-center h-32 text-center text-grey-forest-700">
              <BellIcon className="w-8 h-8 stroke-grey-forest-400" />
              {t('No notifications')}
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
