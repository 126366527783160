import type { FC } from 'react';

import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XIcon,
} from '@heroicons/react/solid';
import 'react-toastify/dist/ReactToastify.css';
import { t } from '@nodal/i18n';
import classNames from 'classnames';
import { ToastContainer } from 'react-toastify';

import type { ToastNotificationProps } from './Toast.interface';
import type { CloseButtonProps, IconProps, TypeOptions } from 'react-toastify';

const ToastButton: FC<CloseButtonProps> = ({ closeToast }) => {
  return (
    <button
      type="button"
      className="inline-flex text-grey-forest-400 hover:text-grey-forest-500 bg-white rounded-md"
      onClick={closeToast}
    >
      <span className="sr-only">{t('Close')}</span>
      <XIcon className="w-5 h-5" aria-hidden="true" />
    </button>
  );
};

const ToastIcon: FC<IconProps> = ({ type }) => {
  switch (type) {
    case 'success':
      return (
        <CheckCircleIcon
          className="w-5 h-5 text-forest-200"
          aria-hidden="true"
        />
      );
    case 'error':
      return <XCircleIcon className="w-5 h-5 text-red-600" />;
    case 'info':
      return <InformationCircleIcon className="w-5 h-5 text-yellow-400" />;
    default:
      return null;
  }
};

export const ToastNotification: FC<ToastNotificationProps> = ({
  title,
  message,
}) => {
  return (
    <div className="w-full">
      <p className="text-sm font-medium text-grey-forest-900">{title}</p>
      <p className="mt-1 text-sm font-normal text-grey-forest-600">{message}</p>
    </div>
  );
};

const customProgressClassName = (type?: TypeOptions) => {
  return classNames(
    'Toastify__progress-bar--animated w-full absolute h-1 left-0 bottom-0 z-10 origin-left',
    {
      'bg-forest-200': type === 'success',
      'bg-red-500': type === 'error',
    },
  );
};

export const ToastProvider = () => (
  <ToastContainer
    autoClose={4000}
    draggable={false}
    position="bottom-left"
    icon={ToastIcon}
    closeButton={ToastButton}
    bodyClassName={() =>
      'flex text-sm leading-5 font-medium text-grey-forest-900 p-0 items-start'
    }
    toastClassName={() =>
      'bg-white relative flex justify-between rounded-lg shadow-lg p-4 w-96 overflow-hidden z-20'
    }
    progressClassName={(toast) => customProgressClassName(toast?.type)}
  />
);
