// ESLint produces warning for GlobalError import
// GlobalError is intentionally only used in the catch block below
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { GlobalError, useApiClient } from '@nodal/api';
import { UsersApiUsersMeProfilePartialUpdateRequest } from '@nodal/api/core';
import { FormikValues } from 'formik';
import { useGooglePlacesApi } from '@nodal/core/hooks/useGooglePlacesApi';
import { t } from '@nodal/i18n';
import React, { FC, useCallback } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ContactDetails } from './ContactDetails';
import { ContactDetailsConnectedProps } from './ContactDetails.interface';
import { useUsersMeRetrieve } from 'api/hooks/useUsersMeRetrieve';

export const useContactDetails = () => {
  const apiClient = useApiClient();

  const userProfile = useUsersMeRetrieve();

  const { data: userData } = userProfile || {};

  const updateProfile = useMutation(
    (requestParameters: UsersApiUsersMeProfilePartialUpdateRequest = {}) =>
      apiClient.api.UsersApi.usersMeProfilePartialUpdate(requestParameters),
  );

  const submit = useCallback(
    async (data: FormikValues) => {
      try {
        const submitResponse = await updateProfile.mutateAsync({
          patchedProfileObject: data,
        });

        await userProfile.refetch();
        toast.success(t('Successfully updated'));

        return submitResponse?.data;
      } catch (e: GlobalError) {
        if (!e?.response?.data?.non_field_errors) {
          toast.error(t('Something went wrong...'));
        }
      }
    },
    [updateProfile, userProfile],
  );

  return {
    profileData: userData?.data.profile,
    submit,
  };
};

export const ContactDetailsConnected: FC<ContactDetailsConnectedProps> = ({
  role,
}) => {
  const placesApiReady = useGooglePlacesApi();
  const { submit, profileData } = useContactDetails();

  return profileData && placesApiReady ? (
    <ContactDetails role={role} submit={submit} profileData={profileData} />
  ) : null;
};
