import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { ProgressBar } from '@nodal/uikit/components/ProgressBar';
import { SurveyCard } from '@nodal/uikit/components/SurveyCard';
import classNames from 'classnames';
import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useEffect, useRef } from 'react';

import { EditTopSection } from './EditTopSection';
import { EditVideo } from './EditVideo';

import type { EditFormProps, EditSectionProps } from './EditForm.interface';
import type {
  ProfileData,
  QuestionData,
} from '@core/flows/MatchProfile/MatchProfile.interface';

const EditOtherSection = <T extends ProfileData>({
  questions,
  values,
}: EditSectionProps<T>) => {
  const renderSurveyClassName = useCallback(
    (type?: string) =>
      classNames(
        'pt-0 border-none',
        type === 'select' || type === 'select_multiple' || type === 'text'
          ? 'w-full sm:w-4/6 '
          : 'w-full',
        type === 'textarea' && 'pb-0',
      ),
    [],
  );

  return (
    <div className="flex overflow-y-auto flex-col gap-10 py-0 first:pt-8 w-full h-full min-h-85 text-left bg-white rounded-lg sm:h-full sm:min-h-full sm:max-h-100-modal">
      {questions.map(
        ({
          value,
          label,
          type,
          choices,
          description,
          placeholder,
          disabled,
        }: QuestionData<T>) => (
          <div
            key={value?.toString()}
            className={`relative last:border-none ${
              type !== 'textarea'
                ? 'border-b border-grey-forest-100'
                : 'last:pb-14'
            }`}
          >
            <SurveyCard
              label={label}
              value={value?.toString()}
              type={type}
              choices={choices}
              error={false}
              description={description}
              placeholder={placeholder}
              className={renderSurveyClassName(type)}
              disabled={disabled ? disabled(values) : false}
              optionsPosition={value === 'religion' ? 'top' : 'bottom'}
            />
          </div>
        ),
      )}
    </div>
  );
};

export const getFieldErrorNames = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formikErrors: any,
): string[] => {
  const transformObjectToDotNotation = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj: any,
    prefix = '',
    result: string[] = [],
  ): string[] => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError = ({}) => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`,
    );

    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });

    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount, isValid, errors]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export const EditForm = <T extends ProfileData>({
  initialValues,
  questions,
  validationSchema,
  onSubmit,
  section,
  role,
  uploadProgress,
}: EditFormProps<T>) => {
  const inputRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (section == 'personalInformation' && role === 'par') {
      if (inputRef.current) {
        for (const [key, value] of Object.entries(initialValues)) {
          if (key === 'lgbtq_identify' || key === 'is_military') {
            if (value === null) {
              inputRef.current.click();
              break;
            }
          }
        }
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderFormComponent = useCallback(
    (values: T) => {
      switch (section) {
        case 'video':
          return <EditVideo questions={questions} />;
        case 'personalInformation':
          return (
            <EditTopSection questions={questions} role={role} values={values} />
          );
        default:
          return <EditOtherSection questions={questions} values={values} />;
      }
    },
    [questions, section, role],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, isSubmitting }) => (
        <Form className="flex flex-col col-span-6 justify-start items-start w-full h-full ">
          {role === 'par' && <ScrollToFieldError />}
          {renderFormComponent(values)}
          <ProgressBar progress={uploadProgress} />
          <div className="py-3 px-8 ml-auto w-full text-right border-t border-grey-forest-200">
            <button type="submit" ref={inputRef}></button>
            <ButtonLoading
              type="submit"
              variant="primary"
              className="justify-center ml-auto w-full sm:w-max"
              loading={isSubmitting}
            >
              {t('Submit')}
            </ButtonLoading>
          </div>
        </Form>
      )}
    </Formik>
  );
};
