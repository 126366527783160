import {
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import classNames from 'classnames';
import { forwardRef } from 'react';

import { screeningDetailStatus } from './SectionDetails.interface';

import type {
  ScreeningSectionDetailsProps,
  ScreeningDetailStatus,
} from './SectionDetails.interface';

const screeningDetailsStatusToIcon = new Map([
  [
    screeningDetailStatus.complete,
    {
      icon: CheckCircleIcon,
      color: 'fill-forest-500',
    },
  ],
  [
    screeningDetailStatus.pending,
    {
      icon: ClockIcon,
      color: 'fill-yellow-500',
    },
  ],
  [
    screeningDetailStatus.rejected,
    {
      icon: XCircleIcon,
      color: 'fill-red-500',
    },
  ],
]);

const StatusIcon = ({ status }: { status?: ScreeningDetailStatus }) => {
  const { icon: Icon, color } = screeningDetailsStatusToIcon.get(status!) || {};

  return Icon ? (
    <span className="bg-white">
      <Icon className={classNames('w-6 h-6', color)} />
    </span>
  ) : null;
};

export const ScreeningSectionDetails = forwardRef<
  HTMLDivElement,
  ScreeningSectionDetailsProps
>(({ screeningDetails }, ref) => (
  <div
    className="flex col-span-12 col-start-2 gap-10 p-4 w-full max-h-[150px] bg-white rounded-lg sm:p-8"
    ref={ref}
  >
    <h3 className="w-1/3 text-xl font-semibold leading-7 text-grey-forest-900">
      {t('Nodal Screening')}
    </h3>
    <div className="grid grid-rows-3 grid-flow-col gap-4 items-center w-2/3">
      {screeningDetails.map(({ title, status }) => (
        <div key={title} className="flex gap-4 items-center w-full">
          <StatusIcon status={status} />
          <p className="font-medium leading-5 text-forest-600">{title}</p>
        </div>
      ))}
    </div>
  </div>
));

ScreeningSectionDetails.displayName = 'ScreeningSectionDetails';
