import type { FC } from 'react';

import { VideoCameraIcon } from '@heroicons/react/outline';
import { ExclamationCircleIcon, TrashIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { useRef } from 'react';

import { useFileReader } from '@uikit/hooks/useFileReader';

import type { VideoInputProps } from './VideoInput.interface';

export const VideoInput: FC<VideoInputProps> = ({
  value,
  onChange,
  onDelete,
  error,
  ...props
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const src = useFileReader(value);

  if (value)
    return (
      <div className="relative">
        <div className="group aspect-video flex relative">
          <video className="object-cover flex-1" src={src} controls />
          <div className="hidden group-hover:flex absolute top-0 right-0 gap-3 justify-end p-4">
            <button
              className="aspect-square w-1/6 bg-white/80 hover:bg-white rounded-lg"
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              <TrashIcon className="aspect-square m-auto w-1/2 fill-grey-forest-500" />
            </button>
          </div>
        </div>
        {error ? (
          <div className="flex absolute gap-1 items-center px-1 mt-1.5">
            <div className="inset-y-0 right-0 pr-1 pointer-events-none">
              <ExclamationCircleIcon
                className="w-4 h-4 text-red-700"
                aria-hidden="true"
              />
            </div>
            <p className="text-xs text-red-700">{error}</p>
          </div>
        ) : null}
      </div>
    );

  return (
    <div
      className="aspect-video grid hover:cursor-pointer"
      onClick={() => {
        if (fileInput.current) {
          fileInput.current.click();
        }
      }}
    >
      <input
        ref={fileInput}
        type="file"
        className="hidden"
        multiple={false}
        accept="video/*"
        onChange={(e) => {
          if (!e.target.files) return;
          onChange(e.target.files[0]);
          e.target.value = ''; // NOTES: Reset current value to allow selecting the same file multiple times in a row
        }}
        {...props}
      />
      <div className="flex flex-col justify-center items-center">
        <VideoCameraIcon className="w-20 h-20 text-grey-forest-400 bg-transparent rounded-full" />
        <p className="text-sm font-semibold leading-5 text-forest-500 underline">
          {t('Upload a file (optional)')}
        </p>
      </div>
    </div>
  );
};
