import type { ChangeEvent, DragEvent } from 'react';

import { useState } from 'react';

import { FileDropZone } from './FileDropZone';

export const FileDropZoneConnected = ({
  onUpload,
  disabled,
  acceptFileTypes,
}: {
  onUpload: (file: File) => void;
  disabled: boolean;
  acceptFileTypes: string;
}) => {
  const [dragActive, setDragActive] = useState(false);

  const onDrag = (e: DragEvent<HTMLDivElement>) => {
    if (disabled) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const onDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);
    if (e?.dataTransfer?.files) {
      onUpload(e.dataTransfer.files[0]);
    }
  };

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      onUpload(e.target.files[0]);
      // NOTES: Reset current value to allow selecting the same file multiple times in a row
      e.target.value = '';
    }
  };

  return (
    <FileDropZone
      dragActive={dragActive}
      onDrag={onDrag}
      onDrop={onDrop}
      onChange={onChange}
      acceptFileTypes={acceptFileTypes}
      disabled={disabled}
    />
  );
};
