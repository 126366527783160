import { paths, signupPaths } from 'consts/paths';
import { ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CheckEmailScreen } from '@nodal/core/flows/SignUp/CheckEmailScreen';
import { ConfirmEmailScreen } from '@nodal/core/flows/SignUp/ConfirmEmailScreen';
import { EmailNotConfirmedScreen } from '@nodal/core/flows/SignUp/EmailNotConfirmedScreen';

export const SignUpRouter = ({ children }: { children: ReactNode }) => {
  return (
    <Routes>
      <Route path={signupPaths.checkEmail} element={<CheckEmailScreen />} />
      <Route
        path={signupPaths.confirmEmail}
        element={
          <ConfirmEmailScreen
            redirectPaths={{
              checkEmail: `../${signupPaths.checkEmail}`,
              signin: paths.signin,
            }}
          />
        }
      />
      <Route
        path={signupPaths.emailNotConfirmed}
        element={
          <EmailNotConfirmedScreen
            redirectPaths={{
              checkEmail: `../${signupPaths.checkEmail}`,
            }}
          />
        }
      />
      {children}
    </Routes>
  );
};
