import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { Checkbox } from '@nodal/uikit/components/Checkbox';
import { PastDateInput } from '@nodal/uikit/components/DateInput';
import { PhoneNumberInput } from '@nodal/uikit/components/PhoneNumberInput';
import { PlaceInput } from '@nodal/uikit/components/PlaceInput';
import { TextInput } from '@nodal/uikit/components/TextInput';

import { getOrdinalNumber } from '@core/utils';

import type { IvfTransferDetail } from './MedicalRecordReview.interface';

// NOTE: The component must be embedded inside Formik
export const IvfTransferDetailsForm: FC<{
  ivfTransfersDetails: IvfTransferDetail[];
  editable: boolean;
}> = ({ ivfTransfersDetails, editable }) => {
  return (
    <>
      {ivfTransfersDetails?.map((detail: IvfTransferDetail, idx: number) => (
        <div className="grid grid-cols-1 gap-x-4 gap-y-10" key={idx}>
          <div className="relative">
            <div
              className="flex absolute inset-0 items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="flex relative justify-start">
              <span className="pr-3 text-base font-semibold leading-7 text-gray-900 bg-white">
                {t(
                  '{numberWithOrdinal} IVF Transfer',
                  `${getOrdinalNumber(idx + 1)}`,
                )}
              </span>
            </div>
          </div>
          <PastDateInput
            label={t('Date of transfer')}
            name={`ivf_transfers_details.${idx}.transfer_date`}
            placeholder="YYYY-DD-MM"
            className="lg:w-1/2"
            disabled={!editable}
            min="1990-01-01"
            openToDate={new Date('1990-01-01')}
          />
          {idx !== 0 && (
            <Checkbox
              name={`ivf_transfers_details.${idx}.same_doctor_details_as_first`}
              label={t('Same doctor details as first transfer')}
              disabled={!editable}
            />
          )}

          {(idx === 0 || !detail.same_doctor_details_as_first) && (
            <>
              <TextInput
                label={t("Doctor's First Name")}
                name={`ivf_transfers_details.${idx}.doctor_first_name`}
                type="text"
                disabled={!editable}
              />
              <TextInput
                label={t("Doctor's Middle Name (optional)")}
                name={`ivf_transfers_details.${idx}.doctor_middle_name`}
                type="text"
                disabled={!editable}
              />
              <TextInput
                label={t("Doctor's Last Name")}
                name={`ivf_transfers_details.${idx}.doctor_last_name`}
                type="text"
                disabled={!editable}
              />
              <PhoneNumberInput
                label={t("Doctor's Phone Number")}
                name={`ivf_transfers_details.${idx}.doctor_phone_number`}
                placeholder={'(555) 987-6543'}
                type="text"
                className="lg:w-1/2"
                disabled={!editable}
              />
              <TextInput
                label={t("Doctor's Clinic Name")}
                name={`ivf_transfers_details.${idx}.doctor_clinic_name`}
                type="text"
                disabled={!editable}
              />
              <PlaceInput
                name={`ivf_transfers_details.${idx}.doctor_clinic_address`}
                label={t("Doctor's Clinic Address")}
                type="address"
                disabled={!editable}
                placeholder={t('Enter the address and select location')}
              />
            </>
          )}
        </div>
      ))}
    </>
  );
};
