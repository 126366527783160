import type { FC } from 'react';

import { apiEnums } from '@nodal/api/enums';
import { t } from '@nodal/i18n';
import { differenceInMilliseconds, formatDistanceStrict } from 'date-fns';
import { useMemo, useState } from 'react';

import { useCurrentDate } from '@core/hooks/useCurrentDate';
import { sortPhotosByOrder, createInitials } from '@core/utils';

import { IntroductionRequestCard } from './IntroductionRequestCard';
import { doesIntroductionRequireAction } from './utils';

import type {
  IntroductionRequestCardPropsConnected,
  ModalType,
} from './IntroductionRequestCard.interface';
import type { RejectReason } from './RejectionForm';
import type { ApiModel } from '@nodal/api';

const profileDisplayName = (profile: ApiModel.ProfileObject): string => {
  if ('partner_first_name' in profile && !!profile.partner_first_name) {
    return `${profile.first_name} ${t('and')} ${profile.partner_first_name}`;
  }

  return `${profile.first_name}`;
};

const useIntroductionRequest = ({
  introduction,
  role,
}: IntroductionRequestCardPropsConnected) => {
  const {
    donor_profile,
    parent_profile,
    initial_parent_accepted: initialParentAccepted,
    match_accepted_parent,
    match_accepted_donor,
    expiration_date,
    updated,
  } = introduction;

  const currentTimestamp = useCurrentDate();

  const otherUserRole =
    role === apiEnums.UserRoleEnum.Dnr
      ? apiEnums.UserRoleEnum.Par
      : apiEnums.UserRoleEnum.Dnr;
  const otherUser =
    otherUserRole === apiEnums.UserRoleEnum.Dnr
      ? donor_profile
      : parent_profile;
  const otherUserDisplayName = profileDisplayName(otherUser);
  const otherUserMatchAccepted =
    otherUserRole === apiEnums.UserRoleEnum.Par
      ? match_accepted_parent
      : match_accepted_donor;
  const meUserMatchAccepted =
    role === apiEnums.UserRoleEnum.Par
      ? match_accepted_parent
      : match_accepted_donor;
  const actionRequired = doesIntroductionRequireAction(introduction, role);
  const otherUserAvatar =
    sortPhotosByOrder(otherUser.user.profile_photos)[0]?.image_url || undefined;
  const otherUserInitials =
    otherUser.first_name && otherUser.last_name
      ? createInitials(otherUser.first_name, otherUser.last_name)
      : undefined;

  const expirationTimestamp = useMemo(
    () => (expiration_date ? Date.parse(expiration_date) : undefined),
    [expiration_date],
  );

  const updatedTimestamp = useMemo(
    () => (updated ? Date.parse(updated) : undefined),
    [updated],
  );

  const expirationTimestampDistance = expirationTimestamp
    ? differenceInMilliseconds(expirationTimestamp, currentTimestamp)
    : 0;
  const initialExpirationDateDistance =
    expirationTimestamp && updatedTimestamp
      ? differenceInMilliseconds(expirationTimestamp, updatedTimestamp)
      : 0;
  const progress = initialExpirationDateDistance
    ? expirationTimestampDistance / initialExpirationDateDistance
    : 0;

  const expired = !(
    !!expirationTimestamp && expirationTimestamp > currentTimestamp
  );
  const expiresIn = expired
    ? undefined
    : formatDistanceStrict(expirationTimestamp, currentTimestamp);

  return {
    otherUserRole,
    otherUser,
    otherUserDisplayName,
    initialParentAccepted: initialParentAccepted ?? false,
    otherUserMatchAccepted: otherUserMatchAccepted ?? false,
    meUserMatchAccepted: meUserMatchAccepted ?? false,
    actionRequired,
    otherUserAvatar,
    otherUserInitials,
    progress,
    expiresIn,
    myRole: role,
  };
};

const useIntroductionModalHandlers = ({
  onUpdate,
  introduction,
  role,
}: IntroductionRequestCardPropsConnected) => {
  const [modalType, setModalType] = useState<ModalType>();
  const [modalOpen, setisModalOpen] = useState(false);

  const openModal = () => setisModalOpen(true);

  const closeModal = () => setisModalOpen(false);

  const rejectReasonByRole = (
    role: ApiModel.UserRoleEnum,
    rejectReason?: RejectReason,
  ) => {
    const { passing_reason, other_answer } = rejectReason || {};
    return role === apiEnums.UserRoleEnum.Par
      ? {
          parent_passing_reason: passing_reason,
          parent_other_answer: other_answer,
        }
      : {
          donor_passing_reason: passing_reason,
          donor_other_answer: other_answer,
        };
  };

  const acceptIntroduction = () =>
    onUpdate({
      id: introduction.id,
      patchedIntroductionFull: { initial_parent_accepted: true },
    });

  const rejectIntroduction = (rejectReason?: RejectReason) =>
    onUpdate({
      id: introduction.id,
      patchedIntroductionFull: {
        initial_parent_accepted: false,
        ...rejectReasonByRole(role, rejectReason),
      },
    });

  const acceptMatch = () =>
    onUpdate({
      id: introduction.id,
      patchedIntroductionFull:
        role === apiEnums.UserRoleEnum.Par
          ? { match_accepted_parent: true }
          : { match_accepted_donor: true },
    });

  const rejectMatch = (rejectReason?: RejectReason) =>
    onUpdate({
      id: introduction.id,
      patchedIntroductionFull:
        role === apiEnums.UserRoleEnum.Par
          ? {
              match_accepted_parent: false,
              ...rejectReasonByRole(role, rejectReason),
            }
          : {
              match_accepted_donor: false,
              ...rejectReasonByRole(role, rejectReason),
            },
    });

  const confirmAcceptIntroduction = () => {
    openModal();
    setModalType('introduction_confirmation');
  };

  const confirmRejectIntroduction = () => {
    openModal();
    setModalType('introduction_rejection');
  };

  const confirmAcceptMatch = () => {
    openModal();
    setModalType('match_confirmation');
  };

  const confirmRejectMatch = () => {
    openModal();
    setModalType('match_rejection');
  };

  const handleModalConfirm = async (rejectReason?: RejectReason) => {
    switch (modalType) {
      case 'introduction_confirmation':
        await acceptIntroduction();
        break;
      case 'match_confirmation':
        await acceptMatch();
        break;
      case 'introduction_rejection':
        await rejectIntroduction(rejectReason);
        break;
      case 'match_rejection':
        await rejectMatch(rejectReason);
        break;
    }

    closeModal();
  };

  const handleAccept = () => {
    switch (introduction.stage) {
      case apiEnums.IntroductionStageEnum.Intros:
        confirmAcceptIntroduction();
        break;
      case apiEnums.IntroductionStageEnum.MatchAcceptance:
        confirmAcceptMatch();
        break;
      default:
        break;
    }
  };

  const handleReject = () => {
    switch (introduction.stage) {
      case apiEnums.IntroductionStageEnum.Intros:
        confirmRejectIntroduction();
        break;
      case apiEnums.IntroductionStageEnum.MatchAcceptance:
        confirmRejectMatch();
        break;
      default:
        break;
    }
  };

  return {
    onAccept: handleAccept,
    onReject: handleReject,
    modalOpen: modalOpen,
    modalType: modalType,
    onCloseModal: closeModal,
    onModalConfirm: handleModalConfirm,
  };
};

export const IntroductionRequestCardConnected: FC<
  IntroductionRequestCardPropsConnected
> = ({ className, userHidden, ...props }) => {
  const request = useIntroductionRequest(props);
  const modalHandlers = useIntroductionModalHandlers(props);

  return (
    <IntroductionRequestCard
      className={className}
      {...request}
      {...modalHandlers}
      stage={props.introduction.stage}
      userHidden={userHidden}
    />
  );
};
