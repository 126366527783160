import type { FC } from 'react';

import { t } from '@nodal/i18n';

import { NumberTagInput } from '@uikit/components/NumberTagInput';

import type { BmiInputProps } from './BmiInput.interface';

export const BmiInput: FC<BmiInputProps> = ({ name }) => {
  return (
    <div className="flex flex-col gap-2">
      <NumberTagInput
        name={`${name}.weight`}
        tag="lbs"
        label={t('Weight')}
        className="mb-8 w-32"
      />
      <div className="flex gap-3 justify-start items-center">
        <NumberTagInput
          name={`${name}.height_ft`}
          label={t('Height')}
          tag="ft"
          className="w-32"
        />
        <NumberTagInput
          name={`${name}.height_in`}
          tag="in"
          className="mt-5 w-32"
          error={null}
        />
      </div>
    </div>
  );
};
