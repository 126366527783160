import type { Address } from '../models';

export const getFormattedAddress = (
  address: Address,
  displayedKeys: Array<keyof Address>,
) => {
  const addressElements: string[] = [];

  displayedKeys.forEach((key) => {
    const addressValue = address[key];

    if (addressValue && typeof addressValue === 'string') {
      addressElements.push(addressValue);
    }
  });

  const formattedAddress = addressElements?.length
    ? addressElements.join(', ')
    : undefined;

  return formattedAddress;
};

// NOTE: function is used to format a given number of bytes into a human-readable string, using binary prefixes
// It is based on the binary system (1024 bytes per kilobyte),
// which is commonly used for memory and storage devices.
// Ref: https://web.archive.org/web/20120505062446/http://codeaid.net/php/convert-size-in-bytes-to-a-human-readable-format-(php)
export const convertBytesToReadableFileSize = (
  bytes: number,
  decimals = 1,
): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${
    sizes[i]
  }`;
};
