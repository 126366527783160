import { useSortable as useSortableDndKit } from '@dnd-kit/sortable';

export const useSortable = ({
  id,
  disabled,
}: {
  id: number;
  disabled: boolean;
}) => {
  const sortable = useSortableDndKit({ id, disabled });

  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transition,
    index,
    overIndex,
  } = sortable;

  const hovered = overIndex === index && !isDragging;

  return {
    dragged: isDragging,
    hovered,
    attributes,
    listeners,
    setNodeRef,
    style: transition ? { transition } : undefined,
  };
};
