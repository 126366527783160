import {
  FatherWithChild,
  FatherWithChildJpg,
  Pregnacy,
  PregnacyJpg,
} from 'assets';
import classNames from 'classnames';
import { paths, signupPaths } from 'consts/paths';
import { t } from '@nodal/i18n';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ImgWithFallback } from '@nodal/uikit/components/ImgWithFallback';
import { SignUpCardProps } from './SignUpSelectionView.interface';

const SignUpCard: FC<SignUpCardProps> = ({ img, title, description }) => (
  <div className="flex gap-5 p-4 shadow-sm">
    <div className="overflow-hidden relative justify-center items-center w-44 h-20 rounded-lg">
      <ImgWithFallback {...img} />
    </div>
    <div className="flex flex-col gap-2 justify-center">
      <h2 className="text-base font-semibold leading-6 text-grey-forest-900">
        {title}
      </h2>
      <p className="text-sm font-normal leading-5 text-grey-forest-600">
        {description}
      </p>
    </div>
  </div>
);

export const SignUpSelectionView: FC = () => {
  const registerCardLinkClassName = classNames(
    'bg-white rounded-lg border border-grey-forest-200 hover:border-forest-500 focus:border-forest-500 focus:ring-1 focus:ring-forest-500 focus:ring-offset-2 focus:ring-offset-white',
  );
  return (
    <>
      <div className="flex flex-col gap-4">
        <h2 className="mt-6 text-2xl font-semibold leading-8 text-gray-900 sm:text-3xl sm:font-bold sm:leading-9">
          {t('Join Nodal')}
        </h2>
        <p className="text-base font-normal leading-6 text-grey-forest-600 sm:text-lg sm:leading-7">
          {t('Connecting Future Families Together')}
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <Link to={signupPaths.surrogate} className={registerCardLinkClassName}>
          <SignUpCard
            title={t('As a Surrogate')}
            description={t(
              'Join Nodal and see if you qualify to become a surrogate',
            )}
            img={{
              source: { src: Pregnacy },
              fallback: {
                src: PregnacyJpg,
                alt: t('Pregnacy'),
                className: 'object-cover object-bottom absolute w-full h-28',
              },
            }}
          />
        </Link>
        <Link to={signupPaths.parents} className={registerCardLinkClassName}>
          <SignUpCard
            title={t('As an Intended Parent')}
            description={t(
              'Join Nodal and see if you qualify to become an intended parent',
            )}
            img={{
              source: { src: FatherWithChild },
              fallback: {
                src: FatherWithChildJpg,
                alt: t('Father with child'),
                className: 'object-cover object-center absolute w-full h-full',
              },
            }}
          />
        </Link>
      </div>
      <div className="pb-4 text-base font-normal leading-6 text-center text-grey-forest-600">
        {t('Already have an account?')}&nbsp;
        <Link
          to={paths.signin}
          className="text-base font-semibold leading-6 text-forest-500"
        >
          {t('Log In')}
        </Link>
      </div>
    </>
  );
};
