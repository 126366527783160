import { apiEnums, useApiClient } from '@nodal/api';
import { User } from 'api/api.interface';
import { useQuery, UseQueryResult } from 'react-query';
import { AxiosResponse } from 'axios';
import { queryKeys } from '@nodal/core/consts/query';

export const useUsersMeRetrieve = (enabled = true) => {
  const apiClient = useApiClient();

  const userMe = useQuery(
    queryKeys.usersMeRetrieve,
    () => apiClient.api.UsersApi.usersMeRetrieve(),
    {
      enabled,
      onSuccess: () => {
        const user = userMe?.data?.data;

        // NOTE: Currently only 'dnr' and 'par' are supported.
        if (
          import.meta.env.DEV &&
          user &&
          user.role !== apiEnums.UserRoleEnum.Dnr &&
          user.role !== apiEnums.UserRoleEnum.Par &&
          user.role !== apiEnums.UserRoleEnum.Nap
        ) {
          throw new Error('The user is not a donor, parent or navigator.');
        }
      },
    },
    // NOTE: Type casting here is required, because we use only donor and parents users
    // (without organization user)
  ) as UseQueryResult<AxiosResponse<User, unknown>, unknown>;

  return userMe;
};
