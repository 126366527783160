import type { FC } from 'react';

import { useEffect, useRef } from 'react';

import { useGooglePlacesAutocomplete } from '@uikit/hooks/useGooglePlacesAutocomplete';
import type { Address } from '@uikit/models';

import { AddressInput } from './AddressInput';

import type { AddressInputConnectedProps } from './AddressInput.interface';

const useAutocompleteAddressInput = (onChange: (address: Address) => void) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { address } = useGooglePlacesAutocomplete(inputRef);

  useEffect(() => {
    if (address) {
      onChange({ ...address });
    }
    // NOTE: Only if one of the address fields will change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address?.line1, address?.city, address?.state, address?.zipcode]);

  return { inputRef };
};

export const AddressInputConnected: FC<AddressInputConnectedProps> = ({
  onChange,
  name,
  states,
  disabled = false,
}) => {
  const { inputRef } = useAutocompleteAddressInput(onChange);

  return (
    <AddressInput
      name={name}
      states={states}
      disabled={disabled}
      ref={inputRef}
    />
  );
};
