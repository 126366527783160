import { BillingMethodDetails } from './SubscriptionPurchased.interface';
import { SubscriptionPurchased } from './SubscriptionPurchased';
import { useApiClient } from '@nodal/api';
import { useQuery } from 'react-query';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { queryKeys } from '@nodal/core/consts/query';

export const SubscriptionPurchasedConnected = () => {
  const apiClient = useApiClient();

  const { data: customer } = useQuery(
    queryKeys.billingCustomerRetrieve,
    () => apiClient.api.BillingApi.billingCustomerRetrieve(),
    // Subscription details may change in the stripe customer portal.
    // Refetch customer data to observe for expected billing details change
    { refetchInterval: 5000 },
  );

  const { subscription } = customer?.data || {};

  if (!subscription) return <LoadingScreen />;

  const { plan, stripe_payment_method } = subscription || {};

  return (
    <SubscriptionPurchased
      plan={plan}
      // NOTE: added type cast because type for stripe_payment_method is less specific than type defined by us
      billingDetails={stripe_payment_method as BillingMethodDetails}
    />
  );
};
