import type { FC, MouseEventHandler } from 'react';

import classNames from 'classnames';
import { useMemo, useState } from 'react';

import { Spinner } from '@uikit/components/Spinner';

import type { ButtonLoadingProps } from './ButtonLoading.interface';

export const ButtonLoading: FC<ButtonLoadingProps> = ({
  children,
  variant = 'primary',
  onClick,
  className = '',
  disabled,
  loading = false,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoadingIndicator = useMemo(
    () => loading || isLoading,
    [loading, isLoading],
  );

  const handleClick: MouseEventHandler<HTMLButtonElement> = async (e) => {
    if (!onClick) return;

    try {
      setIsLoading(true);
      await onClick(e);
    } finally {
      setIsLoading(false);
    }
  };

  const primaryClassName = classNames(
    'text-white bg-forest-500 border-transparent',
    showLoadingIndicator ? 'hover:bg-forest-500' : 'hover:bg-forest-400',
  );

  const secondaryClassName = classNames(
    'text-forest-500 bg-sage-500 border-transparent',
    showLoadingIndicator ? 'hover:bg-sage-500' : 'hover:bg-sage-600',
  );

  const tertiaryClassName = classNames(
    'text-gray-700 bg-white border-gray-300',
    showLoadingIndicator ? 'bg-white' : 'hover:bg-gray-100',
  );

  const dangerClassName = classNames(
    'text-white bg-red-700 border-transparent focus:ring-red-700',
    showLoadingIndicator ? 'bg-red-700' : 'hover:bg-red-600',
  );

  const buttonClassName = {
    primary: primaryClassName,
    secondary: secondaryClassName,
    tertiary: tertiaryClassName,
    danger: dangerClassName,
  };

  const loadingButtonClassName = classNames(
    'inline-flex items-center sm:text-base text-sm font-medium rounded-xl border focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-sm focus:ring-forest-500 px-4 py-2',
    disabled
      ? 'text-white bg-grey-forest-500 outline-none border-grey-forest-500 hover:bg-grey-forest-500'
      : buttonClassName[variant],
    className,
  );

  const spinnerVariant = variant === 'primary' ? 'primary' : 'secondary';

  return (
    <button
      disabled={showLoadingIndicator || disabled}
      className={loadingButtonClassName}
      onClick={handleClick}
      {...props}
    >
      <div className="flex relative justify-center items-center">
        <p className={`${showLoadingIndicator ? 'invisible' : 'visible'}`}>
          {children}
        </p>
        {showLoadingIndicator && (
          <Spinner variant={spinnerVariant} size="small" />
        )}
      </div>
    </button>
  );
};
