import classNames from 'classnames';
import { forwardRef } from 'react';

import { FormInputError } from '@uikit/components/FormInputError';

import type { TextInputProps } from './TextInput.interface';

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    { id, name, value, label, error, className = '', helperText, ...props },
    inputRef,
  ) => {
    const inputClassName = classNames(
      'w-full block pr-3 focus:outline-none sm:text-sm text-base rounded-lg',
      error
        ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
        : 'shadow-sm focus:ring-forest-500 focus:border-forest-500 border-grey-forest-300',
      props.disabled ? 'bg-beige-50' : '',
    );

    return (
      <div className={`relative ${className}`}>
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <p className="mt-1 text-sm text-gray-500">{helperText}</p>
        <div className="relative mt-1 w-full rounded-md shadow-sm">
          <input
            id={id}
            name={name}
            value={value || ''}
            className={inputClassName}
            ref={inputRef}
            {...props}
          />
        </div>
        {error && name ? <FormInputError error={error} id={name} /> : null}
      </div>
    );
  },
);

TextInput.displayName = 'TextInput';
