import type { FC } from 'react';

import { t } from '@nodal/i18n';
import React, { useState } from 'react';

import { EditPhotosItem } from './EditPhotosItem';
import { ImageCropperModal } from './ImageCropperModal';

import type { EditPhotosProps } from './EditPhotos.interface';

export const EditPhotos: FC<EditPhotosProps> = ({
  photos,
  loadingPhotos,
  editedImageData,
  onAdd,
  onEdit,
  onDelete,
  onSave,
}) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <>
      <div className="grid relative gap-8 p-4 m-auto bg-white sm:py-8 sm:px-14">
        <ul className="px-4 text-sm list-disc text-grey-forest-600">
          <li>
            {t(
              `Photos should be bright, in-focus, and show you as the focal point.`,
            )}
          </li>
          <li>
            {t(
              `Try to avoid: photos where the brightest light is behind you, causing your face to be in darkness; photos where there are shadows on your face; and photos that are blurry.`,
            )}
          </li>
          <li>
            {t(
              `Select a variety of images that capture your family life and personality. We suggest a minimum of 5 photos.`,
            )}
          </li>
          <li>
            {t(
              `Your photos should include full body pictures of yourself, pictures of you with your spouse, and pictures with you with your children.`,
            )}
          </li>
          <li>
            {t(
              `Try to avoid selfie photos, photos taken in mirrors, and photos that have boarders and stickers on them.`,
            )}
          </li>
          <li>
            {t(
              `Pay attention to clothing and surroundings in your picture. You want to represent your family and personality, not your bathroom or your car.`,
            )}
          </li>
        </ul>
        <ul
          role="list"
          className="grid grid-cols-2 gap-4 sm:grid-cols-3 sm:gap-4 md:gap-8"
        >
          {photos?.length &&
            photos.map((item, index) => (
              <EditPhotosItem
                key={`${index}_${item?.image}`}
                item={item}
                onChange={(file) => {
                  onAdd!(file, index, item);
                  setEditMode(true);
                }}
                onDelete={async () => await onDelete(item)}
                loading={!!loadingPhotos.find((photo) => photo == item?.id)}
                onEdit={() => {
                  onEdit(item);
                  setEditMode(true);
                }}
              />
            ))}
        </ul>
      </div>
      <ImageCropperModal
        onClose={() => setEditMode(false)}
        open={editMode}
        imageDataUrl={editedImageData?.dataUrl}
        onSave={onSave}
        variant="rectangle"
      />
    </>
  );
};
