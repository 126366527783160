import type { ApiModel } from '@nodal/api';
import { apiEnums } from '@nodal/api/enums';
import { IntroductionRequestCard } from '@nodal/core/flows/IntroductionRequestCard';
import React, { FC } from 'react';
import { MatchesListProps } from './MatchesList.interface';

export const MatchesList: FC<MatchesListProps> = ({
  matches,
  role,
  onUpdateIntroduction,
  onMatchClick,
}) => {
  const renderMatch = (introduction: ApiModel.IntroductionFull) => {
    if (
      introduction.stage === apiEnums.IntroductionStageEnum.Expired ||
      introduction.stage === apiEnums.IntroductionStageEnum.Broken
    )
      return null;

    return (
      <li
        key={introduction.id}
        onClick={() => onMatchClick(introduction, role)}
        className="hover:cursor-pointer"
      >
        <IntroductionRequestCard
          introduction={{ ...introduction, stage: introduction.stage }}
          role={role}
          onUpdate={onUpdateIntroduction}
        />
      </li>
    );
  };

  return (
    <div className="flex flex-1 justify-center py-6">
      <div className="flex-1 px-4 max-w-5xl">
        <ul className="flex flex-col gap-4">{matches.map(renderMatch)}</ul>
      </div>
    </div>
  );
};
