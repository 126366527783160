import type { FC } from 'react';

import {
  PencilAltIcon,
  PhotographIcon,
  TrashIcon,
  UploadIcon,
} from '@heroicons/react/outline';
import { t } from '@nodal/i18n';
import { Tooltip } from '@nodal/uikit/components/Tooltip';
import { useFileReader } from '@nodal/uikit/hooks/useFileReader';
import classNames from 'classnames';
import { useRef } from 'react';

import { SortablePhoto } from '../SortablePhoto';

import type { EditPhotosItemProps } from './EditPhotosItem.interface';

export const EditPhotosItem: FC<EditPhotosItemProps> = ({
  item,
  onChange,
  onDelete,
  loading,
  onEdit,
  sortable = true,
  variant = 'rectangle',
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const src = useFileReader(item?.image);

  return (
    <div
      className={classNames('group flex relative items-stretch', {
        'h-40 w-full': variant === 'rectangle',
        'w-56 h-56': variant === 'rounded',
      })}
    >
      <input
        ref={fileInput}
        type="file"
        className="hidden"
        multiple={false}
        accept="image/*"
        onChange={(e) => {
          if (!e.target.files) return;
          onChange(e.target.files[0]);
          e.target.value = ''; // NOTES: Reset current value to allow selecting the same file multiple times in a row
        }}
        disabled={loading}
      />
      {item?.image && src ? (
        <>
          <div
            className={classNames(
              'flex group-hover:flex absolute top-0 right-0 z-50 gap-2 p-4 px-2 w-full sm:hidden',
              {
                'justify-end': variant === 'rectangle',
                'justify-center': variant === 'rounded',
              },
            )}
          >
            <Tooltip
              message={t('Upload new photo')}
              className="relative w-10"
              position="top"
            >
              <button
                className="w-10 h-10 bg-white/80 hover:bg-white rounded-lg"
                onClick={(e) => {
                  e.stopPropagation();
                  !loading && fileInput.current && fileInput.current.click();
                }}
                type="button"
              >
                <UploadIcon className="aspect-square m-auto w-1/2 stroke-grey-forest-500" />
              </button>
            </Tooltip>
            <Tooltip
              message={t('Remove')}
              className="relative w-10"
              position="top"
            >
              <button
                className="w-10 h-10 bg-white/80 hover:bg-white rounded-lg"
                onClick={(e) => {
                  e.stopPropagation();
                  !loading && onDelete();
                }}
                type="button"
              >
                <TrashIcon className="aspect-square m-auto w-1/2 stroke-grey-forest-500" />
              </button>
            </Tooltip>
            <Tooltip
              message={t('Edit')}
              className="relative w-10"
              position="top"
            >
              <button
                className="w-10 h-10 bg-white/80 hover:bg-white rounded-lg"
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit();
                }}
                type="button"
              >
                <PencilAltIcon className="aspect-square m-auto w-1/2 stroke-grey-forest-500" />
              </button>
            </Tooltip>
          </div>
          {sortable ? (
            <SortablePhoto id={item?.id} src={src} loading={loading} />
          ) : (
            <div
              className={classNames('flex w-full h-full', {
                '-mt-2': variant === 'rounded',
              })}
            >
              <div
                className={classNames(
                  'flex-1 mb-2 w-full h-full bg-center bg-cover outline-none select-none',
                  {
                    'rounded-lg': variant === 'rectangle',
                    'rounded-full': variant === 'rounded',
                  },
                )}
                style={{ backgroundImage: `url(${src})` }}
              />
            </div>
          )}
        </>
      ) : (
        <div
          className="flex items-stretch w-full h-full"
          onClick={() => fileInput.current && fileInput.current.click()}
        >
          <div
            className={classNames(
              'flex flex-1 justify-center items-center bg-grey-forest-50 border-2 border-grey-forest-200 border-solid hover:cursor-pointer',
              {
                'rounded-lg': variant === 'rectangle',
                'rounded-full': variant === 'rounded',
              },
            )}
          >
            <PhotographIcon className="aspect-square w-1/3 stroke-grey-forest-400" />
          </div>
        </div>
      )}
    </div>
  );
};
