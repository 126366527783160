import { forwardRef } from 'react';

import { TextInput } from 'components/TextInput/TextInput';

import type { SsnInputProps } from './SsnInput.interface';

export const SsnInput = forwardRef<HTMLInputElement, SsnInputProps>(
  ({ value, onChange, name, ...props }, inputRef) => {
    return (
      <TextInput
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        ref={inputRef}
        {...props}
      />
    );
  },
);

SsnInput.displayName = 'SsnInput';
