import type { FC } from 'react';

import { CheckIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { Link } from 'react-router-dom';

import type { ResetPasswordSuccessScreenProps } from './ResetPasswordSuccessScreen.interface';

export const ResetPasswordSuccessScreen: FC<
  ResetPasswordSuccessScreenProps
> = ({ title, message, redirectPaths }) => (
  <div className="flex flex-col gap-4 justify-center items-center">
    <div className="flex justify-center items-center w-12 h-12 bg-forest-100 rounded-full">
      <CheckIcon className="w-6 h-6 text-forest-300" />
    </div>
    <div className="text-center">
      <h2 className="mb-2 text-2xl font-semibold leading-8 text-grey-forest-900">
        {title}
      </h2>
      <p className="text-base font-normal leading-6 text-grey-forest-600">
        {message}
      </p>
    </div>
    <Link to={redirectPaths.signin} className="w-full sm:w-max">
      <Button variant="primary" className="justify-center mt-2 w-full">
        {t('Log In')}
      </Button>
    </Link>
  </div>
);
