import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { ChevronLeftIcon } from '@heroicons/react/solid';
import { PaymentSummaryCard } from '@nodal/uikit/components/Payment';
import { t } from '@nodal/i18n';
import { paths } from 'consts/paths';
import { PaymentFlowProps } from './PaymentFlow.interface';
import { PaymentMethod } from 'components/SubscriptionFlow';
import { PaymentMethodSelect } from './PaymentMethodSelect';
import { PaymentForm } from './PaymentForm';
import { Features } from '../Features';

export const PaymentFlow: FC<PaymentFlowProps> = ({
  amount,
  paymentMethods,
  paymentPackage,
}) => {
  const [method, setMethod] = useState<PaymentMethod>(paymentMethods[0].method);
  const selectedPaymentDetails = paymentMethods.find(
    (m) => m.method === method,
  );

  return (
    <div className="w-full">
      <div className="flex relative flex-col gap-8 py-5 pb-4 mx-auto max-w-5xl h-full bg-transparent sm:flex sm:flex-row sm:py-10 lg:grid">
        <div className="gap-14 justify-start items-start px-4 w-full h-full sm:px-8 lg:px-0">
          <Link
            to={paths.paymentService}
            className="text-sm font-medium leading-5 text-grey-forest-600"
          >
            <div className="flex items-center py-2 w-full">
              <ChevronLeftIcon className="w-6 h-6 text-grey-forest-500" />
              {t('Nodal Services')}
            </div>
          </Link>

          <div className="flex flex-col-reverse gap-4 justify-center items-start py-5 w-full sm:flex-row sm:gap-8">
            <div className="flex flex-col gap-6 py-4 px-8 w-full bg-white rounded-lg border border-grey-forest-200 shadow-sm">
              <PaymentMethodSelect
                onChange={setMethod}
                selected={method}
                options={paymentMethods}
              />
              <div className="flex flex-col gap-6 w-full">
                <p className="text-base font-medium leading-6 text-grey-forest-600">
                  {t('Payment Information')}
                </p>
                <PaymentForm
                  // NOTE: Key is required here
                  // Ref: https://github.com/stripe/react-stripe-js/issues/246
                  // TODO: Consider fixing this by using fetchUpdates()
                  // but this will require some refactoring
                  // https://stripe.com/docs/js/elements_object/fetch_updates
                  key={selectedPaymentDetails?.secret}
                  clientSecret={selectedPaymentDetails?.secret}
                />
              </div>
            </div>
            <div className="flex flex-col gap-4 w-full sm:flex-col sm:w-1/2">
              <Features amount={amount} {...paymentPackage} />
              <PaymentSummaryCard
                subtotal={amount}
                fees={selectedPaymentDetails?.fees || undefined}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
