import { apiEnums, ApiModel, GlobalError, useApiClient } from '@nodal/api';
import { FC } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { MatchingAcceptedWithoutPayment } from 'components/Matching/MatchingProgress';
import { MatchesList } from './MatchesList';
import { NoMatchesScreen } from 'components/MatchesScreen/NoMatchesScreen';
import { useMatching } from 'components/Matching';
import { toast } from 'react-toastify';
import { t } from '@nodal/i18n';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { queryKeys } from '@nodal/core/consts/query';
import { MatchingUser } from 'api';

const useMatchesList = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const introductionsList = useQuery(
    queryKeys.introductionsList,
    () => apiClient.api.IntroductionsApi.introductionsList(),
    {
      cacheTime: 0,
    },
  );

  const matches = introductionsList.data?.data;

  const { profileData: usersMe } = useMatching();

  const role = usersMe?.role;

  const { mutateAsync: updateIntroduction } = useMutation(
    'updateIntroduction',
    (
      requestParameters: ApiModel.IntroductionsApiIntroductionsPartialUpdateRequest,
    ) =>
      apiClient.api.IntroductionsApi.introductionsPartialUpdate(
        requestParameters,
      ),
    {
      // NOTE: data of these queries contains an introduction or introductions_left property
      // that needs to be updated
      onSuccess: () =>
        Promise.all([
          queryClient.invalidateQueries(queryKeys.introductionsList),
          queryClient.invalidateQueries(queryKeys.usersMeRetrieve),
        ]),
      onError: (error: GlobalError) => {
        console.log(error?.response?.data);
        if (error?.response?.data) {
          toast.error(t('Something went wrong...'));
        }
      },
    },
  );

  const onMatchClick = (
    introduction: ApiModel.IntroductionFull,
    role: MatchingUser['role'],
  ) => {
    const userIdByRole = {
      par: introduction.donor_profile?.user?.id,
      dnr: introduction.parent_profile?.user?.id,
    };

    const userId = userIdByRole[role];

    if (userId) {
      navigate(`${userId}`);
    }
  };

  if (!matches || !role) return undefined;

  return {
    matches,
    role,
    onUpdateIntroduction: updateIntroduction,
    onMatchClick,
    isLoading: introductionsList?.isLoading,
    nodalServicesPaymentComplete: usersMe?.has_nodal_services_payment_completed,
  };
};

export const MatchesListConnected: FC = () => {
  const matches = useMatchesList();

  if (matches?.isLoading || !matches) return <LoadingScreen />;

  const { role, nodalServicesPaymentComplete, matches: matchesList } = matches;

  if (role === apiEnums.UserRoleEnum.Par && !nodalServicesPaymentComplete) {
    return <MatchingAcceptedWithoutPayment />;
  }

  if (matchesList && !matchesList.length) {
    return <NoMatchesScreen role={role} />;
  }

  return <MatchesList {...matches} />;
};
