import useScript from '@charlietango/use-script';

export const useGooglePlacesApi = () => {
  const [placesApiReady] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${
      import.meta.env.VITE_GOOGLE_MAPS_KEY
    }&libraries=places`,
  );

  return placesApiReady;
};
