import { t } from '@nodal/i18n';
import { paths } from 'consts/paths';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SignUpScreenProps } from './SignUpScreen.interface';
import { SignUpForm } from '@nodal/core/flows/SignUp/SignUpForm';

export const SignUpScreen: FC<SignUpScreenProps> = ({
  initialValues,
  onSubmit,
  title,
  validationSchema,
  description,
}) => {
  return (
    <>
      <div className="flex flex-col gap-4">
        <h2 className="mt-6 text-2xl font-semibold leading-8 text-gray-900 sm:text-3xl sm:font-bold sm:leading-9">
          {title}
        </h2>
        <p className="text-base font-normal leading-6 text-grey-forest-600 sm:text-lg sm:leading-7">
          {description}
        </p>
      </div>

      <div>
        <SignUpForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          roleSelectionVisible={false}
        />
        <div className="pb-4 mt-8 text-base font-normal leading-6 text-center text-grey-forest-600">
          {t('Already have an account?')}&nbsp;
          <Link
            to={paths.signin}
            className="text-base font-semibold leading-6 text-forest-500"
          >
            {t('Log In')}
          </Link>
        </div>
      </div>
    </>
  );
};
