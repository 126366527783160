import type { FC } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { setAxiosAuthInterceptors } from '../engine';
import { AuthConfig, AuthProviderProps } from './AuthProvider.interface';

const AuthContext = createContext<AuthConfig | undefined>(undefined);

export const AuthProvider: FC<AuthProviderProps> = ({ children, value }) => {
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const auth = useContext(AuthContext);
  if (!auth) {
    throw new Error('Auth not configured!');
  }

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    undefined,
  );

  const { axios, tokenProvider, callbacks, noRefresh } = auth;

  useEffect(() => {
    const updateIsAuthenticated = async () =>
      setIsAuthenticated(await tokenProvider.getIsAuthenticated());

    updateIsAuthenticated();
    // NOTE:
    // Don't update when token changes, only on initial mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAxiosAuthInterceptors({
      axios,
      tokenProvider,
      callbacks,
      noRefresh,
    });
  }, [axios, tokenProvider, callbacks, noRefresh]);

  return { ...auth, isAuthenticated };
};
