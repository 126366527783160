import type { FC } from 'react';

import { useSendVerificationLink } from '@core/flows/hooks/useSendVerificationLink';

import { ConfirmEmailErrorScreen } from './ConfirmEmailErrorScreen';

import type { ConfirmEmailErrorScreenConnectedProps } from './ConfirmEmailErrorScreen.interface';

export const CheckEmailErrorScreenConnected: FC<
  ConfirmEmailErrorScreenConnectedProps
> = ({ email, title, message, redirectPaths }) => {
  const { handleSend } = useSendVerificationLink({ redirectPaths, email });

  return (
    <ConfirmEmailErrorScreen
      onSend={handleSend}
      title={title}
      message={message}
    />
  );
};
