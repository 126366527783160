import type { FC } from 'react';

import { useMemo } from 'react';

import { ConfirmEmailErrorScreen } from './ConfirmEmailErrorScreen';
import { ConfirmEmailInfoScreen } from './ConfirmEmailInfoScreen';
import { ConfirmEmailSuccessScreen } from './ConfirmEmailSuccessScreen';

import type { ConfirmEmailScreenProps } from './ConfirmEmailScreen.interface';

export const ConfirmEmailScreen: FC<ConfirmEmailScreenProps> = ({
  email,
  onConfirm,
  confirmation,
  redirectPaths,
}) => {
  const renderScreen = useMemo(() => {
    if (confirmation) {
      const { status, title, message } = confirmation || {};
      switch (status) {
        case 'error':
          return (
            <ConfirmEmailErrorScreen
              title={title}
              message={message}
              email={email}
              redirectPaths={{ checkEmail: redirectPaths.checkEmail }}
            />
          );
        case 'success':
          return (
            <ConfirmEmailSuccessScreen
              title={title}
              message={message}
              redirectPaths={{ signin: redirectPaths.signin }}
            />
          );
        default:
          return null;
      }
    }

    return <ConfirmEmailInfoScreen email={email} onConfirm={onConfirm} />;
  }, [email, onConfirm, confirmation, redirectPaths]);

  return renderScreen;
};
