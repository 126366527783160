import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import * as Yup from 'yup';

import { isInRelationship } from '@core/utils/profile';
import { convertToFeetAndInches, convertToInches } from 'utils';

import {
  alternativeSmokingMethodsChoices,
  birthControlChoices,
  radioChoices,
  traditionalSmokingMethodsChoices,
} from './questionChoices';

import type {
  DonorQuestionnaireScreen,
  FormQuestion,
} from '@core/flows/Screening/FormQuestions';
import type { ApiModel } from '@nodal/api';

const additionalQuestions: (
  isNodalUser: boolean,
) => FormQuestion<DonorQuestionnaireScreen>[] = (isNodalUser) =>
  isNodalUser
    ? [
        {
          value: 'current_passport',
          label: t('Do you have a current passport?'),
          type: 'radio',
          choices: radioChoices,
          default: null,
          required: true,
        },
        {
          value: 'willing_travel_to_canada',
          label: t('Would you be willing to travel to Canada?'),
          type: 'radio',
          choices: radioChoices,
          default: null,
          required: true,
        },
      ]
    : [];

export const questions: (
  isNodalUser: boolean,
) => FormQuestion<DonorQuestionnaireScreen>[] = (isNodalUser) => [
  {
    value: 'body_measurements',
    label: t('Weight and Height'),
    type: 'bmi',
    default: {
      weight: null,
      height_in: null,
      height_ft: null,
    },
    required: true,
  },
  {
    value: 'previous_pregnancy_count',
    label: t('Number of previous pregnancies'),
    type: 'number',
    default: 0,
    required: true,
  },
  {
    value: 'last_pregnancy_delivery_date',
    label: t('Date of last delivery?'),
    type: 'delivery_past_date',
    default: null,
    required: true,
  },
  {
    value: 'last_pregnancy_delivery_type',
    label: t('Was this a c-section or vaginal delivery?'),
    type: 'select',
    choices: [
      {
        label: t('C-section'),
        value: 'c_section',
      },
      {
        value: 'vaginal',
        label: t('Vaginal'),
      },
    ],
    default: null,
    required: true,
  },
  {
    value: 'currently_breastfeeding',
    label: t('Are you currently breastfeeding?'),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'currently_breastfeeding_wean_date',
    label: t('If so, when do you plan to wean?'),
    type: 'future_date',
    default: null,
    required: true,
    hidden: ({ currently_breastfeeding = false }) =>
      !currently_breastfeeding ||
      currently_breastfeeding?.toString() === 'false',
  },
  {
    value: 'previous_c_section_count',
    label: t('Number of previous C-sections'),
    type: 'number',
    default: 0,
    required: true,
  },
  {
    value: 'miscarriage',
    label: t('Have you ever had a miscarriage past 10 weeks?'),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'previous_ivf_transfer_count',
    label: t('Have you ever had an IVF transfer? If so, how many?'),
    type: 'number',
    default: 0,
    required: true,
  },
  {
    value: 'failed_embryo_count',
    label: t('Have you ever had a failed embryo transfer, if so, how many?'),
    type: 'number',
    default: 0,
    required: true,
  },
  {
    value: 'history_of_pre_eclampsia',
    label: t(
      'Did you have pre-eclampsia or blood pressure issues during any pregnancy or postpartum?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'suffered_from_postpartum_depression',
    label: t('Have you ever been diagnosed with any complications postpartum?'),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'has_exclusion_conditions',
    label: t(
      'Do you currently have any of the following conditions? HIV-1, HIV-2, HIV group 0, Hepatitis B, Hepatitis C, CMV, N. gonorrhoae (gonorrhea), C. trachomatis (chlaymdia), Diabetes?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'history_of_depression',
    label: t(
      'Do you have a history of major depression, bipolar disorder, eating disorders, psychosis, or a significant anxiety disorder?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'history_of_cardiac',
    label: t(
      'Do you have a history of any cardiac disorders including (blood pressure issues, clotting disorders or cardiovascular health problems)?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'history_of_autoimmune',
    label: t(
      'Do you have a history of any autoimmune, clotting or coagulation disorders?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'history_of_abdominal',
    label: t(
      'Do you have a history of genitourinary or major abdominal surgery?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'history_of_abuse',
    label: t(
      'Do you have a history of addiction, trauma from child abuse, sexual abuse or physical abuse, or traumatic pregnancy, labor and/or delivery?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'relationship_status',
    label: t('What is your legal relationship status?'),
    type: 'select',
    choices: [
      {
        label: t('Single'),
        value: 'sin',
      },
      {
        label: t('In relationship'),
        value: 'rel',
      },
      {
        label: t('Engaged'),
        value: 'eng',
      },
      {
        label: t('Married'),
        value: 'mar',
      },
      {
        label: t('Divorced'),
        value: 'div',
      },
      {
        label: t('Separated'),
        value: 'sep',
      },
      {
        label: t('Widowed'),
        value: 'wid',
      },
      {
        label: t('Other'),
        value: 'oth',
      },
    ],
    default: null,
    required: true,
  },
  {
    value: 'partner_name',
    label: t("What is your partner's first and last name?"),
    type: 'name',
    default: {
      first_name: null,
      last_name: null,
    },
    required: true,
    hidden: ({ relationship_status = null }) =>
      !isInRelationship(relationship_status),
  },
  {
    value: 'sex_partners_last_6_months',
    label: t('Number of sexual partner(s) in last 6 months?'),
    type: 'number',
    default: 0,
    required: true,
  },
  {
    value: 'birth_control_method',
    label: t('What is your current method of contraception (birth control)?'),
    type: 'select',
    choices: birthControlChoices,
    default: null,
    required: true,
  },
  {
    value: 'birth_control_method_details',
    label: t('Please specify other method of contraception'),
    type: 'text',
    default: null,
    required: true,
    hidden: ({ birth_control_method = null }) =>
      !birth_control_method || birth_control_method !== 'other',
  },
  {
    value: 'birth_control_pill_brand',
    label: t(
      "If you're currently on the pill for birth control, what is the name of the brand?",
    ),
    type: 'text',
    default: null,
    required: true,
    hidden: ({ birth_control_method = null }) =>
      !birth_control_method || birth_control_method !== 'pill',
  },
  {
    value: 'comfortable_abstaining_from_sex',
    label: t(
      'Are you comfortable abstaining from sex for 8-12 weeks prior to transfer?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'us_citizen',
    label: t('Are you a US citizen or permanent resident?'),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  ...additionalQuestions(isNodalUser),
  {
    value: 'native_american',
    label: t(
      'Are you or any member of your family an active member of a Native American tribe?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'receipt_of_government_assistance',
    label: t('Are you in receipt of government assistance?'),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'usage_of_grass',
    label: t('Do you currently use marijuana, CBD or herbal supplements?'),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'traditional_smoking_history',
    label: t(
      'Do you or have you ever smoked? If so, when is the last time you smoked?',
    ),
    type: 'radio',
    choices: traditionalSmokingMethodsChoices,
    default: null,
    required: true,
  },
  {
    value: 'alternative_methods_smoking_history',
    label: t('Have you vaped or used e-cigs in the last year?'),
    type: 'radio',
    choices: alternativeSmokingMethodsChoices,
    default: null,
    required: true,
  },
  {
    value: 'smoked_last_year_within_house',
    label: t(
      'Do you currently live with someone who smokes, vapes or uses e-cigs?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'tattoo_last_year_date',
    label: t('If applicable, when was the date of your most recent tattoo?'),
    type: 'past_date',
    default: null,
    required: false,
  },
  {
    value: 'suspect_postpartum_depression',
    label: t(
      'When the time comes, do you believe that you would have difficulty giving up the child?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'has_family_support',
    label: t(
      'Is your family and/or partner supportive with your desire to become a surrogate?',
    ),
    type: 'radio',
    choices: radioChoices,
    default: null,
    required: true,
  },
  {
    value: 'finished_having_kids',
    label: t('Are you finished with having your own biological children?'),
    choices: radioChoices,
    type: 'radio',
    default: null,
    required: true,
  },
];

export const getDonorSchema = (isNodalUser: boolean) =>
  Yup.object({
    last_pregnancy_delivery_type: Yup.string()
      .nullable()
      .required('This field is required'),
    currently_breastfeeding: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    currently_breastfeeding_wean_date: Yup.string()
      .nullable()
      .when('currently_breastfeeding', {
        is: true,
        then: Yup.string().nullable().required(t('This field is required')),
      }),
    miscarriage: Yup.boolean().nullable().required(t('This field is required')),
    native_american: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    us_citizen: Yup.boolean().nullable().required(t('This field is required')),
    relationship_status: Yup.string()
      .nullable()
      .required(t('This field is required')),
    partner_name: Yup.object()
      .nullable()
      .when('relationship_status', {
        is: isInRelationship,
        then: Yup.object()
          .nullable()
          .shape({
            first_name: Yup.string()
              .nullable()
              .required(t('This field is required')),
            last_name: Yup.string()
              .nullable()
              .required(t('This field is required')),
          }),
      }),
    sex_partners_last_6_months: Yup.number()
      .nullable()
      .required(t('This field is required')),
    birth_control_method: Yup.string()
      .nullable()
      .required(t('This field is required')),
    birth_control_method_details: Yup.string()
      .nullable()
      .when('birth_control_method', {
        is: apiEnums.BirthControlMethodEnum.Other,
        then: Yup.string().nullable().required(t('This field is required')),
      }),
    birth_control_pill_brand: Yup.string()
      .nullable()
      .when('birth_control_method', {
        is: apiEnums.BirthControlMethodEnum.Pill,
        then: Yup.string().nullable().required(t('This field is required')),
      }),
    history_of_pre_eclampsia: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    comfortable_abstaining_from_sex: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    previous_pregnancy_count: Yup.number()
      .nullable()
      .required(t('This field is required')),
    previous_ivf_transfer_count: Yup.number()
      .nullable()
      .required(t('This field is required')),
    last_pregnancy_delivery_date: Yup.string()
      .nullable()
      .required(t('This field is required')),
    previous_c_section_count: Yup.number()
      .nullable()
      .required(t('This field is required')),
    body_measurements: Yup.object()
      .nullable()
      .shape({
        weight: Yup.number().nullable().required(t('This field is required')),
        height_ft: Yup.number()
          .nullable()
          .required(t('This field is required')),
        height_in: Yup.number()
          .nullable()
          .required(t('This field is required')),
      }),
    failed_embryo_count: Yup.number()
      .nullable()
      .required(t('This field is required')),
    receipt_of_government_assistance: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    has_exclusion_conditions: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    history_of_depression: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    history_of_cardiac: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    history_of_autoimmune: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    history_of_abdominal: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    history_of_abuse: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    suffered_from_postpartum_depression: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    usage_of_grass: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    traditional_smoking_history: Yup.string()
      .nullable()
      .required(t('This field is required')),
    alternative_methods_smoking_history: Yup.string()
      .nullable()
      .required(t('This field is required')),
    smoked_last_year_within_house: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    tattoo_last_year_date: Yup.string().nullable(),
    suspect_postpartum_depression: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    has_family_support: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    finished_having_kids: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    current_passport: isNodalUser
      ? Yup.boolean().nullable().required(t('This field is required'))
      : Yup.boolean().nullable(),
    willing_travel_to_canada: isNodalUser
      ? Yup.boolean().nullable().required(t('This field is required'))
      : Yup.boolean().nullable(),
  });

export const transformSubmitValues = (
  data: DonorQuestionnaireScreen,
): ApiModel.DonorQuestionnaireScreen => {
  const { body_measurements, partner_name, ...postData } = data || {};

  const { height_in, height_ft, weight } = body_measurements || {};
  const { first_name, last_name } = partner_name || {};

  return {
    ...postData,
    height: convertToInches(height_ft, height_in),
    weight,
    partner_first_name: first_name,
    partner_last_name: last_name,
  };
};

export const transformInitialValues = (
  values: ApiModel.DonorQuestionnaireScreen,
): DonorQuestionnaireScreen => {
  const { height, weight, partner_first_name, partner_last_name } =
    values || {};

  const { feet, inches } = convertToFeetAndInches(height ?? null);

  return {
    ...values,
    body_measurements: {
      height_in: inches,
      height_ft: feet,
      weight,
    },
    partner_name: {
      first_name: partner_first_name,
      last_name: partner_last_name,
    },
  };
};
