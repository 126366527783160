import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import { Fragment, useMemo } from 'react';

import { FormInputError } from '@uikit/components/FormInputError';

import type { SelectProps, SelectOption } from './Select.interface';

export const Select = <T extends string | number>({
  value,
  name,
  label,
  options,
  error,
  className = '',
  optionsPosition = 'bottom',
  multiple = false,
  ...props
}: SelectProps<T>) => {
  const selectClassNames = classNames(
    'relative py-2 pr-10 pl-3 w-full h-10 text-left rounded-md border focus:outline-none shadow-sm cursor-default sm:text-sm text-base',
    error
      ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
      : 'border-grey-forest-300 focus:ring-1 focus:ring-forest-500 focus:border-forest-500',
    props.disabled ? 'bg-beige-50' : 'bg-white',
  );

  const chevronIconClassNames = classNames(
    'w-5 h-5',
    error ? 'text-red-300' : 'text-grey-forest-400',
  );

  const selectedOptionLabel = useMemo(
    () =>
      multiple && Array.isArray(value)
        ? options
            .filter((opt) => (value?.find((v) => v === opt.value) ? opt : null))
            .map((sel) => sel.label)
            .join(', ')
        : options.find((o) => o.value === value)?.label || '',
    [options, value, multiple],
  );

  return (
    <div className={`relative ${className}`}>
      <Listbox
        {...props}
        value={multiple ? value || [] : value}
        name={name}
        multiple={multiple}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium leading-5 text-grey-forest-700">
              {label}
            </Listbox.Label>
            <div className="relative mt-1">
              <Listbox.Button className={selectClassNames}>
                <span className="block truncate">{selectedOptionLabel}</span>
                <span className="flex absolute inset-y-0 right-0 items-center pr-2 pointer-events-none">
                  {open ? (
                    <ChevronUpIcon
                      className={chevronIconClassNames}
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronDownIcon
                      className={chevronIconClassNames}
                      aria-hidden="true"
                    />
                  )}
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className={classNames(
                    'overflow-auto absolute z-10 py-1 mt-1 w-full max-h-60 text-base bg-white rounded-md focus:outline-none ring-1 ring-black/5 shadow-lg sm:text-sm',
                    {
                      'bottom-full': optionsPosition === 'top',
                    },
                  )}
                >
                  {options.map((option: SelectOption<T>) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        classNames(
                          active ? 'font-semibold bg-sage-200' : 'font-normal',
                          'cursor-default select-none relative py-2 pl-3 pr-9 text-sm leading-5',
                          option?.disabled
                            ? 'text-grey-forest-400'
                            : 'text-grey-forest-900',
                        )
                      }
                      {...option}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            )}
                          >
                            {option.label}
                          </span>

                          {selected ? (
                            <span className="flex absolute inset-y-0 right-0 items-center pr-4 text-forest-400">
                              <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {error && name && <FormInputError error={error} id={name} />}
    </div>
  );
};
