import React, { FC } from 'react';
import { BillingDetails } from '../BillingDetails';
import { CompletedPaymentSummaryProps } from './CompletedPaymentSummary.interface';
import { t } from '@nodal/i18n';
import { nodalEmail } from 'consts/email';
import { Features } from '../Features';

export const CompletedPaymentSummary: FC<CompletedPaymentSummaryProps> = ({
  amount,
  billingDetails,
  paymentPackage,
}) => {
  return (
    <div className="flex flex-col items-center py-8 px-4">
      <div className="flex flex-col gap-4 max-w-4xl sm:min-w-[500px]">
        <Features amount={amount} {...paymentPackage} />
        <BillingDetails billingDetails={billingDetails} />
        <p className="text-xs font-normal leading-4 text-grey-forest-600">
          {t('*If you would like to cancel, please')}
          &nbsp;
          <a
            className="font-medium text-forest-400"
            href={`mailto:${nodalEmail}`}
          >
            {t('contact Nodal')}
          </a>
          &nbsp;
          {t('for assistance.')}
        </p>
      </div>
    </div>
  );
};
