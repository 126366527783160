import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { Resources } from './Resources';
import { ResourcesConnectedProps } from './Resources.interface';
import { getAvailableResources, getColumnsCount } from './utils';

export const ResourcesConnected: FC<ResourcesConnectedProps> = ({
  currentStage,
}) => {
  const apiClient = useApiClient();

  const { data: resources } = useQuery(
    queryKeys.tasksDocumentResourcesList,
    () => apiClient.api.TasksApi.tasksDocumentResourcesList(),
  );

  if (!resources?.data) return null;

  const availableResources = getAvailableResources(
    resources.data,
    currentStage,
  );
  const resourcesCount = availableResources.length;
  const columnsCount = getColumnsCount(resourcesCount);

  if (!availableResources.length) return null;

  return (
    <Resources
      resources={availableResources.sort((a, b) =>
        a.created > b.created ? -1 : 1,
      )}
      columns={columnsCount}
    />
  );
};
