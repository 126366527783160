import type { ChangeEvent, DragEvent } from 'react';

import { UploadIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import classNames from 'classnames';
import { useRef } from 'react';

export const FileDropZone = ({
  dragActive,
  disabled,
  acceptFileTypes,
  onChange,
  onDrag,
  onDrop,
}: {
  dragActive: boolean;
  disabled: boolean;
  acceptFileTypes: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onDrag: (e: DragEvent<HTMLDivElement>) => void;
  onDrop: (e: DragEvent<HTMLDivElement>) => void;
}) => {
  const fileInput = useRef<HTMLInputElement>(null);

  return (
    <div
      className={classNames(
        'flex flex-col relative p-3 w-full justify-center items-center rounded-t-lg',
        dragActive ? 'bg-forest-100' : 'bg-grey-forest-50',
        disabled && 'cursor-not-allowed',
      )}
      onDragEnter={onDrag}
      onDragLeave={onDrag}
      onDragOver={onDrag}
      onDrop={onDrop}
    >
      <input
        ref={fileInput}
        type="file"
        className="hidden"
        onChange={onChange}
        accept={acceptFileTypes}
      />
      <div className="flex flex-col gap-2 justify-center items-center">
        <UploadIcon className="w-8 h-8 text-forest-400 bg-transparent rounded-full" />
        <p className="text-sm font-medium leading-5 text-center text-forest-500">
          <span>{t('Drag and drop or')}</span>
          <br />
          <span
            className={classNames('underline', !disabled && 'cursor-pointer')}
            onClick={() => {
              if (fileInput.current && !disabled) {
                fileInput.current.click();
              }
            }}
          >
            {t('browse')}
          </span>
          <span> {t('your file')}</span>
        </p>
      </div>
    </div>
  );
};
