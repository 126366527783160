import { Form, Formik } from 'formik';
import { FC } from 'react';
import { t } from '@nodal/i18n';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { SurrogateContactDetailsProps } from './SurrogateContactDetails.interface';
import { PastDateInput } from '@nodal/uikit/components/DateInput';
import { PhoneNumberInput } from '@nodal/uikit/components/PhoneNumberInput';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { Address } from 'models';
import { AddressInput } from '@nodal/uikit/components/AddressInput';

export const SurrogateContactDetails: FC<SurrogateContactDetailsProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  states,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
    validateOnBlur={false}
    validationOnChange={false}
  >
    {({ isSubmitting, setFieldValue, setFieldTouched }) => (
      <Form className="space-y-4 w-full lg:w-step-view">
        <div className="p-8 bg-white rounded-lg">
          <h3 className="text-xl font-semibold leading-7 text-gray-900">
            {t('Personal Information')}
          </h3>
          <div className="grid grid-cols-1 gap-x-4 gap-y-10 mt-6">
            <TextInput label={t('First Name')} name="first_name" type="text" />
            <TextInput
              label={t('Middle Name')}
              name="middle_name"
              type="text"
            />
            <TextInput label={t('Last Name')} name="last_name" type="text" />
            <TextInput
              label={t(
                'Maiden name or any other names used in the past (optional)',
              )}
              name="maiden_name"
              type="text"
            />
            <PastDateInput
              label={t('Date of Birth')}
              name="date_of_birth"
              placeholder="YYYY-DD-MM"
              className="lg:w-1/2"
            />
          </div>
        </div>

        <div className="p-8 bg-white rounded-lg">
          <div>
            <h3 className="text-xl font-semibold leading-7 text-gray-900">
              {t('Contact Information')}
            </h3>
          </div>
          <div className="grid grid-cols-1 gap-x-4 gap-y-10 mt-6">
            <PhoneNumberInput
              label={t('Phone Number')}
              name="phone_number"
              placeholder="(555) 987-6543"
              type="text"
              className="lg:w-1/2"
            />
            <AddressInput
              name="address"
              onChange={(address: Address) =>
                Object.entries(address).forEach(([key, value]) => {
                  setFieldValue(
                    `address.${key}`,
                    key === 'state' &&
                      states.find((s) => s.value === value)?.disabled
                      ? null
                      : value,
                  );
                  setFieldTouched(`address.${key}`, false);
                })
              }
              states={states}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <ButtonLoading
            type="submit"
            variant="primary"
            loading={isSubmitting}
            className="justify-center w-full sm:w-max"
          >
            {t('Submit')}
          </ButtonLoading>
        </div>
      </Form>
    )}
  </Formik>
);
