export const settings = {
  getBasePath() {
    return import.meta.env.VITE_BASE_PATH;
  },
  getMatchingSignUpBasePath() {
    return import.meta.env.VITE_MATCHING_SIGN_UP_BASE_PATH;
  },
  getNavigatorSignUpBasePath() {
    return import.meta.env.VITE_NAVIGATOR_SIGN_UP_BASE_PATH;
  },
  getStripePublishableKey() {
    return import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || '';
  },
  getApiUrl() {
    return import.meta.env.VITE_API_URL || '';
  },
  getPostHogEnabled() {
    return !!import.meta.env.VITE_POSTHOG_API_KEY;
  },
  getSignUpHidden() {
    return import.meta.env.VITE_SIGN_UP_HIDDEN === 'true';
  },
  getHelloSignAppId() {
    return import.meta.env.VITE_HELLOSIGN_APP_ID || '';
  },
  getIpDashboardFeatureEnabled() {
    return import.meta.env.VITE_IP_DASHBOARD_FEATURE_ENABLED === 'true';
  },
};
