import React, { FC } from 'react';
import { MatchedScreenProps } from './MatchedScreen.interface';
import { t } from '@nodal/i18n';
import { apiEnums } from '@nodal/api';
import { ParentsMatchProfile } from 'components/MatchProfile/ParentsMatchProfile';
import { Alert } from '@nodal/uikit/components/Alert';
import { SurrogateMatchProfile } from 'components/MatchProfile/SurrogateMatchProfile';

export const MatchedScreen: FC<MatchedScreenProps> = ({ user }) => {
  return (
    <div className="flex relative flex-col col-span-12 w-full h-full font-avenir-next text-sm font-normal leading-5">
      <div className="w-full h-16">
        <div className="fixed z-10 w-full">
          <Alert
            variant="success"
            title={t('Congratulations on finding your match!')}
          />
        </div>
      </div>

      <div className="h-full">
        <div className="flex-1 mx-auto max-w-5xl">
          {user.role === apiEnums.UserRoleEnum.Par && (
            <ParentsMatchProfile
              profileData={user}
              requestIntroductionHidden
              owner={false}
            />
          )}
          {user.role === apiEnums.UserRoleEnum.Dnr && (
            <SurrogateMatchProfile
              profileData={{
                ...user,
                role: apiEnums.UserRoleEnum.Dnr,
                profile: {
                  ...user.profile,
                  bmi: null,
                },
              }}
              owner={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};
