import { Popover } from '@headlessui/react';
import { apiEnums, ApiModel } from '@nodal/api';
import type { User } from 'api';
import { paths } from 'consts/paths';
import { FC, useMemo } from 'react';
import { DesktopNav } from './DesktopNav/DesktopNav';
import { MobileNav } from './MobileNav';
import { TopNavProps } from './TopNav.interface';
import {
  roleToNavigationItemsScreeningComplete,
  roleToNavigationItemsDeactivated,
  roleToNavigationItemsMatched,
  roleToUserMenuItemsDeactivated,
  roleToUserMenuItemsAlwaysDisplayed,
  roleToUserMenuItemsScreeningComplete,
  roleToNavigationItemsAlwaysDisplayed,
} from './topNavItems';

export const getMenuItems = (
  role: User['role'],
  status: ApiModel.UserStatusEnum,
  passedScreens: boolean,
) => {
  switch (status) {
    case apiEnums.UserStatusEnum['Scr']:
    case apiEnums.UserStatusEnum['Dis']:
      return roleToUserMenuItemsAlwaysDisplayed[role];
    case apiEnums.UserStatusEnum['Dea']:
      return passedScreens
        ? roleToUserMenuItemsDeactivated[role]
        : roleToUserMenuItemsAlwaysDisplayed[role];
    // NOTE: For Nac status, we want to enable payment service in menu items
    case apiEnums.UserStatusEnum['Nac']:
      return roleToUserMenuItemsScreeningComplete[role].map((item) =>
        item.href === paths.paymentService
          ? { ...item, disabled: false }
          : item,
      );
    default:
      return roleToUserMenuItemsScreeningComplete[role];
  }
};

const getNavigationItems = (
  role: User['role'],
  status: ApiModel.UserStatusEnum,
  passedScreens: boolean,
) => {
  switch (status) {
    case apiEnums.UserStatusEnum['Scr']:
      return roleToNavigationItemsAlwaysDisplayed[role];
    case apiEnums.UserStatusEnum['Mat+']:
      return roleToNavigationItemsMatched[role];
    case apiEnums.UserStatusEnum['Dea']:
    case apiEnums.UserStatusEnum['Dis']:
      return passedScreens ? roleToNavigationItemsDeactivated[role] : [];
    default:
      return roleToNavigationItemsScreeningComplete[role];
  }
};

export const TopNav: FC<TopNavProps> = ({ profileData }) => {
  const { role, status, passed_screens } = profileData || {};

  const menuItems = useMemo(() => {
    return (role === apiEnums.UserRoleEnum.Dnr ||
      role === apiEnums.UserRoleEnum.Par ||
      role === apiEnums.UserRoleEnum.Nap) &&
      status
      ? getMenuItems(role, status, !!passed_screens)
      : [];
  }, [role, status, passed_screens]);

  const navigationItems = useMemo(() => {
    return (role === apiEnums.UserRoleEnum.Dnr ||
      role === apiEnums.UserRoleEnum.Par ||
      role === apiEnums.UserRoleEnum.Nap) &&
      status
      ? getNavigationItems(role, status, !!passed_screens)
      : [];
  }, [role, status, passed_screens]);

  return (
    <Popover
      as="nav"
      className="px-0 w-full bg-forest-500 sm:px-4 md:px-6 lg:px-8"
    >
      {({ open, close }) => (
        <>
          <div className="hidden sm:block">
            <DesktopNav
              menuItems={menuItems}
              navigationItems={navigationItems}
              profileData={profileData}
            />
          </div>
          <div className="block sm:hidden">
            <MobileNav
              open={open}
              onClose={close}
              menuItems={menuItems}
              navigationItems={navigationItems}
              profileData={profileData}
            />
          </div>
          {open && (
            <Popover.Overlay className="block fixed w-full h-full bg-gray-500/75 sm:hidden" />
          )}
        </>
      )}
    </Popover>
  );
};
