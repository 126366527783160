import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { NavigatorMatchProfile } from 'components/MatchProfile/NavigatorMatchProfile';
import { navigatorPaths } from 'consts/paths';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NavigatorProps } from './Navigator.interface';
import { NavigatorStartConnected as NavigatorStart } from './NavigatorStart.connect';

export const Navigator: FC<NavigatorProps> = ({ user }) => {
  return (
    <div className="flex relative flex-col flex-1 gap-8 bg-transparent sm:flex sm:flex-row">
      <Routes>
        <>
          <Route
            path={navigatorPaths.profile}
            element={<NavigatorMatchProfile profileData={user} />}
          />

          <Route path={navigatorPaths.start} element={<NavigatorStart />} />
        </>
        <Route path="*" element={<LoadingScreen />} />
      </Routes>
    </div>
  );
};
