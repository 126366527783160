import type { FC } from 'react';

import { useField } from 'formik';
import { useCallback } from 'react';

import { PhoneNumberInput } from './PhoneNumberInput';

import type { PhoneNumberInputConnectedProps } from './PhoneNumberInput.interface';

export const PhoneNumberInputConnected: FC<PhoneNumberInputConnectedProps> = ({
  id,
  onChange,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(props);

  const handleChange = useCallback(
    (newValue: PhoneNumberInputConnectedProps['value']) => {
      setValue(newValue);

      if (onChange) {
        onChange(newValue);
      }
    },
    [setValue, onChange],
  );

  return (
    <PhoneNumberInput
      id={id || props.name}
      error={meta.touched && meta.error ? meta.error : undefined}
      {...props}
      {...field}
      onChange={handleChange}
    />
  );
};
