import type { FC } from 'react';
import { t } from '@nodal/i18n';
import { SignInForm } from '@nodal/core/flows/SignInForm';
import { Link } from 'react-router-dom';

import { paths } from 'consts/paths';

import type { SignInScreenProps } from './SignInScreen.interface';
import { settings } from 'settings';

export const SignInScreen: FC<SignInScreenProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
}) => (
  <>
    <div className="flex flex-col gap-4">
      <h2 className="mt-6 text-2xl font-semibold leading-8 text-gray-900 sm:text-3xl sm:font-bold sm:leading-9">
        {t('Welcome back')}
      </h2>
      <p className="text-base font-normal leading-6 text-grey-forest-600 sm:text-lg sm:leading-7">
        {t('Connecting Future Families Together')}
      </p>
    </div>
    <div className="">
      <SignInForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        redirectPaths={{ forgotPassword: paths.forgotPassword }}
      />
    </div>
    {!settings.getSignUpHidden() ? (
      <div className="text-base font-normal leading-6 text-center text-grey-forest-600">
        {t("Don't have an account?")}&nbsp;
        <Link
          to={paths.signup}
          className="text-base font-semibold leading-6 text-forest-500"
        >
          {t('Sign up')}
        </Link>
      </div>
    ) : (
      <div className="text-base font-normal leading-6 text-center text-grey-forest-600">
        <p>{t('Looking to join Nodal?')}</p>
        <p className="font-medium text-forest-600">
          <a
            href="https://www.nodal.com/surrogates#surrogates-2nd/"
            target="_blank"
            rel="noreferrer"
          >
            {t('Surrogates apply here')}
          </a>
        </p>
        <p className="font-medium text-forest-600">
          <a
            href="https://www.nodal.com/parents#parents-2nd"
            target="_blank"
            rel="noreferrer"
          >
            {t('Parents apply here')}
          </a>
        </p>
      </div>
    )}
  </>
);
