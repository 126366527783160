import type { PropsWithChildren } from 'react';

import { t } from '@nodal/i18n';
import { Alert } from '@nodal/uikit/components/Alert';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { SurveyCard } from '@nodal/uikit/components/SurveyCard';
import { Form, Formik } from 'formik';

import type {
  FormQuestionsProps,
  FormValues,
  QuestionnaireScreen,
} from './FormQuestions.interface';
import type { FormikErrors, FormikTouched } from 'formik';

const isError = <T extends QuestionnaireScreen>(
  field: string,
  errors: FormikErrors<FormValues<T>>,
  touched: FormikTouched<FormValues<T>>,
) => {
  return !!(
    Object.keys(errors).find((e) => e === field) &&
    Object.keys(touched).find((t) => t === field)
  );
};

export const FormQuestions = <T extends QuestionnaireScreen>(
  props: PropsWithChildren<FormQuestionsProps<T>>,
) => {
  const {
    title,
    description,
    questions,
    initialValues,
    validationSchema,
    alert,
    onSubmit,
  } = props;

  const shouldRenderSurveyCard = (
    formValues: FormValues<T>,
    hidden?: (value: FormValues<T>) => boolean,
  ) => (hidden ? !hidden(formValues) : true);

  if (alert) {
    return (
      <div className="flex flex-col justify-start items-start w-full lg:w-step-view">
        <div className="pb-6 w-full">
          <Alert {...alert} />
        </div>
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, touched, values, isSubmitting }) => (
        <Form className="flex flex-col gap-4 justify-start items-start w-full h-full lg:w-step-view">
          <div
            className={`p-8 w-full text-left bg-beige-500 rounded-lg border border-sand-200`}
          >
            <div className="flex flex-col gap-4">
              <h3 className="text-2xl font-semibold leading-8 text-sand-900">
                {title}
              </h3>
              <p className="text-base font-normal leading-6 text-sand-900">
                {description}
              </p>
            </div>
          </div>
          {questions.map(
            ({ value, label, type, choices, description, hidden }) =>
              shouldRenderSurveyCard(values, hidden) && (
                <SurveyCard
                  key={value?.toString()}
                  label={value === 'weight' ? 'Weight and Height' : label}
                  value={value?.toString()}
                  type={type}
                  choices={choices}
                  error={isError(value?.toString(), errors, touched)}
                  description={description}
                />
              ),
          )}
          <ButtonLoading
            type="submit"
            variant="primary"
            className="justify-center ml-auto w-full sm:w-max"
            loading={isSubmitting}
          >
            {t('Submit')}
          </ButtonLoading>
        </Form>
      )}
    </Formik>
  );
};
