import { apiEnums, useApiClient } from '@nodal/api';
import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { MatchedScreen } from './MatchedScreen';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { sortIntroductionsByDate } from './utils';
import { queryKeys } from '@nodal/core/consts/query';

const useMatchedScreenData = () => {
  const apiClient = useApiClient();

  const { data: usersMe } = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );
  const myRole = usersMe?.data.role;

  const introductionsList = useQuery(queryKeys.introductionsList, () =>
    apiClient.api.IntroductionsApi.introductionsList({
      params: { matched: true },
    }),
  );

  const matches = introductionsList.data?.data ?? [];

  const finalMatch = sortIntroductionsByDate(matches).find(
    ({ match_accepted_parent, match_accepted_donor, stage }) =>
      match_accepted_parent &&
      match_accepted_donor &&
      stage === apiEnums.IntroductionStageEnum.Matched,
  );

  const user = useMemo(() => {
    if (!finalMatch) return undefined;

    const { donor_profile, parent_profile } = finalMatch;
    const role =
      myRole === apiEnums.UserRoleEnum.Par
        ? apiEnums.UserRoleEnum.Dnr
        : apiEnums.UserRoleEnum.Par;
    const profile =
      role === apiEnums.UserRoleEnum.Par ? parent_profile : donor_profile;

    return {
      ...profile.user,
      profile,
      role,
    };
  }, [finalMatch, myRole]);

  return {
    user,
  };
};

export const MatchedScreenConnected: FC = () => {
  const { user } = useMatchedScreenData();

  if (!user) return <LoadingScreen />;

  return <MatchedScreen user={user} />;
};
