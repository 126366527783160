import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { apiEnums, NavigatorUser } from '@nodal/api';
import { navigatorPaths as paths } from 'consts/paths';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useAppProtected } from 'app';
import { useUsersMeRetrieve } from 'api/hooks/useUsersMeRetrieve';
import { NavigatorAction, NavigatorState } from './Navigator.interface';
import { Navigator } from './Navigator';

const navigatorReducer = (
  state: NavigatorState,
  action: NavigatorAction,
): NavigatorState => {
  switch (action.type) {
    case 'updateCompleted':
      return { ...state, completed: action.payload };
    case 'updateStarted':
      return { ...state, started: action.payload };
    default:
      return state;
  }
};

export const NavigatorContext = createContext<
  | { state: NavigatorState; dispatch: (action: NavigatorAction) => void }
  | undefined
>(undefined);

export const NavigatorContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(navigatorReducer, {
    completed: false,
    started: false,
  });

  return (
    <NavigatorContext.Provider value={{ state, dispatch }}>
      {children}
    </NavigatorContext.Provider>
  );
};

export const useNavigator = () => {
  const context = useContext(NavigatorContext);

  if (!context) {
    throw new Error(
      'useNavigator must be used within a NavigatorContextProvider',
    );
  }

  const {
    state: { completed, started },
    dispatch,
  } = context;

  const userProfile = useUsersMeRetrieve();

  const { data: userData } = userProfile || {};

  const refresh = useCallback(async () => {
    const { data } = userData || {};
    const { status } = data || {};

    if (status === apiEnums.UserStatusEnum.Nac) {
      dispatch({
        type: 'updateCompleted',
        payload: true,
      });
    }
  }, [userData, dispatch]);

  const start = useCallback(() => {
    dispatch({
      type: 'updateStarted',
      payload: true,
    });
  }, [dispatch]);

  return {
    // NOTE: Only navigator user appear in the navigator
    user: userData?.data as NavigatorUser,
    refresh,
    start,
    started,
    completed,
  };
};

const NavigatorInitialRedirect: FC = () => {
  const { started, refresh, completed } = useNavigator();
  const { matchProfileCompleted } = useAppProtected();
  const navigate = useNavigate();
  const params = useParams();
  const path = params['*'];

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (started && path === paths.start) {
      navigate(paths.profile);
      return;
    }

    // NOTE: below we use condition path === '' because we check whether it's auto redirect or not
    if (
      (matchProfileCompleted || completed) &&
      (path === '' || path === paths.start)
    ) {
      navigate(paths.profile);
      return;
    }

    if (!started && !matchProfileCompleted && path === '') {
      navigate(paths.start);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [started, navigate, matchProfileCompleted]);

  return null;
};

const NavigatorContextConsumer: FC = () => {
  const { user } = useNavigator();
  return <Navigator user={user} />;
};

export const NavigatorConnected: FC = () => (
  <NavigatorContextProvider>
    <Routes>
      <Route path="*" element={<NavigatorInitialRedirect />} />
    </Routes>
    <NavigatorContextConsumer />
  </NavigatorContextProvider>
);
