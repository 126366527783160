import type { FC } from 'react';

import classNames from 'classnames';
import { useCallback } from 'react';

import { AvatarPlaceholder } from '@uikit/components/AvatarPlaceholder';

import type { AvatarProps } from './Avatar.interface';

export const Avatar: FC<AvatarProps> = ({
  avatarUrl,
  initials,
  className = '',
}) => {
  const containerClassName = classNames(
    avatarUrl ? 'bg-transparent' : 'bg-gray-100',
    'w-9 h-9 flex items-center justify-center overflow-hidden rounded-full',
    className,
  );

  const renderContent = useCallback(() => {
    if (avatarUrl) {
      return (
        <img
          src={avatarUrl}
          className="inline-block object-cover w-full h-full rounded-full"
          alt=""
        />
      );
    } else if (initials) {
      return <span className="text-forest-500">{initials}</span>;
    } else {
      return <AvatarPlaceholder />;
    }
  }, [avatarUrl, initials]);

  return <div className={containerClassName}>{renderContent()}</div>;
};
