import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import * as Yup from 'yup';

import { placeSchema } from '@core/consts/placeSchema';

import type { ApiModel } from '@nodal/api';

// TODO: write some logic allowing to build a validation object
// instead of repeating this 'when' condition all over
export const schema = Yup.object({
  ssn_last_4_digits: Yup.string()
    .nullable()
    .required(t('This field is required')),
  no_insurance: Yup.boolean().nullable(),
  health_insurance: Yup.string()
    .nullable()
    .when('no_insurance', {
      is: false,
      then: Yup.string().nullable().required(t('This field is required')),
    }),
  previous_pregnancy_count: Yup.number()
    .nullable()
    .required(t('This field is required')),
  pregnancy_details: Yup.array().of(
    Yup.object()
      .shape({
        same_doctor_details_as_first: Yup.boolean(),
        delivery_date: Yup.string()
          .nullable()
          .required(t('This field is required')),
        doctor_first_name: Yup.string()
          .nullable()
          .when('same_doctor_details_as_first', {
            is: false,
            then: Yup.string().nullable().required(t('This field is required')),
          }),
        doctor_middle_name: Yup.string().nullable().optional(),
        doctor_last_name: Yup.string()
          .nullable()
          .when('same_doctor_details_as_first', {
            is: false,
            then: Yup.string().nullable().required(t('This field is required')),
          }),
        doctor_phone_number: Yup.number()
          .nullable()
          .when('same_doctor_details_as_first', {
            is: false,
            then: Yup.number().nullable().required(t('This field is required')),
          }),
        doctor_clinic_name: Yup.string()
          .nullable()
          .when('same_doctor_details_as_first', {
            is: false,
            then: Yup.string()
              .nullable()
              .required(t('This field is required'))
              .max(100, t('Must be {length} characters or less', '100')),
          }),
        doctor_clinic_country: Yup.string()
          .nullable()
          .when('same_doctor_details_as_first', {
            is: false,
            then: Yup.string().nullable().required(t('This field is required')),
          }),
        doctor_clinic_address: Yup.object().when(
          ['same_doctor_details_as_first', 'doctor_clinic_country'],
          {
            is: (
              same_doctor_details_as_first: boolean,
              doctor_clinic_country: string,
            ) =>
              !same_doctor_details_as_first &&
              doctor_clinic_country === apiEnums.DoctorClinicCountryEnum.Us,
            then: placeSchema,
          },
        ),
        doctor_clinic_international_address: Yup.string()
          .nullable()
          .when(['same_doctor_details_as_first', 'doctor_clinic_country'], {
            is: (
              same_doctor_details_as_first: boolean,
              doctor_clinic_country: string,
            ) =>
              !same_doctor_details_as_first &&
              doctor_clinic_country === apiEnums.DoctorClinicCountryEnum.Other,
            then: Yup.string().nullable().required(t('This field is required')),
          }),
        facility_type: Yup.string()
          .nullable()
          .when('same_doctor_details_as_first', {
            is: false,
            then: Yup.string().nullable().required(t('This field is required')),
          }),
        delivery_address: Yup.object()
          .when(
            [
              'same_doctor_details_as_first',
              'facility_type',
              'doctor_clinic_country',
            ],
            {
              is: (
                same_doctor_details_as_first: boolean,
                facility_type: ApiModel.FacilityTypeEnum,
                doctor_clinic_country: string,
              ) =>
                !same_doctor_details_as_first &&
                (facility_type === 'hospital' ||
                  facility_type === 'birth_center') &&
                doctor_clinic_country === apiEnums.DoctorClinicCountryEnum.Us,
              then: placeSchema,
            },
          )
          .nullable(),
        delivery_international_address: Yup.string()
          .nullable()
          .when(
            [
              'same_doctor_details_as_first',
              'facility_type',
              'doctor_clinic_country',
            ],
            {
              is: (
                same_doctor_details_as_first: boolean,
                facility_type: ApiModel.FacilityTypeEnum,
                doctor_clinic_country: string,
              ) =>
                !same_doctor_details_as_first &&
                (facility_type === 'hospital' ||
                  facility_type === 'birth_center') &&
                doctor_clinic_country ===
                  apiEnums.DoctorClinicCountryEnum.Other,
              then: Yup.string()
                .nullable()
                .required(t('This field is required')),
            },
          ),
      })
      .required(t('This field is required')),
  ),
  ivf_transfers_details: Yup.array().of(
    Yup.object()
      .shape({
        same_doctor_details_as_first: Yup.boolean(),
        transfer_date: Yup.string()
          .nullable()
          .required(t('This field is required')),
        doctor_first_name: Yup.string()
          .nullable()
          .when('same_doctor_details_as_first', {
            is: false,
            then: Yup.string().nullable().required(t('This field is required')),
          }),
        doctor_middle_name: Yup.string().nullable().optional(),
        doctor_last_name: Yup.string()
          .nullable()
          .when('same_doctor_details_as_first', {
            is: false,
            then: Yup.string().nullable().required(t('This field is required')),
          }),
        doctor_phone_number: Yup.number()
          .nullable()
          .when('same_doctor_details_as_first', {
            is: false,
            then: Yup.number().nullable().required(t('This field is required')),
          }),
        doctor_clinic_name: Yup.string()
          .nullable()
          .when('same_doctor_details_as_first', {
            is: false,
            then: Yup.string()
              .nullable()
              .required(t('This field is required'))
              .max(100, t('Must be {length} characters or less', '100')),
          }),
        doctor_clinic_address: Yup.object().when(
          'same_doctor_details_as_first',
          {
            is: false,
            then: placeSchema,
          },
        ),
      })
      .required(t('This field is required')),
  ),
  ob_gyn_details: Yup.array().of(
    Yup.object()
      .shape({
        doctor_first_name: Yup.string()
          .nullable()
          .required(t('This field is required')),
        doctor_middle_name: Yup.string().nullable().optional(),
        doctor_last_name: Yup.string()
          .nullable()
          .required(t('This field is required')),
        doctor_phone_number: Yup.number()
          .nullable()
          .required(t('This field is required')),
        doctor_clinic_name: Yup.string()
          .nullable()
          .required(t('This field is required'))
          .max(100, t('Must be {length} characters or less', '100')),
        doctor_clinic_address: placeSchema,
      })
      .required(t('This field is required')),
  ),
  insurance_photo_front: Yup.string()
    .nullable()
    .when('no_insurance', {
      is: false,
      then: Yup.string().nullable().required(t('This field is required')),
    }),
  insurance_photo_back: Yup.string()
    .nullable()
    .when('no_insurance', {
      is: false,
      then: Yup.string().nullable().required(t('This field is required')),
    }),
});
