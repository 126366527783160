import type { FC } from 'react';

import type { AssetWithFallback } from './ImgWithFallback.interface';

export const ImgWithFallback: FC<AssetWithFallback> = ({
  source: { src, type = 'image/webp' },
  fallback,
}) => (
  <picture>
    <source srcSet={src} type={type} />
    <img {...fallback} />
  </picture>
);
