import { t } from '@nodal/i18n';
import { SurveyCard } from '@nodal/uikit/components/SurveyCard';

import type { ProfileData } from '../MatchProfile.interface';
import type { EditVideoProps } from './EditForm.interface';

export const EditVideo = <T extends ProfileData>({
  questions,
}: EditVideoProps<T>) => {
  return (
    <div className="flex relative flex-col gap-4 p-8 w-full h-full bg-white">
      <ul className="px-4 text-sm list-disc text-grey-forest-600">
        <li>{t('Video file size should be less than a 150MB.')}</li>
      </ul>

      {questions.map(({ value, label, type, choices, description }) => (
        <SurveyCard
          key={label}
          label={label}
          value={value?.toString()}
          type={type}
          choices={choices}
          error={false}
          description={description}
          className="p-0 pb-0"
        />
      ))}
    </div>
  );
};
