import classNames from 'classnames';
import { t } from '@nodal/i18n';
import { FC } from 'react';
import { PaymentMethodSelectProps } from './PaymentMethodSelect.interface';

export const PaymentMethodSelect: FC<PaymentMethodSelectProps> = ({
  options,
  onChange,
  selected,
}) => {
  const buttonClassName = (selected: boolean) =>
    classNames(
      `p-3 bg-white rounded-lg shadow-sm hover:cursor-pointer group flex gap-2 items-center ${
        selected
          ? 'border-forest-500 border-2'
          : 'border border-grey-forest-200'
      }`,
    );

  const iconClassName = (selected: boolean) =>
    classNames(
      'w-7 h-7 ',
      selected
        ? 'text-forest-400'
        : 'text-grey-forest-600 group-hover:text-grey-forest-900',
    );

  const labelClassName = (selected: boolean) =>
    classNames(
      'font-medium leading-7 text-base',
      selected
        ? 'text-forest-400'
        : 'text-grey-forest-600 group-hover:text-grey-forest-900',
    );
  return (
    <div>
      <p className="text-base font-medium leading-6 text-grey-forest-600">
        {t('Payment Method')}
      </p>
      <div className="flex gap-4 py-4">
        {options.map(({ method, icon: Icon, label }) => (
          <button
            className={buttonClassName(method === selected)}
            onClick={() => onChange(method)}
            key={method}
          >
            <Icon className={iconClassName(method === selected)} />
            <p className={labelClassName(method === selected)}>{label}</p>
          </button>
        ))}
      </div>
    </div>
  );
};
