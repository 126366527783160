import React, { FC } from 'react';
import { UserAddIcon } from '@heroicons/react/outline';
import { t } from '@nodal/i18n';
import { apiEnums } from '@nodal/api';
import { matchingPaths, paths } from 'consts/paths';
import { Button } from '@nodal/uikit/components/Button';
import { Link } from 'react-router-dom';
import { NoMatchesScreenProps } from './NoMatchesScreen.interface';

export const NoMatchesScreen: FC<NoMatchesScreenProps> = ({ role }) => {
  return (
    <div className="flex flex-1 justify-center py-6">
      <div className="flex flex-col flex-1 justify-center items-center">
        <UserAddIcon className="aspect-square mb-8 w-12 stroke-grey-forest-400 stroke-1" />
        <p className="mb-2 font-avenir-next text-2xl font-semibold leading-8 text-grey-forest-900">
          {t('No matches')}
        </p>
        {role === apiEnums.UserRoleEnum.Par && (
          <p className="font-avenir-next text-base font-normal leading-6 text-center text-grey-forest-600">
            {t(
              'Nodal will inform you when one of Gestational Carriers will request an introduction.',
            )}
          </p>
        )}
        {role === apiEnums.UserRoleEnum.Dnr && (
          <>
            <p className="mb-6 font-avenir-next text-base font-normal leading-6 text-grey-forest-600">
              {t("You haven't requested introductions yet.")}
            </p>
            <Button variant="primary">
              <Link to={`${paths.matching}/${matchingPaths.parents}`}>
                {t('Find Parents')}
              </Link>
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
