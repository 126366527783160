import type { FC } from 'react';

import { UserRoleEnum } from '@nodal/api/core';
import { t } from '@nodal/i18n';
import { BmiInput } from '@nodal/uikit/components/BmiInput';
import { Checkbox } from '@nodal/uikit/components/Checkbox';
import { PastDateInput } from '@nodal/uikit/components/DateInput';
import { NumberInput } from '@nodal/uikit/components/NumberInput';
import { NumberTagInput } from '@nodal/uikit/components/NumberTagInput';
import { RadioGroup } from '@nodal/uikit/components/RadioGroup';
import { Select } from '@nodal/uikit/components/Select';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { useCallback } from 'react';

import { states } from '@core/consts/states';
import { isInRelationship } from '@core/utils/profile';

import type {
  ProfileData,
  ParentProfileInformation,
} from '../MatchProfile.interface';
import type {
  EditSectionProps,
  TopBasicInformationProps,
} from './EditForm.interface';

const TopBasicInformation: FC<TopBasicInformationProps> = ({
  withPartner,
  samePartnerAddress,
}) => {
  const prefix = withPartner ? `partner_` : '';
  return (
    <>
      <TextInput
        label={t('First Name')}
        name={`${prefix}first_name`}
        type="text"
        className="sm:col-span-6"
      />
      {withPartner && samePartnerAddress && (
        <div className="sm:col-start-1 sm:col-end-7">
          <Checkbox
            name="same_partner_address"
            label={t('Same address as mine')}
            disabled
          />
        </div>
      )}
      {!samePartnerAddress && (
        <>
          <TextInput
            label={t('City')}
            name={`${prefix}address.city`}
            type="text"
            className="col-start-4 sm:col-span-3"
            disabled
          />
          <Select
            className="col-start-1 sm:col-span-3"
            label={t('State/Province')}
            name={`${prefix}address.state`}
            options={states}
            disabled
          />
        </>
      )}
      <PastDateInput
        label={t('Date of Birth')}
        name={`${prefix}date_of_birth`}
        placeholder="YYYY-DD-MM"
        className="col-start-1 sm:col-span-3"
      />
    </>
  );
};

export const EditTopSection = <T extends ProfileData>({
  questions,
  role,
  values,
}: EditSectionProps<T>) => {
  const getQuestionByValue = useCallback(
    (value: string) => questions?.find((q) => q.value === value),
    [questions],
  );

  // NOTE: TS shows an error, because same_partner_address is not present for DonorProfileInformation
  const { same_partner_address } = (values as ParentProfileInformation) || {};

  return (
    questions && (
      <div className="overflow-y-auto py-4 px-8 w-full max-h-full sm:max-h-100-modal">
        <div className="flex flex-col grid-cols-6 gap-x-4 gap-y-10 sm:grid">
          <TopBasicInformation withPartner={false} />
          {role === UserRoleEnum.Dnr && (
            <>
              <div className="flex flex-col col-span-6 col-start-1 gap-2">
                <h3 className="col-start-1 gap-0 pb-4 text-lg font-medium leading-8 sm:col-span-6">
                  {t('BMI (Body mass index)')}
                </h3>
                <BmiInput name="body_measurements" />
              </div>
              <Select
                className="col-start-1 sm:col-span-4"
                label={getQuestionByValue('experience')?.label}
                name="experience"
                options={getQuestionByValue('experience')?.choices || []}
              />
              <Select
                className="col-start-1 sm:col-span-4"
                label={getQuestionByValue('previous_c_sections')?.label}
                name="previous_c_sections"
                options={
                  getQuestionByValue('previous_c_sections')?.choices || []
                }
              />
              <NumberTagInput
                className="col-start-1 sm:col-span-3"
                label={getQuestionByValue('desired_compensation')?.label}
                name="desired_compensation"
                tag="$"
              />
            </>
          )}
          <TextInput
            label={getQuestionByValue('occupation')?.label}
            name="occupation"
            type="text"
            className="sm:col-span-3 sm:col-start-1"
          />
          <Select
            className="col-start-1 sm:col-span-4"
            label={getQuestionByValue('ideal_start')?.description}
            name="ideal_start"
            options={getQuestionByValue('ideal_start')?.choices || []}
          />
          {role === UserRoleEnum.Dnr && (
            <Select
              className="col-start-1 sm:col-span-4"
              label={getQuestionByValue('household_income')?.description}
              name="household_income"
              options={getQuestionByValue('household_income')?.choices || []}
            />
          )}
          <Select
            className="col-start-1 sm:col-span-4"
            label={getQuestionByValue('relationship_status')?.label}
            name="relationship_status"
            options={getQuestionByValue('relationship_status')?.choices || []}
          />
          {(role === UserRoleEnum.Par || role === UserRoleEnum.Nap) &&
            isInRelationship(values['relationship_status']) && (
              <>
                <div className="col-span-6">
                  <div className="relative">
                    <div
                      className="flex absolute inset-0 items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="flex relative justify-start">
                      <span className="pr-3 text-xl font-semibold leading-7 text-gray-900 bg-white">
                        {t('Partner')}
                      </span>
                    </div>
                  </div>
                </div>
                <TopBasicInformation
                  withPartner
                  samePartnerAddress={!!same_partner_address}
                />
                <TextInput
                  label={getQuestionByValue('partner_occupation')?.label}
                  name="partner_occupation"
                  type="text"
                  className="sm:col-span-3 sm:col-start-1"
                />
              </>
            )}

          <div className="col-span-6">
            <div className="relative">
              <div
                className="flex absolute inset-0 items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="flex relative justify-start">
                <span className="pr-3 text-xl font-semibold leading-7 text-gray-900 bg-white">
                  {t('Details')}
                </span>
              </div>
            </div>
          </div>

          {role === UserRoleEnum.Dnr && (
            <>
              {isInRelationship(values['relationship_status']) && (
                <TextInput
                  label={getQuestionByValue('partner_occupation')?.description}
                  name="partner_occupation"
                  type="text"
                  className="sm:col-span-3 sm:col-start-1"
                />
              )}

              <NumberInput
                name="number_of_children"
                label={getQuestionByValue('number_of_children')?.label}
                className="col-start-1 sm:col-span-6"
              />
            </>
          )}
          <RadioGroup
            className="col-start-1 sm:col-span-4"
            label={getQuestionByValue('lgbtq_identify')?.label}
            name="lgbtq_identify"
            options={getQuestionByValue('lgbtq_identify')?.choices || []}
          />

          <RadioGroup
            options={
              getQuestionByValue(
                role === UserRoleEnum.Dnr ? 'covid_vaccination' : 'is_military',
              )?.choices || []
            }
            name={
              role === UserRoleEnum.Dnr ? 'covid_vaccination' : 'is_military'
            }
            label={
              getQuestionByValue(
                role === UserRoleEnum.Dnr ? 'covid_vaccination' : 'is_military',
              )?.label
            }
            className={`col-span-6 pb-7 ${
              role === UserRoleEnum.Dnr ? 'border-b border-grey-forest-100' : ''
            }`}
          />
          {role === UserRoleEnum.Dnr && (
            <RadioGroup
              options={getQuestionByValue('is_military')?.choices || []}
              name={'is_military'}
              label={getQuestionByValue('is_military')?.label}
              className="col-span-6 pb-7 "
            />
          )}
        </div>
      </div>
    )
  );
};
