import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';

export const roleToSteps = {
  [apiEnums.UserRoleEnum.Dnr]: {
    title: t('Welcome to Nodal!'),
    description: t(
      "It's good to see you here. There are a few steps before you start your journey. First, complete Nodal Application. This is important to meet certain minimum criteria in order to be eligible for surrogacy",
    ),
    steps: [
      {
        title: t('Nodal Application'),
        index: 1,
      },
      {
        title: t('Match Profile'),
        index: 2,
      },
      {
        title: t('Find Parents'),
        index: 3,
      },
    ],
  },
  [apiEnums.UserRoleEnum.Par]: {
    title: t('Welcome to Nodal!'),
    description: t(
      "It's good to see you here. There are a few steps before you start your journey. First, complete Nodal Application. This is important to meet certain minimum criteria in order to be eligible as Intended Parents",
    ),
    steps: [
      {
        title: t('Nodal Application'),
        index: 1,
      },
      {
        title: t('Match Profile'),
        index: 2,
      },
      {
        title: t('Get listed'),
        index: 3,
      },
    ],
  },
  [apiEnums.UserRoleEnum.Nap]: {
    title: t('Welcome to Nodal!'),
    description: t(
      'Thank you for joining Nodal Navigator. We need a few personal details to get started',
    ),
    steps: [
      {
        title: t('Start now'),
        index: 1,
      },
      {
        title: t('Begin editing profile'),
        index: 2,
      },
    ],
  },
};
