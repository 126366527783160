import React, { FC } from 'react';
import { contactDetailsInitialValues } from 'components/ContactDetails/utils';
import { donorContactDetails } from './contactDetails';
import { SurrogateContactDetails } from './SurrogateContactDetails';
import {
  ContactValues,
  SurrogateContactDetailsConnectedProps,
} from './SurrogateContactDetails.interface';
import { surrogateValidationSchema } from './validation';

export const SurrogateContactDetailsConnected: FC<
  SurrogateContactDetailsConnectedProps
> = ({ submit, profileData, states }) => {
  const onSubmit = async (data: ContactValues) => {
    const postData = { ...data };

    // NOTE: We omit sending location in the payload on submit - agreed as part of the api contract
    // NOTE: We silence TS error due to a bug in api schema - location should be optional
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete postData?.address?.location;

    await submit(postData);
  };

  const initialValues = contactDetailsInitialValues(
    donorContactDetails,
    profileData,
    // TODO: Remove if some profile types will be fixed (set as optional).
    // Reported as an issue : https://linear.app/nodal-health/issue/NOD-266/backend-bug-in-api-schema-for-parents-and-donor-profile
  ) as ContactValues;

  return (
    <SurrogateContactDetails
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={surrogateValidationSchema}
      states={states}
    />
  );
};
