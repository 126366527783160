import type { FC } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import type {
  NavigationItem,
  NavigationItemsProps,
} from './NavigationItems.interface';

export const NavigationItems: FC<NavigationItemsProps> = ({
  navigationItems,
  onClose,
}) => {
  const navigationItemClassName = (isActive: boolean) =>
    classNames(
      isActive ? 'bg-forest-400' : '',
      'text-beige-500 block px-3 py-2 rounded-md text-base font-medium sm:text-sm hover:bg-forest-400',
    );

  return (
    <div className="block space-y-1 sm:flex sm:space-y-0 sm:space-x-4">
      {navigationItems?.length
        ? navigationItems.map(({ label, href }: NavigationItem) => (
            <NavLink
              key={label}
              to={href}
              className={({ isActive }) => navigationItemClassName(isActive)}
              onClick={() => onClose && onClose()}
            >
              {label}
            </NavLink>
          ))
        : null}
    </div>
  );
};
