import { ApiModel } from '@nodal/api';
import { parentStepsMeta } from '../utils';

export const getColumnsCount = (resourcesCount: number) => {
  if (resourcesCount === 1) return 1;
  else if (resourcesCount === 2 || resourcesCount === 4) return 2;
  else return 3;
};

export const getAvailableResources = (
  resources: ApiModel.DocumentResource[],
  currentStage: number,
) => {
  return resources.filter((resource) =>
    resource.stage
      ? parentStepsMeta[resource.stage].index <= currentStage
      : false,
  );
};

export const getResourceLink = ({
  fileLink,
  externalLink,
}: {
  fileLink?: string;
  externalLink?: string;
}): string | undefined => {
  if (!fileLink && !!externalLink) {
    return externalLink;
  } else if (!!fileLink && !externalLink) {
    return fileLink;
  } else {
    return undefined;
  }
};
