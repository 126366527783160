import type { FC } from 'react';

import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, useRef, useState } from 'react';

import type { TooltipProps } from './Tooltip.interface';

export const Tooltip: FC<TooltipProps> = ({
  message,
  children,
  className,
  position,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [openState, setOpenState] = useState(false);

  const toggleMenu = () => {
    setOpenState((openState) => !openState);
    buttonRef?.current?.click();
  };

  const tooltipClassName = classNames('absolute inset-x-0', {
    'bottom-full mb-1': position === 'top',
    'top-full mt-1': position === 'bottom',
  });

  const tooltipTriangle = classNames(
    'w-3 h-3 bg-grey-forest-700 rotate-45 z-50',
    {
      'block -mb-2': position === 'bottom',
      'block -mt-2': position === 'top',
    },
  );

  return (
    <Popover className={className}>
      <div
        onMouseEnter={toggleMenu}
        onMouseLeave={toggleMenu}
        className="flex relative flex-col"
      >
        <Popover.Button className="outline-none" ref={buttonRef}>
          {children}
        </Popover.Button>
        <Transition
          show={openState}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className={tooltipClassName}>
            <div className="flex flex-col items-center my-1 w-full whitespace-nowrap">
              {position === 'bottom' && <div className={tooltipTriangle}></div>}
              <span className="relative z-40 p-2 text-xs leading-none text-white bg-grey-forest-700 rounded-md shadow-lg">
                {message}
              </span>
              {position === 'top' && <div className={tooltipTriangle}></div>}
            </div>
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
};
