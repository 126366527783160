import type { FC } from 'react';

import classNames from 'classnames';

import { Step } from '@uikit/components/Step';

import type { StepsProps } from './Steps.interface';

export const Steps: FC<StepsProps> = ({ steps, activeIndex }) => {
  const stepClassName = (stepIdx: number) =>
    classNames(
      'overflow-hidden relative sm:border sm:border-t-0 sm:border-grey-forest-200 border-0 bg-transparent sm:bg-white',
      stepIdx === 0 ? 'sm:border-t sm:rounded-t-md' : '',
      stepIdx === steps.length - 1 ? 'sm:rounded-b-md' : '',
      activeIndex === stepIdx ? 'block' : 'hidden sm:block',
    );

  const stepBarClassName = (stepIdx: number) =>
    classNames(
      'w-full h-full',
      activeIndex < stepIdx ? 'bg-grey-forest-400' : 'bg-forest-500',
    );

  return (
    <nav className="w-full sm:w-max" aria-label="Progress">
      <ol role="list" className="block overflow-hidden sm:fixed">
        <div className="flex gap-1 justify-between w-full h-0.5 sm:hidden">
          {steps.map(({ title, index }) => (
            <span key={title} className={stepBarClassName(index)} />
          ))}
        </div>

        {steps.map(({ title, status, label, index }) => (
          <li key={title} className={stepClassName(index)}>
            <Step
              title={title}
              status={status}
              label={label}
              index={index + 1}
              active={activeIndex === index}
            />
          </li>
        ))}
      </ol>
    </nav>
  );
};
