import type { FC } from 'react';

import { useField } from 'formik';

import type { FieldConfig } from 'formik';

export type HiddenFieldProps = JSX.IntrinsicElements['input'] & {
  error?: string;
};

export const HiddenField: FC<HiddenFieldProps & FieldConfig<string>> = (
  props,
) => {
  const [field] = useField({ ...props });
  return (
    <input
      type="hidden"
      {...props}
      {...field}
      value={field.value || undefined}
    />
  );
};
