import type { FC } from 'react';

import { useApiClient, useAuth } from '@nodal/api';
import { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import type { AuthenticateScreenProps } from './AuthenticateScreen.interface';
import type { ApiModel, GlobalError } from '@nodal/api';

// NOTE: Verify the validity of the user's token before making any API queries.
// This approach enhances security, authentication transparency and improves user experience.
// - User Experience: Provides immediate feedback to users about the validity of their
//   authentication session.
// - Reinforced Security: Provides additional security through the token validation process, showing that it complements existing security measures.
// - Authentication Transparency: Token validation brings a more direct and transparent approach to the authentication process.
// TODO: Add test for this hook, ref: https://linear.app/nodal-health/issue/NOD-680/feadmin-refactor-for-external-payments
/**
 * Hook for checking the validity of a token.
 * @param {string | undefined} token - The token retrieved from search params, if present.
 * @param {string} redirectPath - The path to redirect when the token is not valid.
 * @returns {undefined | {isValid: boolean}} - Returns undefined if the token is not present,
 * otherwise, returns an object {isValid: boolean} indicating token validity.
 * This structure serves as a protection mechanism to avoid potential misinterpretation
 * of the return value as false in certain conditional checks (e.g., if (!!returnValue)).
 */
const useTokenVerify = ({
  token,
  redirectPath,
}: {
  token?: string;
  redirectPath: string;
}) => {
  const apiClient = useApiClient();
  const navigation = useNavigate();
  const [isValid, setIsValid] = useState(false);

  const verifyTokenCreate = useMutation(
    (requestParameters: ApiModel.AuthApiAuthTokenVerifyCreateRequest) =>
      apiClient.api.AuthApi.authTokenVerifyCreate(requestParameters!),
    {
      onSuccess: () => {
        setIsValid(true);
      },
      onError: (e: GlobalError) => {
        if (e.response.data.code === 'token_not_valid') {
          navigation(redirectPath);
        }
      },
    },
  );

  const verifyToken = () =>
    verifyTokenCreate.mutateAsync({
      tokenVerify: { token: token! },
    });

  useEffect(() => {
    if (token) {
      verifyToken();
    }

    // NOTE: Only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!token) {
    return undefined;
  }

  return { isValid };
};

export const AuthenticateScreen: FC<AuthenticateScreenProps> = ({
  redirectPaths,
}) => {
  const auth = useAuth();

  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || undefined;
  const tokenVerify = useTokenVerify({
    token,
    redirectPath: redirectPaths.unauthenticated,
  });

  useEffect(() => {
    if (token && tokenVerify?.isValid) {
      auth.tokenProvider.setAccessToken(token);
      navigation(redirectPaths.app);
    }
  }, [
    auth.tokenProvider,
    navigation,
    token,
    redirectPaths,
    tokenVerify?.isValid,
  ]);

  return null;
};
