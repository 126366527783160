import { useEffect, useState } from 'react';

export const useCurrentDate = (ms = 1000) => {
  const [currentDate, setCurrentDate] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setCurrentDate(Date.now()), ms);
    return () => clearInterval(interval);
  }, [ms]);

  return currentDate;
};
