import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { AppProgress, AppProgressProps } from 'components/AppProgress';
import { roleToSteps } from 'components/AppProgress/steps';
import { useNavigator } from './Navigator.connect';

const completeStep: AppProgressProps['status'] = {
  index: 2,
  status: 'in_progress',
};

export const NavigatorStartConnected = () => {
  const { start } = useNavigator();
  const { steps } = roleToSteps[apiEnums.UserRoleEnum.Nap];
  const { title } = steps.find((s) => s.index === completeStep.index) || {};

  return (
    <AppProgress
      button={title}
      description={t(
        "Next, we'll need you to get started on an Intended Parent Profile. We'll work together to make your profile shine, but we need your basic information to get started",
      )}
      progressBarVisible={false}
      onNext={start}
      title={t('Thank you!')}
      steps={steps}
      status={completeStep}
    />
  );
};
