import type { FC } from 'react';

import { useField } from 'formik';

import { Textarea } from './Textarea';

import type { TextareaConnectedProps } from './Textarea.interface';

export const TextareaConnected: FC<TextareaConnectedProps> = ({
  id,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <Textarea
      id={id || props.name}
      error={meta.touched && meta.error ? meta.error : undefined}
      {...props}
      {...field}
    />
  );
};
