import type { FC } from 'react';

import { ExclamationCircleIcon } from '@heroicons/react/solid';

import type { FormInputErrorProps } from './FormInputError.interface';

export const FormInputError: FC<FormInputErrorProps> = ({ id, error }) => (
  <div className="flex absolute items-center pr-1 mt-1.5 w-max">
    <div className="inset-y-0 right-0 pr-1 pointer-events-none">
      <ExclamationCircleIcon
        className="w-4 h-4 text-red-700"
        aria-hidden="true"
      />
    </div>
    <p className="text-xs text-red-700" id={`${id}-error`}>
      {error}
    </p>
  </div>
);
