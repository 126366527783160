import { t } from '@nodal/i18n';
import * as Yup from 'yup';

export const addressSchema = Yup.object()
  .shape({
    line1: Yup.string().nullable().required(t('This field is required')),
    line2: Yup.string().nullable(),
    state: Yup.string().nullable().required(t('This field is required')),
    zipcode: Yup.string()
      .nullable()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, t('Must be 5 or 9 digits'))
      .required(t('This field is required')),
    city: Yup.string()
      .nullable()
      .max(28, t('Must be {length} characters or less', '28'))
      .required(t('This field is required')),
  })
  .nullable();
