import type { FC, ReactNode } from 'react';

import type { MatchProfileSkeletonProps } from './MatchProfileSkeleton.interface';

const ProfileCardSkeleton = ({ children }: { children: ReactNode }) => (
  <div className=" col-span-12 col-start-2 p-4 w-full bg-white rounded-lg sm:p-8">
    <div className="flex flex-col gap-6 justify-start animate-pulse">
      <div className="w-1/2 h-8 bg-grey-forest-200 rounded-lg sm:w-1/3"></div>
      {children}
    </div>
  </div>
);

const NavigationTopBar = () => (
  <div className="sticky top-0 z-10 w-full bg-white shadow-sm ">
    <div className="flex py-3.5 px-6 mx-auto w-full max-w-7xl animate-pulse sm:py-2 lg:px-8">
      <div className="w-7 h-7 bg-grey-forest-200 rounded-lg sm:w-40 sm:h-10"></div>
      <div className="flex flex-1 justify-center items-center ml-0 sm:justify-start sm:ml-12">
        <div className="flex gap-2 items-center font-medium">
          <div className="w-7 h-7 bg-grey-forest-200 rounded-lg sm:w-40 sm:h-6"></div>
        </div>
      </div>
      <div className="w-7 h-7 bg-grey-forest-200 rounded-lg sm:w-40 sm:h-10"></div>
    </div>
  </div>
);

export const MatchProfileSkeleton: FC<MatchProfileSkeletonProps> = ({
  inMarketplace = false,
}) => (
  <div className="flex flex-col w-full">
    {inMarketplace && <NavigationTopBar />}
    <div className="flex flex-col gap-4 justify-start items-stretch py-6 px-4 mx-auto w-full max-w-5xl sm:py-8">
      <div className="flex flex-col col-span-12 col-start-2 gap-6 justify-start p-4 w-full bg-white rounded-lg  sm:flex-row sm:p-8">
        <div className="flex flex-col gap-5 w-full animate-pulse sm:w-2/5">
          <div className="p-3 h-96 bg-grey-forest-200 rounded-lg"></div>
          <div className="flex gap-2">
            <div className="w-20 h-20 bg-grey-forest-200 rounded-lg"></div>
            <div className="w-20 h-20 bg-grey-forest-200 rounded-lg"></div>
          </div>
        </div>
        <div className="flex flex-col space-y-10 w-full animate-pulse sm:w-3/5">
          <div className="w-1/2 h-8 bg-grey-forest-200 rounded-lg"></div>
          <div className="flex flex-col gap-x-8 gap-y-0 w-full sm:gap-y-3">
            {[...Array(4).keys()].map((el) => (
              <div
                key={el}
                className="grid-cols-1/2 col-span-2 gap-8 items-end py-4 border-b sm:grid sm:py-0 sm:border-none"
              >
                <div className="col-span-1 col-start-1 my-4 w-full h-5 bg-grey-forest-200 rounded-lg sm:my-0"></div>
                <div className="col-span-1 col-start-2 my-4 w-full h-5 bg-grey-forest-200 rounded-lg sm:my-0"></div>
              </div>
            ))}
          </div>
          <div className="flex flex-wrap gap-3">
            {[...Array(3).keys()].map((el) => (
              <div
                className="flex gap-3 justify-between items-center w-28 h-8 bg-grey-forest-200 rounded-full"
                key={el}
              ></div>
            ))}
          </div>
        </div>
      </div>
      <ProfileCardSkeleton>
        <div className="flex h-video-mobile sm:h-video-desktop">
          <div className="flex justify-center items-center w-full h-full bg-grey-forest-200 rounded-lg"></div>
        </div>
      </ProfileCardSkeleton>
      <ProfileCardSkeleton>
        <div className="w-full h-52 bg-grey-forest-200 rounded-lg"></div>
      </ProfileCardSkeleton>
      <ProfileCardSkeleton>
        <div className="flex flex-col space-y-10 w-full">
          {[...Array(4).keys()].map((el) => (
            <div
              className="flex flex-col gap-6 justify-start items-start sm:flex-row"
              key={el}
            >
              <div className="p-3 w-12 h-12 text-moss-500 bg-grey-forest-200 rounded-md"></div>
              <div className="space-y-2 w-full">
                <div className="w-full h-5 bg-grey-forest-200 rounded-lg sm:w-1/2"></div>
                <div className="w-full h-5 bg-grey-forest-200 rounded-lg sm:w-1/2 "></div>
              </div>
            </div>
          ))}
        </div>
      </ProfileCardSkeleton>
      <ProfileCardSkeleton>
        <div className="flex flex-col w-full">
          {[...Array(6).keys()].map((el) => (
            <div
              className="flex flex-col gap-8 justify-start items-start py-4 border-t border-grey-forest-200 sm:flex-row sm:items-center sm:py-3"
              key={el}
            >
              <div className="w-full h-5 bg-grey-forest-200 rounded-lg sm:w-1/2"></div>
              <div className="w-full h-5 bg-grey-forest-200 rounded-lg sm:w-1/2"></div>
            </div>
          ))}
        </div>
      </ProfileCardSkeleton>
      <ProfileCardSkeleton>
        <div className="flex flex-col w-full">
          {[...Array(6).keys()].map((el) => (
            <div
              className="flex flex-col gap-8 justify-start items-start py-4 border-t border-grey-forest-200 sm:flex-row sm:items-center sm:py-3"
              key={el}
            >
              <div className="w-full h-5 bg-grey-forest-200 rounded-lg sm:w-1/2"></div>
              <div className="w-full h-5 bg-grey-forest-200 rounded-lg sm:w-1/2"></div>
            </div>
          ))}
        </div>
      </ProfileCardSkeleton>
    </div>
  </div>
);
