import { FC } from 'react';
import { useScreening } from '@nodal/core/flows/Screening';
import { ScreeningStart } from './ScreeningProgress';
import { ScreeningProgressConnectedProps } from './ScreeningProgress.interface';

export const ScreeningStartConnected: FC<ScreeningProgressConnectedProps> = ({
  role,
}) => {
  const { start } = useScreening();
  return <ScreeningStart role={role} onNext={start} />;
};
