import type { FC } from 'react';

import { format } from 'date-fns';

import { DateInputConnected } from './DateInput.connect';

import type { DateInputConnectedProps } from './DateInput.interface';

export const PastDateInput: FC<DateInputConnectedProps> = (props) => {
  return (
    <DateInputConnected
      openToDate={new Date('1980-01-01')}
      min="1935-01-01"
      max={format(new Date(), 'yyyy-MM-dd')}
      {...props}
    />
  );
};
