import type { FC } from 'react';

import classNames from 'classnames';
import React from 'react';
import Input from 'react-phone-number-input/input';

import { FormInputError } from '@uikit/components/FormInputError';
import { countryPhoneCodes } from '@uikit/consts/countryPhoneCodes';

import type { PhoneNumberInputProps } from './PhoneNumberInput.interface';

export const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  id,
  label,
  error,
  className,
  international = false,
  ...props
}) => {
  const inputClassName = classNames(
    'w-full block pr-3 focus:outline-none sm:text-sm text-base rounded-lg text-grey-forest-900',
    error
      ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
      : 'shadow-sm focus:ring-forest-500 focus:border-forest-500 border-grey-forest-300',
    {
      'pl-16': !international,
      'bg-beige-50': props.disabled,
    },
  );

  return (
    <div className={`relative ${className}`}>
      <label
        htmlFor={id}
        className="block text-sm font-medium text-grey-forest-700"
      >
        {label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        {!international && (
          <div className="flex absolute inset-y-0 left-0 items-center">
            <label htmlFor="country" className="sr-only">
              Country
            </label>
            <select
              id="country"
              name="country"
              autoComplete="country"
              className="py-0 pr-7 pl-3 h-full text-grey-forest-500 bg-transparent rounded-md border-transparent focus:border-forest-500 focus:ring-forest-500 sm:text-sm"
              disabled
            >
              <option value={countryPhoneCodes.us}>
                {countryPhoneCodes.us}
              </option>
            </select>
          </div>
        )}

        <Input
          className={inputClassName}
          defaultCountry={international ? undefined : countryPhoneCodes.us}
          {...props}
        />
      </div>
      {error && id && <FormInputError error={error} id={id} />}
    </div>
  );
};
