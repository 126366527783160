import type { FC } from 'react';

import { useCallback, useState } from 'react';
import {
  InlineWidget,
  PopupModal,
  useCalendlyEventListener,
} from 'react-calendly';

import { Button } from '@uikit/components/Button/Button';
import { usePortal } from '@uikit/hooks/usePortal';

import type {
  CalendlyInlineProps,
  CalendlyModalProps,
} from './CalendlyWidget.interface';

const rootElementId = 'nh-calendly-widget-root';

export const CalendlyInline: FC<CalendlyInlineProps> = ({
  onEventScheduled,
  ...otherProps
}) => {
  useCalendlyEventListener({
    onEventScheduled,
  });

  return <InlineWidget {...otherProps} />;
};

export const CalendlyModal: FC<CalendlyModalProps> = ({
  children,
  onEventScheduled,
  ...otherProps
}) => {
  const rootElement = usePortal(rootElementId);
  const [open, setOpen] = useState(false);

  useCalendlyEventListener({
    onEventScheduled,
  });

  const handleOpenClose = useCallback((open: boolean) => {
    setOpen(open);
  }, []);

  return (
    <div id={rootElementId}>
      <Button variant="primary" onClick={() => handleOpenClose(true)}>
        {children}
      </Button>
      <PopupModal
        open={open}
        onModalClose={() => handleOpenClose(false)}
        rootElement={rootElement}
        {...otherProps}
      />
    </div>
  );
};
