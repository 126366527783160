import type { FC } from 'react';

import { useField } from 'formik';

import { Alert } from '@uikit/components/Alert';

import type { FieldConfig } from 'formik';

export type FormErrorsProps = JSX.IntrinsicElements['input'] & {
  error?: string;
};

export const FormErrors: FC<
  FormErrorsProps & Omit<FieldConfig<string>, 'name'>
> = (props) => {
  const [field, meta] = useField({ ...props, name: 'non_field_errors' });
  return (
    <>
      <input type="hidden" {...props} {...field} />
      {meta.error ? <Alert variant="error" title={meta.error} /> : null}
    </>
  );
};
