import type { FC } from 'react';

import { PhotographIcon } from '@heroicons/react/outline';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilIcon,
} from '@heroicons/react/solid';
import { AvatarPlaceholderSvg } from '@nodal/uikit/assets';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';

import { BadgeJustJoined } from '@core/assets';
import { sortPhotosByOrder } from '@core/utils';

import type { PhotoContainerProps } from './PhotoContainer.interface';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const renderArrowPrev = (clickHandler: () => void, hasPrev: boolean) => {
  return (
    hasPrev && (
      <button
        onClick={clickHandler}
        className={
          'z-10 p-0 m-0 h-full text-center transition-all duration-300 ease-in-out control-arrow control-prev'
        }
      >
        <ChevronLeftIcon className="w-6 h-6" />
        <span className="sr-only">Prev</span>
      </button>
    )
  );
};

const renderArrowNext = (clickHandler: () => void, hasNext: boolean) => {
  return (
    hasNext && (
      <button
        onClick={clickHandler}
        className={
          'right-0 z-10 p-0 m-0 h-full text-center transition-all duration-300 ease-in-out control-arrow control-next'
        }
      >
        <ChevronRightIcon className="w-6 h-6" />
        <span className="sr-only">Next</span>
      </button>
    )
  );
};

const renderSvgThumbIcon = (node: Element, prevOrNext: 'prev' | 'next') => {
  const svgDAttribute =
    prevOrNext === 'prev'
      ? 'M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
      : 'M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z';

  const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const iconPath = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path',
  );
  iconSvg.setAttribute('fill', 'currentColor');
  iconSvg.setAttribute('viewBox', '0 0 24 24');
  iconPath.setAttribute('fill-rule', 'evenodd');
  iconPath.setAttribute('clip-rule', 'evenodd');

  iconPath.setAttribute('d', svgDAttribute);

  iconSvg.appendChild(iconPath);

  return node.appendChild(iconSvg);
};

export const PhotoContainer: FC<PhotoContainerProps> = ({
  photos,
  edit,
  showJustJoinedBadge,
  userId,
}) => {
  useEffect(() => {
    const thumbNext = document.querySelector('.axis-vertical .control-next');
    const svgNext = document.querySelector('.axis-vertical .control-next svg');
    const thumbPrev = document.querySelector('.axis-vertical .control-prev');
    const prevSvg = document.querySelector('.axis-vertical .control-prev svg');

    if (thumbNext && thumbPrev && !svgNext && !prevSvg) {
      renderSvgThumbIcon(thumbNext, 'next');
      renderSvgThumbIcon(thumbPrev, 'prev');
    }
  }, [photos]);

  return photos?.length ? (
    <div className="group relative w-full h-full sm:w-2/5">
      <div className="m-auto space-y-2">
        <Carousel
          key={userId}
          showStatus={false}
          showIndicators={false}
          renderArrowPrev={renderArrowPrev}
          renderArrowNext={renderArrowNext}
          thumbWidth={90}
          selectedItem={0}
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={50}
        >
          {sortPhotosByOrder(photos).map(
            ({ image = undefined, image_url = undefined }) => (
              <img
                key={image}
                // NOTE: Temp workaround due to CORS issues
                // TODO: This should probably be fixed on the server side. Investigate this
                src={`${image_url || AvatarPlaceholderSvg}?id=${Date.now()}`}
                alt={image}
                className={classNames('h-96 hidden object-cover w-full', {
                  'bg-gray-100': !image_url,
                })}
                crossOrigin="anonymous"
              />
            ),
          )}
        </Carousel>
      </div>
      {edit && (
        <button
          onClick={edit}
          type="button"
          className="flex group-hover:flex absolute top-4 right-4 z-10 justify-center items-center w-10 h-10 bg-white/80 rounded-lg sm:hidden"
        >
          <PencilIcon className="w-5 h-5 text-forest-400 sm:w-5 sm:h-5" />
        </button>
      )}
      {showJustJoinedBadge && (
        <div
          style={{ top: -24, left: -25 }}
          className="flex absolute justify-center items-center"
        >
          <img style={{ width: '96px' }} src={BadgeJustJoined} />
        </div>
      )}
    </div>
  ) : (
    <div className="flex flex-col p-3 w-full h-96 bg-grey-forest-50 rounded-lg border-2 border-grey-forest-200 sm:w-2/5 sm:bg-white">
      {edit && (
        <button
          onClick={edit}
          type="button"
          className="flex gap-2 justify-end items-center mx-5 mt-5"
        >
          <PencilIcon className="w-5 h-5 text-forest-400 sm:w-6 sm:h-6" />
        </button>
      )}
      {showJustJoinedBadge && (
        <div className="flex absolute justify-center items-center">
          <img style={{ width: '96px' }} src={BadgeJustJoined} />
        </div>
      )}
      <div className="flex flex-col items-center m-auto space-y-3 text-sm font-medium leading-5">
        <PhotographIcon className="aspect-square w-12 h-12 stroke-grey-forest-500" />
      </div>
    </div>
  );
};
