import React, { FC } from 'react';
import { t } from '@nodal/i18n';
import {
  CreditCardIcon,
  ExclamationCircleIcon,
  LibraryIcon,
} from '@heroicons/react/solid';
import { BillingDetailsProps } from './BillingDetails.interface';
import { PaymentMethod } from 'components/SubscriptionFlow';
import { apiEnums } from '@nodal/api/enums';

const getIcon = (method?: PaymentMethod) => {
  switch (method) {
    case 'card':
      return CreditCardIcon;
    case 'us_bank_account':
      return LibraryIcon;

    default:
      return null;
  }
};

export const BillingDetails: FC<BillingDetailsProps> = ({
  details,
  customerPortalUrl,
  status,
}) => {
  const { method, name, number, expirationDate } = details || {};
  const methodNameClassName = method === 'card' ? 'capitalize' : 'font-medium';
  const Icon = getIcon(method);

  return (
    <>
      <div className="flex flex-col gap-8 justify-between p-8 text-base font-normal leading-6 text-left text-grey-forest-900 bg-white rounded-lg border border-grey-forest-200 shadow-sm">
        <p className="font-medium">{t('Billing Details')}</p>
        <div className="flex flex-col gap-3">
          {status === apiEnums.SubscriptionStatusEnum.PaymentFailed && (
            <div className="flex gap-3 justify-start">
              <ExclamationCircleIcon className="w-7 h-7 text-red-500 sm:w-6 sm:h-6" />
              <div className="text-sm leading-5 text-red-600">
                <p>
                  {t(
                    'Oops! Looks like there was an issue with your payment method.',
                  )}
                </p>
                <p>
                  {t(
                    'Go to manage subscription to see details and retry payment.',
                  )}
                </p>
              </div>
            </div>
          )}
          <div className="flex justify-between items-start ">
            <div className="flex gap-3">
              {Icon && <Icon className="w-6 h-6 fill-grey-forest-400" />}
              <div className="flex-1">
                <div className="flex justify-between">
                  <p>
                    <span className={methodNameClassName}>{name}</span>&nbsp;
                    {method === 'card' ? '' : <br />}
                    {t('with a number ending in ****')}
                    {number}
                  </p>
                </div>
                {!!expirationDate && (
                  <p>{t('Exp on {cardExpirationDate}', expirationDate)}</p>
                )}
              </div>
            </div>
          </div>
          <a
            href={customerPortalUrl}
            target="_blank"
            rel="noreferrer"
            className="ml-8 text-base font-medium leading-6 text-forest-500"
          >
            {t('Manage Subscription')}
          </a>
        </div>
      </div>
    </>
  );
};
