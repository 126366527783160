import { useField } from 'formik';
import { forwardRef } from 'react';

import { TextInput } from './TextInput';

import type { TextInputConnectedProps } from './TextInput.interface';

export const TextInputConnected = forwardRef<
  HTMLInputElement,
  TextInputConnectedProps
>(({ id, ...props }, inputRef) => {
  const [field, meta] = useField(props);

  return (
    <TextInput
      id={id || props.name}
      error={meta.touched && meta.error ? meta.error : undefined}
      {...field}
      {...props}
      ref={inputRef}
    />
  );
});

TextInputConnected.displayName = 'TextInputConnected';
