import type { FC } from 'react';

import { RouteWithErrorBoundary } from '@core/utils/RouteWithErrorBoundary';

import { InitialScreensDecorator } from './InitialScreensDecorator';

import type { InitialScreensDecoratorProps } from './InitialScreensDecorator.interface';

export const InitialScreensDecoratorConnected: FC<
  InitialScreensDecoratorProps
> = ({ children, image }) => (
  <RouteWithErrorBoundary>
    <InitialScreensDecorator image={image}>{children}</InitialScreensDecorator>
  </RouteWithErrorBoundary>
);
