import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useApiClient } from '@nodal/api';
import { SubscriptionPlanSelection } from './SubscriptionPlanSelection';
import { useSubscriptionContext } from '../SubscriptionFlow.connect';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { queryKeys } from '@nodal/core/consts/query';

const useSubscriptionPlanSelection = () => {
  const apiClient = useApiClient();

  const [selectedPlanId, setSelectedPlanId] = useState<number>();

  const onSelectPlan = (planId: number) => {
    if (selectedPlanId === planId) {
      setSelectedPlanId(undefined);
    } else {
      setSelectedPlanId(planId);
    }
  };

  const { data: plans } = useQuery(queryKeys.billingPlansList, () =>
    apiClient.api.BillingApi.billingPlansList(),
  );

  if (!plans?.data) return undefined;

  return {
    plans: plans.data,
    onSelectPlan,
    selectedPlanId,
  };
};

export const SubscriptionPlanSelectionConnected: FC = () => {
  const plans = useSubscriptionPlanSelection();
  const { subscription } = useSubscriptionContext();

  if (!plans || !subscription?.status) return <LoadingScreen />;

  return <SubscriptionPlanSelection status={subscription.status} {...plans} />;
};
