import type { FC } from 'react';

import { ArrowNarrowLeftIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { Route, Routes, Link } from 'react-router-dom';

import { CheckEmailScreen } from './CheckEmailScreen';
import { ForgotPasswordFormScreen } from './ForgotPasswordFormScreen';

import type { ForgotPasswordScreenProps } from './ForgotPasswordScreen.interface';

export const ForgotPasswordScreen: FC<ForgotPasswordScreenProps> = ({
  routePaths,
  redirectPaths,
}) => (
  <Routes>
    <Route
      path={`${routePaths.checkEmail}/*`}
      element={
        <CheckEmailScreen
          redirectPaths={{
            signin: redirectPaths.signin,
          }}
        />
      }
    />
    <Route
      path="*"
      element={
        <>
          <div className="flex flex-col gap-4">
            <h2 className="mt-6 text-2xl font-semibold leading-8 text-gray-900 sm:text-3xl sm:font-bold sm:leading-9">
              {t('Forgot password?')}
            </h2>
            <p className="text-base font-normal leading-6 text-grey-forest-600 sm:text-lg sm:leading-7">
              {t('No worries, we will send you reset instructions.')}
            </p>
          </div>
          <ForgotPasswordFormScreen
            redirectPaths={{ checkEmail: redirectPaths.checkEmail }}
          />
          <div className="flex gap-3 justify-center items-center">
            <ArrowNarrowLeftIcon className="w-5 h-5 text-forest-400" />
            <Link
              to={redirectPaths.signin}
              className="text-base font-medium leading-6 text-forest-500"
            >
              {t('Back to Log In')}
            </Link>
          </div>
        </>
      }
    />
  </Routes>
);
