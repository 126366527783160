import { useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import type { ApiModel, GlobalError } from '@nodal/api';
import type { SignUpFormValues } from 'flows/SignUp/SignUpForm';
import type { FormikConfig, FormikHelpers } from 'formik';

const initialValues: Omit<SignUpFormValues, 'role'> = {
  email: '',
  password1: '',
  password2: '',
  agree_terms: false,
  non_field_errors: '',
};

const validationSchema: Yup.SchemaOf<Omit<SignUpFormValues, 'username'>> =
  Yup.object().shape({
    email: Yup.string().required(t('Required field')),
    password1: Yup.string().required(t('Required field')),
    password2: Yup.string().required(t('Required field')),
    role: Yup.mixed<ApiModel.RegisterRoleEnum>().required(t('Required field')),
    agree_terms: Yup.boolean()
      .required()
      .oneOf([true], t('The Nodal Terms must be accepted')),
    non_field_errors: Yup.string().optional(),
  });

export const useSignUp = ({
  redirectPaths,
  role,
}: {
  redirectPaths: { checkEmail: string };
  role?: ApiModel.RegisterRoleEnum;
}): FormikConfig<SignUpFormValues> => {
  const navigate = useNavigate();
  const apiClient = useApiClient();

  const register = useMutation(
    (payload: ApiModel.RegistrationApiRegistrationCreateRequest) =>
      apiClient.api.RegistrationApi.registrationCreate(payload),
  );

  const handleSubmit = async (
    data: ApiModel.Register,
    { setErrors }: FormikHelpers<SignUpFormValues>,
  ) => {
    await register.mutateAsync(
      {
        register: {
          ...data,
          username: data.email,
          role: data.role,
        },
      },
      {
        onSuccess: () => {
          navigate(redirectPaths.checkEmail, {
            state: { email: data.email },
          });
        },

        onError: (e: GlobalError) => {
          if (e.response.data.code === 'invalid_candidate_id') {
            setErrors({ non_field_errors: t('Invalid registration link') });
          } else if (e.response && e.response.data) {
            setErrors(e.response.data);
          }
        },
      },
    );
  };

  return {
    onSubmit: handleSubmit,
    initialValues: { ...initialValues, role },
    validationSchema,
  };
};
