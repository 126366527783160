import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import axiosFactory from 'axios';
import { posthog } from 'posthog-js';
import {
  ApiClient,
  ApiClientProvider,
  apiEnums,
  AuthProvider,
} from '@nodal/api';
import { createAuthTokensProvider } from 'storage/authTokensProvider';
import { ProtectedRoute } from '@nodal/core/flows/ProtectedRoute';
import { forgotPasswordPaths, paths, signupPaths } from 'consts/paths';
import { settings } from 'settings';
import { AppProtected } from './AppProtected';
import { InitialScreensDecorator } from '@nodal/core/flows/InitialScreensDecorator';
import { ForgotPasswordScreen } from '@nodal/core/flows/ForgotPasswordScreen';
import { ResetPasswordScreen } from '@nodal/core/flows/ResetPasswordScreen';
import { ToastProvider } from '@nodal/uikit/components/Toast';
import { HomePageRedirect } from './HomePageRedirect';
import { AuthenticateScreen } from '@nodal/core/flows/AuthenticateScreen';
import { SignInScreen } from 'components/SignInScreen';
import { Logout, logout } from 'components/Logout';
import {
  MotherHuggingBaby,
  MotherHuggingBabyJpg,
  ParentsInClinic,
  ParentsInClinicJpg,
} from 'assets';
import { t } from '@nodal/i18n';
import { addBasePath } from '@nodal/core/utils';
import { SignUpScreen } from 'components/SignUp/SignUpScreen';
import { SignUpSelectionView } from 'components/SignUp/SignUpSelectionView';
import { SignUpRouter } from 'components/SignUp/SignUpRouter';

export const axios = axiosFactory.create();
const apiClient = new ApiClient(axios, import.meta.env.VITE_API_URL);

// test

// TODO: temp. solution, most likely use effect behavior should be a part of logout function
const WrappedLogout = () => {
  useEffect(() => {
    if (settings.getPostHogEnabled()) {
      console.log('[@nodal/app/App] posthog.reset');
      posthog.reset();
    }
  }, []);

  return <Logout />;
};

const authConfig = {
  axios,
  callbacks: {
    logout,
    // NOTE:
    // Due to broken AuthApi types, some additional handling is required.
    // Ref. to /swagger for detailed information about req res data shapes.
    refresh: (refresh: string) =>
      new Promise<string>((resolve, reject) => {
        apiClient.api.AuthApi.authTokenRefreshCreate({
          tokenRefresh: { refresh, access: '' },
        })
          .then((res) => {
            if (res.data.access) {
              resolve(res.data.access);
            } else {
              reject();
            }
          })
          .catch(reject);
      }),
  },
  tokenProvider: createAuthTokensProvider(),
  noRefresh: [
    `${import.meta.env.VITE_API_URL}/api/auth/login/`,
    `${import.meta.env.VITE_API_URL}/api/auth/token/refresh/`,
  ],
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const unprotectedRouteImage = {
  src: MotherHuggingBaby,
  fallbackSrc: MotherHuggingBabyJpg,
  alt: t('Mother Hugging Baby'),
  position: 'object-right-top',
};

// NOTE: The base path defined in settings is added to the basename of the application
const basename = addBasePath('/', settings.getBasePath());

// NOTE: The signup base path defined in settings
// is added to the basic signup path
const matchingSignupPath = addBasePath(
  paths.signup,
  settings.getMatchingSignUpBasePath(),
);

const navigatorSignupPath = addBasePath(
  paths.signup,
  settings.getNavigatorSignUpBasePath(),
);

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ApiClientProvider value={apiClient}>
        <AuthProvider value={authConfig}>
          <BrowserRouter basename={basename}>
            <Routes>
              <Route
                path={paths.signin}
                element={
                  <InitialScreensDecorator image={unprotectedRouteImage}>
                    <SignInScreen />
                  </InitialScreensDecorator>
                }
              />
              <Route
                path={`${paths.forgotPassword}/*`}
                element={
                  <InitialScreensDecorator image={unprotectedRouteImage}>
                    <ForgotPasswordScreen
                      redirectPaths={{
                        signin: paths.signin,
                        checkEmail: forgotPasswordPaths.checkEmail,
                      }}
                      routePaths={{
                        checkEmail: forgotPasswordPaths.checkEmail,
                      }}
                    />
                  </InitialScreensDecorator>
                }
              />
              <Route
                path={`${paths.resetPassword}/*`}
                element={
                  <InitialScreensDecorator image={unprotectedRouteImage}>
                    <ResetPasswordScreen
                      redirectPaths={{
                        signin: paths.signin,
                        checkEmail: `${paths.forgotPassword}/${forgotPasswordPaths.checkEmail}`,
                      }}
                    />
                  </InitialScreensDecorator>
                }
              />
              <Route path={paths.logout} element={<WrappedLogout />} />

              <Route
                path={`${matchingSignupPath}/*`}
                element={
                  <InitialScreensDecorator image={unprotectedRouteImage}>
                    <SignUpRouter>
                      <Route
                        path={`${signupPaths.surrogate}/*`}
                        element={
                          <SignUpScreen role={apiEnums.RegisterRoleEnum.Dnr} />
                        }
                      />
                      <Route
                        path={`${signupPaths.parents}/*`}
                        element={
                          <SignUpScreen role={apiEnums.RegisterRoleEnum.Par} />
                        }
                      />
                      <Route path="*" element={<SignUpSelectionView />} />
                    </SignUpRouter>
                  </InitialScreensDecorator>
                }
              />
              <Route
                path={`${navigatorSignupPath}/*`}
                element={
                  <InitialScreensDecorator
                    image={{
                      src: ParentsInClinic,
                      fallbackSrc: ParentsInClinicJpg,
                      alt: t('Parents in the Clinic'),
                    }}
                  >
                    <SignUpRouter>
                      <Route
                        path={`${signupPaths.navigator}/*`}
                        element={
                          <SignUpScreen role={apiEnums.UserRoleEnum.Nap} />
                        }
                      />
                      <Route
                        path="*"
                        element={<Navigate to={signupPaths.navigator} />}
                      />
                    </SignUpRouter>
                  </InitialScreensDecorator>
                }
              />
              <Route
                path={paths.authenticate}
                element={
                  <AuthenticateScreen
                    redirectPaths={{
                      app: paths.app,
                      unauthenticated: paths.signin,
                    }}
                  />
                }
              />

              <Route
                path={`${paths.app}/*`}
                element={
                  <ProtectedRoute
                    redirectPaths={{ unauthenticated: paths.signin }}
                  >
                    <AppProtected />
                  </ProtectedRoute>
                }
              />

              <Route
                path={`${paths.root}*`}
                element={
                  <ProtectedRoute
                    redirectPaths={{ unauthenticated: paths.signin }}
                  >
                    <Navigate to={paths.app} />
                  </ProtectedRoute>
                }
              />

              {/* TODO: This is a temporary solution that should be removed as soon as possible after the launch */}
              <Route path="*" element={<HomePageRedirect />} />
            </Routes>
          </BrowserRouter>
          <ToastProvider />
        </AuthProvider>
      </ApiClientProvider>
    </QueryClientProvider>
  );
};

export { App };
