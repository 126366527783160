import { FC } from 'react';
import {
  dataWithSamePartnerAddress,
  dataWithSingleParentNoPartner,
  hasSamePartnerAddress,
  isInRelationship,
} from '@nodal/core/utils/profile';

import { contactDetailsInitialValues } from 'components/ContactDetails/utils';
import { parentContactDetails } from './contactDetails';
import { ParentContactDetails } from './ParentContactDetails';
import {
  ContactValues,
  ParentContactDetailsConnectedProps,
} from './ParentContactDetails.interface';
import { parentValidationSchema } from './validation';

export const ParentContactDetailsConnected: FC<
  ParentContactDetailsConnectedProps
> = ({ submit, profileData }) => {
  const onSubmit = async (data: ContactValues) => {
    const { same_partner_address, ...profileData } = data || {};
    const postData = { ...profileData };

    // NOTE: We omit sending location in the payload on submit - agreed as part of the api contract
    // NOTE: We silence TS error due to a bug in api schema - location should be optional
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete postData?.address?.location;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete postData?.partner_address?.location;

    let updatedData;

    if (same_partner_address && isInRelationship(data.relationship_status)) {
      updatedData = dataWithSamePartnerAddress(postData);
    } else if (!isInRelationship(data.relationship_status)) {
      updatedData = dataWithSingleParentNoPartner(postData);
    } else {
      updatedData = postData;
    }

    await submit(updatedData);
  };

  const initialValues = contactDetailsInitialValues(
    parentContactDetails,
    profileData,
    // TODO: Remove if some profile types will be fixed (set as optional).
    // Reported as an issue : https://linear.app/nodal-health/issue/NOD-266/backend-bug-in-api-schema-for-parents-and-donor-profile
  ) as ContactValues;

  return (
    <ParentContactDetails
      initialValues={{
        ...initialValues,
        same_partner_address: hasSamePartnerAddress(profileData),
      }}
      onSubmit={onSubmit}
      validationSchema={parentValidationSchema}
    />
  );
};
