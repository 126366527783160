import type { FC } from 'react';

import { ClockIcon, CheckCircleIcon } from '@heroicons/react/solid';
import { apiEnums } from '@nodal/api/enums';
import { t } from '@nodal/i18n';
import { Avatar } from '@nodal/uikit/components/Avatar';
import { Button } from '@nodal/uikit/components/Button';
import { ProgressBar } from '@nodal/uikit/components/ProgressBar';
import classNames from 'classnames';

import { ConfirmationModal } from './ConfirmationModal';

import type {
  IntroductionRequestCardProps,
  ModalType,
} from './IntroductionRequestCard.interface';
import type { ApiModel, ActiveStageEnum } from '@nodal/api';

const labelByRole = (role: ApiModel.UserRoleEnum) => {
  switch (role) {
    case apiEnums.UserRoleEnum.Dnr:
      return t('Surrogate');
    case apiEnums.UserRoleEnum.Par:
      return t('Parent(s)');
    default:
      return '';
  }
};

const modalTitle: Record<ModalType, string> = {
  introduction_confirmation: t('Accept introduction?'),
  introduction_rejection: t('Reject introduction?'),
  match_confirmation: t('Accept match?'),
  match_rejection: t('Reject match?'),
};

const statusLabel: Record<ActiveStageEnum, string> = {
  [apiEnums.IntroductionStageEnum.Intros]: t('Pending'),
  [apiEnums.IntroductionStageEnum.MatchAcceptance]: t('In Progress'),
  [apiEnums.IntroductionStageEnum.Matched]: t('Complete'),
};

const AcceptButton: FC<
  Pick<IntroductionRequestCardProps, 'onAccept' | 'initialParentAccepted'>
> = ({ onAccept, initialParentAccepted }) => {
  if (!initialParentAccepted)
    return (
      <Button
        variant="primary"
        onClick={(e) => {
          e.stopPropagation();
          onAccept();
        }}
      >
        {t('Accept Introduction')}
      </Button>
    );

  return (
    <Button
      variant="primary"
      onClick={(e) => {
        e.stopPropagation();
        onAccept();
      }}
    >
      {t('Accept Match')}
    </Button>
  );
};

const ActionButtons: FC<
  Pick<
    IntroductionRequestCardProps,
    'onAccept' | 'onReject' | 'initialParentAccepted'
  >
> = ({ onAccept, onReject, initialParentAccepted }) => (
  <div className="flex gap-2">
    <AcceptButton
      onAccept={onAccept}
      initialParentAccepted={initialParentAccepted}
    />
    <Button
      variant="tertiary"
      onClick={(e) => {
        e.stopPropagation();
        onReject();
      }}
    >
      {t('Reject')}
    </Button>
  </div>
);

const IntrosFooter: FC<
  Pick<
    IntroductionRequestCardProps,
    'initialParentAccepted' | 'myRole' | 'onAccept' | 'onReject'
  >
> = ({ initialParentAccepted, myRole, onAccept, onReject }) => {
  if (initialParentAccepted)
    return (
      <p className="text-sm font-normal leading-5 text-grey-forest-500">
        {t('*One of our admins will be in touch shortly to schedule a call')}
      </p>
    );

  if (myRole === apiEnums.UserRoleEnum.Par)
    return (
      <>
        <div />
        <ActionButtons
          onAccept={onAccept}
          onReject={onReject}
          initialParentAccepted={initialParentAccepted}
        />
      </>
    );

  return null;
};

const MatchAcceptanceOtherUserStatus: FC<
  Pick<IntroductionRequestCardProps, 'otherUserMatchAccepted' | 'otherUserRole'>
> = ({ otherUserMatchAccepted, otherUserRole }) => {
  if (otherUserMatchAccepted)
    return (
      <div className="flex gap-2 py-3">
        <CheckCircleIcon className="aspect-square w-4 fill-forest-200" />
        <p className="text-sm font-normal leading-5 text-grey-forest-500">
          {t('Accepted by')}&nbsp;{labelByRole(otherUserRole)}
        </p>
      </div>
    );

  return (
    <div className="flex gap-2 py-3">
      <ClockIcon className="aspect-square w-4 fill-grey-forest-400" />
      <p className="text-sm font-normal leading-5 text-grey-forest-500">
        {t('Waiting for')}&nbsp;{labelByRole(otherUserRole)}
      </p>
    </div>
  );
};

const MatchAcceptanceUserStatus: FC<
  Pick<
    IntroductionRequestCardProps,
    'meUserMatchAccepted' | 'onAccept' | 'onReject'
  >
> = ({ meUserMatchAccepted, onAccept, onReject }) => {
  if (meUserMatchAccepted)
    return (
      <div className="flex gap-2 py-3">
        <CheckCircleIcon className="aspect-square w-4 fill-forest-200" />
        <p className="text-sm font-normal leading-5 text-grey-forest-500">
          {t('You accepted')}
        </p>
      </div>
    );

  return (
    <ActionButtons
      onAccept={onAccept}
      onReject={onReject}
      initialParentAccepted
    />
  );
};

const MatchAcceptanceFooter: FC<
  Pick<
    IntroductionRequestCardProps,
    | 'meUserMatchAccepted'
    | 'otherUserMatchAccepted'
    | 'otherUserRole'
    | 'onAccept'
    | 'onReject'
  >
> = ({
  meUserMatchAccepted,
  otherUserMatchAccepted,
  otherUserRole,
  onAccept,
  onReject,
}) => {
  return (
    <>
      <MatchAcceptanceOtherUserStatus
        otherUserMatchAccepted={otherUserMatchAccepted}
        otherUserRole={otherUserRole}
      />
      <MatchAcceptanceUserStatus
        meUserMatchAccepted={meUserMatchAccepted}
        onAccept={onAccept}
        onReject={onReject}
      />
    </>
  );
};

const Footer: FC<IntroductionRequestCardProps> = ({
  myRole,
  otherUserRole,
  stage,
  initialParentAccepted,
  otherUserMatchAccepted,
  meUserMatchAccepted,
  onAccept,
  onReject,
}) => {
  switch (stage) {
    case apiEnums.IntroductionStageEnum.Intros: {
      return (
        <IntrosFooter
          initialParentAccepted={initialParentAccepted}
          myRole={myRole}
          onAccept={onAccept}
          onReject={onReject}
        />
      );
    }
    case apiEnums.IntroductionStageEnum.MatchAcceptance: {
      return (
        <MatchAcceptanceFooter
          otherUserRole={otherUserRole}
          otherUserMatchAccepted={otherUserMatchAccepted}
          meUserMatchAccepted={meUserMatchAccepted}
          onAccept={onAccept}
          onReject={onReject}
        />
      );
    }
    default:
      return null;
  }
};

export const IntroductionRequestCard: FC<IntroductionRequestCardProps> = (
  props,
) => {
  const {
    className,
    stage,
    actionRequired,
    progress,
    otherUserAvatar,
    otherUserInitials,
    otherUserDisplayName,
    expiresIn,
    modalOpen,
    modalType,
    onCloseModal,
    onModalConfirm,
    userHidden,
  } = props;

  const modalContentType =
    modalType === 'introduction_confirmation' ||
    modalType === 'match_confirmation'
      ? 'accept'
      : 'reject';

  return (
    <>
      <div
        className={classNames(
          'overflow-hidden relative font-avenir-next text-grey-forest-700 bg-white rounded-lg border border-grey-forest-200 border-solid',
          className,
          {
            'shadow-md': actionRequired,
          },
        )}
      >
        <div className="absolute inset-x-0 top-0">
          <ProgressBar progress={progress} />
        </div>
        <div className="flex gap-5 justify-between px-8 pt-5 pb-4">
          {!userHidden && (
            <Avatar
              avatarUrl={otherUserAvatar}
              initials={otherUserInitials}
              className="!w-16 !h-16"
            />
          )}
          <div className="flex flex-col flex-1 justify-between items-start pt-1">
            {!userHidden && (
              <p className="text-xl font-semibold leading-7">
                {otherUserDisplayName}
              </p>
            )}
            <div className="flex gap-4 items-center">
              {stage && (
                <span
                  className={classNames(
                    'text-xs font-medium leading-4 rounded-full px-3 py-1',
                    {
                      'bg-yellow-100 text-yellow-700':
                        stage === apiEnums.IntroductionStageEnum.Intros,
                      'bg-forest-100 text-forest-400':
                        stage ===
                        apiEnums.IntroductionStageEnum.MatchAcceptance,
                    },
                  )}
                >
                  {statusLabel[stage]}
                </span>
              )}
              <p className="text-sm font-medium leading-5 text-grey-forest-500">
                Responses usually take at least 2-3 business days
              </p>
            </div>
          </div>
          {expiresIn && (
            <div className="flex flex-col justify-center items-end">
              <p className="text-sm font-medium leading-5 text-grey-forest-500">
                {t('Expires in')}
              </p>
              <p className="text-xl font-medium leading-7">{expiresIn}</p>
            </div>
          )}
        </div>
        <div className="flex gap-5 justify-between items-center empty:p-0 py-3 px-8 empty:border-0 border-t">
          <Footer {...props} />
        </div>
      </div>
      <ConfirmationModal
        open={modalOpen}
        title={modalType && modalTitle[modalType]}
        onCancel={onCloseModal}
        onConfirm={onModalConfirm}
        type={modalContentType}
      />
    </>
  );
};
