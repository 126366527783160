import type { FC } from 'react';

import {
  NotificationTopBar,
  useNotificationTopBar,
} from '@nodal/core/flows/MatchProfile';

import type { NotificationTopBarConnectedProps } from './NotificationTopBar.interface';
import { useUserProfile } from 'hooks/useUserProfile';

export const NotificationTopBarConnected: FC<
  NotificationTopBarConnectedProps
> = ({ matchProfileReviewStatus, isMatchProfileCompleted, status, role }) => {
  const { submit } = useUserProfile();

  const { notification } = useNotificationTopBar({
    submit,
    matchProfileReviewStatus,
    isMatchProfileCompleted,
    status,
    role,
  });

  if (!notification) return null;

  return <NotificationTopBar {...notification} />;
};
