import type { FC } from 'react';

import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';

import type { EmailNotConfirmedScreenProps } from './EmailNotConfirmedScreen.interface';

export const EmailNotConfirmedScreen: FC<EmailNotConfirmedScreenProps> = ({
  onSend,
}) => (
  <div className="flex flex-col gap-4 justify-center items-center">
    <div className="flex overflow-hidden relative z-20 justify-center items-center w-12 h-12 bg-red-100 rounded-full">
      <ExclamationCircleIcon className="absolute z-10 w-20 h-20 text-red-600 bg-red-100 stroke-1" />
    </div>
    <div className="text-center">
      <h2 className="mx-auto mb-2 text-2xl font-semibold leading-8 text-grey-forest-900">
        {t('E-mail not confirmed')}
      </h2>
      <p className="text-base font-normal leading-6 text-grey-forest-600">
        {t(
          "Your account hasn't been created due to unconfirmed e-mail. Please get a new confirmation link to finalize the sign up process.",
        )}
      </p>
    </div>
    <ButtonLoading
      variant="primary"
      className="justify-center mt-2 w-full"
      onClick={onSend}
    >
      {t('Send New Confirmation Link')}
    </ButtonLoading>
  </div>
);
