import { FC } from 'react';
import { useMatching } from 'components/Matching';
import { MatchingInProgress, MatchingStart } from './MatchingProgress';
import { MatchingProgressConnectedProps } from './MatchingProgress.interface';
import { apiEnums, ApiModel } from '@nodal/api';
import { t } from '@nodal/i18n';
import { useAppProtected } from 'app';

export const MatchingStartConnected: FC<MatchingProgressConnectedProps> = ({
  role,
}) => {
  const { start } = useMatching();

  return <MatchingStart role={role} onNext={start} />;
};

const getDisplayInfo = ({
  role,
  isSubscriptionUser,
  matchProfileCompleted,
}: {
  role: ApiModel.UserRoleEnum;
  isSubscriptionUser: boolean;
  matchProfileCompleted: boolean;
}) => {
  if (role === apiEnums.UserRoleEnum.Dnr) {
    return {
      title: t('Waiting for approval'),
      description: t(
        'We will inform you when you could start searching for Intended Parents.',
      ),
      profileReminderMessage: matchProfileCompleted
        ? undefined
        : t('While waiting please'),
    };
  } else if (role === apiEnums.UserRoleEnum.Par) {
    if (!isSubscriptionUser) {
      return {
        title: t('Matching Unavailable'),
        description: undefined,
        profileReminderMessage: matchProfileCompleted ? undefined : t('Please'),
      };
    }

    return {
      title: t('Waiting for approval'),
      description: t(
        'We will inform you when you could get a subscription and your profile will be listed on the marketplace.',
      ),
      profileReminderMessage: matchProfileCompleted
        ? undefined
        : t('While waiting please'),
    };
  }
};

export const MatchingInProgressConnected: FC<
  MatchingProgressConnectedProps
> = ({ role }) => {
  const {
    matchProfileCompleted,
    screeningConditionalCompleted,
    billingCustomer,
  } = useAppProtected();

  const { is_subscription_user } = billingCustomer || {};

  const { description, title, profileReminderMessage } =
    getDisplayInfo({
      role,
      isSubscriptionUser: !!is_subscription_user,
      matchProfileCompleted,
    }) || {};

  return (
    <MatchingInProgress
      profileReminderMessage={profileReminderMessage}
      description={description}
      title={title}
      showBackgroundCheckReminder={screeningConditionalCompleted}
    />
  );
};
