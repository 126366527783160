import { Navigate, Route, Routes } from 'react-router-dom';
import { FavoriteParentsList } from './FavoriteParentsList';
import { favoritesPaths, matchingPaths } from 'consts/paths';
import { FavoriteParentsProfileScreen } from './FavoriteParentsProfileScreen';

export const FavoriteParents = () => {
  return (
    <Routes>
      <Route path="/" element={<FavoriteParentsList />} />
      <Route
        path={favoritesPaths.profile}
        element={<FavoriteParentsProfileScreen />}
      />
      <Route path="*" element={<Navigate to={matchingPaths.favorites} />} />
    </Routes>
  );
};
