import { FC } from 'react';
import { apiEnums, ApiModel, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { schema as validationSchema } from './schema';

import { ConfirmEmailsForm } from './ConfirmEmailsForm';
import { useModal } from '@nodal/uikit/components/Modal';
import {
  ConfirmEmailsConnectedProps,
  ConfirmEmailsFormValues,
} from './ConfirmEmailsForm.interface';
import { taskTypeToModalDetails } from '../utils';
import { toast } from 'react-toastify';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';

export const ConfirmEmailsFormConnected: FC<ConfirmEmailsConnectedProps> = ({
  id,
  signerId,
  partnerSignerId,
  partnerSignerRole,
  signerRole,
  taskType,
}) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { close } = useModal();

  const { data: user } = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );

  const userProfile = user?.data.profile;
  const userName = `${userProfile?.first_name} ${userProfile?.last_name}`;
  const partnerEmail =
    userProfile && 'partner_email' in userProfile
      ? userProfile.partner_email
      : undefined;
  const partnerName =
    userProfile &&
    'partner_first_name' in userProfile &&
    userProfile.partner_first_name
      ? `${userProfile.partner_first_name} ${userProfile.partner_last_name}`
      : undefined;

  const updateDocumentSignersMutation = useMutation(
    (request: ApiModel.TasksApiTasksDocumentSignerUpdateRequest) =>
      apiClient.api.TasksApi.tasksDocumentSignerUpdate(request),
    {
      onError: () => {
        toast.error(t('Something went wrong...'));
      },
    },
  );

  const taskSubmitMutation = useMutation(
    (request: ApiModel.TasksApiTasksSubmitCreateRequest) =>
      apiClient.api.TasksApi.tasksSubmitCreate(request),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.tasksList),
      onError: () => {
        toast.error(t('Something went wrong...'));
      },
    },
  );

  // Due to schema issues frontend needs to set signer_role, signing_status_code, sign_id even though it shouldn't.
  // This should be adjusted after schema changes
  const onConfirmEmails = async (data: ConfirmEmailsFormValues) => {
    const defaultUserSignerRole = data.partner_email
      ? apiEnums.SignerRoleEnum.IntendedParentA
      : apiEnums.SignerRoleEnum.IntendedParent;

    await updateDocumentSignersMutation
      .mutateAsync({
        signerId: signerId,
        taskId: id,
        remoteDropboxDocumentSigner: {
          signer_role: signerRole || defaultUserSignerRole,
          id: signerId,
          signing_status_code: null,
          sign_id: null,
          display_name: data.display_name,
          email_address: data.email,
        },
      })
      .then(async () => {
        if (data.partner_email && partnerSignerId) {
          await updateDocumentSignersMutation.mutateAsync({
            signerId: partnerSignerId,
            taskId: id,
            remoteDropboxDocumentSigner: {
              signer_role:
                partnerSignerRole || apiEnums.SignerRoleEnum.IntendedParentB,
              id: partnerSignerId,
              signing_status_code: null,
              sign_id: null,
              display_name: data.partner_display_name,
              email_address: data.partner_email,
            },
          });
        }
      })
      .then(async () => {
        await taskSubmitMutation.mutateAsync({
          id,
          taskContentObject: {
            // Change to enum after schema adjustments https://linear.app/nodal-health/issue/NOD-897/be-adjust-task-schema
            task_type: taskType,
            id,
          },
        });
      })
      .then(() =>
        toast.success(
          taskTypeToModalDetails.get(taskType)!.confirmationMessage,
        ),
      );

    close();
  };

  const initialValues = {
    email: user?.data.email,
    display_name: userName,
    partner_email: partnerEmail ?? undefined,
    partner_display_name: partnerName ?? undefined,
  };

  if (taskType === 'ip-match-agreement') {
    return (
      <div className="flex flex-col p-4">
        <p className="text-grey-forest-500">
          {taskTypeToModalDetails.get(taskType)!.description}
        </p>
        <div className="pt-4">
          <Button
            variant="primary"
            onClick={() => onConfirmEmails(initialValues)}
          >
            {t('Ok')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <ConfirmEmailsForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onConfirmEmails}
      showPartnerInput={taskType === 'ip-contract'}
      description={taskTypeToModalDetails.get(taskType)!.description}
    />
  );
};
