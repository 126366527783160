import { matchesPaths, matchingPaths } from 'consts/paths';
import { Navigate, Route, Routes } from 'react-router-dom';
import { MatchesList } from './MatchesList';
import { UserProfileScreen } from './UserProfileScreen';

export const MatchesScreen = () => {
  return (
    <Routes>
      <Route path="/" element={<MatchesList />} />
      <Route path={matchesPaths.profile} element={<UserProfileScreen />} />
      <Route path="*" element={<Navigate to={matchingPaths.matches} />} />
    </Routes>
  );
};
