import { MatchProfile } from 'components/MatchProfile';
import { ParentMatchProfileProps } from './MatchProfile.interface';
import { FC } from 'react';
import { hasSamePartnerAddress } from '@nodal/core/utils/profile';

export const ParentsMatchProfile: FC<ParentMatchProfileProps> = ({
  profileData,
  owner,
  requestIntroductionHidden,
  loadingProfile,
}) => {
  const parentProfileData = profileData && {
    ...profileData,
    profile: {
      ...profileData?.profile,
      same_partner_address: hasSamePartnerAddress(profileData.profile),
    },
  };

  return (
    <MatchProfile
      profileData={parentProfileData}
      owner={owner}
      requestIntroductionHidden={requestIntroductionHidden}
      loadingProfile={loadingProfile}
    />
  );
};
