import type { ReactNode } from 'react';

export interface IdentityCheckProps {
  disabled?: boolean;
  onClick?: () => Promise<void>;
  children?: ReactNode;
}

export enum IdentityCheckResult {
  success = 'success',
  error = 'error',
}

export interface IdentityCheckConnectedProps {
  clientSecret: string;
  onSubmit?: (result: IdentityCheckResult) => void;
}

export interface IdentityCheckHookResponse {
  verify: (clientSecret: string) => void;
  result?: IdentityCheckResult;
}
