import type { FC } from 'react';

import {
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/solid';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import classNames from 'classnames';

import type { NotificationTopBarProps } from './NotificationTopBar.interface';

const messageMap = {
  success: 'text-forest-500',
  error: 'text-red-800',
  info: 'text-forest-500',
  warning: 'text-yellow-700',
};

export const NotificationTopBar: FC<NotificationTopBarProps> = ({
  type,
  message,
  action,
}) => {
  const messageClassName = classNames(
    'sm:text-sm font-medium text-xs text-center py-1',
    { [messageMap[type]]: true },
  );

  return (
    <div className="flex justify-center items-center py-2 px-4 w-full bg-white">
      <div className="grid grid-cols-[auto_auto] gap-2 items-center">
        {type === 'success' && (
          <CheckCircleIcon className="aspect-square w-5 fill-forest-200" />
        )}
        {type === 'error' && (
          <ExclamationCircleIcon className="aspect-square w-5 fill-red-500" />
        )}
        {type === 'warning' && <ClockIcon className="w-5 fill-yellow-400" />}
        <div className="flex flex-col gap-2 items-center sm:flex-row sm:gap-4">
          <p className={messageClassName}>{message}</p>
          {action && (
            <ButtonLoading
              variant="primary"
              onClick={action.callback}
              className="justify-center w-full sm:w-max"
            >
              {action.label}
            </ButtonLoading>
          )}
        </div>
      </div>
    </div>
  );
};
