import type { FC } from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@uikit/components/Button';

import { PaymentStatusCard } from '../PaymentStatusCard';

import type { PaymentCompleteProps } from './PaymentComplete.interface';

export const PaymentComplete: FC<PaymentCompleteProps> = ({
  status,
  email,
  date,
  amount,
  title,
  messages,
  buttonMeta,
}) => {
  return (
    <div className="flex relative flex-col flex-1 grid-cols-12 gap-y-6 justify-center items-center px-4 mx-auto max-w-7xl h-full bg-transparent">
      <PaymentStatusCard
        email={email}
        date={date}
        amount={amount}
        title={title}
        messages={messages}
        status={status}
      />
      {buttonMeta && (
        <Link to={buttonMeta.path}>
          <Button variant="primary" className="justify-center w-full sm:w-max">
            {buttonMeta.title}
          </Button>
        </Link>
      )}
    </div>
  );
};
