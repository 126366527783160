import { t } from '@nodal/i18n';

export const radioChoices = [
  {
    label: t('Yes'),
    value: 'true',
  },
  {
    label: t('No'),
    value: 'false',
  },
];

export const ethnicityChoices = [
  {
    label: t('American Indian or Alaskan Native'),
    value: 'ind',
  },
  {
    label: t('Hispanic American'),
    value: 'lat',
  },
  {
    label: t('Asian/Pacific Islander'),
    value: 'asi',
  },
  {
    label: t('White/caucasian'),
    value: 'w',
  },
  {
    label: t('Black or African American'),
    value: 'afr',
  },
  {
    label: t('Multiple ethnicity / other (please specify)'),
    value: 'o',
  },
];

export const parentageChoices = [
  {
    label: t('Natural Child of Parents'),
    value: 'natural',
  },
  {
    label: t('Adopted'),
    value: 'adopted',
  },
  {
    label: t('Donor Conceived'),
    value: 'donated',
  },
];

export const educationChoices = [
  {
    label: t('No schooling completed'),
    value: 'none',
  },
  {
    label: t('Nursery school to 8th grade'),
    value: 'nursery_school',
  },
  {
    label: t('Some high school'),
    value: 'high_school',
  },
  {
    label: t('High school graduate'),
    value: 'high_school_grad',
  },
  {
    label: t('Some college credit'),
    value: 'college_credit',
  },
  {
    label: t('Trade/technical/vocational training'),
    value: 'training',
  },
  {
    label: t('Associate degree'),
    value: 'associate_degree',
  },
  {
    label: t("Bachelor's degree"),
    value: 'bachelors_degree',
  },
  {
    label: t("Master's degree"),
    value: 'masters_degree',
  },
  {
    label: t('Professional degree'),
    value: 'professional_degree',
  },
  {
    label: t('Doctorate degree'),
    value: 'doctorate_degree',
  },
];

export const birthControlChoices = [
  {
    label: t('IUD'),
    value: 'iud',
  },
  {
    label: t('Essure'),
    value: 'essure_iud',
  },
  {
    label: t('Non-hormonal (copper) IUD'),
    value: 'non_hormonal_iud',
  },
  {
    label: t('Hormonal (plastic) implant'),
    value: 'hormonal_implant',
  },
  {
    label: t('The Shot (Depo-Provera)'),
    value: 'depo_provera',
  },
  {
    label: t('The vaginal ring'),
    value: 'vaginal_ring',
  },
  {
    label: t('Patch'),
    value: 'patch',
  },
  {
    label: t('Pill'),
    value: 'pill',
  },
  {
    label: t('Tubal ligation'),
    value: 'tubal_ligation',
  },
  {
    label: t('Partner vasectomy'),
    value: 'partner_vasectomy',
  },
  {
    label: t('Condoms'),
    value: 'condoms',
  },
  {
    label: t('Other'),
    value: 'other',
  },
];

export const traditionalSmokingMethodsChoices = [
  {
    label: t('No, never'),
    value: 'never',
  },
  {
    label: t('Yes, current user'),
    value: 'current_user',
  },
  {
    label: t('Yes, 4 weeks to 6 months'),
    value: 'yes_1_6_months_ago',
  },
  {
    label: t('Yes, 6 months to 1 year'),
    value: 'yes_6_12_months_ago',
  },
  {
    label: t('Yes, more than 1 year'),
    value: 'yes_over_12_months_ago',
  },
];

export const alternativeSmokingMethodsChoices = [
  {
    label: t('Vaped'),
    value: 'vaped',
  },
  {
    label: t('E-cigs'),
    value: 'ecig',
  },
  {
    label: t('Both'),
    value: 'both',
  },
  {
    label: t('No'),
    value: 'no',
  },
];
