import React, { createContext, FC, useContext } from 'react';
import { ApiClientProviderProps } from './api.interface';
import { ApiClient } from './api';

const ApiClientContext = createContext({} as ApiClient);

export const ApiClientProvider: FC<ApiClientProviderProps> = ({
  value,
  children,
}) => (
  <ApiClientContext.Provider value={value}>
    {children}
  </ApiClientContext.Provider>
);

export const useApiClient = () => {
  return useContext(ApiClientContext);
};
