import type { FC } from 'react';

import { HeartIcon as HeartIconOutline } from '@heroicons/react/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';

import type { FavoriteIconProps } from './FavoriteIcon.interface';

export const FavoriteIcon: FC<FavoriteIconProps> = ({ filled }) => {
  const Icon = filled ? HeartIconSolid : HeartIconOutline;
  const title = filled ? t('Remove from favorites') : t('Add to favorites');

  return (
    <span className="flex justify-center items-center" title={title}>
      <Icon className="w-7 h-7 text-white" />
    </span>
  );
};
