import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ResetPasswordErrorScreen } from './ResetPasswordErrorScreen';

import type { ResetPasswordErrorScreenConnectedProps } from './ResetPasswordErrorScreen.interface';
import type { ApiModel, GlobalError } from '@nodal/api';

export const ResetPasswordErrorScreenConnected: FC<
  ResetPasswordErrorScreenConnectedProps
> = ({ email, title, message, redirectPaths }) => {
  const apiClient = useApiClient();
  const navigate = useNavigate();

  const resetPassword = useMutation(
    (data: ApiModel.AuthApiAuthPasswordResetCreateRequest) =>
      apiClient.api.AuthApi.authPasswordResetCreate(data),
  );

  const handleSend = async () => {
    !email
      ? toast.error(t('Something went wrong...'))
      : await resetPassword.mutateAsync(
          { passwordReset: { email } },
          {
            onSuccess: () => {
              navigate(redirectPaths.checkEmail, {
                state: { email },
              });
            },

            onError: (e: GlobalError) => {
              if (e?.response?.data) {
                toast.error(t('Something went wrong...'));
              }
            },
          },
        );
  };

  return (
    <ResetPasswordErrorScreen
      onSend={handleSend}
      title={title}
      message={message}
    />
  );
};
