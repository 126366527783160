import React, { FC } from 'react';
import { BillingDetails } from './BillingDetails';
import {
  BillingDetailsConnectedProps,
  BillingDetails as BillingDetailsType,
} from './BillingDetails.interface';
import { useApiClient } from '@nodal/api';
import { useQuery } from 'react-query';
import {
  CardDetails,
  UsBankAccountDetails,
  BillingMethodDetails,
} from 'components/SubscriptionFlow/SubscriptionPurchased';
import { useSubscriptionContext } from '../SubscriptionFlow.connect';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { queryKeys } from '@nodal/core/consts/query';

const cardDetailsFrom = ({
  brand,
  last4,
  exp_month,
  exp_year,
}: CardDetails): BillingDetailsType => ({
  method: 'card',
  name: brand ?? undefined,
  number: last4 ?? undefined,
  expirationDate:
    exp_month && exp_year ? `${exp_month}/${exp_year}` : undefined,
});

const usBankAccountDetailsFrom = ({
  bank_name,
  last4,
}: UsBankAccountDetails): BillingDetailsType => ({
  method: 'us_bank_account',
  name: bank_name ?? undefined,
  number: last4 ?? undefined,
});

const billingDetailsFrom = (billingInfo?: BillingMethodDetails) => {
  if (!billingInfo) return undefined;

  const { card, us_bank_account } = billingInfo || {};

  if (card) {
    return cardDetailsFrom({ ...card });
  }

  if (us_bank_account) {
    return usBankAccountDetailsFrom({ ...us_bank_account });
  }
};

const useBillingCustomerPortal = () => {
  const apiClient = useApiClient();

  const { data: customerPortalUrl } = useQuery(
    queryKeys.billingCustomerPortalCreate,
    () => apiClient.api.BillingApi.billingCustomerPortalCreate(),
  );

  if (!customerPortalUrl?.data.customer_portal_url) return undefined;

  return {
    customerPortalUrl: customerPortalUrl.data.customer_portal_url,
  };
};

export const BillingDetailsConnected: FC<BillingDetailsConnectedProps> = ({
  billingDetails,
}) => {
  const { customerPortalUrl } = useBillingCustomerPortal() || {};
  const { subscription } = useSubscriptionContext();

  if (!customerPortalUrl || !subscription?.status) {
    return <LoadingScreen />;
  }

  return (
    <BillingDetails
      details={billingDetailsFrom(billingDetails)}
      customerPortalUrl={customerPortalUrl}
      status={subscription.status}
    />
  );
};
