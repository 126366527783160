import type { FC } from 'react';

import { useAuth } from '@nodal/api/auth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RouteWithErrorBoundary } from '@core/utils/RouteWithErrorBoundary';

import type { ProtectedRouteProps } from './ProtectedRoute.interface';

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  children,
  redirectPaths,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth.tokenProvider.getIsAuthenticated().then(setIsAuthenticated);

    if (isAuthenticated === false) {
      navigate(redirectPaths.unauthenticated);
    }
  }, [
    isAuthenticated,
    navigate,
    auth.tokenProvider,
    redirectPaths.unauthenticated,
  ]);

  return <RouteWithErrorBoundary>{children}</RouteWithErrorBoundary>;
};
