import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useApiClient } from '@nodal/api';
import type { NotificationPatched } from 'api';
import { NotificationCenter } from './NotificationCenter';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { queryKeys } from '@nodal/core/consts/query';

const orderNotifications = (notifications: NotificationPatched[]) => {
  const emptyNotifications = {
    readNotifications: [],
    unreadNotifications: [],
  };
  if (!notifications) return emptyNotifications;

  return notifications.reduce<{
    readNotifications: NotificationPatched[];
    unreadNotifications: NotificationPatched[];
  }>((acc, introduction) => {
    if (introduction.is_read) {
      acc.readNotifications.push(introduction);
    } else {
      acc.unreadNotifications.push(introduction);
    }
    return acc;
  }, emptyNotifications);
};

const useNotifications = () => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();

  const [isOpen, setIsOpen] = useState(false);
  const [shownNotifications, setShownNotifications] = useState<
    NotificationPatched[]
  >([]);

  const { data: notifications } = useQuery(
    queryKeys.notificationsList,
    () => apiClient.api.NotificationsApi.notificationsList(),
    {
      enabled: !isOpen,
      refetchInterval: 5000,
    },
  );

  const { mutateAsync: onMarkAsRead } = useMutation(
    (id: number) =>
      apiClient.api.NotificationsApi.notificationsPartialUpdate({
        id,
        patchedNotification: { is_read: true },
      }),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeys.notificationsList),
    },
  );

  useEffect(() => {
    if (isOpen || !notifications?.data) return;

    // NOTE: Bug in API schema
    setShownNotifications(notifications.data as NotificationPatched[]);
  }, [isOpen, notifications]);

  const { readNotifications, unreadNotifications } = useMemo(
    () => orderNotifications(shownNotifications),
    [shownNotifications],
  );

  const onMarkAllAsRead = useCallback(
    () => Promise.all(unreadNotifications.map(({ id }) => onMarkAsRead(id))),
    [unreadNotifications, onMarkAsRead],
  );

  const onToggleOpen = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    onMarkAllAsRead();
  }, [onMarkAllAsRead]);

  return {
    isOpen,
    onToggleOpen,
    readNotifications,
    unreadNotifications,
    onMarkAllAsRead,
  };
};

export const NotificationCenterConnected: FC = () => {
  const { isOpen, onToggleOpen, unreadNotifications, readNotifications } =
    useNotifications();

  return (
    <NotificationCenter
      unreadNotifications={unreadNotifications}
      readNotifications={readNotifications}
      isOpen={isOpen}
      onToggleOpen={onToggleOpen}
    />
  );
};
