import type { FC } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { Fragment, useRef } from 'react';

import { RejectionForm } from '../RejectionForm';

import type { ConfirmationModalProps } from './ConfirmationModal.interface';

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  open,
  title,
  onConfirm,
  onCancel,
  type,
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onCancel}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </Transition.Child>

        <div className="overflow-y-auto fixed inset-0 z-10">
          <div className="flex flex-col justify-center items-center p-4 min-h-full text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="overflow-hidden relative my-16 text-left bg-white rounded-lg shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="py-5 px-4 sm:flex sm:items-start sm:px-8">
                  <div className="text-center sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-medium leading-7 text-grey-forest-900"
                    >
                      {title}
                    </Dialog.Title>
                  </div>
                </div>
                {type === 'accept' && (
                  <div className="flex gap-2 justify-end py-3 px-6">
                    <Button
                      variant="tertiary"
                      onClick={onCancel}
                      ref={cancelButtonRef}
                    >
                      {t('Cancel')}
                    </Button>
                    <ButtonLoading
                      variant="primary"
                      onClick={async () => await onConfirm()}
                    >
                      {t('Yes, accept')}
                    </ButtonLoading>
                  </div>
                )}
                {type === 'reject' && (
                  <>
                    <hr />
                    <RejectionForm
                      onConfirm={onConfirm}
                      onCancel={onCancel}
                      ref={cancelButtonRef}
                      initialValues={{}}
                    />
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
