import Compressor from 'compressorjs';

import type { ApiModel, UserProfilePhoto } from '@nodal/api';

export const createInitials = (firstName: string, lastName: string) =>
  `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;

export const addBasePath = (path: string, basePath?: string) =>
  [basePath, path].filter(Boolean).join('');

export const addBasePathReduce = <T extends Record<string, string>>(
  object: T,
  basePath?: string,
): Record<keyof T, string> =>
  Object.entries(object).reduce((result, [key, value]: [keyof T, string]) => {
    result[key] = addBasePath(value, basePath);
    return result;
  }, {} as Record<keyof T, string>);

export const convertToInches = (
  feet?: number | null,
  inches?: number | null,
) => {
  return (feet || feet === 0) && (inches || inches === 0)
    ? feet * 12 + inches
    : null;
};

export const convertToFeetAndInches = (inches: number | null) => {
  const feet = inches || inches === 0 ? Math.floor(inches / 12) : null;
  const remainingInches =
    (inches || inches === 0) && (feet || feet === 0)
      ? inches - feet * 12
      : null;

  return { feet, inches: remainingInches };
};

// NOTE: Ref. to the source of the function:
// https://leancrew.com/all-this/2020/06/ordinal-numerals-and-javascript/
// TODO: This needs to be improved - this implementation is incompatible with our approach to i18n
export const getOrdinalNumber = (number: number): string => {
  const suffix = ['th', 'st', 'nd', 'rd'];
  const remainder = number % 100;
  return (
    number + (suffix[(remainder - 20) % 10] || suffix[remainder] || suffix[0])
  );
};

export const getFormattedAddress = (
  address: ApiModel.DonorProfileAddress,
  displayedKeys: Array<keyof ApiModel.DonorProfileAddress>,
) => {
  const addressElements: string[] = [];

  displayedKeys.forEach((key) => {
    const addressValue = address[key];

    if (addressValue && typeof addressValue === 'string') {
      addressElements.push(addressValue);
    }
  });

  const formattedAddress = addressElements?.length
    ? addressElements.join(', ')
    : undefined;

  return formattedAddress;
};

export const convertDataUrlToFile = async ({
  dataUrl,
  fileName,
}: {
  dataUrl: string;
  fileName: string;
}) => {
  const response = await fetch(dataUrl);
  const blob = await response.blob();
  return new File([blob], fileName, { type: blob.type });
};

export const sortPhotosByOrder = (array: Array<UserProfilePhoto>) =>
  array.sort((a, b) => ((a?.order || 0) > (b?.order || 0) ? 1 : -1));

export const convertBirthDateToAge = (birthDateString: string) => {
  const today = new Date();
  const birthDate = new Date(birthDateString);

  const yearsDifference = today.getFullYear() - birthDate.getFullYear();

  if (
    today.getMonth() < birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() &&
      today.getDate() < birthDate.getDate())
  ) {
    return yearsDifference - 1;
  }

  return yearsDifference;
};

export const degreesToRadians = (degrees: number) => {
  return (degrees * Math.PI) / 180;
};

// NOTE: Function uses Haversine formula method
// Ref: https://www.movable-type.co.uk/scripts/latlong.html
export const distanceInMilesBetweenCoordinates = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) => {
  const earthRadiusMi = 3956;

  const dLat = degreesToRadians(lat2 - lat1);
  const dLon = degreesToRadians(lon2 - lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return (earthRadiusMi * c).toFixed(1);
};

export const objectToFormData = <T extends object>(object: T) => {
  const formData = new FormData();

  Object.entries(object).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
};

export const getCompressedFile = async ({
  file,
  fileName,
}: {
  file: File | Blob;
  fileName: string;
}) => {
  const compressedBlob: Blob = await new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.85,
      mimeType: 'image/jpeg',
      maxHeight: 4096,
      maxWidth: 4096,
      checkOrientation: false,
      success(result: Blob) {
        resolve(result);
      },
      error(error) {
        reject(error);
      },
    });
  });

  return new File([compressedBlob], fileName, {
    type: compressedBlob.type,
  });
};

export const convertDataUrlToCompressedFile = async ({
  dataUrl,
  fileName,
}: {
  dataUrl: string;
  fileName: string;
}) => {
  const response = await fetch(dataUrl);
  const blob = await response.blob();

  return getCompressedFile({ file: blob, fileName });
};
