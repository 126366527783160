import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ForgotPasswordFormScreen } from './ForgotPasswordFormScreen';

import type { ForgotPasswordFormValues } from './ForgotPasswordFormScreen.interface';
import type { ApiModel, GlobalError } from '@nodal/api';
import type { FormikHelpers } from 'formik';

const initialValues: ForgotPasswordFormValues = {
  email: '',
  non_field_errors: '',
};

export const ForgotPasswordFormScreenConnected: FC<{
  redirectPaths: { checkEmail: string };
}> = ({ redirectPaths }) => {
  const apiClient = useApiClient();
  const navigate = useNavigate();

  const resetPassword = useMutation(
    (data: ApiModel.AuthApiAuthPasswordResetCreateRequest) =>
      apiClient.api.AuthApi.authPasswordResetCreate(data),
  );

  const handleSubmit = async (
    { email }: ForgotPasswordFormValues,
    { setErrors }: FormikHelpers<ForgotPasswordFormValues>,
  ) => {
    await resetPassword.mutateAsync(
      { passwordReset: { email } },
      {
        onSuccess: () => {
          navigate(redirectPaths.checkEmail, {
            state: { email },
          });
        },

        onError: (e: GlobalError) => {
          if (e.response && e.response.data) {
            setErrors(e.response.data);
          }
        },
      },
    );
  };

  return (
    <ForgotPasswordFormScreen
      initialValues={initialValues}
      onSubmit={handleSubmit}
    />
  );
};
