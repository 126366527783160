import { apiEnums, ApiModel } from '@nodal/api';
import { t } from '@nodal/i18n';
import { PaymentPackage } from './SinglePaymentService.interface';

export const userRoleToPaymentPackage = new Map<
  ApiModel.UserRoleEnum,
  PaymentPackage
>([
  [
    apiEnums.UserRoleEnum.Par,
    {
      title: t('Nodal Basic'),
      features: [
        {
          id: 1,
          included: true,
          title: t('Highly-vetted surrogates'),
        },
        {
          id: 2,
          included: true,
          title: t('Legal & Insurance support'),
        },
        {
          id: 3,
          included: true,
          title: t('Transparency'),
        },
        {
          id: 4,
          included: true,
          title: t('Educational tools and resources'),
        },
      ],
    },
  ],
  [
    apiEnums.UserRoleEnum.Nap,
    {
      title: t('Nodal Navigator'),
      features: [
        {
          id: 1,
          included: true,
          title: t('Education and guidance'),
        },
        {
          id: 2,
          included: true,
          title: t('Unique support from our team of experts'),
        },
        {
          id: 3,
          included: true,
          title: t('Expert help in building your intended parent profile'),
        },
        {
          id: 4,
          included: true,
          title: t('and more'),
        },
      ],
    },
  ],
]);
