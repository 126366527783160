import { states } from '@nodal/core/consts/states';
import { t } from '@nodal/i18n';
import { AddressValue, Choice, ContactDetail } from 'components/ContactDetails';
import { ContactValues } from './ParentContactDetails.interface';

export const preferredPronounsChoices: Choice[] = [
  {
    label: t('He/him/his'),
    value: 'he',
  },
  {
    label: t('She/her/hers'),
    value: 'she',
  },
  {
    label: t('They/their/theirs'),
    value: 'the',
  },
  {
    label: t('Ze/hir/hirs'),
    value: 'ze',
  },
  {
    label: t('Ze/zir/zirs'),
    value: 'ze2',
  },
  {
    label: t('Xe/xern/xyrs'),
    value: 'xe',
  },
  {
    label: t('Other'),
    value: 'oth',
  },
];

export const sexualOrientationChoices: Choice[] = [
  {
    label: t('Asexual'),
    value: 'ase',
  },
  {
    label: t('Bisexual'),
    value: 'bis',
  },
  {
    label: t('Gay/Lesbian'),
    value: 'gay',
  },
  {
    label: t('Heterosexual'),
    value: 'het',
  },
  {
    label: t('Pansexual'),
    value: 'pan',
  },
  {
    label: t('Queer'),
    value: 'que',
  },
  {
    label: t('Other not listed'),
    value: 'oth',
  },
  {
    label: t('Prefer not to state'),
    value: 'pre',
  },
];

export const relationshipStatusChoices: Choice[] = [
  {
    label: t('Single'),
    value: 'sin',
  },
  {
    label: t('In relationship'),
    value: 'rel',
  },
  {
    label: t('Engaged'),
    value: 'eng',
  },
  {
    label: t('Married'),
    value: 'mar',
  },
  {
    label: t('Divorced'),
    value: 'div',
  },
  {
    label: t('Separated'),
    value: 'sep',
  },
  {
    label: t('Widowed'),
    value: 'wid',
  },
  {
    label: t('Other'),
    value: 'oth',
  },
];

export const reasonForApplyingChoices: Choice[] = [
  {
    value: 'mne',
    label: t('Medically Necessary'),
  },
  {
    value: 'spa',
    label: t('Single Parent By Choice'),
  },
  {
    value: 'lgb',
    label: t('LGBTQ+ Parents'),
  },
  {
    value: 'oth',
    label: t('Other'),
  },
];

export const parentAddressDetails: AddressValue[] = [
  {
    value: 'line1',
    label: t('Address Line 1'),
    type: 'text',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'line2',
    label: t('Address Line 2'),
    type: 'text',
    choices: null,
    default: null,
    required: false,
  },
  {
    value: 'city',
    label: t('City'),
    choices: null,
    type: 'text',
    default: null,
    required: true,
  },
  {
    value: 'state',
    label: t('State/Province'),
    choices: states,
    type: 'select',
    default: null,
    required: true,
  },
  {
    value: 'zipcode',
    label: t('Zip/Postal'),
    choices: null,
    type: 'text',
    default: null,
    required: true,
  },
];

export const parentContactDetails: ContactDetail<ContactValues>[] = [
  {
    value: 'first_name',
    label: t('First Name'),
    type: 'text',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'middle_name',
    label: t('Middle Name'),
    type: 'text',
    choices: null,
    default: null,
    required: false,
  },
  {
    value: 'last_name',
    label: t('Last Name'),
    type: 'text',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'date_of_birth',
    label: t('Date of Birth'),
    type: 'past_date',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'occupation',
    label: t('Occupation'),
    type: 'text',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'preferred_pronouns',
    label: t('Preferred Pronouns'),
    type: 'select',
    choices: preferredPronounsChoices,
    default: null,
    required: true,
  },
  {
    value: 'relationship_status',
    label: t('Relationship status'),
    type: 'select',
    choices: relationshipStatusChoices,
    default: null,
    required: true,
  },
  {
    value: 'partner_first_name',
    label: t('First Name'),
    type: 'text',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'partner_middle_name',
    label: t('Middle Name'),
    type: 'text',
    choices: null,
    default: null,
    required: false,
  },
  {
    value: 'partner_last_name',
    label: t('Last Name'),
    type: 'text',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'partner_date_of_birth',
    label: t('Date of Birth'),
    type: 'past_date',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'partner_occupation',
    label: t('Occupation'),
    type: 'text',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'partner_preferred_pronouns',
    label: t('Preferred Pronouns'),
    type: 'select',
    choices: preferredPronounsChoices,
    default: null,
    required: true,
  },
  {
    value: 'phone_number',
    label: t('Phone Number'),
    type: 'phone',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'address',
    choices: null,
    default: parentAddressDetails,
    required: true,
  },
  {
    value: 'partner_phone_number',
    label: t('Phone Number'),
    type: 'phone',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'partner_email',
    label: t('Email address'),
    type: 'text',
    choices: null,
    default: null,
    required: true,
  },
  {
    value: 'partner_address',
    choices: null,
    default: parentAddressDetails,
    required: true,
  },
  {
    value: 'reason_for_applying',
    label: t('Reason for applying'),
    choices: reasonForApplyingChoices,
    type: 'text',
    default: null,
    required: true,
  },
];
