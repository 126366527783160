import { FC } from 'react';
import { ResourcesProps } from './Resources.interface';
import { DocumentIcon, ExternalLinkIcon } from 'assets';
import classNames from 'classnames';
import { t } from '@nodal/i18n';
import { getResourceLink } from './utils';

export const Resources: FC<ResourcesProps> = ({ resources, columns }) => (
  <div className="pt-8">
    <h2 className="text-2xl font-semibold text-forest-500">{t('Resources')}</h2>
    <div
      className={classNames('gap-4 p-8 my-6 bg-white rounded-3xl md:px-8', {
        'flex flex-col': columns === 1,
        'grid md:grid-cols-2': columns === 2,
        'grid md:grid-cols-3': columns === 3,
      })}
    >
      {resources.map(({ id, title, file, external_resource_url }) => {
        const resourceLink = getResourceLink({
          fileLink: file ?? undefined,
          externalLink: external_resource_url ?? undefined,
        });

        if (!resourceLink) return null;

        return (
          <a
            key={id}
            href={resourceLink}
            target="_blank"
            rel="noreferrer"
            className="flex col-span-1 gap-4 items-center p-4 rounded-2xl border border-gray-200"
          >
            <div className="p-2 bg-light-green rounded-lg">
              {!!external_resource_url ? (
                <ExternalLinkIcon />
              ) : (
                <DocumentIcon />
              )}
            </div>
            <p className="text-grey-forest-900">{title}</p>
          </a>
        );
      })}
    </div>
  </div>
);
