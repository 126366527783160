import type { ProfileData } from '../MatchProfile.interface';
import type { ApiModel } from '@nodal/api';

export interface AboutSectionDetailsProps {
  about: ProfileData['about'];
}

export interface VideoSectionDetailsProps {
  videoUrl: ProfileData['video_url'];
}

export const screeningDetailStatus = {
  pending: 'pending',
  complete: 'complete',
  rejected: 'rejected',
} as const;

export type ScreeningDetailStatus =
  typeof screeningDetailStatus[keyof typeof screeningDetailStatus];

export interface ScreeningDetail {
  title?: string;
  status?: ScreeningDetailStatus;
}

export interface ScreeningSectionDetailsProps {
  screeningDetails: ScreeningDetail[];
}

export interface SectionDetailsProps<T extends ProfileData> {
  profile: T;
  role?: ApiModel.UserRoleEnum;
  edit?: boolean;
}
