import { forwardRef } from 'react';

import type { OverlayPhotoProps } from './OverlayPhoto.interface';

export const OverlayPhoto = forwardRef<HTMLDivElement, OverlayPhotoProps>(
  ({ src }, ref) => {
    return (
      <div
        ref={ref}
        className="group block relative w-full h-full rounded-lg cursor-grabbing"
      >
        <div className="flex overflow-hidden w-full h-full rounded-lg border border-transparent">
          <div
            className="flex-1 w-fit h-full bg-center bg-cover outline-none select-none"
            style={{ backgroundImage: `url(${src})` }}
          />
        </div>
      </div>
    );
  },
);

OverlayPhoto.displayName = 'OverlayPhoto';
