import { FC } from 'react';
import { AppProgressPicture } from 'assets';
import { Button } from '@nodal/uikit/components/Button';
import { AppProgressBarProps, AppProgressProps } from './AppProgress.interface';
import { CheckIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

const ProgressBar: FC<AppProgressBarProps> = ({ steps, status }) => {
  return (
    <nav aria-label="Progress" className="w-full">
      <ol role="list" className="flex justify-between items-center w-full">
        {steps.map((step, stepIdx) => (
          <li
            key={step.title}
            className={classNames(
              stepIdx !== steps.length - 1 ? 'w-full' : '',
              'relative',
            )}
          >
            {step.index < status.index && (
              <>
                <div
                  className="flex absolute inset-0 items-center"
                  aria-hidden="true"
                >
                  <div className="w-full h-0.5 bg-forest-400" />
                </div>
                <div className="flex relative justify-center items-center w-8 h-8 bg-forest-400 hover:bg-forest-400 rounded-full">
                  <CheckIcon
                    className="w-5 h-5 text-white"
                    aria-hidden="true"
                  />
                  <span className="absolute top-full p-2 w-min text-sm font-medium text-center text-forest-400 lg:w-max">
                    {step.title}
                  </span>
                </div>
              </>
            )}
            {step.index === status.index && (
              <>
                <div
                  className="flex absolute inset-0 items-center"
                  aria-hidden="true"
                >
                  <div className="w-full h-0.5 bg-moss-400" />
                </div>
                <div
                  className="flex relative justify-center items-center w-8 h-8 bg-white rounded-full border-2 border-forest-400"
                  aria-current="step"
                >
                  <span
                    className="absolute w-2.5 h-2.5 bg-forest-400 rounded-full"
                    aria-hidden="true"
                  />
                  <span className="absolute top-full p-2 w-min text-sm font-medium text-center text-forest-400 lg:w-max">
                    {step.title}
                  </span>
                </div>
              </>
            )}
            {step.index > status.index && (
              <>
                <div
                  className="flex absolute inset-0 items-center"
                  aria-hidden="true"
                >
                  <div className="w-full h-0.5 bg-moss-400" />
                </div>
                <div className="group flex relative justify-center items-center w-8 h-8 bg-white rounded-full border-2 border-moss-300">
                  <span
                    className="w-2.5 h-2.5 bg-transparent rounded-full"
                    aria-hidden="true"
                  />
                  <span className="absolute top-full p-2 w-min text-sm font-medium text-center text-forest-400 lg:w-max">
                    {step.title}
                  </span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export const AppProgress: FC<AppProgressProps> = ({
  button,
  description,
  status,
  steps,
  title,
  onNext,
  progressBarVisible,
}) => (
  <>
    <div className="flex flex-col grid-cols-8 col-span-10 col-start-2 gap-x-8 justify-center items-center py-16 px-4 m-auto bg-transparent rounded-lg sm:grid sm:justify-center sm:px-0 lg:grid-cols-10">
      <div className="flex col-span-6 col-start-2 row-start-1 justify-center items-center w-full lg:col-start-3">
        <img src={AppProgressPicture} className="object-cover w-20 h-20" />
      </div>
      <h1 className="col-span-6 col-start-2 row-start-2 pt-12 m-auto w-max text-2xl font-semibold leading-8 text-center text-grey-forest-900 sm:text-3xl sm:font-bold sm:leading-9 lg:col-start-3">
        {title}
      </h1>
      <p className="col-span-6 col-start-2 row-start-3 pt-4 w-full text-base font-normal leading-6 text-center text-grey-forest-700 sm:text-lg lg:col-start-3">
        {description}
      </p>
      {progressBarVisible && (
        <div className="col-span-4 col-start-3 row-start-4 px-9 pt-8 pb-12 w-full sm:m-0 lg:col-start-4 lg:py-8">
          <ProgressBar steps={steps} status={status} />
        </div>
      )}
      <div className="col-span-6 col-start-2 row-start-5 pt-8 w-full text-center lg:col-start-3">
        <Button variant="primary" onClick={onNext}>
          {button}
        </Button>
      </div>
    </div>
  </>
);
