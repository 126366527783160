import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';

import { useGooglePlacesAutocomplete } from 'hooks/useGooglePlacesAutocomplete';

import type { Place } from '@uikit/models';

import type { FormikValues } from 'formik';

// NOTE: hook responsible for autocomplete address fields when position from google places api is selected
export const useAutocompletePlaceInput = (
  name: string,
  type: string,
  getFormattedPlace: (place: Place) => Place,
) => {
  const { setFieldValue, setFieldTouched } = useFormikContext<FormikValues>();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    address,
    name: placeName,
    location,
  } = useGooglePlacesAutocomplete(inputRef, [type]);

  useEffect(() => {
    if (address) {
      const place = getFormattedPlace({
        ...address,
        name: placeName,
        location,
      });

      Object.entries(place).forEach(([key, value]) => {
        setFieldValue(`${name}.${key}`, value);
        setFieldTouched(`${name}.${key}`, false);
      });
    }
    // NOTE: Only if one of the place fields will change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, placeName, location]);

  return { inputRef };
};
