import classNames from 'classnames';
import { forwardRef } from 'react';

import type { ButtonProps } from './Button.interface';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, variant = 'primary', className = '', ...props }, forwardRef) => {
    const buttonClassName = classNames(
      'inline-flex items-center sm:text-base text-sm font-medium rounded-xl border focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-sm focus:ring-forest-500 px-4 py-2',
      props.disabled
        ? 'text-white bg-grey-forest-500 outline-none border-grey-forest-500 hover:bg-grey-forest-500'
        : {
            'text-white bg-forest-500 hover:bg-forest-400 border-transparent':
              variant === 'primary',
            'text-forest-500 bg-sage-500 hover:bg-sage-600 border-transparent':
              variant === 'secondary',
            'text-gray-700 bg-white hover:bg-gray-100 border border-gray-300':
              variant === 'tertiary',
            'text-white bg-red-700 hover:bg-red-600 border-transparent focus:ring-red-600':
              variant === 'danger',
          },
      className ? className : 'px-4 py-2',
    );

    return (
      <button className={buttonClassName} ref={forwardRef} {...props}>
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';
