import type { FC } from 'react';

import { useField } from 'formik';

import { Checkbox } from './Checkbox';

import type { CheckboxConnectedProps } from './Checkbox.interface';

export const CheckboxConntected: FC<CheckboxConnectedProps> = ({
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <Checkbox
      id={props.id || props.name}
      error={meta.touched && meta.error ? meta.error : undefined}
      {...field}
      {...props}
    />
  );
};
