import { apiEnums } from '@nodal/api';
import { AvatarPlaceholderSvg } from '@nodal/uikit/assets';
import { Badges } from '@nodal/core/flows/MatchProfile';
import {
  getFormattedLocation,
  getFormattedName,
} from '@nodal/core/flows/MatchProfile/utils';
import { t } from '@nodal/i18n';
import React, { FC } from 'react';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { FavoriteProfileCardProps } from './FavoriteProfileCard.interface';

export const FavoriteProfileCard: FC<FavoriteProfileCardProps> = ({
  profileData,
  profilePhoto,
  onRequestIntroduction,
  onFavoriteClick,
  remainingRequests,
}) => {
  const { profile, introduction, id } = profileData;
  const { address } = profile;

  return (
    <div className="p-4 w-full max-w-sm h-full">
      <div
        className="relative w-full max-w-sm h-[460px] bg-white bg-center bg-cover rounded-tr-[50px] rounded-bl-[50px] cursor-pointer"
        style={{
          backgroundImage: `url(${
            profilePhoto?.image_url || AvatarPlaceholderSvg
          })`,
        }}
      >
        <div className="w-full h-full" onClick={() => onFavoriteClick(id)} />
        <div className="flex absolute inset-x-8 bottom-8 flex-col gap-3 p-3 bg-white rounded-2xl cursor-default">
          <div className="flex flex-wrap gap-2 justify-between items-center">
            <p className="text-base font-medium text-grey-forest-900">
              {getFormattedName(profile)}
            </p>
            <p className="text-xs text-grey-forest-500">
              {getFormattedLocation(address)}
            </p>
          </div>
          <Badges
            profile={profile}
            size="small"
            role={apiEnums.UserRoleEnum.Par}
          />
          <ButtonLoading
            variant="primary"
            className="justify-center"
            onClick={async () => await onRequestIntroduction(profileData)}
            disabled={!!introduction || !remainingRequests}
          >
            <span className="text-sm">
              {introduction
                ? t('Introduction Request Sent')
                : t('Request an Introduction')}
            </span>
          </ButtonLoading>
        </div>
      </div>
    </div>
  );
};
