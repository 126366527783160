import type { FC } from 'react';

import { KeyIcon } from '@heroicons/react/outline';
import { ArrowNarrowLeftIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ResetPasswordErrorScreen } from './ResetPasswordErrorScreen';
import { ResetPasswordForm } from './ResetPasswordForm';
import { ResetPasswordSuccessScreen } from './ResetPasswordSuccessScreen';

import type { ResetPasswordScreenProps } from './ResetPasswordScreen.interface';

export const ResetPasswordScreen: FC<ResetPasswordScreenProps> = ({
  initialValues,
  onSubmit,
  resetPasswordStatus,
  email,
  redirectPaths,
}) => {
  const renderScreen = useMemo(() => {
    const { status, title = '', message = '' } = resetPasswordStatus || {};
    switch (status) {
      case 'success':
        return (
          <ResetPasswordSuccessScreen
            title={title}
            message={message}
            redirectPaths={{ signin: redirectPaths.signin }}
          />
        );
      case 'not_found_error':
        return (
          <ResetPasswordErrorScreen
            title={title}
            message={message}
            email={email}
            redirectPaths={{ checkEmail: redirectPaths.checkEmail }}
          />
        );
      default:
        return (
          <>
            <div className="flex flex-col gap-4 justify-center items-center">
              <KeyIcon className="w-16 h-16 text-grey-forest-400 stroke-1" />
              <h2 className="mt-2 text-2xl font-semibold leading-8 text-gray-900 sm:mt-4 sm:text-3xl sm:font-bold sm:leading-9">
                {t('Set new password')}
              </h2>
              <p className="text-base font-normal leading-6 text-grey-forest-600 sm:text-lg sm:leading-7">
                {t('Password must contain at least 8 characters')}
              </p>
            </div>
            <ResetPasswordForm
              initialValues={initialValues}
              onSubmit={onSubmit}
            />
            <div className="flex gap-3 justify-center items-center">
              <ArrowNarrowLeftIcon className="w-5 h-5 text-forest-400" />
              <Link
                to={redirectPaths.signin}
                className="text-base font-medium leading-6 text-forest-500"
              >
                {t('Back to Log In')}
              </Link>
            </div>
          </>
        );
    }
  }, [initialValues, onSubmit, resetPasswordStatus, email, redirectPaths]);

  return renderScreen;
};
