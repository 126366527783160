import type { ReactNode } from 'react';

import { createContext, useCallback, useMemo, useState } from 'react';

import type { ScreeningProviderProps } from './Screening.interface';

const initialValues: ScreeningProviderProps = {
  started: false,
  completed: false,
  steps: [],
  currentStep: undefined,
};

export const ScreeningContext = createContext(initialValues);

export const ScreeningContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [currentStep, setCurrentStep] =
    useState<ScreeningProviderProps['currentStep']>();

  const [steps, setSteps] = useState<ScreeningProviderProps['steps']>([]);

  const [started, setStarted] = useState(false);
  const [completed, setCompleted] = useState(false);

  const updateCurrentStep = useCallback(
    (step: ScreeningProviderProps['currentStep']) => {
      setCurrentStep(step);
    },
    [],
  );

  const updateSteps = useCallback((value: ScreeningProviderProps['steps']) => {
    setSteps(value);
  }, []);

  const updateCompleted = useCallback((value: boolean) => {
    setCompleted(value);
  }, []);

  const updateStarted = useCallback((value: boolean) => {
    setStarted(value);
  }, []);

  const value = useMemo(
    () => ({
      currentStep,
      updateCurrentStep,
      steps,
      updateSteps,
      started,
      updateStarted,
      completed,
      updateCompleted,
    }),
    [
      currentStep,
      updateCurrentStep,
      steps,
      updateSteps,
      completed,
      updateCompleted,
      started,
      updateStarted,
    ],
  );

  return (
    <ScreeningContext.Provider value={value}>
      {children}
    </ScreeningContext.Provider>
  );
};
