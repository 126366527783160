import { apiEnums, useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n/src';
import { useQuery, useQueryClient } from 'react-query';

import { queryKeys } from '@core/consts/query';
import { useEmbeddedSignature } from '@core/flows/hooks/useEmbeddedSignature';

import { ConsentForm } from '../ConsentForm';
import { HipaaForm } from '../HipaaForm';

import type { ApiModel } from '@nodal/api';

export const useHipaaConsentForms = ({
  currentStep,
  submit,
  clientId,
  skipDomainVerification,
  consentFormType,
}: {
  currentStep: ApiModel.Screen | undefined;
  submit: (
    values: ApiModel.HipaaConsentFormScreen,
  ) => Promise<ApiModel.Screen | undefined>;
  clientId: string;
  skipDomainVerification: boolean;
  consentFormType:
    | typeof apiEnums.DropboxDocumentTypeEnum.ConsentForm
    | typeof apiEnums.DropboxDocumentTypeEnum.ConsentFormB2b;
}) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const { data: userData } = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );

  const { data: usersDocumentsList } = useQuery(
    [queryKeys.usersDocumentsList, userData?.data.id],
    () =>
      apiClient.api.UsersApi.usersDocumentsList({
        userId: userData!.data.id,
      }),
    { enabled: !!userData?.data?.id },
  );

  const documents = usersDocumentsList?.data;

  // NOTE: Too many screen types defined in API types cause TS error
  // Expected type in this screen is ApiModel.HipaaConsentFormScreen
  const screenContent =
    currentStep?.content_object as ApiModel.HipaaConsentFormScreen;

  const consentForm = documents?.find(
    ({ document_type }) => document_type === consentFormType,
  );

  const hipaaForm = documents?.find(
    ({ document_type }) =>
      document_type === apiEnums.DropboxDocumentTypeEnum.Hipaa,
  );

  const { sign_url: consentRequestUrl, sign_id: consentRequestId } =
    consentForm || {};
  const { sign_url: hipaaRequestUrl, sign_id: hipaaRequestId } =
    hipaaForm || {};

  const handleSubmitForm = async (
    values: Omit<ApiModel.HipaaConsentFormScreen, 'id'>,
  ) => {
    if (currentStep?.id) {
      const response = await submit({ id: currentStep.id, ...values });

      if (response) {
        // NOTE: After submitting, we need to refresh the document signing URL
        // to ensure that multi-document signing works correctly.
        // This is a workaround as long as we use hellosign in useEmbeddedSignature hook
        // TODO: consider improving/fixing this
        await queryClient.invalidateQueries([
          queryKeys.usersDocumentsList,
          userData?.data.id,
        ]);
      }
    }
  };

  const consentSignRef = useEmbeddedSignature({
    clientId,
    onSubmit: async () => handleSubmitForm({ consent_form_signed: true }),
    requestUrl: consentRequestUrl || undefined,
    requestId: consentRequestId || undefined,
    signed: !!screenContent?.consent_form_signed,
    skipDomainVerification,
  });

  const hipaaSignRef = useEmbeddedSignature({
    clientId,
    onSubmit: async () => handleSubmitForm({ hipaa_signed: true }),
    requestUrl: hipaaRequestUrl || undefined,
    requestId: hipaaRequestId || undefined,
    signed: !!screenContent?.hipaa_signed,
    skipDomainVerification,
  });

  return [
    {
      title: t('HIPAA Form'),
      id: 'hipaa-form',
      signed: !!screenContent?.hipaa_signed,
      render: () => <HipaaForm ref={hipaaSignRef} />,
    },
    {
      title: t('Consent Form'),
      id: 'consent-form',
      signed: !!screenContent?.consent_form_signed,
      render: () => <ConsentForm ref={consentSignRef} />,
    },
  ];
};
