import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Button } from '@nodal/uikit/components/Button';
import { NotificationProps } from './Notification.interface';

export const Notification: FC<NotificationProps> = ({
  title,
  content,
  isRead,
  timestamp,
  action,
}) => {
  const [showFullContent, setShowFullContent] = useState(false);

  const handleClick = () => {
    setShowFullContent((prevShowFullContent) => !prevShowFullContent);
  };

  return (
    <div
      className={classNames(
        'flex relative flex-col h-28 py-4 px-4 border-b last:border-b-0 border-b-grey-forest-200 hover:cursor-pointer',
        {
          'opacity-60 bg-gray-50': isRead,
          'h-auto': showFullContent,
        },
      )}
      onClick={handleClick}
    >
      <div className={'flex relative gap-4 justify-between px-4'}>
        {!isRead && (
          <div className="overflow-hidden absolute top-1.5 -left-1 w-2.5 h-2.5 bg-forest-300 rounded-full " />
        )}
        <p className="overflow-hidden text-lg font-semibold leading-6 text-grey-forest-900 text-ellipsis whitespace-nowrap">
          {title}
        </p>
        <p className="text-xs font-normal leading-4 text-grey-forest-600 whitespace-nowrap">
          {timestamp}
        </p>
      </div>
      <div
        className={classNames(
          'overflow-hidden text-base font-normal leading-6 text-grey-forest-700 gap-2 flex flex-col px-4',
          showFullContent ? 'line-clamp-none' : 'line-clamp-2',
        )}
      >
        <p>{content}</p>
        {action && (
          <Link to={action.path} className="pb-1">
            <Button variant="primary" onClick={action.callback}>
              {action.label}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};
