import type { FC } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { Fragment } from 'react';

import type { ModalProps } from './Modal.interface';

export const Modal: FC<ModalProps> = ({
  children,
  open,
  onClose,
  title,
  subTitle,
  size = 'default',
  variant = 'default',
}) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="relative z-50" onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </Transition.Child>

      <div className="overflow-y-auto fixed inset-0 z-10">
        <div className="flex justify-center items-end p-0 min-h-full text-center sm:items-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel
              className={classNames(
                'overflow-hidden relative m-0 w-full h-full text-left bg-white rounded-lg shadow-xl transition-all sm:max-w-2xl',
                {
                  'p-0': variant === 'default',
                  'p-6': variant === 'confirmation',
                  'sm:max-w-5xl': size === 'lg',
                },
              )}
            >
              <div
                className={classNames('flex justify-between', {
                  'py-5 px-8 border-b border-grey-forest-200':
                    variant === 'default',
                  'px-4': variant === 'confirmation',
                })}
              >
                <Dialog.Title
                  as="h3"
                  className="text-xl font-semibold leading-7 text-grey-forest-900 "
                >
                  {title}
                </Dialog.Title>
                {variant === 'default' && (
                  <button onClick={onClose} type="button">
                    <XIcon className="w-5 h-5 text-grey-forest-500 " />
                  </button>
                )}
              </div>
              {subTitle && <p className="py-4 px-8">{subTitle}</p>}
              <div>{children}</div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);
