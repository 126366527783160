import type { FC } from 'react';

import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

import type { RouteProps } from 'react-router-dom';

export const RouteWithErrorBoundary: FC<RouteProps> = ({ children }) => {
  return (
    <SentryErrorBoundary>
      <>{children}</>
    </SentryErrorBoundary>
  );
};
