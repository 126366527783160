import type { FC } from 'react';

import { useField } from 'formik';
import { useCallback } from 'react';

import { NumberTagInput } from './NumberTagInput';

import type { NumberTagInputConnectedProps } from './NumberTagInput.interface';

export const NumberTagInputConnected: FC<NumberTagInputConnectedProps> = ({
  id,
  onChange,
  error,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(props);
  const formError = meta.touched && meta.error ? meta.error : undefined;

  const handleChange = useCallback(
    (newValue: number | undefined) => {
      setValue(newValue);

      if (onChange) {
        onChange(newValue);
      }
    },
    [setValue, onChange],
  );

  return (
    <NumberTagInput
      id={id || props.name}
      errorMsg={error || error === null ? error : formError}
      error={formError}
      {...props}
      {...field}
      onChange={handleChange}
    />
  );
};
