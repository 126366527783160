import { useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { ApiModel, GlobalError } from '@nodal/api';

// NOTE: This hook requires component to be embedded inside ToastProvider
export const useSendVerificationLink = ({
  redirectPaths,
  email,
}: {
  redirectPaths: { checkEmail: string };
  email?: string | null;
}): { handleSend: () => Promise<void> } => {
  const navigate = useNavigate();
  const apiClient = useApiClient();

  const resend = useMutation(
    (payload: ApiModel.RegistrationApiRegistrationResendEmailCreateRequest) =>
      apiClient.api.RegistrationApi.registrationResendEmailCreate(payload),
  );

  const handleSend = async () => {
    !email
      ? toast.error(t('Something went wrong...'))
      : await resend.mutateAsync(
          { resendEmailVerification: { email } },
          {
            onSuccess: () => {
              navigate(redirectPaths.checkEmail, {
                state: { email },
              });
            },

            onError: (e: GlobalError) => {
              if (e?.response?.data) {
                toast.error(t('Something went wrong...'));
              }
            },
          },
        );
  };

  return { handleSend };
};
