import type { FC } from 'react';

import { t } from '@nodal/i18n';
import classNames from 'classnames';

import { AvatarPlaceholderSvg } from '@uikit/assets';
import { Tooltip } from '@uikit/components/Tooltip';

import type { ImageControlsProps } from './ImageControls.interface';

export const ImageControls: FC<ImageControlsProps> = ({ src, controls }) => (
  <div className="flex relative justify-center items-center w-full h-full rounded-lg cursor-pointer">
    {controls.length > 1 ? (
      <div className="flex absolute gap-2 justify-evenly w-full">
        {controls.map(({ title, onClick, icon: Icon }) => (
          <Tooltip
            message={title}
            position="top"
            className={classNames(
              'aspect-square z-10 w-12 opacity-0 group-hover:opacity-100',
            )}
            key={title}
          >
            <Icon onClick={onClick} className="stroke-grey-forest-600" />
          </Tooltip>
        ))}
      </div>
    ) : (
      controls.map(({ title, onClick, icon: Icon }) => (
        <div
          className={classNames(
            'aspect-square z-10 w-16 opacity-0 absolute group-hover:opacity-100',
          )}
          key={title}
        >
          <Icon onClick={onClick} className="stroke-grey-forest-600" />
        </div>
      ))
    )}
    <img
      src={src || AvatarPlaceholderSvg}
      alt={t('Uploaded Image')}
      className={classNames(
        'object-cover w-full h-full rounded-lg group-hover:opacity-50',
        {
          'bg-gray-100': !src,
        },
      )}
    />
  </div>
);
