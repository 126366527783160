import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { paths, paymentServicePaths } from 'consts/paths';
import { PaymentFlow } from './PaymentFlow';
import { PaymentComplete } from './PaymentComplete';
import { SubscriptionProps } from './SubscriptionFlow.interface';
import { SubscriptionPlanSelection } from './SubscriptionPlanSelection';
import { SubscriptionProcessing } from './SubscriptionProcessing';
import { apiEnums } from '@nodal/api';
import { SubscriptionPurchased } from './SubscriptionPurchased';

export const SubscriptionFlow: FC<SubscriptionProps> = ({ status }) => (
  <Routes>
    {(status === apiEnums.SubscriptionStatusEnum.Active ||
      status === apiEnums.SubscriptionStatusEnum.PaymentFailed) && (
      <Route path="/" element={<SubscriptionPurchased />} />
    )}
    {status === apiEnums.SubscriptionStatusEnum.Processing && (
      <Route path="/" element={<SubscriptionProcessing />} />
    )}
    {(status === apiEnums.SubscriptionStatusEnum.Inactive ||
      status === apiEnums.SubscriptionStatusEnum.SubscriptionFailed) && (
      <>
        <Route path="/" element={<SubscriptionPlanSelection />} />
        <Route
          path={paymentServicePaths.subscriptionPayment}
          element={<PaymentFlow />}
        />
      </>
    )}
    <Route
      path={paymentServicePaths.paymentComplete}
      element={<PaymentComplete />}
    />
    <Route path="*" element={<Navigate to={paths.paymentService} />} />
  </Routes>
);
