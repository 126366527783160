import { ApiModel } from '@nodal/api';
import { ContactValues as DonorContactValues } from './SurrogateContactDetails/SurrogateContactDetails.interface';
import { ContactValues as ParentContactValues } from './ParentContactDetails/ParentContactDetails.interface';
import { ContactDetail, ContactValue } from './ContactDetails.interface';

const contactDetailWithProfileValue = (
  profileValue: ContactValue,
  contactDetail:
    | ContactDetail<DonorContactValues>
    | ContactDetail<ParentContactValues>,
) => {
  if (Array.isArray(contactDetail.default) && profileValue === null) {
    return {
      [contactDetail.value]: Object.fromEntries(
        contactDetail.default.map((address) => [
          address.value,
          address.default,
        ]),
      ),
    };
  } else {
    return { [contactDetail.value]: profileValue };
  }
};

export const contactDetailsInitialValues = (
  contactDetails: (
    | ContactDetail<DonorContactValues>
    | ContactDetail<ParentContactValues>
  )[],
  profileData: ApiModel.ProfileObject,
) =>
  contactDetails.reduce((acc, contactDetail) => {
    const [, profileValue] =
      Object.entries(profileData).find(
        ([key]) => !!(key === contactDetail.value),
      ) || [];

    return {
      ...acc,
      ...contactDetailWithProfileValue(profileValue, contactDetail),
    };
  }, {});
