import type { FC } from 'react';

import { useField } from 'formik';

import { ImageInput } from './ImageInput';

import type { ImageInputConnectedProps } from './ImageInput.interface';

export const ImageInputConnected: FC<ImageInputConnectedProps> = ({
  name,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(name);

  return (
    <ImageInput
      error={meta.touched && meta.error ? meta.error : undefined}
      {...field}
      {...props}
      name={name}
      onChange={setValue}
    />
  );
};
