import React, { FC } from 'react';
import { SubscriptionPlan } from 'components/SubscriptionFlow/SubscriptionPlan';
import { BillingDetails } from 'components/SubscriptionFlow/BillingDetails';
import { SubscriptionPurchasedProps } from './SubscriptionPurchased.interface';
import { t } from '@nodal/i18n';
import { nodalEmail } from 'consts/email';

export const SubscriptionPurchased: FC<SubscriptionPurchasedProps> = ({
  plan,
  billingDetails,
}) => {
  return (
    <div className="flex flex-col items-center py-8 px-4">
      <div className="flex flex-col gap-4 max-w-4xl">
        {!!plan && (
          <SubscriptionPlan name={plan.name} amount={plan.price.amount} />
        )}
        <BillingDetails billingDetails={billingDetails} />
        <p className="text-xs font-normal leading-4 text-grey-forest-600">
          {t('*If you want to cancel your subscription')}
          &nbsp;
          <a
            className="font-medium text-forest-400"
            href={`mailto:${nodalEmail}`}
          >
            {t('contact Nodal')}
          </a>
          &nbsp;
          {t('and we will do it for you right away.')}
        </p>
      </div>
    </div>
  );
};
