import { t } from '@nodal/i18n';

import { isInRelationship } from '@core/utils/profile';

import { dnrProfileInformation } from '../profileInformationOptions';
import {
  getFormattedDateOfBirth,
  getFormattedLocation,
  getFormattedValueTag,
  getFormattedValueForNumber,
  getQuestionLabel,
  getValueFromChoice,
} from '../utils';

import type {
  DonorProfileInformation,
  TopProfileChoice,
  ProfileData,
} from '../MatchProfile.interface';
import type { SectionDetailsProps } from './SectionDetails.interface';

const getDetailsForPersonalInformation = (
  profile: DonorProfileInformation,
): TopProfileChoice[] => {
  const {
    personalInformation: { questions },
  } = dnrProfileInformation;

  const {
    address,
    date_of_birth,
    relationship_status,
    occupation,
    partner_occupation,
    household_income,
    experience,
    desired_compensation,
    bmi,
    ideal_start,
  } = profile || {};

  const occupationInformation = isInRelationship(relationship_status)
    ? [
        {
          value: occupation?.toString(),
          label: getQuestionLabel(questions, 'occupation'),
        },
        {
          value: partner_occupation?.toString(),
          label: getQuestionLabel(questions, 'partner_occupation'),
        },
      ]
    : [
        {
          value: occupation?.toString(),
          label: getQuestionLabel(questions, 'occupation'),
        },
      ];

  return [
    {
      value: getFormattedLocation(address),
      label: t('Location'),
    },
    {
      value: getFormattedDateOfBirth(date_of_birth),
      label: t('Age'),
    },
    ...occupationInformation,
    {
      value: getValueFromChoice(
        questions,
        'household_income',
        household_income,
      ),
      label: getQuestionLabel(questions, 'household_income'),
    },
    {
      value: getValueFromChoice(questions, 'experience', experience),
      label: getQuestionLabel(questions, 'experience'),
    },
    {
      value: desired_compensation
        ? getFormattedValueTag(
            questions,
            'desired_compensation',
            getFormattedValueForNumber(desired_compensation),
          )
        : undefined,
      label: getQuestionLabel(questions, 'desired_compensation'),
    },
    {
      value: bmi ? getFormattedValueForNumber(bmi) : undefined,
      label: getQuestionLabel(questions, 'bmi'),
    },
    {
      value: getValueFromChoice(questions, 'ideal_start', ideal_start),
      label: getQuestionLabel(questions, 'ideal_start'),
    },
  ];
};

export const SurrogatePersonalSectionDetails = <T extends ProfileData>({
  profile,
}: SectionDetailsProps<T>) => {
  return (
    <div className="flex flex-col gap-x-8 gap-y-0 w-full text-sm font-medium leading-5 text-grey-forest-900 sm:gap-y-3">
      {getDetailsForPersonalInformation(profile).map(({ label, value }) => (
        <div
          key={label}
          className="grid-cols-2/3 col-span-2 gap-x-8 items-end py-4 border-b sm:grid sm:py-0 sm:border-none"
        >
          <p className="col-span-1 col-start-1 text-grey-forest-500">{label}</p>
          <p className="col-span-1 col-start-2">{value}</p>
        </div>
      ))}
    </div>
  );
};
