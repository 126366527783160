import type { FC } from 'react';

import { PhotographIcon, UploadIcon, EyeIcon } from '@heroicons/react/outline';
import { t } from '@nodal/i18n';
import classNames from 'classnames';
import { useRef } from 'react';

import { useFileReader } from 'hooks/useFileReader';

import { FormInputError } from '@uikit/components/FormInputError';

import { ImageControls } from './ImageControls';

import type { ImageInputProps } from './ImageInput.interface';

export const ImageInput: FC<ImageInputProps> = ({
  onChange,
  label,
  name,
  error,
  value,
  className,
  disabled = false,
  previewEnabled = false,
  ...props
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const src = useFileReader(value);

  const imageControls = [
    {
      title: t('Upload new photo'),
      onClick: () => fileInput.current && fileInput.current.click(),
      icon: UploadIcon,
      hidden: disabled,
    },
    {
      title: t('Preview photo'),
      onClick: () => window.open(src, '_blank'),
      icon: EyeIcon,
      hidden: !previewEnabled,
    },
  ];

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div
        className={classNames(
          'group flex flex-col p-3 mt-1 w-full bg-grey-forest-50 rounded-lg border-2 border-grey-forest-200 sm:bg-white',
          className,
        )}
      >
        <input
          ref={fileInput}
          type="file"
          className="hidden"
          multiple={false}
          accept="image/*"
          onChange={(e) => {
            if (!e.target.files) return;
            onChange(e.target.files[0]);
          }}
          disabled={disabled}
          {...props}
        />

        {src ? (
          <ImageControls
            src={src}
            controls={imageControls.filter(({ hidden }) => !hidden)}
          />
        ) : (
          <div
            className="flex items-stretch w-full h-full cursor-pointer"
            onClick={() => fileInput.current && fileInput.current.click()}
          >
            <div className="flex flex-col items-center m-auto space-y-3 text-sm font-medium leading-5">
              <PhotographIcon className="aspect-square w-12 h-12 stroke-grey-forest-500" />
            </div>
          </div>
        )}
      </div>
      {error ? <FormInputError error={error} id={name} /> : null}
    </div>
  );
};
