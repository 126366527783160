import { StageBanner } from './StageBanner';
import { StageProgress } from './StageProgress';
import { TasksContainer } from 'components/TasksContainer';
import { FC } from 'react';
import { DashboardProps } from './Dashboard.interface';
import { SupportTeamCard } from './SupportTeamCard';
import { Resources } from './Resources';
import { t } from '@nodal/i18n';

export const Dashboard: FC<DashboardProps> = ({
  welcomeMessage,
  tasks,
  currentStage,
  currentStageTitle,
  titles,
  availableIntros,
  bannerDescription,
  bannerTitle,
  userId,
  matched,
  inactiveUser,
  showAvailableIntros = false,
}) => (
  <div>
    <div className="bg-light-green">
      {inactiveUser && (
        <p className="pt-4 mx-auto w-[90%] text-sm italic font-medium text-center text-red-600">
          {t(
            'Your account is currently inactive; kindly reach out to our support team for any inquiries!',
          )}
        </p>
      )}
      <div className="flex relative flex-col gap-2 items-center py-6 md:grid md:grid-cols-2 md:gap-4 md:mx-auto md:w-[90%]">
        <h2 className="text-3xl font-semibold leading-8 text-forest-500">
          {welcomeMessage}
        </h2>
        <div className="px-6 pb-4 w-full md:px-0">
          <StageProgress titles={titles} currentStage={currentStage} />
        </div>
      </div>
    </div>
    <div className="mx-auto w-[90%]">
      <div className="flex gap-8 pt-4 lg:grid lg:grid-cols-3">
        <div className="lg:col-span-2">
          <StageBanner
            description={bannerDescription}
            title={bannerTitle}
            availableIntros={availableIntros}
            showAvailableIntros={showAvailableIntros}
            live={!matched}
          />
        </div>
        <div className="hidden lg:block lg:col-span-1">
          <SupportTeamCard userId={userId} />
        </div>
      </div>
    </div>
    <div className="mx-auto w-[90%]">
      <TasksContainer
        currentStageTitle={currentStageTitle}
        tasks={tasks}
        disabled={inactiveUser}
      />
    </div>
    <div className="mx-auto w-[90%]">
      <Resources currentStage={currentStage} />
    </div>
    <div className="flex py-10 mx-auto w-[90%] md:py-0 lg:hidden">
      <SupportTeamCard userId={userId} />
    </div>
  </div>
);
