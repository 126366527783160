import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { FormErrors } from '@nodal/uikit/components/FormErrors';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import type {
  ForgotPasswordFormScreenProps,
  ForgotPasswordFormValues,
} from './ForgotPasswordFormScreen.interface';

const validationSchema: Yup.SchemaOf<ForgotPasswordFormValues> =
  Yup.object().shape({
    email: Yup.string().required(t('Required field')),
    non_field_errors: Yup.string().optional(),
  });

export const ForgotPasswordFormScreen: FC<ForgotPasswordFormScreenProps> = ({
  initialValues,
  onSubmit,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ isSubmitting }) => (
      <Form className="space-y-8">
        <FormErrors />

        <TextInput
          label={t('Email')}
          name="email"
          autoComplete="email"
          required
          type="text"
        />
        <ButtonLoading
          variant="primary"
          type="submit"
          className="justify-center w-full"
          loading={isSubmitting}
        >
          {t('Send Reset Instructions')}
        </ButtonLoading>
      </Form>
    )}
  </Formik>
);
