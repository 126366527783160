import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useUploadMedicalDocuments } from '@nodal/core/hooks/useUploadMedicalDocuments';
import { useConfirmationDialog } from '@nodal/uikit/components/ConfirmationDialog';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { useUsersMeRetrieve } from 'api/hooks/useUsersMeRetrieve';
import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { UploadRecords } from './UploadRecords';

export const UploadRecordsConnected = () => {
  const { data: userData } = useUsersMeRetrieve();
  const confirm = useConfirmationDialog();
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const { data: medicalDocuments } = useQuery(
    queryKeys.medicalDocumentsList,
    () =>
      apiClient.api.MedicalReviewsApi.medicalReviewsMedicalDocumentsOwnList(),
  );

  useEffect(() => {
    return () => {
      queryClient.removeQueries(queryKeys.medicalDocumentsList);
    };
  }, [queryClient]);

  const { onDelete, onUpload, uploadedFiles, disabled } =
    useUploadMedicalDocuments({
      documents: medicalDocuments?.data || [],
      confirm,
      documentType: apiEnums.MedicalDocumentDocumentTypeEnum.SurroDoc,
      medicalReviewId: userData?.data?.medical_review_id,
    });

  if (!medicalDocuments?.data) {
    return <LoadingScreen />;
  }

  return (
    <UploadRecords
      onDelete={onDelete}
      onUpload={onUpload}
      files={uploadedFiles}
      disabled={disabled}
    />
  );
};
