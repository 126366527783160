import React, { FC } from 'react';
import { t } from '@nodal/i18n';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { matchingPaths, paths } from 'consts/paths';
import { ParentsMatchProfile } from 'components/MatchProfile/ParentsMatchProfile';
import { apiEnums } from '@nodal/api/enums';
import { SurrogateMatchProfile } from 'components/MatchProfile/SurrogateMatchProfile';
import { UserProfileScreenProps } from './UserProfileScreen.interface';

export const UserProfileScreen: FC<UserProfileScreenProps> = ({
  userProfile,
}) => {
  return (
    <div className="flex overflow-hidden relative flex-col col-span-12 w-full h-full font-avenir-next text-sm font-normal leading-5">
      <div className="overflow-auto justify-center">
        <div className="flex-1 py-6 mx-auto max-w-5xl sm:py-8">
          <div className="flex flex-col gap-8 px-4 w-fit">
            <Link to={`${paths.matching}/${matchingPaths.matches}`}>
              <div className="flex gap-2 items-center font-avenir-next text-sm font-medium leading-5 text-grey-forest-600">
                <ChevronLeftIcon className="aspect-square w-6 fill-current" />
                {t('Back to matches')}
              </div>
            </Link>
          </div>
          {userProfile.role === apiEnums.UserRoleEnum.Par && (
            <ParentsMatchProfile
              profileData={userProfile}
              owner={false}
              requestIntroductionHidden
            />
          )}
          {userProfile.role === apiEnums.UserRoleEnum.Dnr && (
            <SurrogateMatchProfile profileData={userProfile} owner={false} />
          )}
        </div>
      </div>
    </div>
  );
};
