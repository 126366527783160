import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { Checkbox } from '@nodal/uikit/components/Checkbox';
import { FileDropZone } from '@nodal/uikit/components/FileDropZone';
import { ImageInput } from '@nodal/uikit/components/ImageInput';
import { NumberInput } from '@nodal/uikit/components/NumberInput';
import { SsnInput } from '@nodal/uikit/components/SsnInput';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { UploadedFileList } from '@nodal/uikit/components/UploadedFileList';
import { FieldArray, Form, Formik } from 'formik';
import isNumber from 'lodash/isNumber';

import {
  ivfTransferInitialValues,
  pregnancyDetailInitialValues,
} from './initialValues';
import { IvfTransferDetailsForm } from './IvfTransferDetailsForm';
import { ObGynDetailsForm } from './ObGynDetailsForm';
import { PregnancyDetailsForm } from './PregnancyDetailsForm';

import type { MedicalRecordReviewProps } from './MedicalRecordReview.interface';

export const MedicalRecordReview: FC<MedicalRecordReviewProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  displayPregnancyCountInput,
  displayIvfTransferCountInput,
  title,
  description,
  editable,
  previewPhotoEnabled,
  documentsControl,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
    validateOnBlur={false}
    validationOnChange={false}
  >
    {({ isSubmitting, values, setFieldValue }) => (
      <Form className="space-y-4 w-full lg:w-step-view">
        <div className="p-8 bg-white rounded-lg border border-grey-forest-200">
          <div className="flex flex-col gap-4 pb-6 w-full text-left">
            <h3 className="text-2xl font-semibold leading-8 text-grey-forest-900">
              {title}
            </h3>
            <p className="text-base font-normal leading-6 text-grey-forest-600">
              {description}
            </p>
          </div>

          <div className="flex flex-col gap-x-4 gap-y-10 mt-4">
            <SsnInput
              label={t('Last 4 of SSN')}
              name="ssn_last_4_digits"
              className="lg:w-1/2"
              disabled={!editable}
            />
            <Checkbox
              label={t("I don't have health insurance")}
              name="no_insurance"
              disabled={!editable}
            />
            {!values.no_insurance && (
              <>
                <TextInput
                  label={t('Who is your health insurance provider?')}
                  name="health_insurance"
                  type="text"
                  disabled={!editable}
                />
                <div className="flex flex-col w-full">
                  <label className="block text-sm font-medium text-gray-700">
                    {t(
                      'Please upload a photo of the front and back of your health insurance card',
                    )}
                  </label>
                  <div className="flex gap-4 justify-between w-full">
                    <div className="m-1 w-full text-center">
                      <p className="block mt-1 text-sm text-gray-500">
                        {t('Front')}
                      </p>
                      <ImageInput
                        name="insurance_photo_front"
                        className="h-40"
                        disabled={!editable}
                        previewEnabled={previewPhotoEnabled}
                      />
                    </div>

                    <div className="m-1 w-full text-center">
                      <p className="block mt-1 text-sm text-gray-500">
                        {t('Back')}
                      </p>
                      <ImageInput
                        name="insurance_photo_back"
                        className="h-40"
                        disabled={!editable}
                        previewEnabled={previewPhotoEnabled}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="p-8 space-y-4 bg-white rounded-lg border border-grey-forest-200">
          <p className="text-xl font-semibold text-grey-forest-900">
            {t('Current OB/GYN')}
          </p>

          <p className="pb-6 text-base font-normal leading-6 text-grey-forest-600">
            {t('Please provide contact details for your current OB/GYN.')}
          </p>
          <ObGynDetailsForm editable={editable} />
        </div>
        <div className="p-8 space-y-6 bg-white rounded-lg border border-grey-forest-200">
          <p className="text-xl font-semibold text-grey-forest-900">
            {t('Pregnancy and Delivery')}
          </p>
          <FieldArray name="pregnancy_details">
            {({ push, remove }) => (
              <>
                {displayPregnancyCountInput && (
                  <NumberInput
                    name="previous_pregnancy_count"
                    label={t('Number of previous pregnancies')}
                    onChange={(value?: number) => {
                      if (isNumber(value) && value > 0) {
                        [...Array(value).keys()].forEach((idx) =>
                          !values.pregnancy_details[idx]
                            ? push(pregnancyDetailInitialValues)
                            : null,
                        );

                        values.pregnancy_details.forEach((details, idx) =>
                          idx >= value ? remove(idx) : null,
                        );
                      }

                      setFieldValue(
                        'previous_pregnancy_count',
                        value && value > 0 ? value : 1,
                      );
                    }}
                    disabled={!editable}
                  />
                )}
                <p>
                  {t(
                    'For each pregnancy, please list your doctor, their clinic, and the hospital or location where you gave birth.',
                  )}
                </p>
                <PregnancyDetailsForm
                  pregnancyDetails={values.pregnancy_details}
                  editable={editable}
                />
              </>
            )}
          </FieldArray>
        </div>
        <div className="p-8 space-y-6 bg-white rounded-lg border border-grey-forest-200">
          <p className="text-xl font-semibold text-grey-forest-900">
            {t('Previous IVF Experience')}
          </p>
          <FieldArray name="ivf_transfers_details">
            {({ push, remove }) => (
              <>
                {displayIvfTransferCountInput && (
                  <NumberInput
                    name="previous_ivf_transfer_count"
                    label={t('Number of previous IVF Transfers')}
                    onChange={(value?: number) => {
                      if (isNumber(value) && value >= 0) {
                        [...Array(value).keys()].forEach((idx) =>
                          !values.ivf_transfers_details[idx]
                            ? push(ivfTransferInitialValues)
                            : null,
                        );

                        values.ivf_transfers_details.forEach((details, idx) =>
                          idx >= value ? remove(idx) : null,
                        );
                      }

                      setFieldValue(
                        'previous_ivf_transfer_count',
                        value && value > 0 ? value : 0,
                      );
                    }}
                    disabled={!editable}
                  />
                )}
                {values.previous_ivf_transfer_count > 0 && (
                  <>
                    <p>
                      {t(
                        'The following questions are to find out your previous IVF experience, if any.',
                      )}
                    </p>
                    <IvfTransferDetailsForm
                      ivfTransfersDetails={values.ivf_transfers_details}
                      editable={editable}
                    />
                  </>
                )}
              </>
            )}
          </FieldArray>
        </div>
        {documentsControl && (
          <div className="p-8 space-y-6 bg-white rounded-lg border border-grey-forest-200">
            <p className="text-xl font-semibold text-grey-forest-900">
              {t('Medical Records')}
            </p>
            <p className="pb-6 text-base font-normal leading-6 text-grey-forest-600">
              {t(
                'As part of the medical review process, we collect all previous medical records from your pregnancies and deliveries for review. If you have access to any past medical records please upload them below. This will help speed up the medical review process.',
              )}
            </p>
            <div className="flex flex-col col-span-2 row-start-1 rounded-lg border border-grey-forest-100">
              <div className="border-b border-grey-forest-100">
                <FileDropZone
                  onUpload={documentsControl.onUpload}
                  disabled={documentsControl.disabled}
                  acceptFileTypes="image/*, .pdf, .doc, .docx"
                />
              </div>
              <UploadedFileList
                files={documentsControl.uploadedFiles}
                onDelete={documentsControl.onDelete}
                uploaderIconVisible={false}
              />
            </div>
          </div>
        )}
        {editable && (
          <div className="flex justify-end">
            <ButtonLoading
              type="submit"
              variant="primary"
              loading={isSubmitting}
              className="justify-center px-6 w-full sm:w-max"
            >
              {t('Submit')}
            </ButtonLoading>
          </div>
        )}
      </Form>
    )}
  </Formik>
);
