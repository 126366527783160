import { FC, useCallback, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { apiEnums } from '@nodal/api/enums';
import { SurrogateMatchProfile } from 'components/MatchProfile/SurrogateMatchProfile';
import { MatchingProps } from './Matching.interface';
import { ParentsMatchProfile } from 'components/MatchProfile/ParentsMatchProfile';
import { MatchingInProgress, MatchingStart } from './MatchingProgress';
import { paths, matchingPaths } from 'consts/paths';
import { ParentsMarketplaceScreen } from 'components/ParentsMarketplaceScreen';
import { MatchedScreen } from 'components/MatchedScreen';
import { FavoriteParents } from 'components/FavoriteParents';
import { MatchesScreen } from 'components/MatchesScreen';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';

export const Matching: FC<MatchingProps> = ({ profileData }) => {
  const parentsElement = useCallback(
    (MatchingComponent: () => JSX.Element) => {
      switch (profileData.status) {
        case apiEnums.UserStatusEnum['Mat']:
          return <MatchingComponent />;
        case apiEnums.UserStatusEnum['Mat+']:
          return <Navigate to={`${paths.matching}/${matchingPaths.matches}`} />;
        case apiEnums.UserStatusEnum['Dea']:
          return <Navigate to={`${paths.matching}/${matchingPaths.profile}`} />;
        default:
          return <MatchingInProgress role={profileData.role} />;
      }
    },
    [profileData.role, profileData.status],
  );

  const matchesElement = useMemo(() => {
    switch (profileData.status) {
      case apiEnums.UserStatusEnum['Mat']:
        return <MatchesScreen />;
      case apiEnums.UserStatusEnum['Mat+']:
        return <MatchedScreen />;
      case apiEnums.UserStatusEnum['Dea']:
        return <Navigate to={`${paths.matching}/${matchingPaths.profile}`} />;
      default:
        return <MatchingInProgress role={profileData.role} />;
    }
  }, [profileData.role, profileData.status]);

  return (
    <div className="flex relative flex-col flex-1 gap-8 bg-transparent sm:flex sm:flex-row">
      <Routes>
        {profileData.role === apiEnums.UserRoleEnum.Dnr && (
          <>
            <Route
              path={matchingPaths.profile}
              element={
                <SurrogateMatchProfile profileData={profileData} owner={true} />
              }
            />
            <Route
              path={matchingPaths.parents}
              element={parentsElement(ParentsMarketplaceScreen)}
            />
            <Route
              path={`${matchingPaths.favorites}/*`}
              element={parentsElement(FavoriteParents)}
            />
            <Route
              path={matchingPaths.start}
              element={<MatchingStart role={apiEnums.UserRoleEnum.Dnr} />}
            />
          </>
        )}
        {profileData.role === apiEnums.UserRoleEnum.Par && (
          <>
            <Route
              path={matchingPaths.profile}
              element={
                <ParentsMatchProfile profileData={profileData} owner={true} />
              }
            />

            <Route
              path={matchingPaths.start}
              element={<MatchingStart role={apiEnums.UserRoleEnum.Par} />}
            />
          </>
        )}
        <Route path={`${matchingPaths.matches}/*`} element={matchesElement} />
        <Route path="*" element={<LoadingScreen />} />
      </Routes>
    </div>
  );
};
