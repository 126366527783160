import type { FC } from 'react';

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSendVerificationLink } from '@core/flows/hooks/useSendVerificationLink';

import { EmailNotConfirmedScreen } from './EmailNotConfirmedScreen';

import type {
  EmailNotConfirmedScreenConnectedProps,
  EmailValue,
} from './EmailNotConfirmedScreen.interface';

export const EmailNotConfirmedScreenConnected: FC<
  EmailNotConfirmedScreenConnectedProps
> = ({ redirectPaths }) => {
  const navigate = useNavigate();

  // NOTE: added type cast, because there is an issue with types in react-router v6
  const { state } = useLocation() as { state: EmailValue };
  const email = state?.email;

  useEffect(() => {
    if (!email) {
      navigate('../');
    }
  }, [email, navigate]);

  const { handleSend } = useSendVerificationLink({
    redirectPaths: {
      checkEmail: redirectPaths.checkEmail,
    },
    email,
  });

  return <EmailNotConfirmedScreen onSend={handleSend} />;
};
