import type { FC } from 'react';

import type { Place } from '@uikit/models';
import { getFormattedAddress } from '@uikit/utils';

import { useAutocompletePlaceInput } from './hooks/useAutocompletePlaceInput';
import { usePlaceInputField } from './hooks/usePlaceInputField';
import { PlaceInput } from './PlaceInput';

import type { PlaceInputConnectedProps } from './PlaceInput.interface';

const defaultFormatter = (place: Place) => {
  const placeWithName = {
    ...place,
    line1: place.name,
  };

  return {
    ...placeWithName,
    line2: getFormattedAddress(placeWithName, [
      'line1',
      'city',
      'state',
      'zipcode',
    ]),
  };
};

export const PlaceInputConnected: FC<PlaceInputConnectedProps> = ({
  name,
  disabled = false,
  label,
  type,
  formatter = defaultFormatter,
  placeholder,
  hiddenFieldKeys,
}) => {
  const { inputRef } = useAutocompletePlaceInput(name, type, formatter);

  // NOTE: Fields that are associated with the change of this component - line2 and location
  // -> when there is no selected location - show a validation error
  // -> when the input field is changed (line2) - reset the location
  // -> when address is selected from google places api list -> set location
  const { error, onChange, onBlur } = usePlaceInputField({
    inputName: `${name}.line2`,
    locationName: `${name}.location`,
  });

  return (
    <PlaceInput
      name={name}
      disabled={disabled}
      ref={inputRef}
      label={label}
      onChange={onChange}
      error={error}
      onBlur={onBlur}
      placeholder={placeholder}
      hiddenFieldKeys={hiddenFieldKeys}
    />
  );
};
