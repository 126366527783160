import { useApiClient, useAuth } from '@nodal/api';
import { t } from '@nodal/i18n';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

// ESLint produces warning for GlobalError import
// GlobalError is intentionally only used in the catch block below
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { ApiModel, GlobalError } from '@nodal/api';
import type { AxiosResponse } from 'axios';
import type { SignInFormValues } from 'flows/SignInForm';
import type { FormikConfig, FormikHelpers } from 'formik';

const initialValues: SignInFormValues = {
  email: '',
  password: '',
  non_field_errors: '',
};

const validationSchema: Yup.SchemaOf<Omit<SignInFormValues, 'username'>> =
  Yup.object().shape({
    email: Yup.string().required(t('Required field')),
    password: Yup.string().required(t('Required field')),
    non_field_errors: Yup.string().optional(),
  });

export const useSignIn = ({
  onNavigateToApp,
  onNavigateToEmailNotConfirmed,
  permittedRoles,
}: {
  onNavigateToApp: () => void;
  onNavigateToEmailNotConfirmed: (
    email: string,
    role: ApiModel.RegisterRoleEnum,
  ) => void;
  permittedRoles: ApiModel.UserRoleEnum[];
}): FormikConfig<SignInFormValues> => {
  const apiClient = useApiClient();
  const auth = useAuth();

  const login = useMutation((data: ApiModel.Login) =>
    apiClient.api.AuthApi.authLoginCreate({ login: data }),
  );

  const handleSuccess = async ({
    data: { access_token, refresh_token },
  }: AxiosResponse<ApiModel.JWT>) => {
    await auth.tokenProvider.setTokens({
      access: access_token,
      refresh: refresh_token,
    });
    onNavigateToApp();
  };

  const handleSubmit = async (
    data: ApiModel.Login,
    { setErrors }: FormikHelpers<SignInFormValues>,
  ) => {
    try {
      const response = await login.mutateAsync(data);

      if (permittedRoles.includes(response.data.user.role)) {
        await handleSuccess(response);
      } else {
        setErrors({ non_field_errors: t('Incorrect e-mail or password') });
      }
    } catch (e: GlobalError) {
      if (e?.response?.data) {
        const { code, user_role } = e?.response?.data || {};
        if (data?.email && code === 'email_not_verified') {
          onNavigateToEmailNotConfirmed(data.email, user_role);
        } else if (code === 'invalid_credentials') {
          setErrors({ non_field_errors: t('Incorrect e-mail or password') });
        } else {
          setErrors(e.response.data);
        }
      }
    }
  };

  return {
    onSubmit: handleSubmit,
    initialValues,
    validationSchema,
  };
};
