import type { FC } from 'react';

import { useField } from 'formik';

import { VideoInput } from './VideoInput';

import type { VideoInputConnectedProps } from './VideoInput.interface';
import type { FieldHookConfig } from 'formik';

export const VideoInputConnected: FC<VideoInputConnectedProps> = (props) => {
  // TODO: Figure out why Formik doesn't allow File type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [field, meta, { setValue }] = useField(props as FieldHookConfig<any>);

  const handleDelete = () => setValue(undefined);

  return (
    <VideoInput
      error={meta.touched && meta.error ? meta.error : undefined}
      {...props}
      {...meta}
      {...field}
      onChange={setValue}
      onDelete={handleDelete}
    />
  );
};
