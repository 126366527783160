import { BillingMethodDetails } from './CompletedPaymentSummary.interface';
import { CompletedPaymentSummary } from './CompletedPaymentSummary';
import { useApiClient } from '@nodal/api';
import { useQuery } from 'react-query';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { queryKeys } from '@nodal/core/consts/query';
import { useSinglePaymentService } from '../SinglePaymentService.connect';

export const CompletedPaymentSummaryConnected = () => {
  const apiClient = useApiClient();
  const { paymentPackage } = useSinglePaymentService();

  const { data: customer } = useQuery(
    queryKeys.billingCustomerRetrieve,
    () => apiClient.api.BillingApi.billingCustomerRetrieve(),
    // Subscription details may change in the stripe customer portal.
    // Refetch customer data to observe for expected billing details change
    { refetchInterval: 5000 },
  );

  const { services_payment } = customer?.data || {};

  if (!services_payment) return <LoadingScreen />;

  const { stripe_payment_method } = services_payment || {};

  return (
    <CompletedPaymentSummary
      amount={services_payment.price.amount}
      // NOTE: added type cast because type for stripe_payment_method is less specific than type defined by us
      billingDetails={stripe_payment_method as BillingMethodDetails}
      paymentPackage={paymentPackage}
    />
  );
};
