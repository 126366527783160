import { t } from '@nodal/i18n';

import { distanceInMilesBetweenCoordinates } from '@core/utils';

import { dnrProfileInformation } from '../profileInformationOptions';
import { getLocationCoordinates } from '../utils';

import type { DonorProfileInformation } from '../MatchProfile.interface';
import type { Badge } from './Badges.interface';

export const badgeSelectKeys: Array<keyof DonorProfileInformation> = [
  'relationship_status',
  'lgbtq_identify',
  'termination_choice',
  'is_military',
  'covid_vaccination',
  'previous_c_sections',
];

export const badgePlaceKeys: Array<keyof DonorProfileInformation> = [
  'hospital_address',
  'clinic_address',
  'airport_address',
];

export const badgeChildrenKeys: Array<keyof DonorProfileInformation> = [
  'number_of_children',
];

const {
  personalInformation: { questions: personalInformationQuestions },
  journey: { questions: journeyQuestions },
} = dnrProfileInformation;

const getSelectBadges = (profile: DonorProfileInformation) => {
  return badgeSelectKeys.reduce(
    (arr: Array<Badge>, badge: keyof DonorProfileInformation) => {
      const badgeQuestion = [
        ...personalInformationQuestions,
        ...journeyQuestions,
      ].find((question) => question.value === badge);

      const { choices, value, icon } = badgeQuestion || {};

      if (value) {
        return [
          ...arr,
          {
            label:
              choices?.find(
                (choice) =>
                  choice.value?.toString() === profile[value]?.toString(),
              )?.badge || undefined,
            icon,
          },
        ];
      }

      return [...arr];
    },
    [],
  );
};

const getPlaceBadges = (profile: DonorProfileInformation) => {
  return badgePlaceKeys.reduce(
    (arr: Array<Badge>, badge: keyof DonorProfileInformation) => {
      const badgeQuestion = journeyQuestions.find(
        (question) => question.value === badge,
      );

      const { value, icon } = badgeQuestion || {};

      const address = getLocationCoordinates(profile, 'address');
      const place = getLocationCoordinates(profile, value);

      if (address && place) {
        const [addressLat, addressLon] = address;
        const [placeLat, placeLon] = place;

        if (value) {
          return [
            ...arr,
            {
              label: `${distanceInMilesBetweenCoordinates(
                addressLat,
                addressLon,
                placeLat,
                placeLon,
              )} mi`,
              icon,
            },
          ];
        }
      }
      return [...arr];
    },
    [],
  );
};

const getChildrenBadges = (profile: DonorProfileInformation) => {
  return badgeChildrenKeys.reduce(
    (arr: Array<Badge>, badge: keyof DonorProfileInformation) => {
      const badgeQuestion = personalInformationQuestions.find(
        (question) => question.value === badge,
      );
      const { number_of_children } = profile || {};
      const { icon } = badgeQuestion || {};

      return [
        ...arr,
        {
          label:
            number_of_children || number_of_children === 0
              ? `${number_of_children} ${t('kids')}`
              : '',
          icon,
        },
      ];
    },
    [],
  );
};

export const getSurrogateBagdes = (
  profile: DonorProfileInformation,
): Badge[] => [
  ...getChildrenBadges(profile),
  ...getSelectBadges(profile),
  ...getPlaceBadges(profile),
];
