import { FC, useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { apiEnums } from '@nodal/api/enums';
import { BackgroundCheck } from './BackgroundCheck';
import { SurrogateContactDetails } from 'components/ContactDetails/SurrogateContactDetails';
import { IdentityVerification } from './IdentityVerification';
import { PhoneScreen } from './PhoneScreen';
import { ScreeningStart } from './ScreeningProgress';
import { ScreeningProps } from './Screening.interface';
import { ParentContactDetails } from 'components/ContactDetails/ParentContactDetails';
import { screeningPaths as paths } from 'consts/paths';
import { states } from '@nodal/core/consts/states';
import { buildDisplaySteps } from '@nodal/core/flows/Screening/utils';
import { StepProps as Step } from '@nodal/uikit/components/Step';
import { Steps } from '@nodal/uikit/components/Steps';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import classNames from 'classnames';
import { MedicalRecordReview } from './MedicalRecordReview';
import { HipaaConsentForms } from './HipaaConsentForms';
import { FormQuestions } from './FormQuestions';
import { DisqualifiedScreen } from './DisqualifiedScreen';
import { settings } from 'settings';

export const Screening: FC<ScreeningProps> = ({
  steps: screeningSteps,
  activeStepIndex,
  submit,
  userData,
}) => {
  const params = useParams();
  const path = params['*'];
  const { status, role } = userData;

  const steps: Step[] = useMemo(
    () => buildDisplaySteps(screeningSteps, activeStepIndex),
    [screeningSteps, activeStepIndex],
  );

  const shouldStepsBeRendered =
    path !== '' &&
    path !== paths.start &&
    status !== apiEnums.UserStatusEnum.Dis &&
    status !== apiEnums.UserStatusEnum.Dea;

  const screeningContainerClassName = classNames('w-full', {
    'flex flex-col flex-1 justify-center items-center': !shouldStepsBeRendered,
  });

  return (
    <div className={screeningContainerClassName}>
      <div className="flex flex-col gap-4 py-10 mx-4 max-w-full bg-transparent sm:flex sm:flex-row sm:gap-8 sm:mx-6 lg:mx-8 2xl:mx-auto 2xl:max-w-7xl">
        {shouldStepsBeRendered && (
          <div className="w-full h-full sm:w-72">
            <Steps steps={steps} activeIndex={activeStepIndex} />
          </div>
        )}
        <div className="flex-1 w-full">
          <Routes>
            {(status === apiEnums.UserStatusEnum.Dis ||
              status === apiEnums.UserStatusEnum.Dea) && (
              <Route
                path={paths.disqualified}
                element={<DisqualifiedScreen />}
              />
            )}
            {role === apiEnums.UserRoleEnum.Dnr && (
              <>
                <Route
                  path={paths.profile}
                  element={
                    <SurrogateContactDetails
                      submit={submit}
                      profileData={userData?.profile}
                      states={states}
                    />
                  }
                />
                <Route
                  path={paths.start}
                  element={<ScreeningStart role={apiEnums.UserRoleEnum.Dnr} />}
                />
              </>
            )}
            {role === apiEnums.UserRoleEnum.Par && (
              <>
                <Route
                  path={paths.profile}
                  element={
                    <ParentContactDetails
                      submit={submit}
                      profileData={userData?.profile}
                    />
                  }
                />
                {!settings.getIpDashboardFeatureEnabled() && (
                  <Route
                    path={paths.start}
                    element={
                      <ScreeningStart role={apiEnums.UserRoleEnum.Par} />
                    }
                  />
                )}
              </>
            )}
            {role === apiEnums.UserRoleEnum.Nap && (
              <>
                <Route
                  path={paths.profile}
                  element={
                    <ParentContactDetails
                      submit={submit}
                      profileData={userData?.profile}
                    />
                  }
                />
                <Route
                  path={paths.start}
                  element={<ScreeningStart role={apiEnums.UserRoleEnum.Nap} />}
                />
              </>
            )}
            <Route
              path={paths.questions}
              element={<FormQuestions role={role} />}
            />
            <Route path={paths.identity} element={<IdentityVerification />} />
            <Route path={paths.signing} element={<HipaaConsentForms />} />
            <Route path={paths.phone} element={<PhoneScreen />} />
            <Route path={paths.medical} element={<MedicalRecordReview />} />
            <Route path={paths.background} element={<BackgroundCheck />} />
            <Route path="*" element={<LoadingScreen />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};
