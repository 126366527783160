import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { Alert } from '@nodal/uikit/components/Alert';
import { IdentityCheck } from '@nodal/uikit/components/IdentityCheck';
import { useCallback } from 'react';

import type { IdentityVerificationProps } from './IdentityVerification.interface';

export const IdentityVerification: FC<IdentityVerificationProps> = ({
  title,
  id,
  alert,
  onSubmit,
}) => {
  const handleSubmit = useCallback(async () => {
    await onSubmit(id);
  }, [id, onSubmit]);

  return (
    <div className="flex flex-col justify-start items-start w-full lg:w-step-view">
      {alert ? (
        <div className="pb-6 w-full">
          <Alert {...alert} />
        </div>
      ) : (
        <div className="p-4 w-full h-fit bg-white rounded-lg sm:p-8">
          <div>
            <h2 className="hidden pb-2 text-2xl font-semibold leading-8 text-grey-forest-900 sm:block">
              {title}
            </h2>
            <p className="text-sm leading-5 text-gray-500">
              {t(
                'To verify your identity, please follow the prompts to upload your documents and answers some questions. This step must be completed in one session to avoid having to restart the process.',
              )}
            </p>
          </div>
          <div className="pt-6 w-full">
            <div className="flex gap-4 justify-start w-full sm:w-max">
              <IdentityCheck onClick={handleSubmit}>
                {t('Verify Identity')}
              </IdentityCheck>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
