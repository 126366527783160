import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import compact from 'lodash/compact';
import every from 'lodash/every';

import { medicalReviewsUids } from '@core/consts/medicalReviewsUids';
import { screenTypes } from '@core/consts/screenTypes';
import { getFormattedAddress, convertBirthDateToAge } from '@core/utils';

import { placeTypeKeys } from './placeTypeKeys';
import {
  dnrProfileInformation,
  parProfileInformation,
} from './profileInformationOptions';
import { screeningDetailStatus } from './SectionDetails/SectionDetails.interface';

import type {
  Choice,
  ParentProfileInformation,
  QuestionData,
  DonorProfileInformation,
  SectionDetails,
  ProfileData,
  SectionTypes,
  ProfileInformationWithSections,
  MatchProfileData,
} from './MatchProfile.interface';
import type { ScreeningDetailStatus } from './SectionDetails';
import type { ApiModel } from '@nodal/api';

export const getFormattedSectionDetailsValue = (
  name: keyof ParentProfileInformation | keyof DonorProfileInformation,
  value?: string | number | boolean | string[] | ApiModel.DonorProfileAddress,
): string | undefined => {
  if (Array.isArray(value)) {
    return value.join(', ');
  }

  if (typeof value === 'object' && value) {
    return placeTypeKeys.find((placeKey) => placeKey === name)
      ? getFormattedAddress(value, ['line1', 'city', 'state'])
      : undefined;
  }

  return value?.toString();
};

export const getValueFromChoice = (
  questions: Array<QuestionData<ProfileData>>,
  name: keyof ParentProfileInformation | keyof DonorProfileInformation,
  value: ProfileData[keyof ProfileData],
): string | undefined => {
  const choices = questions.find(
    (q: QuestionData<ProfileData>) => q.value === name,
  )?.choices;

  if (Array.isArray(value)) {
    const choiceLabels = choices?.reduce((arr: string[], choice: Choice) => {
      return value.find((val) => val?.toString() === choice.value?.toString())
        ? [...arr, choice.label]
        : arr;
    }, []);

    return choiceLabels?.join(', ');
  }

  return choices?.find(
    (choice) => choice.value?.toString() === value?.toString(),
  )?.label;
};

export const getDetailsForSection = (
  profileData: ParentProfileInformation | DonorProfileInformation,
  questions: QuestionData<ParentProfileInformation | DonorProfileInformation>[],
): Array<SectionDetails> =>
  questions.reduce(
    (
      arr: Array<SectionDetails>,
      data:
        | QuestionData<ParentProfileInformation>
        | QuestionData<DonorProfileInformation>,
    ): Array<SectionDetails> => {
      const { value, icon, label, required, disabled, hidden } = data || {};
      const [, profileValue] =
        Object.entries(profileData).find(([key]) => key === value) || [];

      if (
        (!profileValue && !required) ||
        (disabled && disabled(profileData)) ||
        (hidden && hidden(profileData))
      ) {
        return arr;
      }

      return [
        ...arr,
        {
          label,
          value:
            getValueFromChoice(questions, value, profileValue) ||
            getFormattedSectionDetailsValue(value, profileValue),
          icon,
        },
      ];
    },
    [],
  );

export const getFormattedValueForNumber = (value: number): string =>
  value?.toLocaleString('en-US', {
    maximumFractionDigits: 1,
  });

export const getFormattedLocation = (
  address: ProfileData['address'],
): string => {
  const { city, state } = address ?? {};
  return city && state ? `${city}, ${state}` : '';
};

export const getFormattedName = ({
  first_name,
  partner_first_name,
}: ApiModel.ParentsProfile): string =>
  partner_first_name
    ? `${first_name} & ${partner_first_name}`
    : `${first_name || ''}`;

export const getFormattedValueTag = (
  questions: Array<QuestionData<ProfileData>>,
  name: keyof ParentProfileInformation | keyof DonorProfileInformation,
  value: ProfileData[keyof ProfileData],
) => {
  const tag =
    questions.find((q: QuestionData<ProfileData>) => q.value === name)?.tag ||
    '';

  return `${tag}${value}`;
};

export const getFormattedDateOfBirth = (
  dateOfBirth: ProfileData['date_of_birth'],
  name?: ProfileData['first_name'],
) => {
  if (dateOfBirth) {
    return name
      ? `${name} - ${convertBirthDateToAge(dateOfBirth)}`
      : convertBirthDateToAge(dateOfBirth)?.toString();
  }

  return '';
};

export const getQuestionLabel = (
  questions: Array<QuestionData<ProfileData>>,
  name: keyof ParentProfileInformation | keyof DonorProfileInformation,
) => questions.find((q: QuestionData<ProfileData>) => q.value === name)?.label;

export const getQuestionsWithMappedValues = (
  questions: Array<
    QuestionData<ParentProfileInformation | DonorProfileInformation>
  >,
  profileDataWithValues: ProfileData,
): Array<QuestionData<ParentProfileInformation | DonorProfileInformation>> =>
  questions.map((question) => {
    const profileKeyValue = Object.entries(profileDataWithValues).find(
      ([key]) => !!(key === question.value),
    );

    if (profileKeyValue) {
      const [, value] = profileKeyValue;
      return {
        ...question,
        default: value === null ? question.default : value,
      };
    }

    return question;
  });

export const getSectionQuestionsByRole = (
  section: SectionTypes,
  role?: ApiModel.UserRoleEnum,
) => {
  const profileInformationOptions =
    role === apiEnums.UserRoleEnum.Dnr
      ? dnrProfileInformation
      : parProfileInformation;

  return profileInformationOptions[section]?.questions;
};

export const getLocationCoordinates = (
  profile: ProfileData,
  value?: string,
) => {
  const [, address] =
    Object.entries(profile).find(([key]) => key === value) || [];

  return address?.location;
};

export const buildProfileInformationWithSections = (
  profileInformationWithSections:
    | ProfileInformationWithSections<DonorProfileInformation>
    | ProfileInformationWithSections<ParentProfileInformation>,
  profileData: MatchProfileData,
) =>
  Object.fromEntries(
    Object.entries(profileInformationWithSections).map(
      ([section, sectionData]) => [
        section,
        {
          ...sectionData,
          questions: getQuestionsWithMappedValues(sectionData.questions, {
            ...profileData.profile,
            profile_photos: profileData.profile_photos,
          }),
        },
      ],
    ),
    // NOTE: using cast, because Object.fromEntries() produces too-wide-to-be-useful type (key as PropertyKey)
  ) as ProfileInformationWithSections<ProfileData>;

const reviewStatusToScreeningDetailStatus = new Map<
  ApiModel.ReviewStepStatusEnum,
  ScreeningDetailStatus
>([
  [apiEnums.ReviewStepStatusEnum.Complete, screeningDetailStatus.complete],
  [apiEnums.ReviewStepStatusEnum.Pending, screeningDetailStatus.pending],
  [apiEnums.ReviewStepStatusEnum.Rejected, screeningDetailStatus.rejected],
]);

const screenStatusToScreeningDetailStatus = new Map<
  ApiModel.ScreenStatusEnum,
  ScreeningDetailStatus
>([
  [apiEnums.ScreenStatusEnum.App, screeningDetailStatus.complete],
  [apiEnums.ScreenStatusEnum.Half, screeningDetailStatus.complete],
  [apiEnums.ScreenStatusEnum.Pend, screeningDetailStatus.pending],
  [apiEnums.ScreenStatusEnum.Proc, screeningDetailStatus.pending],
  [apiEnums.ScreenStatusEnum.Rev, screeningDetailStatus.pending],
  [apiEnums.ScreenStatusEnum.Rej, screeningDetailStatus.rejected],
]);

// NOTE: We do not show all candidate screens in screening details (according to requirements)
const excludedScreenTypes = [
  screenTypes.donorProfile,
  screenTypes.hipaaConsentForm,
  screenTypes.medicalRecordReview,
];

export const buildScreeningDetails = ({
  screens,
  medicalReviewSteps,
  partnersBackgroundCheckStatuses,
}: {
  screens: ApiModel.ScreenNested[] | ApiModel.UserFromIntroductionScreen[];
  medicalReviewSteps: ApiModel.ReviewStepUserSelf[];
  partnersBackgroundCheckStatuses: string[];
}) => {
  // NOTE: The completion status of the medial review section is determined by the reviewDecisionStep
  const medicalReviewStatus =
    medicalReviewSteps.find(
      ({ uid }) => uid === medicalReviewsUids.donorReviewDecision,
    )?.status || apiEnums.ReviewStepStatusEnum.Pending;

  const partnersBackgroundCheckStatus = every(
    partnersBackgroundCheckStatuses,
    // NOTE: we get the statuses from the checkr and we don't have types defined in the api schema.
    // according to the documentation, we know what statuses we can expect
    // Doc: https://docs.checkr.com/?_gl=1*1g3ayl9*_ga*MTA3Njk1MzE3OS4xNjk3NjM2OTY4*_ga_V7EME6CVPL*MTY5OTM1MzUwMi44LjEuMTY5OTM1MzU2MC4yLjAuMA..#tag/Reports
    (status) => status === 'complete',
  )
    ? screeningDetailStatus.complete
    : screeningDetailStatus.pending;

  const displayScreeningScreens = screens
    .filter(
      (screen) =>
        !excludedScreenTypes.find((screenType) => screenType === screen.type),
    )
    .map(({ title, status }) => ({
      title: title || undefined,
      status: screenStatusToScreeningDetailStatus.get(status!),
    }));

  return [
    ...displayScreeningScreens,
    ...compact([
      {
        title: t('Medical Review'),
        status: reviewStatusToScreeningDetailStatus.get(medicalReviewStatus),
      },
      partnersBackgroundCheckStatuses.length && {
        title: t("Partner's Background Check"),
        status: partnersBackgroundCheckStatus,
      },
    ]),
  ];
};
