import { Spinner } from '@nodal/uikit/components/Spinner';
import classNames from 'classnames';
import { forwardRef, memo } from 'react';

import type { SortablePhotoProps } from './SortablePhoto.interface';

export const SortablePhoto = memo(
  forwardRef<HTMLDivElement, SortablePhotoProps>(
    ({ src, loading, hovered, style, dragged, ...props }, ref) => {
      const frameClassName = classNames(
        'group block relative w-full h-full w-full rounded-lg cursor-grab',
        hovered ? 'border-forest-200 border-2 scale-95' : 'border-0',
        {
          'opacity-50': dragged || loading,
        },
      );

      const photoContainerClassName = classNames(
        'flex-1 w-fit h-full bg-center bg-cover select-none outline-none',
        {
          'opacity-50': dragged,
        },
      );

      return (
        <>
          {loading && (
            <div className="absolute -top-2.5 -left-2.5 z-10 justify-center items-center w-full h-full translate-x-1/2 translate-y-1/2">
              <Spinner size="small" variant="secondary" />
            </div>
          )}
          <div ref={ref} className={frameClassName} style={style} {...props}>
            <div className="flex overflow-hidden w-full h-full rounded-lg border border-transparent">
              <div
                className={photoContainerClassName}
                style={{ backgroundImage: `url(${src})` }}
              />
            </div>
          </div>
        </>
      );
    },
  ),
);

SortablePhoto.displayName = 'SortablePhoto';
