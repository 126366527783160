import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

const defaultOptions = {
  scale: 2,
  scrollY: -window.scrollY,
  windowWidth: 1400,
  backgroundColor: '#F8F7F6',
  dpi: 300,
  letterRendering: true,
  useCORS: true,
};

export const convertToPdf = (element: HTMLDivElement, name: string) => {
  html2canvas(element, defaultOptions).then((canvas: HTMLCanvasElement) => {
    const imgData = canvas.toDataURL('image/jpeg');
    const pdf = new jsPDF('p', 'mm', 'a4');

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    const imgHeight = (canvas.height * pageWidth) / canvas.width;

    let heightLeft = imgHeight - pageHeight;

    pdf.addImage(imgData, 'JPEG', 0, 0, pageWidth, imgHeight);

    let position = 0;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'JPEG', 0, position, pageWidth, imgHeight + 5);
      heightLeft -= pageHeight;

      // NOTE: Fill the rest of the pdf page with a defined background color
      pdf.setFillColor(defaultOptions.backgroundColor);
      pdf.rect(0, pageHeight, pageWidth, heightLeft, 'F');
    }

    pdf.save(`${name}.pdf`);

    window.close();
  });
};
