import { ApiModel, useApiClient } from '@nodal/api';
import { objectToFormData } from '@nodal/core/utils';
import { useUsersMeRetrieve } from 'api/hooks/useUsersMeRetrieve';
import { FormikValues } from 'formik';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';

export const useUserProfile = () => {
  const apiClient = useApiClient();

  const userProfile = useUsersMeRetrieve();

  const { data: userData } = userProfile || {};

  const [uploadProgress, setUploadProgress] = useState<number>();
  const updateProfile = useMutation(
    (requestParameters: {
      patchedProfileObject:
        | ApiModel.PatchedDonorProfile
        | ApiModel.PatchedParentsProfile
        | ApiModel.PatchedNavigatorParentsProfile;
    }) => {
      const multipart = !!requestParameters.patchedProfileObject?.video;

      return apiClient.api.UsersApi.usersMeProfilePartialUpdate(
        multipart
          ? {
              patchedProfileObject: objectToFormData(
                requestParameters.patchedProfileObject,
              ) as ApiModel.PatchedProfileObject,
            }
          : requestParameters,
        {
          headers: {
            'Content-Type': multipart
              ? 'multipart/form-data'
              : 'application/json',
          },
          onUploadProgress: (e) => {
            if (e.total) {
              setUploadProgress(e.loaded / e.total);
            }
          },
        },
      );
    },
  );

  const submit = useCallback(
    async (data: FormikValues) => {
      try {
        const submitResponse = await updateProfile.mutateAsync({
          patchedProfileObject: data,
        });

        await userProfile.refetch();

        return submitResponse?.data;
      } catch (error) {
        // TODO: remove this if we will have error handling
        return console.error(error);
      }
    },
    [updateProfile, userProfile],
  );

  return {
    profileData: userData?.data,
    submit,
    uploadProgress,
  };
};
