import React, { FC } from 'react';
import { StartPaymentPreview } from './StartPaymentPreview';
import { useSinglePaymentService } from '../SinglePaymentService.connect';
import { t } from '@nodal/i18n';
import { apiEnums, ApiModel } from '@nodal/api';
import { useAppProtected } from 'app';

export const userRoleToInfoDetails = new Map<
  ApiModel.UserRoleEnum,
  { title: string; description: string }
>([
  [
    apiEnums.UserRoleEnum.Par,
    {
      title: t('Get unlimited access to everything on Nodal'),
      description: t(
        'Saving you time and money as you create a real connection with your future surrogate.',
      ),
    },
  ],
  [
    apiEnums.UserRoleEnum.Nap,
    {
      title: t('Jumpstarting your surrogacy journey'),
      description: t(
        "Whether you're new to surrogacy or at a crossroads, Nodal's team of experts is here to guide you.",
      ),
    },
  ],
]);

export const StartPaymentPreviewConnected: FC = () => {
  const { paymentModel, paymentPackage } = useSinglePaymentService();
  const { profile } = useAppProtected();
  const { role } = profile || {};

  const { title, description } = userRoleToInfoDetails.get(role!) || {};

  return (
    <StartPaymentPreview
      status={paymentModel.payment_status}
      amount={paymentModel.price.amount}
      title={title}
      description={description}
      paymentPackage={paymentPackage}
    />
  );
};
