import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';

import type { NotificationTopBarProps as Notification } from './NotificationTopBar.interface';
import type { ApiModel } from '@nodal/api';

const getNotification = ({
  role,
  status,
  matchProfileReviewStatus,
  isMatchProfileCompleted,
  onSubmitReview,
}: {
  role: ApiModel.UserRoleEnum;
  status: ApiModel.UserStatusEnum;
  matchProfileReviewStatus: ApiModel.DonorProfile['match_profile_review_status'];
  isMatchProfileCompleted: boolean;
  onSubmitReview: () => Promise<void>;
}): Notification | undefined => {
  if (status === apiEnums.UserStatusEnum['Dis']) {
    return {
      type: 'error',
      message: t(
        'Your account is currently inactive; kindly reach out to our support team for any inquiries!',
      ),
    };
  }

  if (status === apiEnums.UserStatusEnum['Dea']) {
    return {
      type: 'error',
      message: t(
        'Your account is currently not active, please contact hello@nodal.com to reactivate your account',
      ),
    };
  }

  if (status === apiEnums.UserStatusEnum['Scr+'] && !isMatchProfileCompleted) {
    return {
      type: 'error',
      message:
        role === apiEnums.UserRoleEnum.Nap
          ? t(
              'Your Navigator Profile must be completed in order to move forward in the process',
            )
          : t(
              'Your Match Profile must be completed in order to be eligible for matching',
            ),
    };
  }

  if (status === apiEnums.UserStatusEnum['Scr+'] && isMatchProfileCompleted) {
    switch (matchProfileReviewStatus) {
      case apiEnums.MatchProfileReviewStatusEnum.ToReview:
        return {
          type: 'warning',
          message:
            role === apiEnums.UserRoleEnum.Nap
              ? t(
                  'Your Navigator Profile has been sent to review. A Nodal team member will contact you with feedback',
                )
              : t(
                  'Your Match Profile has been sent to review. A Nodal team member will contact you with feedback',
                ),
        };
      case apiEnums.MatchProfileReviewStatusEnum.Accepted:
        return {
          type: 'success',
          message:
            role === apiEnums.UserRoleEnum.Nap
              ? t(
                  'Your Navigator Profile has been reviewed and accepted, please wait for approval in order to move forward in the process',
                )
              : t(
                  'Your Match Profile has been reviewed and accepted, please wait for approval to begin matching',
                ),
        };
      // NOTE: For rejected we will show the same notification as for pending
      // Temp. solution for now, agreed with PM
      case apiEnums.MatchProfileReviewStatusEnum.Pending:
      case apiEnums.MatchProfileReviewStatusEnum.Rejected:
      default:
        return {
          type: 'info',
          message: t(
            'Ready to submit your profile draft for review? A Nodal team member will contact you with feedback',
          ),
          action: {
            label: t('Submit'),
            callback: onSubmitReview,
          },
        };
    }
  }
};

export const useNotificationTopBar = ({
  role,
  submit,
  matchProfileReviewStatus,
  isMatchProfileCompleted,
  status,
}: {
  role: ApiModel.UserRoleEnum;
  submit: (data: ApiModel.PatchedProfileObject) => Promise<void>;
  matchProfileReviewStatus: ApiModel.DonorProfile['match_profile_review_status'];
  isMatchProfileCompleted: boolean;
  status: ApiModel.UserStatusEnum;
}) => {
  const onSubmitReview = async () => {
    await submit({
      match_profile_review_status:
        apiEnums.MatchProfileReviewStatusEnum.ToReview,
    });
  };

  const notification = getNotification({
    role,
    matchProfileReviewStatus,
    isMatchProfileCompleted,
    status,
    onSubmitReview,
  });

  return { notification };
};
