import { getDetailsForSection, getSectionQuestionsByRole } from '../utils';

import type { ProfileData } from '../MatchProfile.interface';
import type { SectionDetailsProps } from './SectionDetails.interface';

export const GetToKnowSectionDetails = <T extends ProfileData>({
  profile,
  role,
}: SectionDetailsProps<T>) => {
  const questions = getSectionQuestionsByRole('getToKnow', role);

  return (
    <div className="flex flex-col space-y-10 w-full">
      {getDetailsForSection(profile, questions).map(
        ({ label, value, icon: Icon }) => (
          <div
            className="flex flex-col gap-6 justify-start items-start sm:flex-row"
            key={label}
          >
            <div className="p-3 w-12 h-12 text-moss-500 bg-sage-500 rounded-md">
              {Icon ? <Icon /> : null}
            </div>
            <div className="space-y-2 w-fit">
              <h3 className="text-lg text-grey-forest-900">{label}</h3>
              <p className="text-grey-forest-500 whitespace-pre-wrap">
                {value}
              </p>
            </div>
          </div>
        ),
      )}
    </div>
  );
};
