import { ApiModel } from '@nodal/api';
import { t } from '@nodal/i18n';
import { isInRelationship } from '@nodal/core/utils/profile';
import { addressSchema } from '@nodal/core/consts/addressSchema';
import * as Yup from 'yup';

export const parentValidationSchema = Yup.object({
  same_partner_address: Yup.boolean().nullable(),
  first_name: Yup.string().nullable().required(t('This field is required')),
  middle_name: Yup.string().nullable(),
  last_name: Yup.string().nullable().required(t('This field is required')),
  phone_number: Yup.number().nullable().required(t('This field is required')),
  address: addressSchema.required(),
  date_of_birth: Yup.string().nullable().required(t('This field is required')),
  preferred_pronouns: Yup.string()
    .nullable()
    .required(t('This field is required')),
  occupation: Yup.string().nullable().required(t('This field is required')),
  relationship_status: Yup.string()
    .nullable()
    .required(t('This field is required')),
  reason_for_applying: Yup.string()
    .nullable()
    .required(t('This field is required')),

  partner_first_name: Yup.string()
    .nullable()
    .when('relationship_status', {
      is: isInRelationship,
      then: Yup.string().nullable().required(t('This field is required')),
    }),
  partner_middle_name: Yup.string().nullable().when('relationship_status', {
    is: isInRelationship,
    then: Yup.string().nullable(),
  }),
  partner_last_name: Yup.string()
    .nullable()
    .when('relationship_status', {
      is: isInRelationship,
      then: Yup.string().nullable().required(t('This field is required')),
    }),
  partner_phone_number: Yup.number()
    .nullable()
    .when('relationship_status', {
      is: isInRelationship,
      then: Yup.number().nullable().required(t('This field is required')),
    }),
  partner_email: Yup.string()
    .nullable()
    .when('relationship_status', {
      is: isInRelationship,
      then: Yup.string()
        .nullable()
        .email(t('Enter a valid email address'))
        .required(t('This field is required')),
    }),
  partner_address: Yup.object()
    .nullable()
    .when(['relationship_status', 'same_partner_address'], {
      is: (
        relationship_status: ApiModel.ParentsProfile['relationship_status'],
        same_partner_address: boolean | null,
      ) => isInRelationship(relationship_status) && !same_partner_address,
      then: addressSchema.required(),
    }),
  partner_date_of_birth: Yup.string()
    .nullable()
    .when('relationship_status', {
      is: isInRelationship,
      then: Yup.string().nullable().required(t('This field is required')),
    }),
  partner_preferred_pronouns: Yup.string()
    .nullable()
    .when('relationship_status', {
      is: isInRelationship,
      then: Yup.string().nullable().required(t('This field is required')),
    }),
  partner_occupation: Yup.string()
    .nullable()
    .when('relationship_status', {
      is: isInRelationship,
      then: Yup.string().nullable().required(t('This field is required')),
    }),
});
