import React, { createContext, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useApiClient } from '@nodal/api';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { SinglePaymentService } from './SinglePaymentService';
import { t } from '@nodal/i18n';
import { SinglePaymentServiceContextValuesProps } from './SinglePaymentService.interface';
import { useAppProtected } from 'app';
import { queryKeys } from '@nodal/core/consts/query';
import { userRoleToPaymentPackage } from './paymentPackage';

const SinglePaymentServiceContext = createContext<
  SinglePaymentServiceContextValuesProps | undefined
>(undefined);

export const useSinglePaymentService = () => {
  const context = useContext(SinglePaymentServiceContext);

  if (!context) {
    throw new Error(
      'useSinglePaymentService must be used within a SinglePaymentServiceContextProvider',
    );
  }

  return context;
};

const useInitiatePayment = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const initiatePaymentCreate = useMutation(
    () =>
      apiClient.api.BillingApi.billingStripeNodalServicesPaymentInitiatePaymentCreate(),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeys.billingCustomerRetrieve),
    },
  );

  const initiatePayment = async () => {
    try {
      await initiatePaymentCreate.mutateAsync();
      return { success: true, error: undefined };
    } catch (error) {
      return { success: false, error: t('Something went wrong') };
    }
  };

  return {
    initiatePayment,
  };
};

export const SinglePaymentServiceConnected = () => {
  const { billingCustomer, profile } = useAppProtected();
  const { role } = profile || {};
  const { initiatePayment } = useInitiatePayment();
  const paymentPackage = userRoleToPaymentPackage.get(role!);

  if (!billingCustomer || !paymentPackage) return <LoadingScreen />;

  return (
    <SinglePaymentServiceContext.Provider
      value={{
        paymentModel: billingCustomer.services_payment,
        initiatePayment,
        paymentPackage,
      }}
    >
      <SinglePaymentService
        status={billingCustomer.services_payment.payment_status}
      />
      ;
    </SinglePaymentServiceContext.Provider>
  );
};
