import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n';
import { ToastNotification } from '@nodal/uikit/components/Toast';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CheckEmailScreen } from './CheckEmailScreen';

import type { CheckEmailScreenConnectedProps } from './CheckEmailScreen.interface';
import type { ApiModel, GlobalError } from '@nodal/api';

export const CheckEmailScreenConnected: FC = () => {
  const navigate = useNavigate();

  // NOTE: added type cast, because there is an issue with types in react-router v6
  const { state } = useLocation() as { state: CheckEmailScreenConnectedProps };

  const email = state?.email;

  useEffect(() => {
    if (!email) {
      navigate('../');
    }
  }, [email, navigate]);

  const apiClient = useApiClient();

  const resend = useMutation(
    (payload: ApiModel.RegistrationApiRegistrationResendEmailCreateRequest) =>
      apiClient.api.RegistrationApi.registrationResendEmailCreate(payload),
  );

  const handleResend = () => {
    resend.mutate(
      { resendEmailVerification: { email: email || '' } },
      {
        onSuccess: () => {
          toast.success(
            <ToastNotification
              title={t('E-mail resent!')}
              message={t('Check your e-mail and follow instructions.')}
            />,
          );
        },

        onError: (e: GlobalError) => {
          if (e?.response?.data?.email) {
            toast.error(t('Something went wrong...'));
          }
        },
      },
    );
  };

  return <CheckEmailScreen onResend={handleResend} email={email} />;
};
