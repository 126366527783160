import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { paymentServicePaths } from 'consts/paths';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { SubscriptionPlan } from 'components/SubscriptionFlow/SubscriptionPlan';
import { SubscriptionPlanSelectionProps } from './SubscriptionPlanSelection.interface';
import { apiEnums } from '@nodal/api/enums';

export const SubscriptionPlanSelection: FC<SubscriptionPlanSelectionProps> = ({
  plans,
  selectedPlanId,
  onSelectPlan,
  status,
}) => {
  const activePlanId =
    selectedPlanId === undefined && plans.length === 1
      ? plans[0].id
      : selectedPlanId;

  return (
    <div className="flex relative flex-col grid-cols-12 gap-8 py-8 pb-4 mx-auto max-w-7xl h-full bg-transparent sm:flex sm:flex-row sm:py-12 lg:grid">
      <div className="col-span-6 col-start-4 gap-14 justify-start items-start px-4 w-full h-full sm:px-8 lg:px-0">
        <div className="flex flex-col gap-2 px-4 sm:px-8 lg:px-0">
          <h2 className="pt-2 text-2xl font-semibold leading-8 text-center text-grey-forest-900 sm:pt-3">
            {t('Get unlimited access to everything on Nodal')}
          </h2>
          <p className="text-center sm:pb-1">
            {t(
              'Saving you time and money as you create a real connection with your future surrogate.',
            )}
          </p>
          {status === apiEnums.SubscriptionStatusEnum.SubscriptionFailed && (
            <div className="flex justify-center items-center">
              <div className="font-medium text-center text-red-600">
                <p>{t('Something went wrong with your last payment.')}</p>
                <p>
                  {t('Please, select subscription again and retry payment.')}
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-4 py-10 sm:py-14">
          <div className="flex flex-col gap-2">
            {plans.map((plan) => (
              <div
                className="flex-1"
                onClick={() => onSelectPlan(plan.id)}
                key={plan.id}
              >
                <SubscriptionPlan
                  name={plan.name}
                  amount={plan.price.amount}
                  selected={selectedPlanId === plan.id}
                />
              </div>
            ))}
          </div>
          <Link
            to={
              activePlanId
                ? `${paymentServicePaths.subscriptionPayment.replace(
                    ':planId',
                    activePlanId.toString(),
                  )}`
                : '#'
            }
            className="self-end"
          >
            <Button variant="primary" disabled={activePlanId === undefined}>
              {t('Continue')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
