import { MatchProfile } from 'components/MatchProfile';
import { FC } from 'react';
import { SurrogateMatchProfileProps } from './MatchProfile.interface';
import { convertToFeetAndInches } from '@nodal/core/utils';
import { DonorMatchProfileUser } from '@nodal/core/flows/MatchProfile';

const transformProfileData = (profileData: DonorMatchProfileUser) => {
  const {
    profile: { height, weight },
  } = profileData;

  const heightValue = height || null;

  const { feet, inches } = convertToFeetAndInches(
    typeof heightValue === 'number' ? heightValue : null,
  );

  const donorProfileData: DonorMatchProfileUser = profileData && {
    ...profileData,
    profile: {
      ...profileData?.profile,
      body_measurements: {
        height_in: inches,
        height_ft: feet,
        weight,
      },
    },
  };

  return donorProfileData;
};

export const SurrogateMatchProfile: FC<SurrogateMatchProfileProps> = ({
  profileData,
  owner,
}) => {
  return (
    <MatchProfile
      profileData={transformProfileData(profileData)}
      owner={owner}
    />
  );
};
