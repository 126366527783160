import { t } from '@nodal/i18n';
import { Alert } from '@nodal/uikit/components/Alert';

export const DisqualifiedScreen: React.FC = () => {
  return (
    <div className="mx-auto lg:w-[50%]">
      <Alert
        variant="error"
        title={t('Thank you!')}
        message={t(
          'We appreciate your interest in Nodal but can no longer continue with your application at this time. If you have any questions or need further clarification, please feel free to reach out. Thank you for considering Nodal!',
        )}
      />
    </div>
  );
};
