import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useQuery } from 'react-query';
import { Dashboard } from './Dashboard';
import {
  getTasksForCurrentStage,
  getWelcomeMessage,
  parentStepsMeta,
  stagesTitles,
} from './utils';

export const DashboardConnected = () => {
  const apiClient = useApiClient();

  const { data: userMe } = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );

  const { data: tasksMe } = useQuery(queryKeys.tasksMeStageRetrieve, () =>
    apiClient.api.TasksApi.tasksMeStageRetrieve(),
  );

  const { data: tasksList } = useQuery(queryKeys.tasksList, () =>
    apiClient.api.TasksApi.tasksList(),
  );

  const user = userMe?.data;
  const userProfile = user?.profile;
  const partnerFirstName =
    userProfile && 'partner_first_name' in userProfile
      ? userProfile.partner_first_name
      : null;
  const tasksStage = tasksMe?.data;

  if (!user || !tasksList?.data || !tasksStage) return null;

  const currentStage =
    tasksStage.forced_tasks_stage !== 'null' && !!tasksStage.forced_tasks_stage
      ? tasksStage.forced_tasks_stage
      : tasksStage.calculated_task_stage;

  const tasksForCurrentStage = getTasksForCurrentStage(
    currentStage,
    tasksList?.data,
  );

  const welcomeMessage = getWelcomeMessage(
    userProfile?.first_name,
    partnerFirstName,
  );

  const showAvailableIntros =
    currentStage === apiEnums.TaskStageEnum.Introduction ||
    currentStage === apiEnums.TaskStageEnum.Match;

  return (
    <Dashboard
      welcomeMessage={welcomeMessage}
      currentStage={parentStepsMeta[currentStage].index}
      titles={stagesTitles}
      currentStageTitle={parentStepsMeta[currentStage].title}
      tasks={tasksForCurrentStage}
      availableIntros={user.introductions_left}
      showAvailableIntros={showAvailableIntros}
      bannerDescription={parentStepsMeta[currentStage].bannerDescription}
      bannerTitle={parentStepsMeta[currentStage].bannerTitle}
      userId={user.id}
      matched={user.status === apiEnums.UserStatusEnum['Mat+']}
      inactiveUser={
        user.status === apiEnums.UserStatusEnum.Dis ||
        user.status === apiEnums.UserStatusEnum.Dea
      }
    />
  );
};
