import { parProfileInformation } from '../profileInformationOptions';

import type { ParentProfileInformation } from '../MatchProfile.interface';
import type { Badge } from './Badges.interface';

const badgeSelectKeys: Array<keyof ParentProfileInformation> = [
  'relationship_status',
  'lgbtq_identify',
  'termination_choice',
  'is_military',
  'agency',
];

export const getParentsBagdes = (
  profile: ParentProfileInformation,
): Badge[] => {
  const {
    personalInformation: { questions: personalInformationQuestions },
    journey: { questions: journeyQuestions },
  } = parProfileInformation;

  return badgeSelectKeys.reduce(
    (arr: Badge[], badge: keyof ParentProfileInformation) => {
      const badgeQuestion = [
        ...personalInformationQuestions,
        ...journeyQuestions,
      ].find((question) => question.value === badge);

      const { choices, value, icon } = badgeQuestion || {};

      if (value) {
        return [
          ...arr,
          {
            label:
              choices?.find(
                (choice) =>
                  choice.value?.toString() === profile[value]?.toString(),
              )?.badge || undefined,
            icon,
          },
        ];
      }

      return [...arr];
    },
    [],
  );
};
