import { t } from '@nodal/i18n';
import * as Yup from 'yup';

export const schema = Yup.object({
  email: Yup.string()
    .nullable()
    .required(t('This field is required'))
    .email(t('Enter a valid email address')),
  display_name: Yup.string().nullable().required(t('This field is required')),
  partner_email: Yup.string()
    .nullable()
    .email(t('Enter a valid email address')),
  partner_display_name: Yup.string().nullable(),
});
