import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { apiEnums } from '@nodal/api';
import { Matching } from './Matching';
import { matchingPaths as paths } from 'consts/paths';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { MatchingContextValuesProps } from './Matching.interface';
import { useAppProtected } from 'app';
import { useUsersMeRetrieve } from 'api/hooks/useUsersMeRetrieve';
import { MatchingUser } from 'api';

const initialValues: MatchingContextValuesProps = {
  completed: false,
  started: false,
  refreshed: false,
  accepted: false,
  updateRefreshed: (value: boolean) => value,
  updateCompleted: (value: boolean) => value,
  updateStarted: (value: boolean) => value,
  updateAccepted: (value: boolean) => value,
};

export const MatchingContext = createContext(initialValues);

export const MatchingContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [started, setStarted] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [refreshed, setRefreshed] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const updateStarted = useCallback((value: boolean) => {
    setStarted(value);
  }, []);

  const updateCompleted = useCallback((value: boolean) => {
    setCompleted(value);
  }, []);

  const updateRefreshed = useCallback((value: boolean) => {
    setRefreshed(value);
  }, []);

  const updateAccepted = useCallback((value: boolean) => {
    setAccepted(value);
  }, []);

  const value = useMemo(
    () => ({
      completed,
      updateCompleted,
      started,
      updateStarted,
      updateRefreshed,
      refreshed,
      accepted,
      updateAccepted,
    }),
    [
      completed,
      updateCompleted,
      started,
      updateStarted,
      updateRefreshed,
      refreshed,
      accepted,
      updateAccepted,
    ],
  );

  return (
    <MatchingContext.Provider value={value}>
      {children}
    </MatchingContext.Provider>
  );
};

export const useMatching = () => {
  const {
    started,
    updateStarted,
    updateRefreshed,
    refreshed,
    updateAccepted,
    accepted,
  } = useContext(MatchingContext);

  const userProfile = useUsersMeRetrieve();

  const { data: userData } = userProfile || {};

  const refresh = useCallback(async () => {
    updateRefreshed(true);

    const { data } = userData || {};
    const { status } = data || {};

    if (
      status === apiEnums.UserStatusEnum['Mat'] ||
      status === apiEnums.UserStatusEnum['Mat+']
    ) {
      updateAccepted(true);
    }
  }, [updateRefreshed, userData, updateAccepted]);

  const start = useCallback(() => {
    updateStarted(true);
  }, [updateStarted]);

  return {
    // NOTE: Only donor or parents users appear in the matching
    profileData: userData?.data as MatchingUser,
    refresh,
    start,
    started,
    refreshed,
    accepted,
  };
};

const MatchingInitialRedirect: FC = () => {
  const { started, refresh, refreshed, accepted } = useMatching();
  const { matchProfileCompleted, profile } = useAppProtected();
  const navigate = useNavigate();
  const params = useParams();
  const path = params['*'];
  const profileStatus = profile?.status;

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshed) {
      if (profileStatus === apiEnums.UserStatusEnum.Dis) {
        navigate(paths.profile);
        return;
      }

      if (started && path === paths.start) {
        navigate(paths.profile);
        return;
      }

      // NOTE: below we use condition path === '' because we check whether it's auto redirect or not
      // TODO: Could this be refactored to use autoRedirect in state
      if (
        !accepted &&
        matchProfileCompleted &&
        (path === '' || path === paths.start)
      ) {
        navigate(paths.profile);
        return;
      }

      if (!started && !matchProfileCompleted && path === '') {
        navigate(paths.start);
        return;
      }

      if (accepted && (path === '' || path === paths.start)) {
        navigate(paths.matches);
        return;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [started, navigate, matchProfileCompleted, refreshed]);

  return null;
};

const MatchingContextConsumer: FC = () => {
  const { profileData } = useMatching();
  return profileData ? <Matching profileData={profileData} /> : null;
};

export const MatchingConnected: FC = () => (
  <MatchingContextProvider>
    <Routes>
      <Route path="*" element={<MatchingInitialRedirect />} />
    </Routes>

    <MatchingContextConsumer />
  </MatchingContextProvider>
);
