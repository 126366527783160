import type { FC } from 'react';
import { t } from '@nodal/i18n';
import { useIdentityVerification } from '@nodal/core/flows/Screening/IdentityVerification/useIdentityVerification';
import { useScreening } from '@nodal/core/flows/Screening';
import {
  IdentityVerification,
  Data,
} from '@nodal/core/flows/Screening/IdentityVerification';
import { settings } from 'settings';
import { useMutation } from 'react-query';
import { ApiModel, useApiClient } from '@nodal/api';
import { toast } from 'react-toastify';

const title = t('Identity Verification');

export const IdentityVerificationConnected: FC = () => {
  const apiClient = useApiClient();
  const { refresh, submit, currentStep } = useScreening<Data>();
  const stripeKey = settings.getStripePublishableKey();

  const stripeSessionRetrieve = useMutation(
    (
      request: ApiModel.ScreensApiScreensStripeIdCheckVerificationSessionRetrieveRequest,
    ) =>
      apiClient.api.ScreensApi.screensStripeIdCheckVerificationSessionRetrieve(
        request,
      ),
    {
      onError: () => {
        toast.error(t('Something went wrong...'));
      },
    },
  );

  const sessionRetrieve = async (id: number) => {
    const response = await stripeSessionRetrieve.mutateAsync({ id });
    return response.data;
  };

  const { onSubmit, alert } = useIdentityVerification({
    stripeKey,
    submit,
    currentStep,
    refresh,
    sessionRetrieve,
  });

  if (!currentStep) {
    return null;
  }

  return (
    <IdentityVerification
      title={title}
      id={currentStep.id}
      alert={alert}
      onSubmit={onSubmit}
    />
  );
};
