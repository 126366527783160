import { useElements, useStripe } from '@stripe/react-stripe-js';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaymentElement } from './PaymentElement';

import type {
  PaymentElementRef,
  PaymentElementConnectedProps,
} from './PaymentElement.interface';

const usePaymentElement = ({
  initiatePayment,
  redirectPath,
}: PaymentElementConnectedProps) => {
  const stripe = useStripe();
  const navigate = useNavigate();

  const elements = useElements();

  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const confirmPaymentIntent = async () => {
    if (!elements || !stripe) {
      throw new Error('Stripe not initialized');
    }

    setProcessing(true);

    const { error: initiateError } = await initiatePayment();

    if (initiateError) {
      setErrorMessage(initiateError);
      setProcessing(false);
      return;
    }

    const { error: stripeError, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/${redirectPath}`,
      },
      // NOTE: do not redirect after successful confirmation, navigation is to be decided by the parent component
      redirect: 'if_required',
    });

    if (stripeError) {
      setErrorMessage(stripeError.message);
      setProcessing(false);

      return;
    }

    setProcessing(false);

    const paymentDetails = {
      // NOTE: Payment intent returns the amount in cents,
      // it should be divided by 100
      amount: paymentIntent.amount / 100,
      // NOTE: The payment intent returns time in seconds,
      // so we're converting it to milliseconds
      created: paymentIntent.created * 1000,
      // NOTE: The payment intent always returns a single element in the array for the payment method
      // since it is always created for one payment method
      method: paymentIntent?.payment_method_types[0],
    };

    navigate(redirectPath, {
      state: paymentDetails,
    });
  };

  return {
    confirmPaymentIntent,
    errorMessage,
    processing,
  };
};

export const PaymentElementConnected = forwardRef(
  (
    props: PaymentElementConnectedProps,
    ref: React.ForwardedRef<PaymentElementRef>,
  ) => {
    const { confirmPaymentIntent, ...payment } = usePaymentElement({
      ...props,
    });

    useImperativeHandle(
      ref,
      () => ({
        confirmPaymentIntent,
      }),
      [confirmPaymentIntent],
    );

    return <PaymentElement {...payment} />;
  },
);

PaymentElementConnected.displayName = 'PaymentElementConnected';
