import type { FC } from 'react';

import { useAuth } from '@nodal/api/auth';
import { useEffect } from 'react';
import { useNavigate, matchPath } from 'react-router-dom';

import { paths } from 'consts/paths';

export const logout = () => {
  if (
    matchPath(paths.signin, window.location.pathname) &&
    matchPath(paths.logout, window.location.pathname)
  ) {
    window.location.href = paths.logout;
  }
};

export const Logout: FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(paths.signin);
    auth.tokenProvider.removeTokens();
    console.log('[@nodal/core/flows/Logout] removeTokens');
  }, [navigate, auth]);

  return <></>;
};
