import { Form, Formik } from 'formik';
import { FC } from 'react';
import { t } from '@nodal/i18n';
import { states } from '@nodal/core/consts/states';
import { PastDateInput } from '@nodal/uikit/components/DateInput';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { Select } from '@nodal/uikit/components/Select';
import { Checkbox } from '@nodal/uikit/components/Checkbox';
import {
  preferredPronounsChoices,
  reasonForApplyingChoices,
  relationshipStatusChoices,
} from './contactDetails';
import { ParentContactDetailsProps } from './ParentContactDetails.interface';
import { PhoneNumberInput } from '@nodal/uikit/components/PhoneNumberInput';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { Address } from 'models';
import { AddressInput } from '@nodal/uikit/components/AddressInput';

export const ParentContactDetails: FC<ParentContactDetailsProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
  >
    {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
      <Form className="space-y-4 w-full lg:w-step-view">
        <div className="p-8 bg-white rounded-lg">
          <h3 className="text-xl font-semibold leading-7 text-gray-900">
            {t('Personal Information')}
          </h3>
          <div className="grid grid-cols-1 gap-x-4 gap-y-10 mt-6">
            <TextInput label={t('First Name')} name="first_name" type="text" />
            <TextInput
              label={t('Middle Name')}
              name="middle_name"
              type="text"
            />
            <TextInput label={t('Last Name')} name="last_name" type="text" />
            <PastDateInput
              label={t('Date of Birth')}
              name="date_of_birth"
              placeholder="YYYY-DD-MM"
              className="lg:w-1/2"
            />
            <TextInput label="Occupation" name="occupation" type="text" />
            <Select
              label={t('Preferred pronouns')}
              name="preferred_pronouns"
              options={preferredPronounsChoices}
              className="lg:w-1/2"
            />

            <Select
              label={t('Relationship status')}
              name="relationship_status"
              options={relationshipStatusChoices}
              className="lg:w-1/2"
            />
            <Select
              label={t('Reason for applying')}
              name="reason_for_applying"
              options={reasonForApplyingChoices}
              className="lg:w-1/2"
            />
          </div>
          {(values['relationship_status'] === 'mar' ||
            values['relationship_status'] === 'eng' ||
            values['relationship_status'] === 'rel') && (
            <div className="pt-10">
              <div className="relative">
                <div
                  className="flex absolute inset-0 items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="flex relative justify-start">
                  <span className="pr-3 text-xl font-semibold leading-7 text-gray-900 bg-white">
                    {t('Partner')}
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-x-4 gap-y-10 mt-6">
                <TextInput
                  label={t('First Name')}
                  name="partner_first_name"
                  type="text"
                />
                <TextInput
                  label={t('Middle Name')}
                  name="partner_middle_name"
                  type="text"
                />
                <TextInput
                  label={t('Last Name')}
                  name="partner_last_name"
                  type="text"
                />
                <PastDateInput
                  label={t('Date of Birth')}
                  name="partner_date_of_birth"
                  placeholder="YYYY-DD-MM"
                  className="lg:w-1/2"
                />
                <TextInput
                  label={t('Occupation')}
                  name="partner_occupation"
                  type="text"
                />
                <Select
                  label={t('Preferred pronouns')}
                  name="partner_preferred_pronouns"
                  options={preferredPronounsChoices}
                  className="lg:w-1/2"
                />
              </div>
            </div>
          )}
        </div>
        <div className="p-8 bg-white rounded-lg">
          <div>
            <h3 className="text-xl font-semibold leading-7 text-gray-900">
              {t('Contact Information')}
            </h3>
          </div>
          <div className="grid grid-cols-1 gap-x-4 gap-y-10 mt-6">
            <PhoneNumberInput
              label={t('Phone Number')}
              name="phone_number"
              placeholder="(555) 987-6543"
              type="text"
              className="lg:w-1/2"
            />
            <AddressInput
              name="address"
              onChange={(address: Address) =>
                Object.entries(address).forEach(([key, value]) => {
                  setFieldValue(`address.${key}`, value);
                  setFieldTouched(`address.${key}`, false);
                })
              }
              states={states}
            />
          </div>
          {(values['relationship_status'] === 'mar' ||
            values['relationship_status'] === 'eng' ||
            values['relationship_status'] === 'rel') && (
            <div className="pt-10">
              <div className="relative">
                <div
                  className="flex absolute inset-0 items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="flex relative justify-start">
                  <span className="pr-3 text-xl font-semibold leading-7 text-gray-900 bg-white">
                    {t('Partner')}
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-x-4 gap-y-10 mt-6">
                <PhoneNumberInput
                  label={t('Phone Number')}
                  className="lg:w-1/2"
                  name="partner_phone_number"
                  placeholder="(555) 987-6543"
                  type="text"
                />
                <TextInput
                  name="partner_email"
                  type="email"
                  label={t('Email address')}
                />

                <Checkbox
                  name="same_partner_address"
                  label={t('Same as mine')}
                />
                <AddressInput
                  name="partner_address"
                  onChange={(address: Address) =>
                    Object.entries(address).forEach(([key, value]) => {
                      setFieldValue(`partner_address.${key}`, value);
                      setFieldTouched(`partner_address.${key}`, false);
                    })
                  }
                  states={states}
                  disabled={values.same_partner_address}
                />
              </div>
            </div>
          )}
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <ButtonLoading
              type="submit"
              variant="primary"
              className="justify-center w-full sm:w-max"
              loading={isSubmitting}
            >
              {t('Submit')}
            </ButtonLoading>
          </div>
        </div>
      </Form>
    )}
  </Formik>
);
