import type { FC } from 'react';

import { t } from '@nodal/i18n';

import { AvailableIntrosProps } from './AvailableIntros.interface';

const maxAvailableIntroductions = 3;

export const AvailableIntros: FC<AvailableIntrosProps> = ({
  count,
  live = false,
}) => (
  <div className="flex relative flex-row-reverse justify-center items-center p-6 bg-grey-forest-50 rounded-2xl md:flex-col">
    <div>
      <div
        className={`flex relative flex-col justify-center items-center w-28 h-28 rounded-full border-[12px] ${
          live ? 'border-forest-400' : 'border-grey-forest-200'
        } md:w-32 md:h-32`}
        aria-current="step"
      >
        <div>
          <span className="text-3xl md:text-4xl">{count}</span>
          <span className=" text-grey-forest-500">
            / {maxAvailableIntroductions}
          </span>
        </div>
        <p className="flex px-4 text-xs text-center text-grey-forest-500 md:text-sm">
          {t('available intros')}
        </p>
      </div>
    </div>
    <div className="flex flex-col pt-5 md:flex-row">
      <span className="pr-2 text-lg font-semibold">{t('Your profile:')}</span>
      <div className="flex items-center">
        <span
          className={`w-2 h-2 ${
            live ? 'bg-green-500' : 'bg-red-600'
          } rounded-full`}
        ></span>
        <span className="pl-2">{live ? t('Live') : t('Not Live')}</span>
      </div>
    </div>
  </div>
);
