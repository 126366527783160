import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { RadioGroup } from '@nodal/uikit/components/RadioGroup';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { Form, Formik } from 'formik';
import { forwardRef } from 'react';
import * as Yup from 'yup';

import type {
  RejectionFormProps,
  RejectReasonQuestions,
} from './RejectionForm.interface';

const rejectReasonQuestions: RejectReasonQuestions[] = [
  {
    value: 'lifestyles_dont_match',
    label: t("Our lifestyles don't match"),
  },
  {
    value: 'too_far_away',
    label: t('Surrogate/parents are too far away'),
  },
  {
    value: 'journey_preferences_dont_match',
    label: t("Our surrogacy journey preferences don't match"),
  },
];

const rejectOtherReasonQuestions: RejectReasonQuestions[] = [
  {
    value: 'other',
    label: t('Other'),
  },
];

const validationSchema = Yup.object({
  passing_reason: Yup.string(),
  other_answer: Yup.string(),
});

export const RejectionForm = forwardRef<HTMLButtonElement, RejectionFormProps>(
  ({ onConfirm, onCancel, initialValues }, buttonRef) => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onConfirm}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, isSubmitting }) => (
          <Form className="relative">
            <div className="flex flex-col py-4 px-8 border-b border-grey-forest-200">
              <div>
                <RadioGroup
                  name="passing_reason"
                  options={rejectReasonQuestions}
                  label={t('What is your reason for passing on this request?*')}
                />
                <div className="flex gap-4 items-center h-16">
                  <RadioGroup
                    name="passing_reason"
                    options={rejectOtherReasonQuestions}
                    className="mb-2"
                  />
                  {values.passing_reason === 'other' && (
                    <TextInput
                      name="other_answer"
                      type="text"
                      className="w-full"
                      placeholder={t('Please enter your other answer')}
                    />
                  )}
                </div>
              </div>

              <p className="text-xs leading-6 text-grey-forest-600">
                {t(
                  `* your feedback is used solely to improve our product and the matching process, it will not be shared with the surrogate or anyone outside of Nodal`,
                )}
              </p>
            </div>

            <div className="flex gap-2 justify-end py-3 px-6">
              <Button
                variant="tertiary"
                type="button"
                onClick={onCancel}
                ref={buttonRef}
              >
                {t('Cancel')}
              </Button>
              <ButtonLoading
                variant="primary"
                type="submit"
                loading={isSubmitting}
              >
                {t('Yes, reject')}
              </ButtonLoading>
            </div>
          </Form>
        )}
      </Formik>
    );
  },
);

RejectionForm.displayName = 'RejectionForm';
