import { t } from '@nodal/i18n';
import * as Yup from 'yup';

import type { ProfileData, QuestionData } from '../MatchProfile.interface';
import type { OptionalObjectSchema } from 'yup/lib/object';

const getValidationSchemaByType = <T extends ProfileData>({
  type,
  required,
}: {
  type: QuestionData<T>['type'];
  required: QuestionData<T>['required'];
}): Yup.AnySchema => {
  switch (type) {
    case 'radio':
      return Yup.boolean().nullable().required('This field is required');

    case 'number':
      return required
        ? Yup.number().nullable().required('This field is required')
        : Yup.number().nullable();

    case 'bmi':
      return Yup.object()
        .nullable()
        .shape({
          weight: Yup.number().nullable().required('This field is required'),
          height_in: Yup.number().nullable().required('This field is required'),
          height_ft: Yup.number().nullable().required('This field is required'),
        });

    case 'photos':
      return Yup.array()
        .of(
          Yup.object().shape({
            image: Yup.string(),
          }),
        )
        .notRequired();

    case 'select_multiple':
      return Yup.array()
        .of(Yup.string())
        .nullable()
        .min(1, t('This field is required'))
        .required(t('This field is required'));

    case 'place':
      return Yup.object()
        .nullable()
        .shape({
          line1: required
            ? Yup.string().nullable().required(t('This field is required'))
            : Yup.string().nullable(),
          line2: required
            ? Yup.string().nullable().required(t('This field is required'))
            : Yup.string().nullable(),
          state: Yup.string().nullable(),
          zipcode: Yup.string().nullable(),
          city: Yup.string().nullable(),
          location: required
            ? Yup.array()
                .of(Yup.string())
                .nullable()
                .when('line2', {
                  is: (line2?: string | null) => line2,
                  then: Yup.array()
                    .of(Yup.string())
                    .nullable()
                    .required(t('You must select a location')),
                })
            : Yup.array().of(Yup.string()).nullable(),
        });

    case 'video':
      return Yup.mixed().test(
        'video_url',
        t(
          'Looks like we ran into a problem uploading the video. If the video is over 150MB, we suggest reducing the size and trying again.',
        ),
        // NOTE: The size in bytes was converted using a binary system calculator found at https://www.gbmb.org/megabytes
        (value) => !value?.size || value.size <= 157286400,
      );

    default:
      return required
        ? Yup.string().nullable().required('This field is required')
        : Yup.string().nullable();
  }
};

export const getValidationSchema = <T extends ProfileData>(
  questions: QuestionData<T>[],
): OptionalObjectSchema<Record<string, Yup.AnySchema>> => {
  let formSchema: Record<string, Yup.AnySchema> = {};

  questions.forEach(({ validationSchema, type, value, required }) => {
    if (validationSchema) {
      formSchema = {
        ...formSchema,
        [value]: validationSchema,
      };
    } else if (type) {
      formSchema = {
        ...formSchema,
        [value]: getValidationSchemaByType({ type, required }),
      };
    }
  });

  return Yup.object(formSchema);
};
