import type { FC } from 'react';

import { Disclosure } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { useState } from 'react';

import type { HipaaConsentFormsProps } from './HipaaConsentForms.interface';

export const HipaaConsentForms: FC<HipaaConsentFormsProps> = ({ forms }) => {
  const [openFormId, setOpenFormId] = useState<string>();

  const handleOpenForm = (id: string) => {
    if (id !== openFormId) {
      setOpenFormId(id);
    }
  };

  return (
    <div className="space-y-4 w-full h-full">
      <div className="py-8 px-6 bg-white rounded-lg border border-grey-forest-200">
        <div className="flex flex-col gap-4">
          <h3 className="text-2xl font-semibold leading-8 text-grey-forest-900">
            {t('HIPAA and Consent Form')}
          </h3>
          <p className="text-base font-normal leading-6 text-sand-900">
            {t('Please sign and submit the following forms.')}
          </p>
        </div>
        <div className="flex flex-col h-full">
          {forms.map(({ title, id, signed, render }) => (
            <Disclosure key={id}>
              <div className="flex flex-col gap-4 py-2 px-4 first:mt-6 w-full bg-sage-500 border first:border-b-0 border-grey-forest-500">
                <div className="flex justify-between items-center w-full">
                  <p className="font-medium text-grey-forest-900">{title}</p>

                  {signed ? (
                    <div className="flex items-center w-10 h-10">
                      <CheckCircleIcon className="w-8 h-8 text-forest-500 rounded-full" />
                    </div>
                  ) : (
                    <Disclosure.Button onClick={() => handleOpenForm(id)}>
                      {openFormId !== id && (
                        <Button variant="primary">
                          {t('Review and Sign')}
                        </Button>
                      )}
                    </Disclosure.Button>
                  )}
                </div>

                <div
                  className={openFormId === id && !signed ? 'block' : 'hidden'}
                >
                  {render()}
                </div>
              </div>
            </Disclosure>
          ))}
        </div>
      </div>
    </div>
  );
};
