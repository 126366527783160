import {
  ScreenStatusEnum,
  RegisterRoleEnum,
  IntroductionStageEnum,
  RelationshipStatusEnum,
  RoleFd4Enum,
  SubscriptionStatusEnum,
  InvitationStatusEnum,
  UserRoleEnum,
  MatchProfileReviewStatusEnum,
  EthnicityEnum,
  FacilityTypeEnum,
  BirthControlMethodEnum,
  CheckrScreenCandidateInvitationStatusEnum,
  WeightEnum,
  ThirdPartyProviderEnum,
  DropboxDocumentTypeEnum,
  ReviewStepStatusEnum,
  UserStatusEnum,
  DoctorClinicCountryEnum,
  ParentPassingReasonEnum,
  PayingEntityEnum,
  PaymentStatusEnum,
  PaymentTypeEnum,
  MedicalDocumentDocumentTypeEnum,
  NameEnum,
  OrganizationTypeEnum,
  DataProviderTypeEnum,
  BillingStatusEnum,
  CandidateStepsEnum,
  SentStatusEnum,
  DonorPassingReasonEnum,
  UsingDonorEnum,
  FaxAdapterTypeEnum,
  MedicalReviewStatusEnum,
  TypeEnum,
  DisqualificationReasonEnum,
  TaskStageEnum,
  TaskStatusEnum,
  CompletionTypeEnum,
  SignerRoleEnum,
  IpContractDocumentTypeDocumentTypeEnum,
  PreviousCSectionsEnum,
} from './core';

export const apiEnums = {
  ScreenStatusEnum,
  RegisterRoleEnum,
  IntroductionStageEnum,
  RelationshipStatusEnum,
  RoleFd4Enum,
  SubscriptionStatusEnum,
  InvitationStatusEnum,
  UserRoleEnum,
  MatchProfileReviewStatusEnum,
  EthnicityEnum,
  FacilityTypeEnum,
  BirthControlMethodEnum,
  CheckrScreenCandidateInvitationStatusEnum,
  WeightEnum,
  ThirdPartyProviderEnum,
  DropboxDocumentTypeEnum,
  ReviewStepStatusEnum,
  UserStatusEnum,
  DoctorClinicCountryEnum,
  ParentPassingReasonEnum,
  PayingEntityEnum,
  PaymentStatusEnum,
  PaymentTypeEnum,
  MedicalDocumentDocumentTypeEnum,
  NameEnum,
  OrganizationTypeEnum,
  DataProviderTypeEnum,
  BillingStatusEnum,
  CandidateStepsEnum,
  SentStatusEnum,
  DonorPassingReasonEnum,
  UsingDonorEnum,
  FaxAdapterTypeEnum,
  MedicalReviewStatusEnum,
  TypeEnum,
  DisqualificationReasonEnum,
  TaskStageEnum,
  TaskStatusEnum,
  CompletionTypeEnum,
  SignerRoleEnum,
  IpContractDocumentTypeDocumentTypeEnum,
  PreviousCSectionsEnum,
};
