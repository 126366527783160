import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { useDragAndDrop } from '@nodal/core/hooks/useDragAndDrop';
import {
  EditPhotos,
  useEditPhotos,
  OverlayPhoto,
} from '@nodal/core/flows/MatchProfile';
import { createPortal } from 'react-dom';
import { useUserProfile } from 'hooks/useUserProfile';
import { useUserMePhotos } from '@nodal/core/flows/hooks/useUserMePhotos';

export const EditPhotosConnected = ({ minPhotos = 9 }) => {
  const { profileData } = useUserProfile();
  const { upload, update, remove } = useUserMePhotos();

  const {
    photos,
    editedImageData,
    loadingPhotos,
    onReorder,
    ...photoHandlers
  } = useEditPhotos({
    minPhotos,
    onUpload: upload,
    onUpdate: update,
    onRemove: remove,
    profileData,
  });

  const { sensors, onDragHandlers, draggedId } = useDragAndDrop(onReorder);

  const draggedPhoto = photos.find((item) => item.id === draggedId);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      {...onDragHandlers}
    >
      <SortableContext items={photos} strategy={rectSortingStrategy}>
        <EditPhotos
          photos={photos}
          loadingPhotos={loadingPhotos}
          editedImageData={editedImageData}
          {...photoHandlers}
        />
      </SortableContext>
      {draggedPhoto?.image &&
        createPortal(
          <DragOverlay>
            <OverlayPhoto src={draggedPhoto.image} />
          </DragOverlay>,
          document.body,
        )}
    </DndContext>
  );
};
