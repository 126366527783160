import { FC, useCallback } from 'react';

import { t } from '@nodal/i18n';

import { TasksContainerConnectedProps } from './TasksContainer.interface';
import { staffCompletedTaskToDetails, taskTypeToModalDetails } from './utils';
import { apiEnums, ApiModel, useApiClient } from '@nodal/api';
import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from '@nodal/core/consts/query';
import { Button } from '@nodal/uikit/components/Button';
import { useModal } from '@nodal/uikit/components/Modal';
import { TasksContainer } from './TasksContainer';
import { ConfirmEmailsForm } from './ConfirmEmailsForm';
import { toast } from 'react-toastify';

export const TasksContainerConnected: FC<TasksContainerConnectedProps> = ({
  tasks,
  currentStageTitle,
  disabled,
}) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { open, close } = useModal();

  const taskSubmitMutation = useMutation(
    (request: ApiModel.TasksApiTasksSubmitCreateRequest) =>
      apiClient.api.TasksApi.tasksSubmitCreate(request),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.tasksList),
    },
  );

  const handleSubmitDocumentReviewTask = useCallback(
    async (data: ApiModel.TasksApiTasksSubmitCreateRequest) => {
      if (data.taskContentObject) {
        await taskSubmitMutation.mutateAsync(data);
      }
    },
    [taskSubmitMutation],
  );

  const handleSubmitSupportCallTask = useCallback(
    async (taskId: number, taskType: string) => {
      await taskSubmitMutation.mutateAsync({
        id: taskId,
        taskContentObject: {
          id: taskId,
          task_type: taskType,
          call_scheduling_requested: true,
        },
      });

      close();
    },
    [close, taskSubmitMutation],
  );

  // Different action should be taken after click on each task. For most of them user should be redirected to proper screen
  // For tasks completed by Nodal Staff, user should be presented with a modal
  // Runbook and Prepare for Intro Call tasks should open a document in a new window and proceed
  const handleTaskClick = useCallback(
    (
      taskType: ApiModel.Task['type'],
      id: ApiModel.Task['id'],
      contentObject: ApiModel.Task['content_object'],
      status?: ApiModel.TaskStatusEnum,
    ) => {
      // Change to enum after https://linear.app/nodal-health/issue/NOD-897/be-adjust-schema-for-ip-dashboard
      if (
        taskType === 'ip-contract' ||
        taskType === 'ip-match-agreement' ||
        taskType === 'ip-match-sheet'
      ) {
        const user =
          'document' in contentObject
            ? contentObject.document.signers.find(
                (signer) =>
                  signer.signer_role ===
                    apiEnums.SignerRoleEnum.IntendedParentA ||
                  signer.signer_role === apiEnums.SignerRoleEnum.IntendedParent,
              )
            : undefined;

        const partner =
          'document' in contentObject
            ? contentObject.document.signers.find(
                (signer) =>
                  signer.signer_role ===
                  apiEnums.SignerRoleEnum.IntendedParentB,
              )
            : undefined;

        const documentType =
          'document' in contentObject && contentObject.document.document_type;

        if ((!user?.id || !documentType) && taskType === 'ip-contract') {
          toast.info(
            t(
              'Your contract is being prepared. You will receive an email when it is ready for your signature.',
            ),
          );
          return;
        }

        if (!user?.id) {
          return;
        }

        if (status === apiEnums.TaskStatusEnum.Processing) {
          open({
            title: t('Check your email'),
            modalVariant: 'confirmation',
            render: () => (
              <div className="p-4">
                <p className="text-grey-forest-500">
                  {taskTypeToModalDetails.get(taskType)!.inProgressDescription}
                </p>
                <div className="pt-4">
                  <Button variant="primary" onClick={close}>
                    {t('Ok')}
                  </Button>
                </div>
              </div>
            ),
          });
          return;
        }

        open({
          title: taskTypeToModalDetails.get(taskType)!.title,
          modalVariant: 'confirmation',
          render: () => (
            <ConfirmEmailsForm
              id={id}
              signerId={user?.id}
              partnerSignerId={partner?.id}
              partnerSignerRole={partner?.signer_role}
              signerRole={user?.signer_role}
              taskType={taskType}
            />
          ),
        });
        return;
      }

      if (
        // TODO change to enum after schema adjustments https://linear.app/nodal-health/issue/NOD-897/be-adjust-schema-for-ip-dashboard
        taskType === 'ip-review-runbook' ||
        taskType === 'ip-review-call-preparation'
      ) {
        const data: ApiModel.TasksApiTasksSubmitCreateRequest = {
          id,
          taskContentObject: {
            task_type: taskType,
            id,
            documents_reviewed: true,
          },
        };

        handleSubmitDocumentReviewTask(data);
        return;
      }

      if (taskType === 'ip-support-call' || taskType === 'ip-final-payment') {
        const details = staffCompletedTaskToDetails.get(taskType);
        const callScheduleRequested =
          'call_scheduling_requested' in contentObject &&
          contentObject.call_scheduling_requested;

        if (details) {
          open({
            title: details.title,
            modalVariant: 'confirmation',
            render: () => (
              <div className="p-4">
                <p className="text-grey-forest-500">{details.description}</p>
                <div className="pt-4">
                  <Button
                    onClick={
                      taskType === 'ip-support-call' && !callScheduleRequested
                        ? () => handleSubmitSupportCallTask(id, taskType)
                        : close
                    }
                    variant="primary"
                  >
                    {t('Ok')}
                  </Button>
                </div>
              </div>
            ),
          });

          return;
        }
      }
    },

    [close, handleSubmitDocumentReviewTask, handleSubmitSupportCallTask, open],
  );

  return (
    <TasksContainer
      currentStageTitle={currentStageTitle}
      tasks={tasks}
      handleTaskClick={handleTaskClick}
      disabled={disabled}
    />
  );
};
