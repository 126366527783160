import { useField } from 'formik';
import { useCallback } from 'react';

import { Select } from './Select';

import type { SelectValue, SelectConnectedProps } from './Select.interface';

export const SelectConntected = <T extends string | number>({
  name,
  value,
  onChange,
  ...props
}: SelectConnectedProps<T>) => {
  const [field, meta, { setValue }] = useField<SelectValue<T>>({
    ...props,
    value: value || '',
    name,
  });

  const handleChange = useCallback(
    (newValue: SelectValue<T>) => {
      setValue(newValue);

      if (onChange) {
        onChange(newValue);
      }
    },
    [setValue, onChange],
  );

  return (
    <Select
      {...field}
      {...props}
      name={name}
      onChange={handleChange}
      error={meta.touched && meta.error ? meta.error : undefined}
    />
  );
};
