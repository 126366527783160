import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';

import type { ScreenStep } from './Screening.interface';
import type { ApiModel } from '@nodal/api';
import type { StepProps as Step } from '@nodal/uikit/components/Step';

const screenStatusToStep = new Map<
  ApiModel.ScreenStatusEnum | undefined,
  Pick<Step, 'status' | 'label'>
>([
  [apiEnums.ScreenStatusEnum.Pend, { status: 'pending', label: t('Up Next') }],
  [
    apiEnums.ScreenStatusEnum.Proc,
    { status: 'processing', label: t('In Progress') },
  ],
  [apiEnums.ScreenStatusEnum.Rev, { status: 'pending', label: t('In Review') }],
  [apiEnums.ScreenStatusEnum.App, { status: 'complete', label: t('Complete') }],
  [
    apiEnums.ScreenStatusEnum.Half,
    { status: 'complete', label: t('Complete') },
  ],
  [apiEnums.ScreenStatusEnum.Rej, { status: 'complete', label: t('Complete') }],
]);

export const buildDisplaySteps = (
  screeningSteps: ScreenStep[],
  activeStepIndex?: number,
): Step[] =>
  screeningSteps.map((step: ScreenStep, stepIdx: number) => {
    const stepStatus =
      activeStepIndex === stepIdx
        ? apiEnums.ScreenStatusEnum.Proc
        : step.status;

    return {
      title: step.title || undefined,
      label:
        // NOTE: We would like to show label only for the previous steps and one step after the active one
        stepIdx <= (activeStepIndex ?? 0) + 1
          ? screenStatusToStep.get(stepStatus)?.label
          : undefined,
      status: screenStatusToStep.get(stepStatus)?.status,
      index: stepIdx,
    };
  });
