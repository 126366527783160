import { FC, useMemo } from 'react';
import { useScreening } from '@nodal/core/flows/Screening';
import {
  FormQuestionsConnectedProps,
  FormQuestionsMeta,
} from './FormQuestions.interface';
import {
  FormQuestions,
  FormValues,
  QuestionnaireScreen,
  getDonorQuestions,
  parentQuestions,
  transformDonorSubmitValues,
  getParentSchema,
  getDonorSchema,
} from '@nodal/core/flows/Screening/FormQuestions';
import { useAlertFormQuestions } from '@nodal/core/flows/Screening/FormQuestions/useAlertFormQuestions';
import { apiEnums, ApiModel } from '@nodal/api';
import { t } from '@nodal/i18n';
import { isInRelationship } from '@nodal/core/utils/profile';
import { User } from 'api';
import { useUsersMeRetrieve } from 'api/hooks/useUsersMeRetrieve';
import { Spinner } from '@nodal/uikit/components/Spinner';

export const getFormQuestionsMeta = (
  role: User['role'],
  relationshipStatus?: ApiModel.DonorProfileRelationshipStatus,
): FormQuestionsMeta => {
  const inRelationship = isInRelationship(relationshipStatus);

  switch (role) {
    case apiEnums.UserRoleEnum.Dnr:
      return {
        title: t('Form Questions Surrogate'),
        description: t(
          'Please complete the following questions of the Nodal Surrogate Application. They must be completed in one session and will take around 5 mins to complete.',
        ),
        questions: getDonorQuestions(true),
        validationSchema: getDonorSchema(true),
        submitFormatter: transformDonorSubmitValues,
        isLoading: false,
      };

    case apiEnums.UserRoleEnum.Par:
      return {
        title: t('Form Questions Parents'),
        description: t(
          'Please complete the following questions of the Nodal Intended Parent Application. They must be completed in one session and will take around 2 mins to complete.',
        ),
        questions: inRelationship
          ? parentQuestions
          : parentQuestions.filter((q) => !q.value.includes('partner_')),
        validationSchema: getParentSchema(inRelationship),
        isLoading: relationshipStatus === null,
      };

    case apiEnums.UserRoleEnum.Nap:
      return {
        title: t('Form Questions Navigator'),
        description: t(
          'Please complete the following questions of the Nodal Navigator Application. They must be completed in one session and will take around 2 mins to complete.',
        ),
        questions: inRelationship
          ? parentQuestions
          : parentQuestions.filter((q) => !q.value.includes('partner_')),
        validationSchema: getParentSchema(inRelationship),
        isLoading: relationshipStatus === null,
      };
  }
};

export const FormQuestionsConnected: FC<FormQuestionsConnectedProps> = ({
  role,
}) => {
  const { submit } = useScreening();

  const { data: userData } = useUsersMeRetrieve(
    role === apiEnums.UserRoleEnum.Par || role === apiEnums.UserRoleEnum.Nap,
  );

  const relationshipStatus =
    userData?.data?.profile?.relationship_status || undefined;

  const {
    questions,
    validationSchema,
    title,
    description,
    submitFormatter,
    isLoading,
  } = getFormQuestionsMeta(role, relationshipStatus);

  const { currentStep } = useScreening<FormValues<QuestionnaireScreen>>();

  const initialValues = useMemo<FormValues<QuestionnaireScreen>>(
    // TODO:
    // Temp. ignore this problem, use reduce instead?
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => Object.fromEntries(questions.map((q) => [q.value, q.default])),
    [questions],
  );

  const onSubmit = (data: QuestionnaireScreen) => {
    const submitData = submitFormatter ? submitFormatter(data) : data;
    submit(submitData);
  };

  const { alert } = useAlertFormQuestions(currentStep);

  if (!currentStep || !initialValues || isLoading) {
    return (
      <div className="flex justify-center items-center m-auto mt-10 w-full">
        <Spinner size="large" variant="secondary" />
      </div>
    );
  }

  return (
    <FormQuestions
      questions={questions}
      initialValues={{ ...initialValues, id: currentStep.id }}
      validationSchema={validationSchema}
      alert={alert}
      onSubmit={onSubmit}
      title={title}
      description={description}
    />
  );
};
