import { t } from '@nodal/i18n';
import * as Yup from 'yup';

export const placeSchema = Yup.object().shape({
  line1: Yup.string().nullable().required(t('This field is required')),
  line2: Yup.string().nullable().required(t('This field is required')),
  state: Yup.string().nullable(),
  zipcode: Yup.string().nullable(),
  city: Yup.string().nullable(),
  location: Yup.array()
    .of(Yup.string())
    .nullable()
    .when('line2', {
      is: (line2?: string | null) => line2,
      then: Yup.array()
        .of(Yup.string())
        .nullable()
        .required(t('You must select a location')),
    }),
});
