import { FC } from 'react';
import { t } from '@nodal/i18n';
import { BackgroundCheck } from '@nodal/core/flows/Screening/BackgroundCheck';
import { useBackgroundCheck } from '@nodal/core/flows/Screening/BackgroundCheck/useBackgroundCheck';
import { useUsersMeRetrieve } from 'api/hooks/useUsersMeRetrieve';
import { useScreening } from '@nodal/core/flows/Screening';
import { ApiModel } from '@nodal/api';

const title = t('Background Check');

export const BackgroundCheckConnected: FC = () => {
  const { data: meUser } = useUsersMeRetrieve();
  const { refresh, submit, currentStep } = useScreening();

  // NOTE: partner_email is not available for donors so we need to cast the profile to ParentsProfile
  const { partner_email } = meUser?.data?.profile as ApiModel.ParentsProfile;

  const {
    alert,
    description,
    submitted,
    validationSchema,
    onSubmit,
    emailData,
  } = useBackgroundCheck({
    refresh,
    submit,
    currentStep,
    relationshipStatus: meUser?.data.profile.relationship_status,
    email: meUser?.data?.email,
    partnerEmail: partner_email || undefined,
  });

  if (!emailData) {
    return null;
  }

  return (
    <BackgroundCheck
      title={title}
      description={description}
      initialValues={{ ...emailData }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      alert={alert}
      submitted={submitted}
    />
  );
};
