import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthConfig } from '../auth';

export const setAxiosAuthInterceptors = ({
  axios,
  tokenProvider: {
    getAccessToken,
    getRefreshToken,
    setAccessToken,
    removeTokens,
  },
  callbacks: { logout, refresh },
  noRefresh,
}: AuthConfig) => {
  axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const accessToken = await getAccessToken();

    if (accessToken) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }

    return config;
  });

  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      if (
        // NOTE:
        // Check user access endpoints to make sure we do not end up in a refresh loop,
        // when e.g. expired token provided.
        noRefresh.some((endpoint) => endpoint === error.config?.url)
      ) {
        await removeTokens();
        logout();
      }

      if (
        (error.response?.status === 401 || error.response?.status === 403) &&
        // TODO: Add missing api / backend type for code === 'token_not_valid'
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error.response?.data?.code === 'token_not_valid'
      ) {
        const refreshToken = await getRefreshToken();

        if (typeof refreshToken === 'string') {
          try {
            const newAccessToken = await refresh(refreshToken);
            await setAccessToken(newAccessToken);
            if (error.config) {
              return axios.request(error.config);
            } else {
              await removeTokens();
              logout();
            }
          } catch (e) {
            await removeTokens();
            logout();
          }
        } else if (!refreshToken) {
          logout();
        }
      }

      return Promise.reject(error);
    },
  );
};
