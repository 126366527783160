import React, { createContext, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useApiClient } from '@nodal/api';
import { SubscriptionContextValuesProps } from './SubscriptionFlow.interface';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { SubscriptionFlow } from './SubscriptionFlow';
import { t } from '@nodal/i18n';
import { useAppProtected } from 'app';
import { queryKeys } from '@nodal/core/consts/query';

const SubscriptionContext = createContext<SubscriptionContextValuesProps>({
  subscription: undefined,
  initiatePayment: () => {
    throw new Error('initiatePayment not initialized');
  },
});

export const useSubscriptionContext = () => {
  return useContext(SubscriptionContext);
};

const useInitiatePayment = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const initiatePaymentCreate = useMutation(
    () =>
      apiClient.api.BillingApi.billingStripeSubscriptionInitiatePaymentCreate(),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeys.billingCustomerRetrieve),
    },
  );

  const initiatePayment = async () => {
    try {
      await initiatePaymentCreate.mutateAsync();
      return { success: true, error: undefined };
    } catch (error) {
      return { success: false, error: t('Something went wrong') };
    }
  };

  return {
    initiatePayment,
  };
};

export const SubscriptionProvider = ({ ...props }) => {
  const { billingCustomer } = useAppProtected();
  const { initiatePayment } = useInitiatePayment();

  if (!billingCustomer) return <LoadingScreen />;

  return (
    <SubscriptionContext.Provider
      value={{ subscription: billingCustomer.subscription, initiatePayment }}
    >
      {props.children}
    </SubscriptionContext.Provider>
  );
};

const SubscriptionContextConsumer = () => {
  const { subscription } = useSubscriptionContext();

  return <SubscriptionFlow status={subscription?.status} />;
};

export const SubscriptionFlowConnected = () => {
  return (
    <SubscriptionProvider>
      <SubscriptionContextConsumer />
    </SubscriptionProvider>
  );
};
