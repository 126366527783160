import { t } from '@nodal/i18n';

import { Form, Formik } from 'formik';
import { FC } from 'react';

import { Button } from '@nodal/uikit/components/Button';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { ConfirmEmailsFormProps } from './ConfirmEmailsForm.interface';

export const ConfirmEmailsForm: FC<ConfirmEmailsFormProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  description,
  showPartnerInput = false,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
  >
    <Form className="flex flex-col col-span-6 justify-start items-start w-full h-full">
      <div className="p-4">
        <p className="text-grey-forest-500">{description}</p>
        <p className="pt-4 text-grey-forest-500">
          {t('Please confirm the correct details below.')}
        </p>
        <div className="flex flex-col gap-4 pt-4">
          <TextInput
            label={t('First and Last Name')}
            name="display_name"
            type="text"
          />
          <TextInput label={t('Email')} name="email" type="text" />

          {showPartnerInput && (
            <>
              <TextInput
                label={t("Partner's First and Last Name (if applicable)")}
                name="partner_display_name"
                type="text"
              />
              <TextInput
                label={t("Partner's Email (if applicable)")}
                name="partner_email"
                type="text"
              />
            </>
          )}
        </div>
        <div className="pt-4">
          <Button variant="primary" type="submit">
            {t('Ok')}
          </Button>
        </div>
      </div>
    </Form>
  </Formik>
);
