import { useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { queryKeys } from 'consts/query';

import type {
  ApiModel,
  UserProfilePhotoFile,
  UserProfilePhoto,
} from '@nodal/api';

export const useUserMePhotos = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const addPhotoMutation = useMutation(
    (requestParameters: ApiModel.UserPhotosApiUserPhotosCreateRequest) =>
      apiClient.api.UserPhotosApi.userPhotosCreate(requestParameters),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.usersMeRetrieve),
      onError: () => {
        toast.error(t('Something went wrong with the photo upload'));
      },
    },
  );

  const updatePhotoMutation = useMutation(
    (requestParameters: ApiModel.UserPhotosApiUserPhotosPartialUpdateRequest) =>
      apiClient.api.UserPhotosApi.userPhotosPartialUpdate(requestParameters),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.usersMeRetrieve),
      onError: () => {
        toast.error(t('Something went wrong with the photo update'));
      },
    },
  );

  const removePhotoMutation = useMutation(
    (requestParameters: ApiModel.UserPhotosApiUserPhotosDestroyRequest) =>
      apiClient.api.UserPhotosApi.userPhotosDestroy(requestParameters),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.usersMeRetrieve),
      onError: () => {
        toast.error(t('Something went wrong while removing the photo'));
      },
    },
  );

  const upload = useCallback(
    async (data: UserProfilePhotoFile) => {
      // NOTE: Bug in API schema - image for update photo has File type
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await addPhotoMutation.mutateAsync(data);
    },
    [addPhotoMutation],
  );

  const update = useCallback(
    async (data: Array<UserProfilePhotoFile>) => {
      try {
        await Promise.all(
          // NOTE: Bug in API schema - image for update photo has File type
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data.map((file) => updatePhotoMutation.mutateAsync(file)),
        );

        return { success: true };
      } catch (error) {
        return { success: false, error };
      }
    },
    [updatePhotoMutation],
  );

  const remove = useCallback(
    async (data: UserProfilePhoto) => {
      await removePhotoMutation.mutateAsync(data);
    },
    [removePhotoMutation],
  );

  return {
    upload,
    update,
    remove,
  };
};
