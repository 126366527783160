export const calculateTotal = (
  subtotal: string,
  fees?: string,
  taxes?: string,
) =>
  (Number(subtotal) + Number(fees || 0) + Number(taxes || 0)).toLocaleString(
    'en-US',
    {
      minimumFractionDigits: 2,
    },
  );

export const getFormattedAmount = (amount: number) => {
  const formattedAmount = amount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
  });
  return `$${formattedAmount}`;
};
