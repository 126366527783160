import { FC, Fragment, useMemo } from 'react';
import { NavigationItems } from '@nodal/uikit/components/NavigationItems';
import { Avatar } from '@nodal/uikit/components/Avatar';
import { Menu, Transition } from '@headlessui/react';
import { createInitials, sortPhotosByOrder } from '@nodal/core/utils';
import { MenuItems } from '@nodal/uikit/components/MenuItems';
import { NodalLogo } from 'assets';
import { DesktopNavProps } from './DesktopNav.interface';
import { NotificationCenter } from 'components/TopNav/NotificationCenter';
import { apiEnums } from '@nodal/api';

export const DesktopNav: FC<DesktopNavProps> = ({
  menuItems,
  navigationItems,
  profileData,
}) => {
  const avatarUrl = useMemo(
    () =>
      profileData?.profile_photos?.length
        ? sortPhotosByOrder(profileData.profile_photos).find((photo) => photo)
            ?.image
        : '',
    [profileData?.profile_photos],
  );

  const { first_name, last_name } = profileData?.profile || {};

  const initials = useMemo(
    () =>
      !avatarUrl && first_name && last_name
        ? createInitials(first_name, last_name)
        : '',
    [first_name, last_name, avatarUrl],
  );

  return (
    <div className="mx-auto max-w-7xl">
      <div className="flex relative justify-start items-center h-[var(--height-top-nav)]">
        <img
          className="block w-[100px] h-10"
          src={NodalLogo}
          alt="Nodal Logo"
        />
        <div className="flex justify-between ml-1 w-full md:ml-6">
          <NavigationItems navigationItems={navigationItems} />
          <div className="flex static inset-auto gap-4 items-center pr-0 ml-auto">
            {profileData?.role !== apiEnums.UserRoleEnum.Nap && (
              <NotificationCenter />
            )}

            <Menu as="div" className="block relative">
              <Menu.Button className="flex text-sm rounded-full focus:outline-none">
                <span className="sr-only">Open user menu</span>
                <Avatar
                  avatarUrl={avatarUrl}
                  initials={initials}
                  className="!w-8 !h-8"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 py-1 mt-2 w-56 bg-white rounded-md focus:outline-none ring-1 ring-black/5 shadow-lg origin-top-right">
                  <MenuItems menuItems={menuItems} />
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};
