import { CheckIcon, ClockIcon } from '@heroicons/react/solid';
import { AppProgress, AppProgressProps } from 'components/AppProgress';
import { roleToSteps } from 'components/AppProgress/steps';
import { t } from '@nodal/i18n';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { matchingPaths, paths } from 'consts/paths';
import {
  MatchingInProgressProps,
  MatchingStartProps,
} from './MatchingProgress.interface';
import { apiEnums } from '@nodal/api';

const completeStep: AppProgressProps['status'] = {
  index: 2,
  status: 'in_progress',
};

export const MatchingStart: FC<MatchingStartProps> = ({ role, onNext }) => {
  const { steps } = roleToSteps[role];
  const { title } = steps.find((s) => s.index === completeStep.index) || {};

  const progressBarVisible =
    (completeStep.index === 1 && role === apiEnums.UserRoleEnum.Dnr) ||
    role === apiEnums.UserRoleEnum.Par;

  const description =
    role === apiEnums.UserRoleEnum.Dnr
      ? t(
          "Thank you for taking the time to complete the Nodal application and screening process. Before viewing parent profiles and sending introductions, you'll need to complete a match profile. This is so intended parents can learn more about you and what you want from your surrogacy journey. And don't forget: If applicable, your partner must complete a background check before you can move on to the next step. We loook forward to continuing this process with you !",
        )
      : t(
          'You have successfully completed the Nodal Application! While waiting for approval, complete the Match Profile.',
        );

  return (
    <AppProgress
      steps={steps}
      status={completeStep}
      title={t('Awesome!')}
      description={description}
      button={`Complete ${title}`}
      onNext={onNext}
      progressBarVisible={progressBarVisible}
    />
  );
};

export const MatchingInProgress: FC<MatchingInProgressProps> = ({
  profileReminderMessage,
  description,
  showBackgroundCheckReminder,
  title,
}) => {
  return (
    <div className="flex flex-col grid-cols-8 col-span-10 col-start-2 gap-x-8 justify-center items-center px-4 pb-16 m-auto">
      <ClockIcon className="mb-8 w-12 h-12 text-grey-forest-400" />
      <h2 className="mb-2 text-2xl font-semibold leading-8 text-grey-forest-900">
        {title}
      </h2>
      <p className="text-base font-normal leading-6 text-center text-grey-forest-600">
        {description}
      </p>
      {showBackgroundCheckReminder && (
        <p className="text-base font-normal leading-6 text-center text-grey-forest-600">
          {t(
            "And don't forget: Your partner or household members must complete a background check before you can move forward.",
          )}
        </p>
      )}
      {profileReminderMessage && (
        <p className="text-base font-normal leading-6 text-center text-grey-forest-600">
          {profileReminderMessage}&nbsp;
          <Link
            to={`${paths.matching}/${matchingPaths.profile}`}
            className="font-medium text-forest-500 underline"
          >
            {t('complete your Match Profile.')}
          </Link>
        </p>
      )}
    </div>
  );
};

export const MatchingAcceptedWithoutPayment: FC = () => {
  return (
    <div className="flex flex-col grid-cols-8 col-span-10 col-start-2 gap-x-8 gap-y-6 justify-center items-center px-4 pb-16 m-auto">
      <div className="flex justify-center items-center w-12 h-12 bg-forest-100 rounded-full">
        <CheckIcon className="w-6 h-6 text-forest-300" />
      </div>
      <div className="text-center">
        <h2 className="mb-2 text-2xl font-semibold leading-8 text-grey-forest-900">
          {t('Good news!')}
        </h2>
        <div className="text-base font-normal leading-6 text-center text-grey-forest-600">
          <p>
            {t(
              "We are happy to inform you that you've been approved by Nodal.",
            )}
          </p>
          <p>
            <Link
              to={paths.paymentService}
              className="font-medium text-forest-500"
            >
              {t('Get Started')}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
