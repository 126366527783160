import type { FC } from 'react';

import classNames from 'classnames';

import { FormInputError } from '@uikit/components/FormInputError';

import type { CheckboxProps } from './Checkbox.interface';

export const Checkbox: FC<CheckboxProps> = ({
  id,
  value,
  label,
  error,
  ...props
}) => {
  const checkboxClassName = classNames(
    'w-5 h-5 rounded',
    error
      ? 'border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500'
      : 'text-forest-500 border-gray-300 focus:ring-forest-500',
    props.disabled ? 'bg-beige-50 text-grey-forest-400' : '',
  );
  return (
    <div className="relative">
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            id={id}
            name={id}
            type="checkbox"
            className={checkboxClassName}
            checked={value ? true : false}
            value={value || undefined}
            {...props}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={id} className="font-medium text-gray-900">
            {label}
          </label>
        </div>
      </div>
      {error && id ? <FormInputError error={error} id={id} /> : null}
    </div>
  );
};
