import { forwardRef } from 'react';

import type { AppShellProps } from './AppShell.interface';

export const AppShell = forwardRef<HTMLDivElement, AppShellProps>(
  ({ top, center }, ref) => (
    <div className="flex flex-col w-full min-h-screen bg-beige-50" ref={ref}>
      <div className="fixed top-0 z-20 w-full">{top}</div>
      <div className="flex flex-col flex-1 mt-[var(--height-top-nav)]">
        {center}
      </div>
    </div>
  ),
);

AppShell.displayName = 'AppShell';
