import { loadStripe } from '@stripe/stripe-js';
import { useCallback, useEffect, useState } from 'react';

import { IdentityCheckResult } from './IdentityCheck.interface';

import type { Stripe } from '@stripe/stripe-js';

export const useStripeIdentity = (stripePublishableKey: string) => {
  const [stripe, setStripe] = useState<Stripe | null>();
  const [result, setResult] = useState<IdentityCheckResult>();

  useEffect(() => {
    const stripePromise = loadStripe(stripePublishableKey);
    const fetchStripe = async () => {
      setStripe(await stripePromise);
    };

    fetchStripe();
  }, [stripePublishableKey]);

  const verify = useCallback(
    async (clientSecret: string) => {
      if (!stripe) {
        // stripe.js has not loaded yet
        return;
      }

      const { error } = await stripe.verifyIdentity(clientSecret);
      setResult(
        !error ? IdentityCheckResult.success : IdentityCheckResult.error,
      );
    },
    [stripe],
  );

  return { result, verify };
};
