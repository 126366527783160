import HelloSign from 'hellosign-embedded';
import { useEffect, useRef } from 'react';

export const useEmbeddedSignature = ({
  clientId,
  onSubmit,
  requestUrl,
  requestId,
  signed,
  skipDomainVerification,
}: {
  clientId: string;
  onSubmit: () => Promise<void>;
  requestUrl?: string;
  requestId?: string;
  signed: boolean;
  skipDomainVerification: boolean;
}) => {
  const signatureRef = useRef(null);

  const client = new HelloSign();

  useEffect(() => {
    if (signatureRef?.current && requestUrl && !signed) {
      client.open(requestUrl, {
        clientId,
        container: signatureRef.current,
        allowCancel: false,
        skipDomainVerification,
      });
    }

    return () => client.close();
    // NOTE: to avoid re-rendering - open only on mount or when request url changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestUrl]);

  client.on('sign', async (data) => {
    if (data.signatureId === requestId && !signed) {
      await onSubmit();
    }

    client.close();
  });

  return signatureRef;
};
