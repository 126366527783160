import type { FC } from 'react';

import { PhotographIcon, TrashIcon } from '@heroicons/react/outline';
import { DocumentIcon, UserGroupIcon, UserIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import classNames from 'classnames';

import { ProgressBar } from '@uikit/components/ProgressBar';
import { convertBytesToReadableFileSize } from '@uikit/utils';

import type { UploadedFileListProps } from './UploadedFileList.interface';

const fileTypeMappings = [
  { keyword: 'pdf', label: t('PDF') },
  { keyword: 'document', label: t('DOC') },
];

const renderIconByFileType = (type: string, error?: string) => {
  const iconClassName = error
    ? 'w-14 h-14 text-grey-forest-200'
    : 'w-14 h-14 text-forest-400';

  const { label } =
    fileTypeMappings.find((mapping) => type.includes(mapping.keyword)) || {};

  if (type.includes('image')) {
    return <PhotographIcon className={iconClassName} />;
  } else {
    return (
      <div className="relative">
        <DocumentIcon className={iconClassName} />
        <p className="absolute top-1/2 left-1/2 text-xs font-medium text-white -translate-x-1/2 -translate-y-1/2">
          {label}
        </p>
      </div>
    );
  }
};

const renderUploaderIcon = (uploader: 'user' | 'organization') => {
  const Icon = uploader === 'user' ? UserIcon : UserGroupIcon;

  return <Icon className="w-8 h-8 text-grey-forest-400" />;
};

export const UploadedFileList: FC<UploadedFileListProps> = ({
  files,
  onDelete,
  uploaderIconVisible,
}) => {
  return (
    <div className="flex overflow-auto flex-col gap-4 p-4 w-full min-h-[50px] max-h-[250px]">
      {files?.length ? (
        files.map(
          ({ url, name, uploadProgress, id, size, type, error, uploader }) => (
            <div key={id} className="flex gap-6 items-center">
              {uploader && uploaderIconVisible
                ? renderUploaderIcon(uploader)
                : null}
              <div className="flex gap-2 justify-between items-center w-full">
                {type ? renderIconByFileType(type, error) : null}
                <div className="flex flex-col gap-2 w-full text-sm">
                  <div className="flex justify-between items-center w-full">
                    <a
                      className={classNames(
                        'font-medium',
                        error
                          ? 'text-grey-forest-400'
                          : 'text-grey-forest-900 cursor-pointer',
                      )}
                      href={url}
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      {name}
                    </a>
                    {uploadProgress === 1 && !error && (
                      <div className="w-max">
                        <TrashIcon
                          className="w-6 h-6 text-forest-500 hover:text-forest-300 cursor-pointer"
                          onClick={() => onDelete(id)}
                        />
                      </div>
                    )}
                  </div>

                  {error ? (
                    <p className="text-sm text-red-500">{error}</p>
                  ) : (
                    <>
                      <ProgressBar progress={uploadProgress} />
                      <p className="text-grey-forest-900">
                        {size ? convertBytesToReadableFileSize(size) : null}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          ),
        )
      ) : (
        <p className="text-center text-grey-forest-900">
          {t('There are no uploaded files')}
        </p>
      )}
    </div>
  );
};
