import { apiEnums, useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n/src';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import type { ApiModel } from '@nodal/api';
import type { ConfirmationOptions } from '@nodal/uikit/components/ConfirmationDialog';
import type { UploadedFile } from '@nodal/uikit/components/UploadedFileList';

export type MedicalDocumentsControl = {
  uploadedFiles: UploadedFile[];
  disabled: boolean;
  onUpload: (file: File) => Promise<void>;
  onDelete: (id: number) => Promise<void>;
};

const documentTypeToUploader = new Map<
  ApiModel.MedicalDocumentDocumentTypeEnum,
  'organization' | 'user'
>([
  [apiEnums.MedicalDocumentDocumentTypeEnum.ClinicalRecords, 'organization'],
  [apiEnums.MedicalDocumentDocumentTypeEnum.SurroDoc, 'user'],
]);

const transformDocumentsIntoUploadedFiles = (
  documents: ApiModel.MedicalDocument[],
): UploadedFile[] =>
  documents.map(
    ({
      original_file_name,
      file_size,
      file_type,
      content_url,
      id,
      document_type,
    }) => ({
      name: original_file_name || undefined,
      uploadProgress: 1,
      id: id,
      size: file_size ? Number.parseInt(file_size) : undefined,
      type: file_type || undefined,
      url: content_url || undefined,
      uploader: documentTypeToUploader.get(document_type),
    }),
  );

export const useUploadMedicalDocuments = ({
  documents,
  confirm,
  documentType,
  medicalReviewId,
}: {
  documents?: ApiModel.MedicalDocument[];
  confirm: (options: ConfirmationOptions) => Promise<boolean>;
  documentType: ApiModel.MedicalDocumentDocumentTypeEnum;
  medicalReviewId?: number;
}): MedicalDocumentsControl => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  useEffect(() => {
    if (documents?.length && !uploadedFiles?.length) {
      setUploadedFiles(transformDocumentsIntoUploadedFiles(documents));
    }
  }, [documents, uploadedFiles]);

  const apiClient = useApiClient();

  const uploadDocument = useMutation(
    (
      requestParameters: ApiModel.MedicalReviewsApiMedicalReviewsMedicalDocumentCreateCreateRequest,
    ) =>
      apiClient.api.MedicalReviewsApi.medicalReviewsMedicalDocumentCreateCreate(
        requestParameters,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (e) => {
            setUploadedFiles((prevFiles) =>
              prevFiles.map((file) =>
                file.id === requestParameters.medicalDocumentCreate.id &&
                e.total
                  ? { ...file, uploadProgress: e.loaded / e.total }
                  : file,
              ),
            );
          },
        },
      ),
  );

  const removeDocument = useMutation(
    (
      requestParameters: ApiModel.MedicalReviewsApiMedicalReviewsMedicalDocumentDeleteDestroyRequest,
    ) =>
      apiClient.api.MedicalReviewsApi.medicalReviewsMedicalDocumentDeleteDestroy(
        requestParameters,
      ),
    {
      onError: () => {
        toast.error(t('Something went wrong'));
      },
    },
  );

  const onDeleteFile = useCallback(
    async (id: number) => {
      const confirmed = await confirm({
        title: t('Delete Record'),
        message: t(
          'Are you sure you want to delete this record? This action cannot be undone and will remove it from the clinic records.',
        ),
        variant: 'alert',
      });

      if (confirmed) {
        await removeDocument.mutateAsync({
          id,
        });

        setUploadedFiles((prevFiles) =>
          prevFiles.filter((file) => file.id !== id),
        );
      }
    },
    [confirm, removeDocument],
  );

  const onUploadFile = useCallback(
    async (data: File) => {
      // NOTE: For a better UX, we show the uploaded file before sending the request to the API.
      // This approach avoids delays in rendering the upload progress and provides immediate visual feedback to users.
      // We also assign generated, unique id before receiving a success response from the API,
      // allows us to manage the file effectively during the upload process.
      const fileId = Date.now() + Math.random();

      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        {
          name: data.name,
          size: data.size,
          type: data.type,
          uploadProgress: 0,
          id: fileId,
        },
      ]);

      try {
        const response = await uploadDocument.mutateAsync({
          medicalDocumentCreate: {
            medical_review: medicalReviewId!,
            document_type: documentType,
            // NOTE: BUG in Api Schema - content should be a File type, not string
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            content: data,
            id: fileId,
          },
        });

        const [uploadedFile] = transformDocumentsIntoUploadedFiles([
          response?.data,
        ]);

        setUploadedFiles((prevFiles) =>
          prevFiles.map((file) => (file.id === fileId ? uploadedFile : file)),
        );
      } catch (error) {
        setUploadedFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.id === fileId
              ? { ...file, error: t('Error while uploading the file') }
              : file,
          ),
        );
      }
    },
    [documentType, uploadDocument, medicalReviewId],
  );

  return {
    onUpload: onUploadFile,
    onDelete: onDeleteFile,
    uploadedFiles,
    // NOTE: We disable access to the filedropzone in two cases:
    // -> when the file has not been uploaded yet, otherwise onUploadProgress will not work for many files
    // -> when the medical review section is not available yet (id has not been created for an unregistered candidate)
    disabled:
      !!uploadedFiles.find((file) => file.uploadProgress !== 1) ||
      !medicalReviewId,
  };
};
