import type { FC } from 'react';

import { t } from '@nodal/i18n';
import classNames from 'classnames';
import { format } from 'date-fns';

import { getFormattedAmount } from '../utils';

import { PaymentStatusIcon } from './PaymentStatusIcon';

import type { PaymentStatusCardProps } from './PaymentStatusCard.interface';

const iconMap = {
  active: 'bg-forest-200',
  processing: 'bg-yellow-400',
  failed: 'bg-red-500',
};

const renderMessage = (line1?: string, line2?: string, boldText?: string) => (
  <>
    <span>{line1}</span>
    <br />
    <span>{line2} </span>
    {boldText ? <span className="font-medium">{boldText} </span> : null}
  </>
);

export const PaymentStatusCard: FC<PaymentStatusCardProps> = ({
  status,
  email,
  date,
  amount,
  title,
  messages,
}) => {
  const topBarClassName = classNames('absolute top-0 w-full h-1', {
    [iconMap[status]]: true,
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col col-span-6 col-start-4 gap-6 justify-end items-center w-full ">
        <PaymentStatusIcon status={status} />

        <div className="text-center">
          <h2 className="mb-2 text-2xl font-semibold leading-8 text-grey-forest-900">
            {title}
          </h2>
          <p className="text-base font-normal leading-6 text-grey-forest-600 break-all">
            {messages.map(({ line1, line2, boldText }, idx) => (
              <span key={`${line1}-${idx}`}>
                {renderMessage(line1, line2, boldText)}
              </span>
            ))}
          </p>
        </div>
      </div>
      <div className="flex flex-col col-span-4 col-start-5 gap-6 items-center">
        <div className="grid relative grid-cols-1/3 gap-y-3 items-center p-6 w-full max-w-fit h-fit text-base leading-6 text-left break-all bg-white rounded-lg shadow-md sm:p-8 sm:min-w-450">
          <div className={topBarClassName} />
          {date && (
            <>
              <p className="text-forest-600">{t('Date:')}</p>
              <p className="text-right text-grey-forest-900">
                {format(new Date(date), 'PPPpp')}
              </p>
            </>
          )}
          <p className="text-forest-600">{t('Amount:')}</p>
          <p className="text-right text-grey-forest-900">
            {getFormattedAmount(amount)}
          </p>
          <p className="text-forest-600">{t('Email:')}</p>
          <p className="text-right text-grey-forest-900">{email}</p>
        </div>
      </div>
    </div>
  );
};
