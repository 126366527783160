import type { ChangeEvent, FC } from 'react';

import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useCallback } from 'react';

import { Button } from '@uikit/components/Button/Button';
import { FormInputError } from '@uikit/components/FormInputError';

import type { NumberInputProps } from './NumberInput.interface';

export const NumberInput: FC<NumberInputProps> = ({
  id,
  name,
  value,
  label,
  error,
  onChange,
  className = '',
  ...props
}) => {
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | undefined) => {
      onChange(e && e.target.value !== '' ? Number(e.target.value) : undefined);
    },
    [onChange],
  );

  const increment = useCallback(() => {
    onChange(value || value === 0 ? value + 1 : 0);
  }, [onChange, value]);

  const decrement = useCallback(() => {
    onChange(value || value === 0 ? value - 1 : undefined);
  }, [onChange, value]);

  const inputClassName = classNames(
    'w-full block pr-3 focus:outline-none sm:text-sm text-base rounded-lg input-number mt-0 text-center sm:leading-6 leading-5',
    error
      ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
      : 'shadow-sm focus:ring-forest-500 focus:border-forest-500 border-grey-forest-300',
    props.disabled ? 'bg-beige-50' : '',
  );

  return (
    <div className={`relative ${className}`}>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-grey-forest-700"
      >
        {label}
      </label>
      <div className="flex gap-3 items-end">
        <Button
          className={`flex justify-center items-center px-3 sm:px-3.5 ${
            value
              ? 'bg-grey-forest-100 hover:bg-grey-forest-200 border-grey-forest-300'
              : ''
          }`}
          onClick={decrement}
          type="button"
          variant="tertiary"
          disabled={!value || props.disabled}
        >
          <MinusIcon
            className={`w-3 h-5 sm:h-6 ${
              value ? 'text-grey-forest-500' : 'text-white'
            }`}
          />
        </Button>

        <div className="relative w-14">
          <input
            type="number"
            className={inputClassName}
            id={id}
            name={name}
            value={value || value == 0 ? value : ''}
            onChange={handleOnChange}
            {...props}
          />
        </div>
        <Button
          onClick={increment}
          type="button"
          className="flex justify-center items-center px-3 bg-grey-forest-100 hover:bg-grey-forest-200 border-grey-forest-300 sm:px-3.5"
          variant="tertiary"
          disabled={props.disabled}
        >
          <PlusIcon className="w-3 h-5 text-grey-forest-500 sm:h-6" />
        </Button>
      </div>
      {error && name && <FormInputError error={error} id={name} />}
    </div>
  );
};
