import type { FC } from 'react';

import {
  CheckCircleIcon,
  ShieldExclamationIcon,
  ClockIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';

import type { AlertProps } from './Alert.interface';

const containerMap = {
  error: 'bg-red-50 border border-red-200',
  warning: 'bg-yellow-50 border border-yellow-200',
  success: 'bg-green-50',
};

const iconMap = {
  error: 'text-red-400',
  warning: 'text-yellow-400',
  success: 'text-green-400',
};

const titleMap = {
  error: 'text-red-800',
  warning: 'text-yellow-800',
  success: 'text-green-800',
};

const messageMap = {
  error: 'text-red-700',
  warning: 'text-yellow-700',
  success: 'text-green-700',
};

export const Alert: FC<AlertProps> = ({ title, message, variant }) => {
  const containerClassName = classNames('p-6 rounded-md', {
    [containerMap[variant]]: true,
  });

  const iconClassName = classNames('w-5 h-5', {
    [iconMap[variant]]: true,
  });

  const titleClassName = classNames('text-sm font-medium', {
    [titleMap[variant]]: true,
  });

  const messageClassName = classNames('mt-2 text-sm', {
    [messageMap[variant]]: true,
  });

  return (
    <div className={containerClassName}>
      <div className="flex">
        <div className="shrink-0">
          {variant === 'error' && (
            <ShieldExclamationIcon
              className={iconClassName}
              aria-hidden="true"
            />
          )}
          {variant === 'warning' && (
            <ClockIcon className={iconClassName} aria-hidden="true" />
          )}
          {variant === 'success' && (
            <CheckCircleIcon className={iconClassName} aria-hidden="true" />
          )}
        </div>
        <div className="ml-3">
          <h3 className={titleClassName}>{title}</h3>
          {message && (
            <div className={messageClassName}>
              <div className="space-y-1 list-disc">{message}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
