import React, { FC } from 'react';
import { FavoriteProfileCard } from './FavoriteProfileCard';
import { FavoriteParentsListProps } from './FavoriteParentsList.interface';
import { Tooltip } from '@nodal/uikit/components/Tooltip';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';

export const FavoriteParentsList: FC<FavoriteParentsListProps> = ({
  favorites,
  onRequestIntroduction,
  onFavoriteClick,
  remainingRequests,
}) => (
  <div className="px-4 mx-auto w-full max-w-7xl">
    <div className="flex justify-center items-center p-6 w-full">
      <div className="flex gap-2 items-center p-4 font-medium bg-white rounded-md">
        <div>{t('Remaining requests')}:</div>
        <div className="aspect-square flex justify-center items-center w-7 bg-grey-forest-100 rounded-md sm:w-6">
          <span>{remainingRequests}</span>
        </div>
        {!remainingRequests && (
          <Tooltip
            message={t(
              'You are currently out of available matches, please wait until one of your match requests expire or is no longer active.',
            )}
            position="bottom"
          >
            <ExclamationCircleIcon className="aspect-square m-auto w-5 fill-red-500" />
          </Tooltip>
        )}
      </div>
    </div>

    <div className="grid grid-cols-1 gap-2 justify-items-center items-stretch sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
      {favorites?.map((favoriteParent) => (
        <FavoriteProfileCard
          profileData={favoriteParent}
          profilePhoto={favoriteParent?.profile_photos?.find(
            (photo) => photo.order === 0,
          )}
          key={favoriteParent?.id}
          onRequestIntroduction={onRequestIntroduction}
          onFavoriteClick={onFavoriteClick}
          remainingRequests={remainingRequests}
        />
      ))}
    </div>
  </div>
);
