import type { FC } from 'react';

import { t } from '@nodal/i18n';

import { MailIcon } from 'assets';
import { SupportTeamCardProps } from './SupportTeamCard.interface';
import { Avatar } from '@nodal/uikit/components/Avatar';

export const SupportTeamCard: FC<SupportTeamCardProps> = ({
  email,
  userName,
  initials,
  avatarUrl,
}) => (
  <div className="flex flex-col justify-between p-8 w-full h-full bg-white rounded-tr-3xl rounded-bl-3xl">
    <div>
      <h3 className="text-3xl font-semibold leading-7 text-forest-500">
        {t('Need help?')}
      </h3>
      <p className="pt-2 text-gray-600">
        {t('Nodal support team is here to assist you.')}
      </p>
    </div>

    <div className="flex gap-2 justify-between pt-6 md:items-center">
      <div className="flex gap-4 items-center">
        <Avatar
          avatarUrl={avatarUrl}
          initials={initials}
          className="shrink-0 !w-12 !h-12 lg:!w-[72px] lg:!h-[72px]"
        />

        <div>
          <h4 className="text-xl font-semibold text-forest-500">{userName}</h4>
          <p className="text-gray-600">{t('Intended Parent Manager')}</p>
        </div>
      </div>
      <a
        href={`mailto:${email}`}
        target="_blank"
        className="flex items-center"
        rel="noreferrer"
      >
        <MailIcon />
      </a>
    </div>
  </div>
);
