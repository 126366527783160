import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { PhoneNumberInput } from '@nodal/uikit/components/PhoneNumberInput';
import { PlaceInput } from '@nodal/uikit/components/PlaceInput';
import { TextInput } from '@nodal/uikit/components/TextInput';

// NOTE: The component must be embedded inside Formik
export const ObGynDetailsForm: FC<{
  editable: boolean;
}> = ({ editable }) => {
  return (
    <>
      {/* API returns this as an array, but should be an object
       * There will be only one element in the array, so that's why
       * we use index 0 in name (ob_gyn_details.0)
       */}
      <div className="grid grid-cols-1 gap-x-4 gap-y-10">
        <TextInput
          label={t("Doctor's First Name")}
          name="ob_gyn_details.0.doctor_first_name"
          type="text"
          disabled={!editable}
        />
        <TextInput
          label={t("Doctor's Middle Name (optional)")}
          name="ob_gyn_details.0.doctor_middle_name"
          type="text"
          disabled={!editable}
        />
        <TextInput
          label={t("Doctor's Last Name")}
          name="ob_gyn_details.0.doctor_last_name"
          type="text"
          disabled={!editable}
        />
        <PhoneNumberInput
          label={t("Doctor's Phone Number")}
          name="ob_gyn_details.0.doctor_phone_number"
          placeholder={'(555) 987-6543'}
          type="text"
          className="lg:w-1/2"
          disabled={!editable}
        />
        <TextInput
          label={t("Doctor's Clinic Name")}
          name="ob_gyn_details.0.doctor_clinic_name"
          type="text"
          disabled={!editable}
        />
        <PlaceInput
          name="ob_gyn_details.0.doctor_clinic_address"
          label={t("Doctor's Clinic Address")}
          type="address"
          disabled={!editable}
          placeholder={t('Enter the address and select location')}
        />
      </div>
    </>
  );
};
